.buffer-section-container {
    .buffer-section-content {
        width: 100%;
        .buffer {
            .title {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; 
            }
            .content {
                margin-top: 30px;
                .row {
                display: flex;
                gap: 40px;
                margin-bottom: 40px;
                }
            }
        }
    
        .accumulated-section {
            .content {
                .row {
                    display: flex;
                    gap: 40px;
                    margin-bottom: 40px;
                }
            }
        }
    
        .field {
            .error {
                background: #fbe5f1;
                border-color: #e41e54;
            }
            
            .label {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; 
    
                &.fo-consumption {
                margin-top: 18px;
                }
    
                &.daily-average-benchmark {
                margin-top:  14px;
                }
            }
    
            .value-text {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px; 
    
                margin-top: 50px;
                margin-left: 10px;
    
                width: 300px;
            }
    
            .text {
                &.total {
                    color: var(--independence-grey-dark-n-800-main-text, #242433);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    width: 520px;
                }
            }
    
            .input-field {    
                //hide arrow   
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                     -webkit-appearance: none;
                     margin: 0;
                }
                input[type=number] {
                     -moz-appearance: textfield;
                }

                input {
                    height: 100%;
                    border-radius: 8px;
                    border-color: #D9D9E3;
                }

                &.emergency-buffer-days {
                    margin-top: 20px;
                    width: 284px;
                }
    
                &.daily-average-benchmark {
                    margin-top: 15px !important;
                    width: 284px;
                }
            }
    
            .btn {
                width: 180px;
                height: 48px;
                border-radius: 8px;
                text-transform: capitalize;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
    
                &.edit {
                    background: var(--duke-blue-p-300-main, #0000A1);
                    margin-left: 180px;
                }
    
                &.save {
                    background: var(--duke-blue-p-300-main, #0000A1);
                }
    
                &.button-bg {
                    background-color: #0000a1;
                    color: #fff !important;
                }  
            }
    
            &.btn-container {
                margin-left: auto;
            }

            .not-support-text {
                margin-top: 30px;
                margin-left: 10px;
                font-weight: 500;
                font-size: 15px;
                font-style: italic;
            }

            .ui.input {
                margin-top: 35px;
                height: 46px;
            }
        }
    }
    .hyperlink {
        font-weight: 600;
        color: #3366CC;
        cursor: pointer;
        &:hover{
            color:#0000EE;
        }
    }
}