.confirm-schedule-container {
  .label-header {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #6b6c70;
    margin-bottom: 1rem;
  }

  .label-value {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #202529;
  }

  .confirm-schedule-content {
    .vessel-name {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #b455aa;
      padding: 1rem;
      text-transform: capitalize;
    }

    .card {
      box-shadow: inset 0px -1px 0px #e5e6dc;
      border-radius: 8px;
      margin-bottom: 17px;

      .header {
        background-color: #fbe165;
        padding: 1rem;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        margin: auto 0;

        &.Material-header{
          background-color: #fbe165;
        }

        &.People-header{
          background-color: #c4dfd7;
        }

        &.Marine-header{
          background-color: #aed2f2;
        }
      }

      .table-content {
        table {
          border: none;
          background-color: transparent;
          thead {
            tr {
              th {
                border: none;
                background-color: transparent;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #6b6c70;
              }
            }
          }
          tbody {
            tr {
              td {
                font-size: 12px;
                line-height: 16px;
                color: #202529;

                .location {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 12px;
                  color: #460073;
                  background: #f0f0fa;
                  border-radius: 8px;
                  padding: 5px;
                }

                .arrow-location {
                  color: #460073;
                  margin: auto 0;
                  margin-right: 12px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
