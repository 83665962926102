@mixin padding-top($value) {
  padding-top: #{$value}px;
}

@mixin padding-right($value) {
  padding-right: #{$value}px;
}

@mixin padding-bottom($value) {
  padding-bottom: #{$value}px;
}

@mixin padding-left($value) {
  padding-left: #{$value}px;
}

@mixin padding($value) {
  padding: #{$value}px;
}
