.progressbar-vod {
  margin-bottom: 20px;
  .progressbar-container {
    position: absolute;
    width: 100%;
    height: 8px;
    background-color: #dae3ec;
    border-radius: 10px;
    .progressbar {
      height: 8px;
      background-color: #85a4c1;

      &.seller {
        background-color: #4b7093;
      }
    }

    .progressbar:first-child {
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }
    .progressbar:last-child {
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}
