$border: 1px solid #cccccc;

.card {
	&.shadow {
		border: none !important;
		box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.2);
	}
	&.padded, .padded {
		padding: $rem-16;
	}
	&.double-padded, .double-padded {
		padding: $rem-32;
	}
	&.flex {
		.card-item {
			&:not(:last-child) {
				border-bottom: none;
				border-right: $border;
			}
			flex-grow: 1;
			flex-shrink: 1;
		}
	}
	.card-item {
		&:not(:last-child) {
			border-bottom: $border;
		}
		&:last-child {
			border-bottom-left-radius: $rem-8;
			border-bottom-right-radius: $rem-8;
			overflow: hidden;
		}
	}
}

body {
	.card {
		background-color: white;
		border: $border;
		border-radius: $rem-8;
		box-shadow: none;
		// overflow: hidden;
	}
}