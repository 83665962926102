.modal-main-container {
  &.display-block {
    display: block;
  }

  &.display-none {
    display: none;
  }
}

.modal-notification {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

  .modal-main {
    text-align: left;
    border-radius: 5px;
    position: fixed;
    background: white;
    width: 40%;
    height: auto;
    top: 40%;
    left: 40%;
    transform: translate(-50%, -50%);

    .button {
      float: right;
    }
  }
}

.modal-msg {
  .modal-main {
    z-index: 999;
    text-align: left;
    border-radius: 5px;
    position: fixed;
    background: rgba(91, 189, 114, 1);
    color: #fff;
    width: 21%;
    height: auto;
    top: 8%;
    left: 89%;
    transform: translate(-50%, -50%);
    display: inline-block;
  }
}
