$MuseoSansLight: "Museo Sans 300";
$MuseoSansRegular: "Museo Sans 500";
$MuseoSansMedium: "Museo Sans 700";
$MuseoSansBold: "Museo Sans 900";

//TODO: to be removed after sync
$font-normal: 'Inter', sans-serif;
// $font-semibold: 'Graphik-Medium', sans-serif;
$font-bold: 'MuseoSans_bold', sans-serif;
$font-light: 'MuseoSans_light', sans-serif;
$font-italic: 'MuseoSans_italic', sans-serif;
$font-boldItalic: 'MuseoSans_boldItalic', sans-serif;
$font-size: 1rem;
$font-small: 0.75rem;
