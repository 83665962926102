.weather-alert-container {
    width: 382px;
    height: auto;
    border-radius: 10px;
    background: rgba(7, 41, 72, 0.90);

    // /* Drop Shadow/Medium */
    // box-shadow: 0px 20px 24px 0px rgba(17, 17, 17, 0.06);

    .inner-container {
        display: inline-flex;
        padding: 25px 22px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .title {
            color: var(--Shades-White, #FFF);
            text-align: right;

            /* Paragraph/Paragraph 1/Bold */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px; /* 144.444% */
        }

        .item {
            display: flex;
            flex-direction: row;

            margin-top: 15px;
            margin-bottom: 15px;

            .description {
                text-align: start;
                color: var(--Shades-White, #FFF);

                /* Paragraph/Paragraph 3/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */

                margin-left: 16px;
            }
        }
    }
}