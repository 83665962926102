.ui {
  font-size: 14px;

  &.toggle.checkbox input:focus:checked~.box:before,
  &.toggle.checkbox input:focus:checked~label:before {
    background-color: $color-white  !important;
  }

  &.toggle.checkbox input:checked~.box:before,
  &.toggle.checkbox input:checked~label:before {
    border: 1px solid #2bcf9e !important;
    background-color: $color-white  !important;
  }

  &.toggle.checkbox input:checked~.box:after,
  &.toggle.checkbox input:checked~label:after {
    border: 1px solid #2bcf9e !important;
    background-color: $color-white  !important;
  }

  &.toggle.checkbox .box:before,
  &.toggle.checkbox label:before {
    border: 1px solid $color-black  !important;
    background-color: $color-white  !important;
  }

  &.toggle.checkbox .box:after,
  &.toggle.checkbox label:after {
    border: 1px solid $color-black  !important;
    background-color: $color-white  !important;
  }

  &.toggle.checkbox {
    padding: 4px 0 !important;
    margin: 0 !important;
  }

  &.toggle.checkbox label {
    font-size: $fontsize-12;
  }

  /*Input*/
  &.input {
    width: 100%;
    background-color: $color-white;
    border-radius: 50px;

    &.optimise-sidebar {
      border-radius: 4px;

      &>input {
        border-color: #202529;
      }

      &.optimise-sidebar-footer {
        &>input {
          border-color: #CCC;
        }
      }
    }

    &>input {
      border-radius: inherit;
      padding: 9.5px 14px;
      background-color: inherit;
      text-align: inherit;
      border-color: inherit;
    }

    &.transparent-bg {
      background-color: transparent;
    }

    &.grey-border {
      border-color: $color-grey-border;
    }

    &.error {
      &>input {
        background-color: $color-primary-light;
        border-color: $color-highlight-red;

        &::placeholder {
          color: #9f3a38;
        }
      }
    }

    input[type="number"] {
      padding-right: 0;
    }

    input[type="number"]::-webkit-inner-spin-button {
      height: 40px;
    }
  }

  /*End of Input*/

  /*Header*/

  .header {
    &.header-with-subtitle {
      margin-bottom: $rem-4;
    }

    &+.header-sub {
      font-weight: 600;
      font-size: $fontsize-12;
    }
  }

  /*End of Header*/

  /*Grid*/
  &.grid {
    &>.row {
      padding-top: 0px;
      padding-bottom: $rem-16;

      &:last-child {
        padding-bottom: 0px;
      }

      &+.ui.divider {
        flex-grow: 1;
        margin: 0;
      }

      &>.column {
        padding-left: $rem-8;
        padding-right: $rem-8;

        &.margin-b-16 {
          margin-bottom: $rem-16;
        }

        &.no-margin {
          margin: 0;
        }
      }
    }
  }

  /*End of Grid*/

  /*Button*/
  &.button {
    padding: $rem-12 $rem-24;

    &.no-padding-margin {
      margin: 0;
      padding: 0;
    }

    &.btn-primary {
      background: $color-white;
      font-family: $font-normal;
      font-style: normal;
      font-weight: $fontweight-700;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid $color-primary;
      box-sizing: border-box;
      border-radius: 0;
      padding: 10px 8px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: $color-primary;

      &:hover,
      &:focus {
        border: 1px solid $color-primary;
        background: $color-primary;
        color: $color-white;
      }
    }

    &.btn-primary-dowload {
      background: $color-white;
      font-style: normal;
      font-family: $font-normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      border: 1px solid $color-primary;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 8px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: $color-primary;

      &:hover,
      &:focus {
        border: 1px solid $color-primary;
        background: $color-primary;
        color: $color-white;
      }
    }

    &.no-border {
      display: flex;
      border: none;
    }

    &.btn-primary-outer {
      color: $color-primary;
      background-color: $color-white;
      border: 2px solid $color-primary;
    }

    &.btn-secondary {
      color: $color-white;
      background-color: $color-secondary-dark;
    }

    &.btn-tertiary {
      color: $color-white;
      background-color: $color-secondary-unselected;
    }

    &.link-primary {
      background: transparent;
      padding: 0;
      margin: 0;
      color: #0000A1;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }

    &:disabled {
      background-color: #f5f7fe;
      color: #96968c;
    }

    &.optimise {
      font-family: $font-normal;
      font-style: normal;
      font-weight: $fontweight-700;
      font-size: $fontsize-16;
      line-height: $fontsize-24;
      padding: 12px 32px;
      border-radius: 4px;
      text-transform: none;

      &.primary {
        background-color: #0000A1;
        color: $color-white;
      }

      &.inverted {
        background-color: transparent;
        color: #0000A1;
        border-color: transparent;
        box-shadow: none !important;

        &:hover {
          background-color: #0000A1;
          color: $color-white;
          border-color: #0000A1;
        }
      }

      &:disabled {
        background-color: #f5f7fe;
        color: #96968c;
        // border: none;
      }
    }

    // &:hover{
    //     color: $color-white;
    //     background-color: $color-primary;
    // }

    &.icon {
      &>.icon {
        &:not(.button) {
          opacity: 1;
          margin-left: $rem-4  !important; //use important because semantic ui used important to make sure margin 0
        }

        &.color-primary {
          color: $color-primary;
        }

        &.color-secondary {
          color: $color-secondary-dark;
        }
      }
    }

    &.disabled {
      background-color: $color-white;
      border: 1px solid $color-grey;
      color: $color-grey;
    }
  }

  /*End of Button*/

  /*Selection - Dropdown, */
  &.selection {
    &.dropdown {
      background-color: initial;
      color: $color-black;
      min-width: 200px;
      padding: 8px;
      font-family: $font-normal;
      font-size: $fontsize-12;
      line-height: 16px;

      &.ddl-primary {
        background-color: $color-secondary-dark;
        color: $color-white;
      }

      &.ddl-secondary {
        background-color: $color-white;
      }

      &.error {
        background-color: $color-primary-light;
        border-color: $color-highlight-red;
        color: #9f3a38;
      }

      &.optimise {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #6c696f;

        &.accordion-underdeck-cargo-input {
          .disabled {
            &.item {
              opacity: 0.45 !important;
            }
          }
        }

        i {
          float: right;
        }
      }

      &.cvs-dropdown {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #6c696f;
        background-color: #FFF;

        i {
          float: right;
        }
      }

      &.optimise-sidebar {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        width: 100%;
        min-width: 185px;
        color: #6c696f;
        background-color: #FFF;
        padding: 9.5px 14px;
        border-color: #202529;

        &.error {
          background: #FBE5F1!important;
          border-color: #E41E54;
          color: #9F3A38;
        }

        &>.text {
          width: calc(100% - 18.5px);
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.accordion-underdeck-cargo-input {
          .disabled {
            &.item {
              opacity: 0.45 !important;
            }
          }
        }

        i {
          float: right;
        }
      }
    }
  }

  /*End of Selection*/

  /*Search*/

  .ui.search>.results {
    max-height: 300px;
    overflow-x: auto;
  }

  &.search {
    .prompt {
      border-radius: 50px;
    }

    &.error {
      .input {
        &>input {
          background-color: $color-primary-light;
          border-color: $color-highlight-red;

          &::placeholder {
            color: #9f3a38;
          }
        }
      }
    }
  }

  /*End of Search*/

  /*Segment*/
  &.segment {
    margin: 0 0 50px 0;
    padding: 0;
  }

  &.violet.progress {
    .bar {
      background-color: #702765 !important;
    }
  }

  /*End of Segment*/

  /*Table*/
  &.table {
    .inner-table {
      background-color: $color-primary-superlight;
    }

    th {
      white-space: nowrap;
    }
  }

  &.small.celled.table.editable {
    border-right: none;
    border-bottom: none;
  }

  &.structured {
    &.celled {
      &.table {
        margin: 0;
        padding: 0;
        border-color: $color-grey-border;

        // &.structured {
        //   &.celled {
        //     &table {
        //       tr {
        //         td {
        //           // border-color: $color-grey-border;
        //         }
        //       }
        //     }
        //   }
        // }

        thead {
          tr {
            th {
              vertical-align: middle;
              background-color: transparent;
              color: $color-secondary-dark;
              border-color: $color-grey-border;
            }
          }
        }

        tbody {
          color: $color-black;

          tr {
            td {
              vertical-align: middle;
              border-color: $color-grey-border;
            }
          }
        }

        &.border-less {
          border: none;

          thead {
            border-bottom: 2px solid $color-secondary-dark;

            tr {
              th {
                border: none;
              }
            }
          }

          tr {
            border-bottom: 1px solid $color-secondary;

            &:last-child {
              border-bottom: 0;
            }

            td {
              border: none;
              vertical-align: top;
            }
          }
        }

        &.border-dot {
          border: none;

          thead {
            tr {
              th {
                border: none;
                border-bottom: 2px solid $color-secondary-dark;
                text-align: center;

                &:nth-child(1) {
                  border: none;
                }
              }
            }
          }

          tr {
            &:last-child {
              td {
                border-bottom: 1px solid $color-secondary-light;

                &:nth-child(1) {
                  border: none;
                }
              }
            }

            &.last-row {
              td {
                border-bottom: 1px solid $color-secondary-light;

                &:nth-child(1) {
                  border: none;
                }
              }
            }

            td {
              border: none;
              vertical-align: top;
              border-bottom: 1px dashed $color-secondary-light;
              text-align: center;

              &:nth-child(1) {
                border: none;
              }
            }
          }
        }
      }
    }
  }

  /*End of Table*/

  /*Popup*/
  &.popup {
    padding: 0;

    &.top,
    &.bottom {
      &:before {
        background-color: $color-white;
      }
    }

    &.bg-blue {
      background-color: $color-white;
    }

    &.top::before,
    &.bottom::before {
      display: none;
      // background-color: $color-secondary-dark;
    }

    &.show::before {
      display: unset;
      border: unset;
    }

    &.popup.bg-blue {
      background-color: #004a97;
    }

    .weather-alert-header {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ef5d60;
    }
  }

  /*End of Popup*/

  /*Dimmer*/

  &.dimmer {
    &.border-round {
      border-radius: 5px;
    }
  }

  /*End of Dimmer*/

  &.page {
    &.dimmer {
      background-color: rgba(90, 90, 90, 0.3);
    }
  }

  /*Modal*/

  &.modal {
    &>.header {
      font-family: $font-normal;

      &.with-close {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .close {
          cursor: pointer;
        }
      }
    }

    &.modal-table {
      .left-header {
        width: 80%;
      }

      .right-header {
        flex: 1;
      }

      .header {
        .header-icon {
          display: inline-block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: $rem-8;
          background-color: $color-secondary-dark;
        }
      }

      .content {
        .left-content {
          width: 25%;
        }

        .right-content {
          flex: 1;
        }

        .mitigation-header {
          color: $color-secondary-dark;
          padding-bottom: 5px;
          font-weight: 700;
        }

        .date {
          margin-top: $rem-12;
          margin-bottom: $rem-12;
          color: $color-grey;
          font-size: $fontsize-12;
        }

        .icon-hover {
          opacity: 0.2;
          max-width: none;
        }

        .icon-hover:hover {
          opacity: 1;
        }

        .rate-point {
          display: inline-block;
          height: 11px;
          width: 11px;
          border-radius: 50%;
          margin-right: $rem-8;

          &.High {
            background-color: $color-highlight-red-orange;
          }

          &.Medium {
            background-color: $color-highlight-yellow;
          }

          &.Low {
            background-color: $color-highlight-yellow;
          }
        }
      }
    }

    &.custom-modal-vessel-schedule {
      top: 0;
      right: 0;
      width: 480px;
      height: 100%;
      overflow-y: hidden;
      // margin: 0 !important;

      &.scenario-modelling-gantt-modal {
        // width: 887px;
        width: 900px;
      }

      .left-content {
        flex: 1;
      }

      .right-content {
        margin: auto;
      }

      .large-text-black {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.03em;
        color: #202529;
        padding: 5px 0;
      }

      .medium-large-text-black {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #202529;
        padding: 5px 0;
      }

      .medium-text-black {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #202529;
        padding: 5px 0;
      }

      .small-text-black {
        font-weight: normal;
        font-size: 10px;
        line-height: 16px;
        color: #202529;
        padding: 5px 0;
      }

      .small-text-bold {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #202529;
      }

      .medium-text-grey {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #6b6c70;
        padding: 5px 0;
        margin: auto 0;
      }

      .medium-text-grey-2 {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #6b6c70;
        padding: 5px 0;
      }

      .modal-header {
        padding: 1rem;

        &.Material {
          background-color: #fbe165;
        }

        &.People {
          background-color: #c4dfd7;
        }

        &.Marine {
          background-color: #aed2f2;
        }

        .button-closed {
          background-color: transparent;
          margin: 0;
          padding: 0;
        }
      }

      .modal-sub-header {
        padding: 1rem;
        background-color: #f5f7fe;

        .bar {
          margin-bottom: 1rem;

          .bartext {
            font-size: 11px;

            div:first-child {
              // width: 70%;
              width: 75%;
              display: inline-block;
              text-align: left;
            }

            div:last-child {
              display: inline-block;
              // width: 30%;
              width: 25%;
              text-align: right;
            }
          }

          .progress-content {
            position: relative;

            .benchmark {
              position: absolute;
              width: 3px;
              height: 15px;
              margin-top: -4px;
              background: #d4d4d4;
              margin-left: auto;
              margin-right: auto;
              left: 30%;
              z-index: 1;
            }

            .progress {
              overflow: hidden;
              background-color: #e9ecef;
              position: relative;

              .progress-bar {
                &.green {
                  background-color: #2bcf9e;
                }

                &.red {
                  background-color: #ef5d60;
                }
              }
            }
          }
        }
      }

      .modal-detail-content {
        padding: 1rem;
        padding-bottom: 5rem;
        width: 100%;

        .location-container {
          .status {
            width: 20px;
            border-left: 2px dashed #a4a7b2;
            margin: 1px 10px;

            img {
              left: -12px;
              position: relative;
              background: #fff;
            }
          }

          .location {
            border-bottom: 1px solid #eaeaea;

            .weather-alert {
              background: #fff3f3;
              border-radius: 8px;
              width: 170px;

              .weather-alert-header {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                color: #ef5d60;
                margin: auto 0;
              }

              .weather-alert-text {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
                color: #202529;
              }
            }
          }
        }

        div:last-child {
          .status {
            border-left: 0;
            padding-left: 3px;
          }
        }
      }
    }

    // custom for optimise modal
    .list-table-container {
      .left-content {
        flex: 1;
      }

      .right-content {
        margin: auto;
      }

      .period-picker {
        &.notify-me button {
          min-width: 220px;
        }
      }

      .text {
        font-style: normal;
        color: #202529;

        &.large-text {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
          margin: auto 0;
        }

        &.medium-large-text-blue {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #4a5496;
        }

        &.medium-text {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;

          span {
            font-weight: 600;
          }
        }

        &.medium-small-text {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
        }
      }
    }

    .table .table-head {
      padding: 10px;
    }

    .heading {
      border-bottom: solid 0.1px $color-grey-border;

      .warning-action-title {
        margin-bottom: 10px;
      }
    }

    .content .warning-action .header {
      align-items: center;

      .actions {
        margin-left: auto;
      }
    }

    .modal-header {
      padding: 15px;
      border-bottom: 1px solid #e6e6dc;
      justify-content: space-between;
    }

    .actions .modal-footer {
      padding: 15px;
      border-top: 1px solid #e6e6dc;
      justify-content: center;
    }

    .modal-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.01em;
      color: #000000;
      text-transform: initial;
      display: flex;
      align-items: center;
    }

    .modal-large-title {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }

    .booking-modal-content {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .booking-modal-large-content {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }

    .modal-header .close {
      margin-top: -20px !important;
      padding: 0.5rem;
      font-size: 32px;
      font-weight: normal;
      margin-right: -10px;
    }

    .modal-body {
      padding: 24px;
      max-height: 80vh;
      overflow-y: auto;
    }

    .modal-backdrop {
      width: 100%;
      height: 100%;
    }
  }

  /*End of Modal*/

  /*Pause Icon*/
  i.circular.icon {
    box-shadow: none !important;
  }

  /*End of Pause Icon*/
  /*Rating Star*/

  &.star.rating {
    .active.icon {
      color: #0000A1 !important;
      text-shadow: 0 -1px 0 #0000A1, -1px 0 0 #0000A1, 0 1px 0 #0000A1, 1px 0 0 #0000A1 !important;
    }

    .selected.icon,
    .active.selected.icon {
      color: #0000A1 !important;
    }
  }

  /*End of Rating Star*/
}

.ui.selection.dropdown>.delete.icon,
.ui.selection.dropdown>.dropdown.icon,
.ui.selection.dropdown>.search.icon {
  opacity: 1;
}

.ui.dropdown>.dropdown.icon {
  font-size: 20px;
}

.ui.selection.dropdown>.delete.icon,
.ui.selection.dropdown>.dropdown.icon,
.ui.selection.dropdown>.search.icon {
  line-height: 0;
  margin: 0;
  margin-right: -10px;
  padding: 3px;
}

.ui.input>input {
  border: 1px solid #ccc;
}

.ui.multiple.dropdown {
  .label {
    font-weight: 400;
    background-color: #f2faff;
    color: $color-black;
  }
}

/*Pagination*/
.ui.menu {
  border: 0px;
  box-shadow: none;

  a {
    font-size: 14px;
    font-weight: 500;
  }

  .item:before {
    content: none;
  }
}

.ui.pagination.menu .active.item {
  background-color: #0000A1;
  color: #ffffff;
  border-radius: 4px;
}

/*End of Pagination*/
.ui.accordion.past-va-accd-ep {
  width: 100%;

  .title:not(.ui):hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px 0px;
  }

  .title:not(.ui) {
    height: 60px;
    padding: 20px;
  }
}

.content.active.past-va-content-ep {
  padding-left: 50px !important;
  padding-right: 20px !important;
}

.past-va-tbl-ep {
  .ui.table thead {
    box-shadow: none;
    border-radius: 8px !important;
  }

  .ui.table thead th {
    border-bottom: none;
    background: #f5f7fe !important;
  }

  .ui.table tr td {
    border-top: none;
  }

  .past-va-positive-color {
    background: #fbfcff;
  }
}

.ui.grid>[class*="four column"].row>.column {
  width: 23% !important;
}