#gantt-chart {
  .footer-voyage {
    position: relative;
    width: 100%;
    height: 10vh;
    background-color: #e6dcff;
    cursor: pointer;
    z-index: 2;

    &:hover {
      background-color: #e6dcff;
    }

    @include hover-add-voyage;
  }
}
