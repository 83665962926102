.bar {
  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: "#202529";
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 10px;
    .title {
    }
    .persent {
      // text-align: right;
      // width: 100%;
      margin-right: 10px;
    }
  }
  .progress-content {
    position: relative;
    padding-top: 5px;
    .benchmark {
      position: absolute;
      width: 3px;
      height: 15px;
      margin-top: -4px;
      background: #6b6c70;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
      border-radius: 1px;
    }
    .progress {
      overflow: hidden;
      background-color: #e9ecef;
      position: relative;
      border-radius: 10px;
    }
  }
}
