.voyage-div {
  z-index: 998;
  background-color: $color-white;
  top: 180px;
  position: fixed;
  left: 0px;
  transition: left 0.3s;
  width: 200px;
  padding-top: 0;
  align-items: center;
  padding-left: 16px;
  height: 40px;
  cursor: pointer;



  .title {
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    font-size: $fontsize-14;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000000;
  }
  .header {
    font-size: $fontsize-14;
    margin-bottom: 5px;
    font-family: $font-normal !important;
    font-weight: $fontweight-700;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000000;
  }
  .sub-header {
    font-size: $fontsize-10;
    margin-bottom: 5px;
    font-family: $font-normal !important;
    font-weight: $fontweight-700;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000000;
  }
  .filter {
    .alpha-chevron {
      background: $color-white;
      z-index: 1001;
      top: 110px;
      position: fixed;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 250px;
      transition: left 0.3s;
    }
    .filter-container {
      z-index: 1001;
      background-color: $color-white;
      top: 80px;
      position: fixed;
      width: 250px;
      height: auto;
      max-height: 440px;
      overflow-y: scroll;
      left: 0px;
      transition: left 0.3s;

      &::-webkit-scrollbar {
        width: 4px!important;
        background-color: #ffffff;
      }
      &-webkit-scrollbar-thumb
      {
        background-color: #dfdbdb;
      }
      &-webkit-scrollbar-track
      {
        background-color: #ffffff;
      }


      .filter-content {
        width: 100%;
        height: auto;
        padding: 22px;

        .title {
          font-family: $font-normal !important;
          font-weight: $fontweight-600;
          font-size: $fontsize-14;
          line-height: 16px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          color: #000000;
        }
        .button {
          display: flex;
          justify-content: center;
          width: 200px;
          button {
            width: 100%;
          }
        }

        .bell-badge {
          font-weight: 700;
          text-align: center;
          top: 25px;
          right: 0;
          padding: 5px 0;
        }
        .bell-select-voyage {
          position: absolute;
          right: 21px;
        }

        &-header {
          max-width: fit-content;
          i {
            margin-left: 20px;
          }
        }
      }
      .inner {
        font-family: $font-normal;
        font-size: $fontsize-12;
        line-height: 16px;
      }
      .status-text {
        font-family: $font-normal;
        font-size: $fontsize-12;
        line-height: 16px;
        font-weight: 700;
      }
      .last-updated {
        font-family: $font-normal;
        font-size: $fontsize-10;
        line-height: 16px;
        color: #b1bcca;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
  .chevron:hover {
    background-color: $color-primary;
    fill: $color-white;
  }
  .goTo {
    margin-left: 31px;
    font-size: 18px;
  }
  .title-content {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px 0px;
    border-radius: 8px;
  }
}
.tr.past-va-positive-color {
  background: #fbfcff;
}
.quick-review {
  display: flex;
  p {
    margin-right: 20px;
    span {
      margin: 0 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #6b6c70;
      &.value {
        color: #202529;
      }
      &.null-value {
        font-weight: 400;
        color: #6b6c70;
      }
    }
  }
}
h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #202529;
  margin: 20px 0;
  span {
    font-weight: 400;
  }
}
.incident-time {
  b {
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #202529;
  }
  span {
    font-size: 12px;
    line-height: 21px;
    color: #6b6c70;
    &.dot {
      font-size: 12px;
      margin: 0 5px;
    }
    &.report {
      color: #0000A1;
      font-weight: 600;
      margin-left: 25px;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.report-button {
  color: #0000A1;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
.ui.table {
  border: none;
}
.incident-reports_header {
  border-radius: 8px;
}
.expand-row {
  border: 1px solid #0000A1;
  border-radius: 8px;
  padding: 8px;
}
.incident-report-body {
  background: #fbfcff;
}
.incident-report-table-ep {
  padding: 20px;
}
.reason-card {
  margin-bottom: 2px;
}
