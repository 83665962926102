.mobile-side-button-container {
    align-self: center;
}

.mobile-side-button {
    cursor: pointer;
}

.mobile-side-button-line {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}

