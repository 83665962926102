.deck-container-page {
    .passenger-container-page {
        .demand-input-column {
            .voyage-input-container {
                max-width: 200px;
                input {
                    border-radius: 4px !important;
                }
            }
            .warning-text {
                color: #E74C3B;
            }
        }
    }
}