.vessel-schedule-page {
  // padding-top: 9rem;
  padding-top: 1px;
  padding-left: 165px;
  padding-right: 165px;
  padding-bottom: 200px;

  &.footer-expanded {
    padding-bottom: 350px;
  }

  .vessel-schedule-main-header {
    // top: 15vh;
    width: 100%;

    position: relative;
    position: fixed;
    width: calc(100% - 330px);
    background-color: #fff;
    padding-top: 100px;
    z-index: 998;

    .vessel-schedule-header {
      position: relative;
      margin: 0;
      left: 0;
      right: 0;
      display: flex;
      width: 100%;
      margin-top: 16px;
  
      .sub-text {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #09010e;
      }
  
      .header-text {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #202529;
      }
  
      .header-text-left {
        float: left;
        width: 50%;
      }
  
      .header-text-right {
        float: right;
        width: 50%;
      }
    }    
  }

  .plan-schedule-content {
    padding: 1rem;
    margin-bottom: 17px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #e5e6dc;
    border-radius: 8px;

    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #5963a5;
      padding-bottom: 1rem;
    }

    button {
      background: #f5f7fe;
      border: 1px solid #c4c8df;
      box-sizing: border-box;
      border-radius: 4px;

      div:last-child {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }
    }
  }

  .action-content {
    padding-bottom: 20px;

    .left-content {
      flex: 1;
      margin: auto;
    }

    .right-content {
      margin: auto;
    }

    .general-dropdown-container{
      padding: 1rem 0;
    }

    .weekly-input-container{
      padding: 1rem;
      background: #F5F7FE;
      .dropdown {
        min-width: 210px;
      }
    }

    .optimise-vessel-dropdown {
      a {
        font-size: 12px !important;
        // max-width: 140px;
      }
    }

    .label-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #09010e;
    }

    .week-of-month {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000A1;
      padding: 2px;
      background-color: transparent;
    }

    .button-action {
      background-color: transparent;
      font-weight: 600;
      font-size: 18px;
      border-radius: 0;
      color: #96968c;

      &.selected {
        color: #09010e;
        border-bottom: 3px solid #0000A1;
      }
    }

    .button-download,
    .button-upload {
      background: $color-white;
      border: 1px solid $color-primary;
      box-sizing: border-box;
      letter-spacing: 0.04em;
      color: $color-primary;
      font-style: normal;
      font-weight: $fontweight-700;
      font-size: $fontsize-16;
      line-height: $fontsize-24;
      padding: 12px 32px;
      border-radius: 4px;
      text-transform: none;

      div:last-child {
        margin: auto;
      }
    }

    .daterange-picker .optimise button{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #6c696f;
      padding: 8px 1rem;
      i {
        align-self: baseline;
      }
    }

    .next-button {
      align-self: end;
    }
    .weather-content {
      margin-top: -20px;
      margin-left: 240px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #5C5C7A;
    }
    .weather-content-alert {
      color: #E74C3B;
    }

    .download-schedule-txt {
      color: #5C5C7A;
      font-weight: 700;
    }

    .download-schedule-btn {
      cursor: pointer;
      user-select: none;
      transition: 1ms;
      &:active {
        opacity: 0.8;
      }
    }
  }

  .vessel-schedule-legend {
    padding: 27.5px 17px;
    border-radius: 0.28571429rem;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

    .legend-icon {
      width: 18px;
      height: 17px;
      margin-right: 0.6rem;
    }

    .legend-label {
      margin-right: 1.8rem;
    }
  }

  .published-vessel-schedule-legend {
    position: fixed;
    left: 0px;
    bottom: 72px;
    width: 100%;
    background-color: #fff;

    padding: 27.5px 0px 27.5px 165px;
    border-radius: 0.28571429rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

    &.expanded {
      bottom: 210px;
    }

    .legend-icon {
      width: 18px;
      height: 17px;
      margin-right: 0.6rem;
    }

    .legend-label {
      margin-right: 1.8rem;
    }
  }

  .gantt-table-content {
    // overflow-y: scroll;
    // max-height: 50vh;
    margin-top: 448px;
  }
}

.fixed-vessel-schedule {
  position: fixed;
  width: 100%;
}

.trash-icon {
  background: red;
  border-radius: 50%;
  text-align: center !important;
  line-height: 50px;
  vertical-align: middle !important;
  height: 50px !important;
  width: 50px !important;
  color: white;
}

.check-icon {
  text-align: center !important;
  line-height: 50px;
  vertical-align: middle !important;
  font-size: xxx-large;
  color: green;
}

.warning-icon {
  text-align: center !important;
  line-height: 50px;
  vertical-align: middle !important;
  font-size: xxx-large;
  color: red;
}

.booking-modal-success-publish{

  .content{
    padding: 120px 1.5rem 110px!important;
  }

  .booking-modal-content-success-publish{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
}

.voyage-sidebar-compare-modal {
  border-radius: 10px !important;
  height: 464px;
  width: 652px !important;
  overflow: hidden;

  .header {
    padding: 15px 32px !important;
    font-weight: 500 !important;
    font-size: 26px !important;
    color: #4b7093 !important;
    background: #ebf0f5 !important;
    border-bottom: none !important;
    .header-content {
      align-items: center;
      svg {
        margin-top: 10px;
      }
    }
  }
  .content {
    padding: 30px 40px !important;
    font-size: 16px;
    .list {
      font-size: 16px;
      margin-left: 35px;
    }
  }

  .footer {
    bottom: 0;
    right: 0;
    position: absolute;

    .btn {
      font-weight: 600;
      font-size: 16px;
      border: 1px solid #0000a1;
      padding: 12px 48px;
      span {
        line-height: 22px;
      }
      border-radius: 8px;
    }

    .btn-action2 {
      color: #0000a1 !important;
      background-color: #ffffff;
      &:hover {
        color: white !important;
        background-color: #0000a1;
        svg path {
          stroke: #ffffff;
        }
      }
    }

    .btn-action3 {
      color: #ffffff !important;
      background-color: #0000a1 !important;
    }
  }
}

