.loginpage {
    .logo-container {
        width: 60%;
        height: 100vh;
        background-image: linear-gradient(92.42deg, #F9B262 -90.63%, #0000A1 33.4%, #460073 173.98%);;
        .logo-title {
            color: $color-white;
            width: 100%;
            font-size: 48px;
            font-weight: 700;
        }
    }
    .login-container {
        width: 40%;
        height: 100vh;
        justify-content: center;
        .input-container {
            border-radius: 5px;
        }
        .login-title {
            font-size: 24px;
            font-weight: 700;
        }
        .button-bg {
            background-color: #0000A1 !important;
            color: #fff !important;
        }
        .forgot-password-text {
            color: #0000A1;
            font-weight: 700;
        }
    }
    
}
