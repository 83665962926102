.color-bar {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 300px;
  .toggle-text {
    font-size: 12px;
  }
}

.min-height-100 {
  min-height: 100px;
}

.img-container{
  height: 50px;
  position: relative;
  &>img {
    clip-path: inset(0px 180px 0px 0px);
    transform: rotate(90deg);
    position: absolute !important;
    top: -40px;
    left: 25px;
    width: 230px;
  }
  
}

@media (max-width: 768px) {
  .ui.toggle.checkbox label {
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--independence-grey-dark-n-800-main-text, #242433);
    /* Paragraph/Paragraph 3/Regular */
    font-family: Inter;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
}
