.custom-button {
    background: #FFF !important;
    border-radius: 8px !important;
    border: 1px solid #0000A1 !important;
    color: #0000A1 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    padding: 12px 48px !important;
    line-height: 22px !important;

    &.primary-btn, &:hover {
        background-color: #0000A1 !important;
        color: #FFF !important;
    }
}