.maintenance-log-page {
  padding: 128px 165px 20px;

  .page-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .ui.secondary.pointing.menu {
    border-bottom: none;
    margin-bottom: 34px;
    margin-top: 34px;
    padding: 0 !important;
    .item {
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      color: #bab3be;
      margin-right: 10px;
      &.active {
        border-color: #0000A1;
        color: #000000;
      }
    }
  }

  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  i {
    color: #0000A1;
    cursor: pointer;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    background-color: #ffffff;
    border: 1px solid #0000A1;
    float: right;
    &:hover {
      color: white;
      background-color: #0000A1;
      svg path {
        stroke: #ffffff;
      }
    }

    .add-maintenance {
      height: 38px;
    }
    svg.injected-svg {
      height: 40px;
      width: 25px;
      path {
        stroke-width: 4px;
      }
    }
    .text-log {
      margin-top: 8px;
    }

    .add-maintenance-sub {
      width: 185px;
      height: 25px;
    }
  }

  .justify-between-center {
    justify-content: space-between;
    align-items: center;
  }

  .btn-back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    border: none;
    width: 250px;
    border-radius: 4px;
    background-color: #ffffff;
    width: auto;
    &:disabled {
      background-color: gray;
      color: white;
    }
    &:hover {
      color: #ffffff;
      background-color: #0000A1;
      i {
        color: white;
      }
    }
  }

  .left-content {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    padding-top: 10px;
  }
  
  .sub-title {
    padding-top: 20px;
    .sub-status {
      height: 25px;
    }
    .status-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 14px;
    }
    .status-detail-box {
      border-radius: 8px;
      margin-top: -3px;
      width: 110px;
      .status-detail {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: white;
        margin-top: 1px;
      }
    }
    .comment-detail {
      font-size: 18px;
      line-height: 24px;
      margin-top: -5px;
      position: static;
    }
    .edit-icon {
      color: #0000A1;
    }
  }

  .maintenance-log-btn-action {
    display: flex;
    justify-content: flex-end;
  }

  .dropdown-list {
    padding-top: 30px;
    margin-left: 4px;
    width: 60%;
    .comment-input {
      border-radius: 8px;
      border-style: none !important;
      width: 100%;
      border: 1px solid #202529 !important;
      input {
        padding: 8px 14px !important;
      }
    }
    .action-side {
      margin-top: 24px;
      .btn {
        cursor: pointer;
      }
      .cancel-button {
        color: #0000A1;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-top: 2px;
      }
      .save-button {
        background: #0000A1;
        border-radius: 4px;
        width: 70px;
        .save-text {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: white;
          margin-top: 4px;
        }
      }
    }
    .ui.selection.dropdown {
      display: flex;
      justify-content: space-between;
      .second-dropdown {
        width: 400px;
      }
    }

    .second-col {
      width: 50%;
      margin-left: -65px;
    }
  }
  .mooring-buoy-table-container {
    padding-top: 30px;
    .schedule-maintenance-task {
      padding: 5px 0;
    }
    .image {
      margin-right: 10px;
      width: 25px;
    }

    table {
      border: none;
      border-collapse: collapse;

      thead th {
        background-color: #f0f0fa !important;
        border-left: 1px solid white !important;
      }

      tbody {
        tr:nth-child(n) {
          background-color: #ffffff !important;
        }
        tr:nth-child(2n) {
          background-color: #f5f7fe !important;
        }

        .view-log {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: #0000A1;
          cursor: pointer;
        }
        .row {
          height: 60px;
          &.expand {
            border: 1.5px solid #0000A1;
            border-bottom: none;
          }

          td {
            padding-top: 22px;
          }
        }

        .row-edit {
          &.expand {
            border: 1.5px solid #0000A1;
            border-top: none;
          }

          .content {
            padding: 1rem 6rem;

            .sub-table-content {
              padding: 1rem;
              .medium-large-text-black-bold {
                color: #4a5496;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
              }
              .ui.table {
                background: transparent !important;
              }
              table tr {
                background-color: transparent !important;
                th,
                td {
                  border: none;
                  font-size: 16px;
                }

                th {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 14px;
                  color: #6b6c70;
                  background-color: transparent !important;
                }

                td {
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  color: #000000;
                  border-bottom: 0.5px solid #e0e1e8;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-maintenance-log {
  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 1rem;
  }

  .maintenance-log-period-picker {
    input {
      padding-top: 8.5px !important;
      padding-bottom: 8.5px !important;
    }
    &.show-invalid {
      input {
        border: 3px solid red !important;
        padding-top: 6px !important;
        padding-bottom: 6px !important;
      }
    }
    .date-range-picker-custom {
      min-width: 85%;
      .center {
        justify-content: space-between;
      }
    }
  }

  .maintenance-log-dropdown {
    display: flex !important;
    padding: 8px 8px !important;
    &.show-invalid {
      border: 3px solid red !important;
      padding: 6px 8px !important;
    }
    div.text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 86%;
    }
  }
  .title-modal {
    background-color: #f5f7fe;
    height: 97px;
    justify-content: space-between;
    .left-title {
      padding: 32px 33px 56px;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }
    .right-title {
      text-align: right;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      padding: 40px 33px;
    }
  }
  .schedule-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: #4a5496;
    padding: 40px;
  }
  .btn-action-add {
    margin-top: 25px;
    margin-left: 42%;
    font-weight: 600;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    color: #0000A1;
    background-color: #ffffff;
    border: 1px solid #0000A1;

    &:hover {
      background-color: #0000A1;
      color: white;
      svg {
        path {
          stroke: white;
          fill: white;
        }
      }
    }

    .items-center {
      align-items: center;
      svg {
        width: 30px !important;
        path {
          stroke-width: 4;
        }
      }
    }
    .icon-style {
      height: 20px;
      width: 20px;
    }
  }
  .row-one {
    margin-left: 40px;
  }
  .row-two {
    margin-top: 20px;
    margin-left: 40px;
  }
  .trash-icon-name {
    color: #0000A1;
    width: 22px;
    height: 22px;
    cursor: pointer;
  }
  .flex.mar-top-4.end {
    margin-bottom: 11px;
    margin-right: 10px;
  }
  .line {
    margin-top: 10px;
    border: 0.4px solid gray;
  }
  .style-icon {
    height: 39px;
  }
  .preview-images-dashed {
    border: 1px dashed #a4a7b2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    min-height: 38px;
  }
  .btn-browser {
    color: #0000A1;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    input {
      display: none;
    }
    label {
      cursor: pointer;
    }
  }

  .maintenance-log-period-picker,
  .maintenance-log-dropdown {
    width: 100% !important;

    input,
    .text {
      font-size: 14px;
      line-height: 24px;
      color: #202529 !important;
    }

    i {
      font-size: 25px;
      padding: 0.3rem 0;
      float: right;
    }
  }

  .maintenance-log-period-picker input,
  .maintenance-log-period-picker button,
  .maintenance-log-dropdown {
    border: 1px solid #202529 !important;
  }

  .maintenance-log-period-picker button {
    font-weight: normal;
    padding: 0 1rem;
    height: 43px;
    i {
      margin-right: unset;
    }
  }

  .maintenance-log-input {
    border: 1px solid #202529;
    border-radius: 4px !important;
  }
}
