.carbon-graph-card {
    justify-content: space-between;
    margin: 0 73px;
    margin-top: 30px;

    .date-distance-form {
        .wrapper-background {
            background-color: #f3faff;
            width: 98%;
            height: 320px;
            padding: 30px;
            margin-top: 20px;
            border-radius: 31px;
        }

        .date-distance-title {
            font-size: 34px;
            line-height: 40px;
            color: #0000a1;
            font-weight: 600;
        }

        .date-distance-paragraph {
            font-size: 16px;
            margin-top: 8px;
            line-height: 24px;
        }

        .ui-container {
            margin-top: 28px;

            .select-year-button {
                width: 181px;
                height: 49px;
                background-color: #0000a1;
                color: #ffffff;
                border-radius: 100px;
                font-size: 16px;
                border: 1px solid #0000a1;
                font-weight: 400;
            }

            .custom-date-button {
                width: 181px;
                height: 49px;
                background-color: #eeeeee;
                color: #0000a1;
                border-radius: 100px;
                font-size: 16px;
                margin-left: 8px;
                border: 1px solid #eeeeee;
            }
        }

        .row-one {
            margin-top: 35px;

            .select-year {
                font-size: 16px;
            }

            .distance-travel {
                font-size: 16px;
                margin-left: 223px;
            }
        }

        .row-two {
            margin-top: 10px;
            display: flex;

            .year-input {
                width: 280px;
                border-radius: 10px;
                margin-right: 21px;
                color: #000000;
            }

            .ui-container-two .ui.input {
                background-color: transparent;

                .textbox-wrapper {
                    position: relative;
                    display: inline-block;
                }

                .left-text {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #000000;
                }

                .right-text {
                    position: absolute;
                    right: 35px;
                    top: 50%;
                    transform: translateY(-50%);
                    color: #999;
                }

                .distance-travel-input {
                    border-radius: 10px;
                    width: 280px;
                    margin-right: 21px;
                }
            }

            .btn-view-result {
                width: 132px;
                height: 40px;
                background-color: #0000a1;
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
                border-radius: 10px;
            }
        }
    }

    .inline-container {
        display: flex;
        position: absolute;
        right: 30px;
        top: -70px;
        font-size: 16px;

        .blue-color {
            color: #0000a1;
        }

        .set-date-distance {
            text-align: right;
            margin-bottom: -30px;
        }

        .arrow-right {
            width: 17px;
            height: 8px;
            margin: -2px 10px;
        }

        .arrow-down {
            margin: -4px 10px;
        }
    }

    .table-wrapper {
        background-color: #f4f4f4;
        width: 98%;
        height: 327px;
        padding: 30px;
        margin-top: 40px;
    }

    .blue-color {
        color: #0000a1;
    }

    .year {
        th {
            color: #4d4d4d !important;
            font-weight: 400 !important;
            font-size: 14px !important;
        }
    }

    .title {
        font-weight: 600;
        color: #000000;
    }

    .d {
        width: 50px;
        height: 20px;
        border-radius: 10px;
        background-color: #ff8080;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
    }

    .e {
        width: 50px;
        height: 20px;
        border-radius: 10px;
        background-color: #b30000;
        color: #ffffff;
        text-align: center;
        padding-top: 3px;
    }
}
