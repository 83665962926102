.mainexplorepage {
  .temp-map-container {
    width: 100%;
    height: 100%;
    position: absolute;
    .leaflet-control-zoom {
      right: 30px;
      position: fixed;
      bottom: 400px!important;
      a {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
    @media (max-width: 768px) {
      .leaflet-control-zoom {
        display: flex;
        flex-flow: row;
        bottom: 80px!important;
        right: 30px;
        border: none;
        z-index: 991;
      }
    }

  }

  .action-groups {
    z-index: 997;
    position: fixed;
    top: 100px;
    left: 20px;
  }

  .search-container {
    margin-top: 6rem;
    width: 100%;
    justify-content: center;
    z-index: 998;
  }


  .speed-monitoring {
    z-index: 997;
    background-color: #ffffff;
    top: 180px;
    position: fixed;
    left: 0px;
    transition: left 0.3s;
    width: 200px;
    padding-top: 0;
    align-items: center;
    padding-left: 16px;
    height: 40px;
    cursor: pointer;

    .title {
      font-family: $font-normal !important;
      font-weight: $fontweight-600;
      font-size: $fontsize-14;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #000000;
    }

    .content-align {
      align-items: center;
    }
  }

  .timeline-speed {
    z-index: 997;
    background-color: #ffffff;
    top: 230px;
    position: fixed;
    left: 0px;
    transition: left 0.3s;
    width: 200px;
    padding-top: 0;
    align-items: center;
    padding-left: 16px;
    height: 100px;
    cursor: pointer;

    .title {
      font-family: $font-normal !important;
      font-weight: $fontweight-600;
      font-size: $fontsize-14;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #000000;
    }
  }

  .group-boxes {
    z-index: 998;
    top: 120px;
    right: 20px;
    position: fixed;
    transition: left 0.3s;
    width: auto;
    padding-top: 0;
    padding-left: 0px;
    align-items: center;
    height: 40px;

    display: flex;
    justify-content: start;

    .box-action {
      display: flex;
      width: 100%;
      height: 100%;

      .action {
        background-color: $color-white;
        width: 40px;
        height: 100%;
        margin-right: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
      }

      .action-not-focus {
        background-color: $color-grey-lighter !important;
      }

    }

    
  }

  .timeline-kpi-cover {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 990;
  }

  .ui.selection.dropdown {
    position: relative;

    .icon {
      z-index: 10;
      position: absolute;
      right: 5px;
      cursor: pointer !important;
    }
  }

  .show-advanace-search{
    z-index: 990;
    position: fixed;
    top: 160px;
    transition: left 0.3s;
    width: 840px;
    right: 30px;
    height: 46px;  
    border-radius: 4px;
    padding: 0 8px;
    background-color: #FFFFFF80;   
  }
}

.box-content {
  min-width: 250px;
  &.more {
    min-width: unset;
    .box-container {
      padding: 0 !important;
      margin: 0 !important;
      &::before {
        content: ""!important;
        display: none!important;
      }
      .download-btn{
        background-color: transparent!important;
      }
    }
  }
  .box-container {
    padding: 0 25px;
    text-align: left;
    &::before {
      content: "";
      vertical-align: middle;
      margin: auto;
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: calc(100% - 6px);
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      border: 1px solid;
      border-color: #ccc transparent transparent #ccc;
      background-color: white;
    }
  }
  .ui {
    &.toggle.checkbox input:checked+label:before {
      background-color: $color-primary !important;
    }
  
    &.toggle.checkbox input:checked~.box:before,
    &.toggle.checkbox input:checked~label:before {
      background-color: $color-primary !important;
    }
  
    &.toggle.checkbox input:checked~.box:before,
    &.toggle.checkbox input:checked~label:before {
      background-color: $color-primary !important;
  
    }
  
    &.toggle.checkbox input:focus:checked~.box:before,
    &.toggle.checkbox input:focus:checked~label:before {
      background-color: $color-primary !important;
    }
  
    &.toggle.checkbox .box:before,
    &.toggle.checkbox label:before {
      border: none !important;
      background-color: $color-grey-light !important;
      height: $rem-12;
      width: $rem-30;
      top: $rem-7;
      left: 90%;
    }
  
    &.toggle.checkbox .box:after,
    &.toggle.checkbox label:after {
      border: 1px solid $color-primary !important;
      background-color: $color-white !important;
      height: $rem-16;
      width: $rem-16;
      left: 90%;
      top: $rem-6
    }
  
    &.toggle.checkbox {
      padding: 4px 0 !important;
      margin: 0 !important;
  
      label {
        padding-left: 0 !important;
      }
  
      input:checked~label::after {
        left: 96% !important;
      }
    }
  }
}


@media (max-width: 768px) {
  .mainexplorepage {
    .ui.selection.dropdown {
      width: 100% !important;
    }
  }

  .box-content {
    .box-container {
      &::before { 
        background-color: transparent;
      }
    }
  }
}
