.finalised-itinerary-page {
  .finalised-detail-container {
    background: #ffffff;
    padding: 40px 32px 64px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .button-demand {
    font-family: $font-normal !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
    padding: 16px 32px !important;
    border-radius: 4px !important;

    &.button-bg {
      background-color: #0000A1 !important;
      color: #fff !important;
    }
  }
  .stepper-container {
    margin-bottom: 47px;
  }

  .table-content-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .table-content-sub-title {
    font-size: 12px;
    line-height: 18px;
  }

  .booking-button-container {
    margin-left: auto;
    display: flex;

    .button-icon-container {
      border: 1px solid #0000A1;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 12px;
      cursor: pointer;
      height: 48px;
      width: 48px;
      &:hover {
        background-color: #0000A1;
        svg path {
          fill: #ffffff;
        }
      }
    }
  }

  .finalised-itinerary-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .finalised-itinerary-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .status-container {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    width: 100px;
    height: 20px;
    padding: 3px 18px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: $color-white;

    &.delete {
      background-color: #ef5d60;
    }

    &.success {
      background-color: #2bcf9e;
    }
  }

  .ui.tabular.menu .active.item {
    svg path {
      fill: #0000A1;
    }
    background: #f8f5fd !important;
    color: #0000A1 !important;
    border-color: #fff;
  }
  .ui.tabular.menu .item {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
    color: #bab3be !important;
  }

  .ui.tabular.menu {
    border-bottom: 1px solid #fff !important;
  }

  .deck-table-header {
    padding: 20px !important;
    color: #0000A1 !important;
    background-color: #f8f5fd !important;
    z-index: 0 !important;
  }

  .detail-container {
    width: 100%;
    max-height: 316px;
    height: 100%;
    background-color: #f5f7fe;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 1px solid #0000A1;

    .hide-details-button {
      position: relative;
      left: calc(100% - 55%);
      margin-bottom: 38px;
      font-size: 12px !important;
      padding: 6px 14px !important;
    }

    .hide-button-height {
      top: 3px;
    }

    .view-button-height {
      top: 20px;
    }

    .detail-container-top {
      height: 62%;
    }

    .detail-container-bottom {
      height: 38%;
      .summary-container {
        width: 80%;
      }
      .detail-content-container {
        .detail-title {
          font-weight: 600;
          font-size: 12px;
          line-height: 13px;
        }
        .detail-content {
          font-size: 16px;
          line-height: 140%;
        }
      }
    }

    .schedule-container {
      width: 60%;
      margin-right: 20px;

      .content-label {
        color: #96968c;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        margin-bottom: 10px;
      }

      .content-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }

      .content-sub-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
      }

      .volume-title {
        font-size: 12px;
        line-height: 16px;
        color: #09010e;
      }

      .arrow {
        position: relative;
        width: 147px;
        margin-top: 40px;
        margin-right: 37px;
        height: 0;
        border-bottom: 2px solid #96968c;
      }

      .arrow::after {
        content: '';
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #96968c;
        position: absolute;
        right: -10px;
        top: -4px;
      }
    }

    .info-box {
      background-color: #ffffff;
      padding: 27px 33px 20px 33px;
      justify-content: center;
      margin-left: auto;
      height: 177px;

      &.align-center {
        align-items: center;
        padding: 20px 33px 20px 33px;
      }

      .info-title {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        color: #6b6c70;
      }
      .info-content {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        color: #202529;
      }
    }

    .detail-content-container {
      width: 25%;
      .detail-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 13px;
      }
      .detail-content {
        font-size: 1rem;
        line-height: 140%;
        font-weight: 400;
      }
      .detail-larger-title {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600px;
      }
    }
  }

  .secondary-label {
    color: #7045af;
  }
}

.search-input-field {
  width: 250px !important;
  border-radius: 8px !important;
  margin-top: 15px !important;
  border-style: none !important;
}
