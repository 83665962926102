@import './text.scss';

@each $label, $color in $colors {
  .border-#{$label} {
    border-color: $color !important;
  }
  .border-left-#{$label} {
    border-left-color: $color !important;
  }
  .border-right-#{$label} {
    border-right-color: $color !important;
  }
  .border-top-#{$label} {
    border-top-color: $color !important;
  }
  .border-bottom-#{$label} {
    border-bottom-color: $color !important;
  }
  .border-x-#{$label} {
    border-left-color: $color !important;
    border-right-color: $color !important;
  }
  .border-y-#{$label} {
    border-top-color: $color !important;
    border-bottom-color: $color !important;
	}
	.box-shadow-#{label} {
		box-shadow: 0px 2px 0px $color !important;
	}
}

.border {
  border: 1px solid;
}

.border-top {
  border-top: 1px solid;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-bottom-2 {
  border-bottom: 2px solid;
}

.border-left {
  border-left: 1px solid;
}

.border-right {
  border-right: 1px solid;
}

.border-x {
  border-left: 1px solid;
  border-right: 1px solid;
}

.border-y {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.border-none {
  border: 0px !important;
}

.br-tl, .br-t, .br-l {
  border-top-left-radius: 0.3rem;
}
.br-tr, .br-t, .br-r {
  border-top-right-radius: 0.3rem;
}
.br-bl, .br-b, .br-l {
  border-bottom-left-radius: 0.3rem;
}
.br-br, .br-b, .br-r {
  border-bottom-right-radius: 0.3rem;
}