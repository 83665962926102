.validation-d3-voyage-popup {
    border-radius: 10px !important;
    .popup-title {
        background-color: #FEF2F1;
        padding: 24px 32px;
        gap: 8px;
        border-radius: 10px 10px 0 0;
        font-size: 26px;
        font-weight: 500;

        .validation-success {
            color: #1c7d55;
        }

        .validation-failed {
            color: #C93626;
        }
    }

    .popup-body {
        padding: 27px 30px;
        .validation-status {
            margin-top: 3px;
            .validation-status-txt {
                font-size: 26px;
                font-weight: 500;
            }

            .validation-status-btn-group {

            }
        }

        .validation-list-container {
            margin-top: 30px;
            background-color: #F9F9FB;
            border-radius: 20px;
            padding: 36px 24px;
            .validation-list-context {                
                height: 444px;
                overflow-y: scroll;
                
                ul {
                    display: block;
                    list-style-type: disc;
                    margin-top: 1em;
                    margin-bottom: 1 em;
                    margin-left: 0;
                    margin-right: 0;
                    padding-left: 40px;
                }
                .validation-list-title, .validation-list-item {
                    padding-right: 28px;
                    color: #242433;
                    font-size: 16px;
                    font-weight: 500;
                }

                .validation-list-item {
                    font-weight: 400;
                    margin-top: 30px;

                    .txt-status-passed {
                        color: #409B31;
                    }

                    .txt-status-failed {
                        color: #E74C3B;
                    }
                }
            }
        }
    }

    .popup-footer {
        padding: 37px 40px;
        padding-top: 10px;
    }

    .direction-btn {
        cursor: pointer;
        &.active {
            svg {
                path {
                    fill: #CACAD8;
                }
            }
        }
    }
}