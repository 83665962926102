.custom-slider {
  .slider-header {
    display: inline-flex;
    font-weight: 600;
    position: relative;
    width: 100%;

    .slider-header-detail {
      margin-left: 24px;
      .slider-header-label {
        font-size: 14px;
        line-height: 14px;
        color: #6b6c70;
        margin-bottom: 8px;
      }

      .slider-header-title {
        font-size: 16px;
        line-height: 16px;
        color: #202529;
      }
    }

    .slider-direction {
      width: 100px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: space-between;

      .slider-direction-item {
        display: flex;
        align-items: center;
        font-family: $font-normal !important;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
      }
    }

    .chevron-right {
      transform: rotate(180deg);
    }
  }

  .slider-body {
    padding: 0 16px;
  }

  .slick-dots li {
    margin: 0;

    button:before {
      font-size: 8px !important;
    }
  }

  .slick-dots li.slick-active button:before,
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    color: #0000A1 !important;
  }
}
