.upload-document-component {
  .file-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #242433;
    cursor: pointer;
  }

  .remove {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #5c5c7a;
    cursor: pointer;
  }

  .error-msg {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #e74c3b;
  }
}
