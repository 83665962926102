.cvsppf-container {
    padding: 2rem 0;
    
    .cvsppf-text {
        font-size: 14px;
        line-height: 14px;
        color: #202529;

        &.cvsppf-text-lg {
            font-size: 16px;
            line-height: 16px;
        }

        &.cvsppf-text-bold {
            font-weight: 600;
        }
    }

    .progress-standard-optimise {
        .progress-standard-header {

            .progress-standard-title,
            .progress-standard-percentage {
                font-size: 14px;
                line-height: 14px;
                font-weight: 600;
                color: #202529;
            }
        }
    }


}