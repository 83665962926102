.report-row {
  border-bottom: 1px solid !important;
  .system-span {
    font-size: 14px;
    color: #90682f;
    padding: 2px 8px;

    background: #fff5e6;
    border: 1px solid #fff3cd;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .user-report-span {
    font-size: 14px;
    color: #90682f;
    padding: 2px 8px;

    background: #ffc87c;
    border: 1px solid #fff3cd;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .color-purple {
    color: #0000A1;
  }
  .color-green {
    color: #376e37;
  }
  .color-red {
    color: #a33e3b;
  }
  .color-warning {
    color: #90682f;
  }

  .report-action {
    display: flex;
    justify-content: end;

    .disabled {
      color: #bab3be;
      cursor: default;
    }
  }

  .align-center {
    display: flex;
    align-items: center;

    svg {
      z-index: 2;
    }
  }

  .sub-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #5C5C7A;
    margin-left: 1.75em;
  }

  .first,
  .last,
  .multiple {
    position: relative;
    overflow: hidden;
  }

  .sp-dash {
    .dash-line {
      border-left: 1px dashed #a4a7b2;
      position: absolute;
      height: 30px;
      left: 1.35em;
      z-index: 1;
    }
    & > .dash-line:first-child {
      top: 0;
    }
    & > .dash-line:last-child {
      top: 2.35em;
      height: 500px;
    }
  }

  .only .dash-line {
    display: none;
  }

  .first .dash-line {
    &:first-child {
      display: none;
    }
  }

  .last .dash-line {
    &:last-child {
      display: none;
    }
  }

  .c-bull {
    font-size: 18px;
    margin: 0 8px;
    color: #bab3be;
  }
}

.incident-report-table {
  border: 1px solid #0000A1 !important;
  box-sizing: border-box;
  border-radius: 4px;
  z-index: 2;

  .action-buttons {
    color: #6b6c70;
    display: flex;

    svg:nth-child(n + 2):last-child {
      margin-left: 30px;
    }
  }
}

.user-rating {
  .sp-comment {
    margin-top: 10px;
    margin-right: 50px;
    vertical-align: top;
  }
  .txt-comment {
    border-radius: 4px;
  }
}

.bg-light-blue {
  background-color: #f5f7fe;
}

.p-relative:not(.only):not(.last) {
  position: relative;
  .dash {
    border-left: 1px dashed #a4a7b2;
    position: absolute;
    left: 1.35em;
    height: 15px;
  }
  & > .dash:first-child {
    top: 0;
  }

  & > .dash:last-child {
    bottom: 0;
  }
}
