.vod-voyage-summary-page {
    padding: 165px 100px 100px 100px;

  .download-attachment {
    color: #0000A1;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }

  .voyage-summary-subtitle {
    font-size: 14px;
    font-weight: 600;
    color: #5C5C7A;
  }

    .header-text {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      color: #242433;
    }
  
    .sub-header-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #242433;
    }

    .table-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #4B7093;
      
    }
    .container{
      margin-top: 50px;
      margin-bottom: 120px;
    }

    .back-button {
      width: 135px;
      height: 48px;
    }

    .footer-row {
      background-color: #dae3ec;
      align-items: center;
      padding: 12px;
      height: 48px;
      th {
        font-size: 14px;
      }
    }

    .btn-action {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000a1;
      background: #ffffff;
      border: 1px solid #0000a1;
      border-radius: 8px;
    }
  
    .btn-action:hover {
      color: #ffffff;
      background: #0000a1;
    }
  
    .btn-text {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #0000a1;
    }
  
    .btn-action-primary,
    .btn-action-primary:hover {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      background: #0000a1;
      border: 1px solid #0000a1;
      border-radius: 8px;
    }

    .table-section {
      margin-top: 40px;
    }
  
    .custom-table {
      .title-table {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #5c5c7a;
      }
  
      table {
        thead th {
          background-color: #dae3ec;
          width: 100px;
          height: 45px;
          padding: 16px 12px;
          font-weight: 600;
          line-height: 22px;
          border-bottom: 1px solid #D9D9E3;
          border-radius: 4px 0px 0px 0px;
        }

        tbody {
          tr {
            .seller {
              background-color: #f0f0f4;
            }
            td {
              vertical-align: middle;
              height: 75px;
              font-weight: 400;
              align-items: center;
              padding: 16px 12px;
              gap: 10px;
              width: 158px;
              height: 70px;
            }
          }
        }
      }
    }
  }
