#gantt-chart-content {
  position: relative;
  .background-content {
    position: absolute;
    width: 100%;
    height: -webkit-fill-available;
    .empty-space {
      width: 200px;
    }
    .svg-backgroud-line {
      width: 100%;
    }
  }
  &.create-vessel-schedule {
    .schedule-content {
      border: 4px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
      background-color: #eaeaea;
      &.cross-previous-month {
        border-left: 1px solid #eaeaea;
      }

      &.cross-next-month {
        border-right: 1px solid #eaeaea;
      }

      .header-content {
        padding: 0px 8px;

        div {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        div:first-child {
          margin-left: 0;
          height: 20px;
          width: auto;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
          color: #202529;
        }

        div:last-child {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 12px;
          color: #202529;
        }
      }
      .body-content {
        padding: 0px 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 10px;
        line-height: 16px;
        background-color: #fff;
        height: 70%;
      }
    }
  }
  &.vessel-schedule {
    top: 28px;
    .content-week-view {
      top: -12px;
      padding-top: calc(4em - 12px);
    }
    .content-month-view {
      z-index: 1;
    }

    .empty-space {
      width: 200px;
    }

    .gantt-today-bg-container {
      position: relative;
    }

    .gantt-today-bg {
      // position: relative;
      position: absolute;
      top: 0px;
      bottom: 0px;
      background-color: #f8f5fd;
      padding: 0.5rem 1px;
      margin: -8px 0px;
    }

    .svg-backgroud-line {
      width: 100%;
      padding: 0.5rem 1px;
      margin: -15px auto;
    }
  }

  //======================NEW=====================
  .gantt-body {
    .gantt-chart-menu {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 72px;
      padding: 8px;
      background: #f5f7fe;
      text-transform: uppercase;

      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #202529;
    }
    .gantt-schedule {
      position: relative;
      background-color: transparent;

      &.no-bg {
        background-color: transparent;
      }

      .schedule-error-period-bg {
        position: absolute;
        height: 100%;
        background-color: #f8d7da;
        opacity: 0.7;
      }

      .schedule-content {
        position: absolute;
        height: 72px;
        cursor: pointer;        

        &.Material {          
          z-index: 2;

          .chart-body-content {
            border: 3px solid #FFF3B3;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: #FFF3B3;
          }     
        }

        &.People {          
          z-index: 2;

          .chart-body-content {
            border: 3px solid #C0E2E8;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: #C0E2E8;
          }
        }

        &.Marine {         
          z-index: 2;

          .chart-body-content {
            border: 3px solid #C4DCF8;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: #C4DCF8;
          }
        }

        &.Standby {         
          z-index: 2;

          .chart-body-content {
            border: 3px solid #e9a85efd;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: #e9a85efd;
          }
        }

        &.voyage-overlap-error {
          background-color: #f7c1bb;
          border: 4px solid #f7c1bb;
          border-bottom: 1px solid #f7c1bb;
        }

        &.voyage-overlap-replaced {
          background-color: #d9d9e3;
          border: 4px solid #d9d9e3;
          border-bottom: 1px solid #d9d9e3;
        }

        &.vessel-unavailable-error {
          border: 4px solid #f8d7da;
          border-bottom: 1px solid #f8d7da;
          background-color: #f8d7da;

          &.cross-previous-month {
            border-left: 1px solid #f8d7da;
          }

          &.cross-next-month {
            border-right: 1px solid #f8d7da;
          }
        }

        &.Voyage {
          .hover-plus {
            visibility: hidden;
          }
        }

        &.Voyage :hover {
          border: 4px solid #e6dcff;
          border-bottom: 1px solid #e6dcff;
          background-color: #e6dcff;

          &.cross-previous-month {
            border-left: 1px solid #e6dcff;
          }

          &.cross-next-month {
            border-right: 1px solid #e6dcff;
          }

          .hover-plus {
            @include hover-add-voyage;
            width: 100%;
            height: 100%;
            border: none;

            img {
              border: none;
            }

            /*.image {
              content: url('../../../../../../assets/images/plus_sign.svg')
            }*/
          }
        }

        &.read-only {
          cursor: unset;
        }

        &.Maintenance {
          // cursor: unset; // temporary hidden first
          cursor: pointer;
          background-color: #ffd9d9;
          border: 4px solid #ffd9d9;
          border-bottom: 1px solid #ffd9d9;

          z-index: 3;

          &.type-general-maintenance,
          &.type-general {
            background-color: #4a5496;
            border: 4px solid #4a5496;
            border-bottom: 1px solid #4a5496;

            span {
              color: #fff;
            }
          }

          &.type-osvis-gl-maintenance,
          &.type-osvis-gl {
            background-color: #c4c8df;
            border: 4px solid #c4c8df;
            border-bottom: 1px solid #c4c8df;
          }

          &.type-class-survey {
            background-color: #ebecf3;
            border: 4px solid #ebecf3;
            border-bottom: 1px solid #ebecf3;

            // &.maint-overlap-with-voyage{
            //   opacity: 0.8;
            // }
          }

          &.maint-overlap-with-voyage {
            opacity: 0.8;
          }

          span {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-weight: 600;
            font-size: 10px;
            line-height: 12px;
            color: #202529;
            padding: 0 8px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .header-content {
          padding: 0px 8px;
          padding-left: 3.5px;
          width: fit-content;
          max-width: 100%;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          height: 26px;

          img {
            height: 16px;
            width: 16px;
          }

          &.Material {
            background-color: #FFF3B3;
          }

          &.Marine {
            background-color: #C4DCF8;
          }

          &.People {
            background-color: #C0E2E8;
          }

          &.Standby {
            background-color: #e9a85efd;
          }

          .voyage_id {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            height: 20px;
            width: auto;
            font-size: 10px;
            line-height: 12px;
            color: #202529;
            text-align: left;
          }

          .voyage_id:first-child {
            flex: 1;
          }
        }

        .body-content {
          padding: 0px 8px;
          margin: 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 12px;
          line-height: 16px;
          background-color: #fff;

          .left-content {
            margin-top: 2px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: auto;
          }

          &.Material {
            border-left: 1px solid #D9D9E3;

            &.no-border {
              border-left: none;
              border-radius: 4px;
            }
            
            &.schedule-content-first {
              border-left: none;
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
            }

            &.schedule-content-last {
              border-right: none;
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
            }
          }

          &.People {
            border-left: 1px solid #c4dfd7;

            &.no-border {
              border-left: none;
              border-radius: 4px;
            }

            &.schedule-content-first {
              border-left: none;
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
            }

            &.schedule-content-last {
              border-right: none;
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
            }
          }

          &.Marine {
            border-left: 1px solid #aed2f2;

            &.no-border {
              border-left: none;
              border-radius: 4px;
            }

            &.schedule-content-first {
              border-left: none;
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
            }

            &.schedule-content-last {
              border-right: none;
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
            }
          }

          &.Standby {
            border-left: 1px solid #70889e;

            &.no-border {
              border-left: none;
              border-radius: 4px;
            }

            &.schedule-content-first {
              border-left: none;
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
            }

            &.schedule-content-last {
              border-right: none;
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
            }
          }

          &.voyage-overlap-error {
            border-right: 1px solid #f7c1bb;
          }

          &.voyage-overlap-replaced {
            border-right: 1px solid #d9d9e3;
          }

          &.vessel-unavailable-error {
            border-right: 1px solid #f8d7da;
          }

          &.outline-green {
            border: 2px solid #409B31 !important;
            color: #409B31 !important;
          }

          &.weather-alert {
            border: 2px solid #ef5d60;
            color: #ef5d60;
          }         
        }
      }
    }
  }

  .double-row{
    height:150px
  }

  .vessel-title {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 202px;
    height: 72px;
    // padding: 8px;
    background: #f5f7fe;

    margin-right: 1rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #6c696f;
    // text-transform: capitalize;
    overflow: hidden;

    &.error {
      background: #fee7e7;
    }

    .vessel-title-href {
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #202529;

      &:hover {
        color: #0000a1;
      }

      .vessel-title-href-filler {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    span {
      font-weight: normal;
      font-size: 10px;
      margin: 0 5px;
    }
  }
}

.popup-content {
  border-radius: 10px !important;
  opacity: 0.90 !important;
}

.red-overlay {
  border: 2px solid #ef5d60 !important;
  color: #ef5d60 !important;
}

.weather-alert-popup {
  border-radius: 10px;
  background: rgba(7, 41, 72, 0.90);
  box-shadow: 0px 20px 24px 0px rgba(17, 17, 17, 0.06)
}

.vessel-breakdown-tooltip {
  max-width: 334px !important;
  background-color: #3b5773e6 !important;
  border-radius: 10px !important;
  top: 25px !important;
  border: none !important;
  .vessel-tooltip {
    padding: 16px;
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #fff;
  }
  &::before {
    width: 10px !important;
    height: 10px !important;
    bottom: -5px !important;
    box-shadow: none !important;
    background: conic-gradient(
      at 50% 50%,
      transparent 45deg,
      #3b5773e6 0,
      #3b5773e6 225deg,
      transparent 0
    ) !important;
  }

  &.broken-down {
    top: 0 !important;
  }
}
