.notifications-page-wrapper {
  padding: 165px 100px 100px 100px;
  background: #F9F9FB;
  min-height: 1000px;

  .header-text {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #242433;
  }

  .notification-menu-container {
    display:block;
    padding: 0;

    
    .notification-title {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: #5C5C7A;
      &.unread {
        font-weight: 600;
        color: #242433;
      }
      .mobile-notification-title {
        font-family: Inter;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #242433;
        font-weight: 400;
        &.unread {
          font-weight: 700;
        }
      }
      .mobile-notification-sub-title {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #242433;
      }
    }

    .notification-subtitle {
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      color: #5C5C7A;
      &.unread {
        color: #242433;
      }
      .btn-see {
        font-weight: bold;
        color: #0000A1;
      }
    }

    .mobile-subtitile-color {
      color: #4B7093;
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }
    .mobile-subtitile-btn {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: right;
    }
    .mobile-subtitile-date {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #5C5C7A;
    }

    .notification-time {
      color: #5C5C7A;
      font-size: 12px;
      text-align: left;
    }
    }

  .notification-list-body {
    background: #ffffff;
  }
}

.notification-itemline {
  padding: 20px;
}


.notification-itemwrapper {
  .notification-item-action {
    display: none;
  }
}

@media (min-width: 768px) { 
  .notification-itemwrapper:hover {
    background: #F0F0FA;
    cursor: pointer;
    
    .notification-item-action {
      display: block;
      .notification-item-action_flag{
        margin: 20px 20px 0 0;
      }
    }
  }
}

.notification-nomsg {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  margin: 50px 0;
}

.notification-list2 {
  width: 100%;

  .notification-header-cover {
    margin: 40px 0;
    
    .toggle-section {
      font-size: 16px;
      display: flex;
      align-items: center;

      .search-input-field {
        border-radius: 4px !important;
        height: 50px;
        margin-top: 0px !important;
        width: 65% !important;
        align-self: center;
      }

      .toggle-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 15%;
      }

      .ui.toggle.checkbox label{
        z-index: 0;
      }
      .ui.toggle.checkbox label:before {
        background-color: #ccc !important;
        border: none !important;
      }
      .ui.toggle.checkbox label:after {
        border: none !important;
      }

      .ui.checked.toggle.checkbox label:before {
        background-color: #0000a1 !important;
        border: none !important;
      }
      .ui.checked.toggle.checkbox label:after {
        border: none !important;
      }

      .toggle-divider {
        color: #ccc;
        font-size: 30px;
        margin: 0 20px;
      }

      .btn-wrapper {
        width: 20%;
        text-align: right;
      }
      .btn-actions{
        border-radius: 8px;
        padding: 12px 48px;
        font-size: 16px;
        background-color: #fff;
        color: #0000A1;
        border: solid 1px #0000A1;
      }
      .btn-actions:hover{
        background-color: #0000A1;
        color: #fff;
      }
    }
  }

  .ui.secondary.pointing.menu {
    margin-bottom: 34px;
    border-bottom: 4px solid #e8e8ee;
  }

  .ui.secondary.pointing.menu .item {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: #bab3be;
    padding: 15px 50px;
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 4px solid #242433;
    color: #000000;
    margin-bottom: -4px;
  }

  .mobile-tab-wrapper {
    margin-top: -30px;
  }
}


.notifications-page-footer {
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  .ui.pagination.menu {
    background: transparent;
  }
  .ui.pagination.menu .item {
    border-radius: 50%;
  }
  .ui.pagination.menu .active.item {
    border-radius: 50%;
    background-color: #242433;
    font-weight: bold;
  }
}

@media (max-width: 768px) { 
  .notifications-page-wrapper {
    padding: 150px 50px 20px 50px;
    background: #F9F9FB;
    min-height: auto;
    .header-text {
      font-family: Inter;
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #242433;

    }
  }
  .notification-list2 {
    .notification-header-cover {
      .toggle-section {
        display: block;

        .search-input-field {
          border-radius: 8px !important;
          height: 50px;
          margin-top: -30px !important;
          margin-bottom: 10px;
          width: 100% !important;
          align-self: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          text-align: left;
        }

        .toggle-div {
          display: flex;
          justify-content:flex-end;
          width: 100%;
        }
        .toggle-divider {
          font-family: Inter;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.0144em;
          text-align: left;
          color: #242433;
        }
      }
    }
  }
  .notifications-page-footer {
    margin-bottom: 50px;
    .active_item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      width: 26px;
      background-color: #242433;
      color: white;
      border-radius: 50%;
      font-family: Inter;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;

    }
    .mobile-pagination-component {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .left-arrow {
        cursor: pointer;
        margin-right: 10px;
        color: #5C5C7A
      }
      .right-arrow {
        cursor: pointer;
        color: #5C5C7A
      }
    }
  }
  .notification-itemwrapper {
    background: #FFFFFF;
    cursor: pointer;
    .notification-item-action {
      display: block;
      .notification-item-action_flag{
        margin: 20px 20px 0 0;
      }
    }
  }
}