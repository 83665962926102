.vod-scoreboard-board {
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  font-size: 14px;
  line-height: 21px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  .close {
    cursor: pointer;
  }
  .table-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-right: 4px;
    padding: 8px 16px;
    background-color: #eaeaea;
    border-radius: 4px;

    font-weight: 600;
    z-index: 1 !important;
  }
  .table-cell {
    word-break: break-all;
    &.first {
      width: 13%;
    }
    &.second {
      width: 30%;
    }
    &.third {
      width: 37%;
    }
    &.fourth {
      width: 20%;
    }
  }
  .table-body {
    max-height: 380px;
    overflow-y: auto;
    position: relative;
    padding-right: 4px;

    /* width */
    &::-webkit-scrollbar {
      position: absolute;
      width: 4px;
      left: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #c4c4c4;
    }
  }
  .table-row {
    display: flex;
    align-items: center;
    margin: 8px 0;
    padding: 8px 16px;
    border: 1px solid #f0f0fa;
    border-radius: 4px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.highlighted {
      font-weight: 600;
      background-color: #f0f0fa;
      border-left: 4px solid #0000A1;
    }
  }
}
