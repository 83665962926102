.reservation-card-content {
  border-radius: 8px;
  box-sizing: border-box;

  &.Material {
    border-left: 8px solid #fbe86a;
  }

  &.Passenger {
    border-left: 8px solid #c0e2e8;
    .voyage-input-container{
      .warning-input {
        input{
          border: solid 1px red;
        }
      }
    }
  }
  &.edited {
    .voyage-card-header {
      background-color: #daeef1;
      border: 1.5px solid #daeef1;
    }
  }

  &.voyage-card-material {
    border-left: 8px solid #fbe86a;
    &.edited {
      .voyage-card-header {
        background-color: #fff3b3;
        border: 1.5px solid #fff3b3;
      }
    }
  }
  .highlighted {
    &.Material-highlighted {
      background-color: #fff3b3;
    }

    &.Passengers-highlighted {
      background-color: #daeef1;
    }
  }

  .reservation-card-top {
    cursor: pointer;
    background: #eaeaea;
    border-radius: 0px 8px 0px 0px;
    position: relative;
    align-items: center;

    .tag-group {
      font-weight: 600;
    }

    .reservation-label {
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      padding: 16px;
      color: #0000a1;
      .special-request-tag {
        border: 1px solid #DAE3EC;
        border-radius: 10px;
        margin-left: 16px;
        background-color: #FFF;
        padding: 4px 12px;
        font-size: 14px;
        color: #4B7093;
        font-weight: bold;
      }
      .label-value {
        font-weight: 600;
      }

      svg {
        height: 24px;
        width: 24px;
      }

      path {
        fill: #0000a1;
      }

      .visable-dot {
        margin-top: -23px;
        margin-left: -11px;
      }
    }
    .new-color {
      color: unset;
    }

    .red {
      color: #c93626;
    }
  }

  .reservation-card-bottom {
    padding: 2rem 1.8rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 8px 0px;
    cursor: pointer;

    .voyage-label {
      color: #0000a1;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
    }

    .reservation-details-container {
      width: 100%;

      .arrow {
        position: relative;
        margin-top: 37px;
        height: 0;
        border-bottom: 4px dashed #6b6c70;
      }

      .arrow::after {
        content: ">";
        font-size: 40px;
        font-weight: 600;
        color: #6b6c70;
        position: absolute;
        right: -3px;
        top: -19px;
      }

      .arrow.active::after {
        content: "";
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 10px solid #fff;
        position: absolute;
        right: -10px;
        top: -4px;
      }

      .reservation-sub-details {
        background-color: #f9f9fb;
        padding: 1rem 2rem;
        width: 100%;
        gap: 10rem;
      }

      .updated-inform {
        display: flex;
        gap: 5px;
        align-items: center;
        color: #1C7D55;
      }
    }

    .status-section {
      justify-content: space-between;
      .btn-ghost {
        border: none !important;
      }
      .download-icon {
        cursor: pointer;
        height: 24px;
        width: 24px;
        right: 24px;
        top: 8px;
        margin: auto;
      }

      .add-booking-details-btn {
        display: flex;
        gap: 8px;
        .icon {
          height: 15px;
        }
      }

      .add-booking-details-btn {
        display: flex;
        gap: 8px;
        .icon {
          height: 15px;
        }
      }

      .reservation-status-container {
        align-items: center;
        flex: 1;

        &.delete {
          svg path {
            stroke: #c93626;
            fill: #c93626;
          }
        }

        &.ready {
          svg path {
            stroke: #4b7093;
            fill: #4b7093;
          }
        }

        &.warning {
          svg path {
            stroke: #f9b262;
            fill: #f9b262;
          }
        }

        &.disabled {
          svg path {
            stroke: #9e9eb7;
            fill: #9e9eb7;
          }
        }
        
        &.confirmed {
          svg path {
            stroke: #1C7D55;
            fill: #1C7D55;
          }
        }

        .reservation-status {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;

          &.delete {
            color: #c93626;
          }

          &.ready {
            color: #4b7093;
          }

          &.warning {
            color: #f9b262;
          }

          &.confirmed {
            color: #1C7D55;
          }

          &.disabled {
            color: #9e9eb7;
          }
          &-expired {
            color: #C93626;
          }
        }
      }

      .reservation-secondary-status {
        margin: 0.5rem 0 0 2.7rem;
        color: #242433;
        // font-weight: 400;
        // font-size: 12px;
        // line-height: 18px;
      }
    }

    .deck-container-page {
      gap: 4px;
      display: flex;
      flex-direction: column;

      .btn-not-hover{
        &:hover{
          svg path{
            stroke: initial !important;
          }
        }
      }
      .edit-deck-properties {
        .content-right {
          width: 22%;
          margin-left: auto;
          justify-content: end!important;
          .btn-action {
            background: none;
            border: none;
            padding: 0;
          }
        }
      }
      .edit-deck-container{
        background: #f9f9fb;
        &:hover {
          background: #e6dcff;
          .add-load-content {
            background: #e6dcff !important;
          }
        }
        .content-right {
          width: 22%;
          justify-content: end;
        }
        .btn-action {
          background: none;
          border: none;
          padding: 0;
        }
        &:nth-child(even) {
          margin-top: 4px;
        }
        /* Saving cost */
      }

      .saving-info {
        .saving-title {
          color: #5c5c7a;
          margin-bottom: 5px;
        }
        .fact-value {
          font-size: 16px;
          font-weight: 600;
          color: #242433;
          margin-right: 5px;
        }
        .old-value {
          font-size: 14px;
          color: #5c5c7a;
        }
        .saving {
          color: #4b7093;
          font-weight: 600;
          margin-top: 5px;
        }
      }
      .input-side-label {
        font-size: 16px;
        line-height: 12px;
        font-weight: 600;
        color: #242433;
        margin: auto;
      }
      .dropdown-side-label {
        font-weight: 600;
        line-height: 12px;
        font-size: 14px;
        margin: auto;
        color: #5c5c7a;
      }
      .text-color-grey {
        color: #5c5c7a;
        font-weight: 400;
      }

      div.text-color-grey {
        margin-bottom: 10px;
      }

      .add-load-content {
        background: #f5f7fe;
        &.edit-deck-properties {
          background: #f9f9fb;
        }
        .payload-tab-input {
          width: 80%;
          border-radius: 4px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          margin-right: 0px;
        }

        .warning-input {
          border: solid 1px red;
        }

        .warning {
          color: red;
          margin-top: 10px;
        }

        .payload-tab-ddl {
          width: 62px;
          display: flex;
          justify-content: space-between;
          background-color: white;
          border: 1px solid #ccc;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px solid #fff;
        }
        .demand-deck-category {
          justify-content: space-between;
          width: 20%;
          height: 38px;
          background-color: #fff;
          font-size: 14px;
          display: flex;
          align-items: center;
        }
        .view-calculation-deck {
          font-size: 12px;
          font-weight: 500;
          color: #686887;
        }
        .available-capacity {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #4b7093;
        }
      }
      .btn-edit-voyage {
        text-align: right;
        padding-right: 0;
        &.btn-add {
          .btn-action {
            &:hover {
              background-color: #0000a1 !important;
              color: #fff !important;
            }
            &.btn-not-hover{
              &:hover{
                background: #000!important;
                svg path{
                  stroke: initial !important;
                }
              }
            }
          }
        }
        button {
          border: 1px solid #0000a1;
          border-radius: 8px;
          color: #0000a1 !important;
          padding: 12px 48px;
          font-weight: 600;
          font-size: 16px;
          text-transform: capitalize;
          background: none;
        }
        .button-bg {
          background-color: #0000a1;
          color: #fff !important;
        }  
        .btn-action {
          &.button-bg-none {
            border: none;
          }
        }
      }

      .reservation-status-container {
        align-items: center;
        flex: 1;

        &.delete {
          svg path {
            stroke: #c93626;
            fill: #c93626;
          }
        }

        &.ready {
          svg path {
            stroke: #4b7093;
            fill: #4b7093;
          }
        }

        &.warning {
          svg path {
            stroke: #f9b262;
            fill: #f9b262;
          }
        }
        &.disabled {
          svg path {
            stroke: #9e9eb7;
            fill: #9e9eb7;
          }
        }
        &.confirmed {
          svg path {
            stroke: #1C7D55;
            fill: #1C7D55;
          }
        }

        .reservation-status {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;

          &.delete {
            color: #c93626;
          }

          &.ready {
            color: #4b7093;
          }

          &.warning {
            color: #f9b262;
          }

          &.disabled {
            color: #9e9eb7;
          }

          &.confirmed {
            color: #1C7D55;
          }
        }
      }
    }
  }
}

.voyage-information {
  margin-top: 48px;
  margin-bottom: 24px;
  .view-details {
    font-size: 16px;
    color: #D0460B;
    font-weight: 600;
    cursor: pointer;
    margin-right: 30px;
  }
  .voyage-information-content {
    color: #5C5C7A;
    span {
      font-weight: bold;
      color: #242433;
      margin-right: 0 !important;
      .arrow-right-icon {
        margin: 0 8px;
      }
    }
  }
}