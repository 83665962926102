.support-page {
  padding-top: 9rem;
  padding-left: 165px;
  padding-right: 165px;
  padding-bottom: 100px;

  .left-content {
    flex: 1;
  }

  .right-content {
    margin: auto;
  }

  .large-text {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #202529;
  }

  .medium-text-bold {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #202529;
  }

  .medium-small-text-bold {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #202529;
  }

  .medium-small-text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #202529;
  }

  .medium-small-text-bold-blue {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #4a5496;
  }

  .medium-small-text-bold-purple {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .small-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #202529;
  }

  .small-text-bold-red {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ef5d60;
  }

  .input-search {
    background: #ffffff;
    border: 1px solid #c4c8df;
    border-right: 0;
    box-sizing: border-box;
    border-radius: 4px 0px 0px 4px;
    width: 100%;
  }

  .button-clear-search {
    background: transparent;
    border-top: 1px solid #c4c8df;
    border-bottom: 1px solid #c4c8df;
    border-radius: 0;
    padding: 0;
    margin: 0;
    i {
      margin: 0 !important;
    }
  }

  .button-search {
    background: #0000A1;
    border-radius: 0px 4px 4px 0px;
    i {
      color: #ffffff;
      margin: 0 !important;
    }
  }

  .category-detail-content {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &.expand {
      background: #f8f5fd;
    }

    .expand-collapse-icon {
      color: #0000A1;
      cursor: pointer;
    }

    .answer-content {
      ul {
        padding-left: 3rem;
        margin-top: 1rem;
        list-style-type: disc;
      }

      p:last-child {
        margin-top: 1rem;
      }

      .bolder {
        font-weight: 600;
      }
    }
  }

  .more-info-content {
    background: #f8f5fd;
    border-radius: 4px;
  }
}
