.cvs-marine-content-container {

    &.cvs-marine-content-container-new-screen {
        width: 100%;
        min-height: calc(100vh - 360px);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .mar-auto {
        margin: auto;
    }

    .cvs-marine-content-input-page-container {
        padding: 2rem 3rem;
    }

    .cvs-mcip-body {
        padding-left: 2rem;
    }

    .cvs-mcip-text {
        margin-bottom: 5px;

        &.cvs-mcip-text-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #202529;
            text-transform: capitalize;
        }

        &.cvs-mcip-text-label {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #6b6c70;
            margin-bottom: 5px;
        }
    }

    .cvs-mcip-radio {
        label {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #202529;
        }
    }

    .cvs-mcip-input {
        min-height: 43px;

        &:not(.period-picker) {
            border-radius: 4px;
            border: 1px solid #ccc;
        }

        &.cvs-mcip-long-input {
            width: 370px !important;
            min-width: 220px;
        }

        &.cvs-mcip-max-input {
            width: 100% !important;
        }

        .date-range-picker-custom {
            width: -webkit-fill-available;
            min-height: 43px;

            .flex {
                display: contents;

                span {
                    float: left;
                }

                .icon {
                    float: right;
                }
            }
        }
    }




}