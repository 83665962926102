.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

// main axis
.flex-main-end {
  justify-content: flex-end;
}

.flex-main-space-between {
  justify-content: space-between;
}

// cross axis
.flex-cross-center {
  align-items: center;
}

.flex-cross-end {
  align-items: flex-end;
}
