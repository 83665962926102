.top-card-cmp {
    display: flex;
    justify-content: space-between;
    margin: 0 73px;
    align-items: center;

    .left-side {
    }
    .title {
        font-weight: 700;
        font-size: 72px;
        color: #0000a1;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .paragraph {
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 14px;
        margin-top: 14px;
        width: 550px;
        line-height: 24px;
    }

    .inline-container {
        display: flex;

        .made-by {
            font-weight: 700;
            font-size: 16px;
            margin-top: 20px;
        }

        .stear-logo {
            margin-top: 17px;
            margin-left: 10px;
            width: 60px;
            height: 19px;
        }
    }

    .right-side {
        .vessel {
            width: 486px;
            height: 265px;
        }
    }
}
