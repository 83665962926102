.new-ui-card-content-container {
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 100%;
    .card {
      display: flex;
      width: 100%;
      height: 302px;
      border-color: #f0f0fa;
      cursor: pointer;
  
      .card-detail-container {
        width: 100%;
        // .left-content {
        //     flex: 1;
        //   }
      
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 58px;
            // background-color: #f0f0fa;
            border-radius: 8px 8px 0px 0px;
            border-top: 1.5px solid var(--independence-grey-light-n-20, #F0F0F4);
            border-right: 1.5px solid var(--independence-grey-light-n-20, #F0F0F4);
            background: var(--independence-grey-light-n-10, #F9F9FB);

            .title {
                color: var(--duke-blue-p-300-main, #0000A1);
                /* Paragraph/Paragraph 1/Medium */
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 26px; /* 144.444% */

                margin-top: 16px;
                margin-left: 24px;
            }

            .nav-arrow {
                margin-top: 16px;
                margin-right: 24px;
            }
          }
      
          .body {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 244px;
    
            .icon-container {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                width: 63px;
    
                // .cross-line {
                //     position: relative;
                //     z-index: 1 !important;
                // }
    
                .box {
                    position: absolute;
                    z-index: 2 !important;
                }
            }
    
            .content {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
          }
      
          .medium-text-black {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 2/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
      
          .medium-text-black-bold {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #202529;
          }
      
          .medium-large-text-black-bold {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Heading/Heading 5/Medium */
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 127.273% */
          }
      
          .medium-text-grey {
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: #6b6c70;
          }
      
          .medium-text-grey-bold {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
          }
    
          .line {
            width: 487px;
          }

          .time {
            color: var(--independence-grey-medium-n-200, #686887);
            /* Heading/Heading 5/Medium */
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 127.273% */
          }
      }
    }
    .card:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;
      border-radius: 0px 0px 8px 8px;
      .nav-arrow svg path {
        stroke: #0000A1;
        stroke-width: 2;
      }
    }
  }

@media (max-width: 768px) { 
    .new-ui-card-content-container {
        flex-direction: column;
        gap: 12px;
        width: 100%;

        .card {
            width: 100%;
            height: 217px;

            .card-detail-container {
                .header {
                    height: 40px;
                    margin: 0px;

                    .title {
                        color: var(--duke-blue-p-300-main, #0000A1);
                        /* Caption/Medium */
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 18px; /* 150% */

                        margin: 15px;
                    }

                    .nav-arrow {
                      margin-top: 4px;
                      margin-right: 10px;
                    }
                }

                .body {
                    height: 178px;

                    .icon-container {
                      width: 60px;
                    }

                    .content {
                      width: 100%;
                    }
                    
                    .medium-large-text-black-bold {
                        color: var(--independence-grey-dark-n-800-main-text, #242433);
                        /* Paragraph/Paragraph 3/Medium */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px; /* 157.143% */
                    }

                    .medium-text-black {
                        color: var(--independence-grey-dark-n-800-main-text, #242433);
                        /* Caption/Regular */
                        font-family: Inter;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 150% */
                    }

                    .time {
                        color: var(--independence-grey-medium-n-200, #686887);
                        /* Paragraph/Paragraph 3/Medium */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px; /* 157.143% */
                    }

                    .medium-text-grey-bold {
                        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                        /* Mobile 10pt/Regular */
                        font-family: Inter;
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px; /* 180% */
                    }

                    .line {
                        width: 95%;
                    }
                }
            }
        }
    }
}
