.vessel-detail-card {
    justify-content: space-between;
    margin: 0 73px;
    margin-top: 30px;

    .vessel-detail {
        color: #0000a1;
        font-size: 34px;
        font-weight: 600;
        padding-top: 30px;
    }

    .vessel-detail-child {
        font-size: 16px;
        margin-top: 16px;
    }
}


.vessel-detail-grid {
    input, .vessel-type-input {
        margin-top: 10px !important;
        border-radius: 8px !important;
    }

    .react-dropdown-select-dropdown {
        top: 37px;
        box-shadow: none;
        border-radius: 8px;
      }
  
    .react-dropdown-select-item {
        padding: 10px 13px;
    }

    .react-dropdown-select-content {
        padding-left: 7px;
        align-items: center;
    }

    .react-dropdown-select-input {
        margin: 7px 0;
    }
}

