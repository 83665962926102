.modal-d3-schedule-status {
    width: 370px;
    text-align: left;
    .modal-d3-schedule-status-content {
        height: 367px;
        overflow: auto;
        padding-bottom: 1rem;
        .modal-container {
            padding: 18px;
            padding-bottom: 0;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
                background-color: #eef5f2;
                border-radius: 5px;
            }
            .divider {
                margin-bottom: 0;
                &:not(.vertical):not(.horizontal) {
                    border-color: #F8F8F8 !important;
                }
    
            }
        }
        .modal-content {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 10px;        
        }
        .modal-content-detail-message {
            width: 100%;
            .trip-id {
                font-weight: 600;
                color: #242433;
            }
            .status {
                font-weight: 500;
                margin-top: 3px;
                color: #5C5C7A;
            }
            .progress-bar {
                margin-top: 7px;
                margin-bottom: 0;
                &.progress-bar-completed {
                    .bar {
                        background: #26A671;
                    }                
                }
                &.progress-bar-processing {
                    .bar {
                        background: #FAC72B;
                    }
                }
                &.progress-bar-error {
                    .bar{
                        background: #E74C3B;
                    }
                }
            }
        }        
    }
    .modal-d3-schedule-status-title {
        padding: 18px;
        border-bottom: 1px solid #D9D9E3;
        user-select: none;
        span {
            font-weight: 600;
            color: #2aa773;
        }
    }
}

.processing-icon {
    svg {
        vertical-align: middle;
        animation: anime 2s linear infinite;
    }
}