.gantt-chart-container {
  margin-bottom: 200px;
  .gantt-chart-header {
    width: 100%;
    .gantt-chart-menu {
      width: 200px;
    }

    .gantt-header-days-group {
      position: relative;
      background-color: #F5F7FE;
      border-radius: 4px;
      border: 1px solid #fff;
    }

    .gantt-header-days{
      padding: 5px;
    }
  }

  .gantt-chart-content {
    position: relative;
    .background-content {
      position: absolute;
      width: 100%;
      height: 100%;
      .empty-space {
        width: 200px;
      }
      .svg-backgroud-line {
        width: 100%;
        padding: 0.5rem 1px;
        margin: -15px auto;
      }
    }

    .gantt-chart-body {
      .gantt-chart-menu {
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 72px;
        padding: 8px;
        background: #f5f7fe;
        text-transform: capitalize;

        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #202529;
      }
      .gantt-chart-schedule {
        position: relative;

        .schedule-content {
          position: absolute;
          height: 100%;
          cursor: pointer;

          border: 4px solid #eaeaea;
          border-bottom: 1px solid #eaeaea;
          background-color: #eaeaea;

          &.cross-previous-month {
            border-left: 1px solid #eaeaea;
          }

          &.cross-next-month {
            border-right: 1px solid #eaeaea;
          }

          &.Material {
            border: 4px solid #fbe165;
            border-bottom: 1px solid #fbe165;
            background-color: #fbe165;

            &.cross-previous-month {
              border-left: 1px solid #fbe165;
            }

            &.cross-next-month {
              border-right: 1px solid #fbe165;
            }
          }

          &.People {
            border: 4px solid #c4dfd7;
            border-bottom: 1px solid #c4dfd7;
            background-color: #c4dfd7;

            &.cross-previous-month {
              border-left: 1px solid #c4dfd7;
            }

            &.cross-next-month {
              border-right: 1px solid #c4dfd7;
            }
          }

          &.Marine {
            border: 4px solid #aed2f2;
            border-bottom: 1px solid #aed2f2;
            background-color: #aed2f2;

            &.cross-previous-month {
              border-left: 1px solid #aed2f2;
            }

            &.cross-next-month {
              border-right: 1px solid #aed2f2;
            }
          }

          &.Maintenance {
            background-color: #ffd9d9;
            border: 4px solid #ffd9d9;
            border-bottom: 1px solid #ffd9d9;

            &.type-general-maintenance {
              background-color: #4a5496;
              border: 4px solid #4a5496;
              border-bottom: 1px solid #4a5496;

              span {
                color: #fff;
              }
            }

            &.type-osvis-gl-maintenance {
              background-color: #c4c8df;
              border: 4px solid #c4c8df;
              border-bottom: 1px solid #c4c8df;
            }

            &.type-class-survey {
              background-color: #ebecf3;
              border: 4px solid #ebecf3;
              border-bottom: 1px solid #ebecf3;
            }

            span {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              color: #202529;
              padding: 0 8px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .header-content {
            padding: 0px 8px;

            div {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            div:first-child {
              margin-left: 0;
              height: 20px;
              width: auto;
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              color: #202529;
            }

            div:last-child {
              font-style: normal;
              font-weight: normal;
              font-size: 10px;
              line-height: 12px;
              color: #202529;
            }
          }
          .body-content {
            padding: 0px 8px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 10px;
            line-height: 16px;
            background-color: #fff;
            height: 70%;
          }
        }
      }
    }
  }
}
