.kpi-overview-option-container {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 998;
  box-shadow: 0px -5px 20px 0px #ccc;
  transition: height 0.3s;
  left: 0px;
  right: 0px;
  transition: bottom 0.3s;

  .hide {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transform: rotate(-90deg);
    width: 40px;
    height: 40px;
    padding-left: 10px;
    padding-top: 15px;
    position: relative;
    left: 40%;
    top: -17px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 44%;
    transition: bottom 0.3s;
    color: $color-primary-2;
    justify-content: center;
  }

  .hide:hover {
    background-color: $color-primary-2;
    color: white;
    transform: rotate(90deg);
  }

  .hidebtn_Bottom {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transform: rotate(-90deg);
    width: 40px;
    height: 40px;
    padding-left: 10px;
    padding-top: 15px;
    position: relative;
    left: 87.2%;
    top: -17px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 44%;
    transition: bottom 0.3s;
    color: $color-primary-2;
    justify-content: center;
  }

  .hidebtn_Bottom:hover {
    background-color: $color-primary-2;
    color: white;
    transform: rotate(90deg);
  }

  .kpi-overview-option-content {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;

    .trigger-btn:focus {
      background-color: $color-white;
    }
    .trigger-btn:hover {
      background-color: $color-white;
    }

    .vessel-container {
      min-width: 200px;
      margin-bottom: 15px;
      margin-top: 15px;
      border-right: 1px solid #ccc;
      justify-content: center;
      .vessel-value {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #000000;
      }
    }

    .kpi-col {
      width: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;

      .subtitle {
        margin-top: 8px;
        margin-bottom: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        color: #2d1e4e;
      }

      .title {
        font-size: 16px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #96968c;
        font-weight: 600;
      }
    }
    .export-container {
      min-width: 200px;
      margin-bottom: 15px;
      margin-top: 15px;
      border-left: 1px solid #ccc;
      justify-content: center;
      .export-text {
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.04em;
        color: #0041f0;
        text-align: start;
      }
    }
    .share-btn {
      cursor: pointer;
    }
  }
}
