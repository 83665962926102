.search-filter-container {
  align-items: center;
  height: fit-content;
  border: 1px solid #EAEAEA;
  border-radius: 4px;

  .search-box {
    display: flex;
    flex-direction: row;
    padding: 0 10px;
    align-items: center;
    justify-content: center;    
    height: 40px;
    width: 400px;
    margin-right: 0 !important;
    border-right: 1px solid #EAEAEA;

    .icon {
      margin-right: 0 !important;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  
    .search-input-wrapper {
        width: 400px;

        input {
          padding: 0 !important;
          padding: 9.5px 14px 9.5px 10px !important;
        }
    }
  
    &.active {
      background-color: $color-white !important;

      .search-input-wrapper {
        width: 400px;
      }
    }
  }

  .filter {
    padding: 0px 20px;
  }
}

.popup-filter-maintenance {
  width: 568px;
  height: 388px;
  z-index: 2000;
  .title {
    padding: 12px 0 0 8px;
  }
  .maintenance-log-grid {
    padding: 30px 30px !important;
    height: 440px;
    .vessel-management-period-picker {
      button {
        width: 100%;
        text-align: left;
      }
    }
    .btn-cancel {
      width: 100%;
      margin-top: 10px;
      background-color: white;
      border: 1px solid #0000A1;
      color: #0000A1;
    }
    .btn-search {
      width: 100%;
      margin-top: 10px;
      background-color: #0000A1;
      color: white;
    }
  }
}

.checked-category-container {
  max-width: 100%;
  flex-wrap: wrap;
}