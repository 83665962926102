.release-note {
    width: 100%;
    height: 500px;

    .header {
        font-size: 24px;
        font-weight: 600;
    }

    .slider-body {
        .content-scrollable {
            width: fit-content !important;
            margin-left: -2px;
            padding-left: 2px;
            height: 230px;
            overflow-x: hidden;

            .title-type {
                color: #6B6C70;
                font-weight: 600;
                font-size: 16px;
            }
            .release-date {
                font-weight: 600;
                font-size: 16px;
            }

            .content {
                .content-description {
                    font-weight: 400;
                    font-size: 14px;
                    padding-right: 1rem;
                }
            }
        }
    }
}