.current-schedule-details-container {
    position: fixed;
    background-color: white;
    height: calc(100% - 100px);; 
    width: 100%; 
    overflow-y: auto; 
    left: 0; 
    right: 0; 
    top: 100px;
    z-index: 993;

    &::-webkit-scrollbar {
        width: 8px!important;
        background: none !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #CACAD8!important;
    }

    &::-webkit-scrollbar-track {
        background: none !important;
    }

    .close-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;
        margin-right: 40px;
    }

    .header {
        display: flex;
        margin-bottom: 12px;
    }

    .location-name {
        color: var(--neutrals-dark-almost-black, #202529);
        /* Subheading/Medium */
        font-family: Inter;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 136.364% */
    }

    .location-type {
        display: inline-flex;
        padding: 2px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;

        border-radius: 10px;
        background: var(--duke-blue-p-50, #E6E6F6);

        color: var(--duke-blue-p-300-main, #0000A1);
        text-align: center;

        /* Paragraph 3/Semibold */
        font-family: Commissioner;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 157.143% */
        letter-spacing: 0.189px;

        margin-left: 15px;
    }

    .arrival-and-depature-title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        margin-left: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .planned {
        display: flex;
        margin-left: 23px;
        margin-bottom: 8px;
        .title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            text-align: right;
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
        }
        .values {
            margin-left: 10px;
            p {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                /* Paragraph/Paragraph 3/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
            }
        }
    }

    .actual {
        display: flex;
        margin-left: 23px;
        margin-bottom: 20px;
        .title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
        }
        .values {
            margin-left: 21px;
            p {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                /* Paragraph/Paragraph 3/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
            }
        }
    }

    .line {
        width: 100%;
        height: 2px;
        background: #F1F1F1;

        &.short {
            margin-left: 30px;
            width: 90%;
        }
    }

    .mcr-and-expected-speed {
        display: flex;
        margin-left: 23px;
        margin-top: 15px;
        .mcr {
            display: flex;
            .title {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                /* Paragraph/Paragraph 3/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
            }
            .value {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                /* Paragraph/Paragraph 3/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */

                margin-left: 10px;
            }
        }

        .expected-speed {
            display: flex;
            margin-left: 10px;
            .title {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                /* Paragraph/Paragraph 3/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */
            }
            .value {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                /* Paragraph/Paragraph 3/Regular */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px; /* 157.143% */

                margin-left: 10px;
            }
        }
    }

    .cwot {
        display: flex;
        margin-left: 23px;
        margin-top: 6px;
        margin-bottom: 20px;
        .title {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
        }
        .value {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */

            margin-left: 10px;
        }
    }

    .m-timeline {  
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 30px;
        margin-left: 20px;
        
        .first,
        .last,
        .multiple {
          position: relative;
          overflow: hidden;
        }

        .first .dash-line {
            &:first-child {
              display: none;
            }
        }

        .last .dash-line {
            &:last-child {
              display: none;
            }
        }

        .only .dash-line {
            display: none;
        }

        .color-purple {
            color: #0000A1;
        }

        .color-green {
            color: #376e37;
        }

        .color-red {
            color: #a33e3b;
        }

        .color-warning {
            color: #90682f;
        }
        
        .sub-text {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #5C5C7A;
            margin-left: 1.75em;
        }

        .sp-dash {
            .dash-line {
              border-left: 1px dashed #a4a7b2;
              position: absolute;
              height: 30px;
              left: 1.35em;
              z-index: 1;
            }
            & > .dash-line:first-child {
              top: 0;
            }
            & > .dash-line:last-child {
              top: 2.35em;
              height: 500px;
            }
        }
    }

    .report {
        margin-left: 33px;
        margin-top: 20px;
        margin-bottom: 14px;
        .report-container {
            border-radius: 10px;
            background: var(--alert-warning-y-50, #FDF1CE);
            margin-top: 3px;
            margin-bottom: 3px;
            width: 110px;
            margin-left: -8px;

            span {
                color: var(--alert-warning-y-500, #C79500);
                text-align: center;
                /* Paragraph/Paragraph 3/Medium */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 157.143% */
                margin-left: 10px;
            }
        }

        .event-name {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */

            margin-top: 3px;
            margin-bottom: 3px;
        }

        .view-report {
            color: var(--duke-blue-p-300-main, #0000A1);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */

            margin-top: 3px;
            margin-bottom: 3px;
        }
    }

    .rating {
        margin-left: 33px;
        margin-top: 15px;
        margin-bottom: 15px;
        .rating-count {
            margin-top: 10px;
            margin-bottom: 3px;
            width: 200px;
            margin-left: -2px;
        }

        .user-comment {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */

            margin-top: 3px;
            margin-bottom: 3px;
        }

        .view-rating {
            color: var(--duke-blue-p-300-main, #0000A1);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */

            margin-top: 3px;
            margin-bottom: 10px;
        }
    }
}