.confirm-demand-page {
  margin-bottom: 50px;
  .confirm-booking-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .numbered-list {
    margin-left: 35px;
    .line-content {
      display: list-item;
      list-style-position: inside;
      color: #000000;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
    }
  }

  .bottom-alert-text {
    align-items: center;

    .warning-icon {
      svg path {
        fill: #000000;
      }
    }

    .warning-bottom-label {
      color: #000000;
      font-size: 12px;
      line-height: 12px;
      font-weight: 600;
    }
  }

  .confirm-booking-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  .demand-details-title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: #6b6c70;
  }
  .demand-details-sub-title {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #000000;
  }

  .booking-button-container {
    margin-left: auto;
    display: flex;

    .button-icon-container {
      border: 1px solid #0000A1;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 12px;
      cursor: pointer;
      height: 48px;
      width: 48px;
      &:hover {
        background-color: #0000A1;
        svg path {
          fill: #ffffff;
        }
      }
    }
  }

  .confirm-cards-container {
    width: 100%;
    margin-bottom: 35px;
    .confirm-back-card-container {
      width: 100%;
      border-radius: 8px;
      background-color: #eaeaea;

      .front {
        width: 100%;
        .confirm-type-label {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          color: #242433;
          margin-left: 41px;
          margin-top: 16px;
        }
        .confirm-front-card-container {
          padding: 21px 60px 16px 40px;
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
          background: #ffffff;
          border-radius: 8px;
          position: relative;
          top: 18px;
          left: 0px;

          &.empty-card {
            top: -16px;
          }

          .savings-label {
            color: #0000A1;
            font-weight: 600;
            font-size: 16px;
            line-height: 14px;
            margin-bottom: 18px;
          }

          .vessel-container {
            background-color: #f5f7fe;
            border-radius: 8px;
            padding: 7px 10px;
            margin-left: auto;
            color: #a055f5;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            align-items: center;
          }

          .location-container {
            width: 100%;

            &.passengers-card-container {
              width: 90%;
            }

            .start-location-container {
              width: 200px;
            }

            .end-location-container {
              margin-left: 40px;
              margin-right: 30px;
              width: 25%;
            }

            .payload-details-container {
              width: 25%;

              .payload-content-container {
                align-items: center;
              }

              .payload-details {
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                color: #202529;
              }
            }

            .seats-column {
              margin-top: 23px;
              margin-left: 30px;

              .seats-label {
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                color: #202529;
                width: 200px;
              }
            }

            .top-card-label {
              font-weight: 600;
              font-size: 12px;
              line-height: 12px;
              color: #6b6c70;
              margin-bottom: 10px;
            }

            .content-sub-title {
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;
              &.active {
                color: #ffffff;
              }
            }

            .content-date-sub-title {
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
              &.active {
                color: #ffffff;
              }
            }

            .content-label {
              color: #96968c;
              font-size: 12px;
              font-weight: 600;
              line-height: 12px;

              &.active {
                color: #ffffff;
              }
            }

            .arrow {
              position: relative;
              width: 180px;
              margin-top: 40px;
              height: 0;
              border-bottom: 2px solid #bab3be;
              &.active {
                border-bottom: 2px solid #ffffff;
              }
            }

            .arrow::after {
              content: "";
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 10px solid #bab3be;
              position: absolute;
              right: -10px;
              top: -4px;
            }

            .arrow.active::after {
              content: "";
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 10px solid #fff;
              position: absolute;
              right: -10px;
              top: -4px;
            }
          }
        }
      }
    }
  }
}

.button-demand {
  font-family: $font-normal !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  padding: 16px 32px !important;
  border-radius: 4px !important;

  &.button-bg {
    background-color: #0000A1 !important;
    color: #fff !important;
  }
}

.modal-coloured-bold {
  color: #0000A1;
  font-weight: 600;
}

.modal-label-field {
  font-weight: 600;
  color: #202529;
  font-size: 14px;
  line-height: 14px;
}

.iap-ddl {
  width: 400px !important;
  font-size: 16px !important;
}
