.modal-voyage-updated-plt {
    width: 652px !important;
    border-radius: 10px !important;
    .content {
        padding: 0 !important;
        border-radius: 10px !important;
        margin: 0 !important;
        overflow: hidden;
        .modal-header {
            padding: 20px !important;
            justify-content: start !important;
            .title {
                font-size: 26px;
                // font-weight: 600;
                color: #4B7093;
            }
            background: #EBF0F5;
        }
        .modal-content {
            padding: 50px 20px;
            color: #242433;
            font-size: 16px;
            text-align: center;
            line-height: 24px;
        }
        .modal-actions {
            gap: 8px;
            margin-bottom: 25px;
            justify-content: flex-end;
            padding-right: 25px;
            .btn {
                font-size: 16px;
                color: #0000A1 !important;
                border-radius: 10px;
                padding: 12px 48px;
                font-weight: 600;
                line-height: 24px;
                text-transform: none;
            }
            .btn-maybe-later {
                background-color: #0000A1;
                color: #FFF !important;
            }
            .btn-continue {
                &:hover {
                    color: #FFF !important;
                }
            }
        }
    }
}