.departure-date-dropdown {
    position: relative;
    .sort-by-dropdown {
        font-size: 14px !important;
        height: 40px !important;
        border-radius: 8px !important;
        border: 1px solid #D9D9E3 !important;
        display: flex !important;
        align-items: center;
        min-width: 220px !important;
    }
    .period-picker-icon {
        position: absolute;
        top: 12px;
        left: 253px;
    }
    .filter-text {
        color: #5C5C7A;
        font-size: 14px;
        font-weight: normal;
    }
}