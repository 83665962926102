.voyage-schedule-table {
  margin-right: 9px;
  font-size: 14px;

  .text-bold {
    font-weight: bold;
  }
  .table-header {
    background-color: #fbfcff;
  }
  .row-header {
    background-color: #f0f0fa;

    td:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .font-16 {
      font-size: 16px;
    }

    .type {
      color: #4a5496;
      background: #ffffff;
      border: 1px solid #c4c8df;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 2px 8px;
      font-size: 14px;
    }

    .arrival-departure-col:hover {
      cursor: pointer;
    }
  }

  .trip-date {
    display: flex;

    .date-title {
      width: 50%;
      text-align: right;
    }
  }
  .pr-32 {
    padding-right: 32px !important;
  }
  .mb-8 {
    margin-bottom: 8.5px !important;
  }
  .ml-8 {
    margin-left: 8px !important;
  }
  .ml-20 {
    margin-left: 20px !important;
  }
  .ml-16 {
    margin-left: 16px !important;
  }
  .mr-28 {
    margin-right: 28px !important;
  }

  .mr-8 {
    margin-right: 8px !important;
  }

  .mr-16 {
    margin-right: 16px !important;
  }

  & > thead > tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 3;
  }

  .no-pad-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .MuiRating-root {
    color: #0000A1;
    padding: 0;
  }

  .voyage-list-card {

    margin-top: 40px;
    cursor: pointer;
    .card-header {
      width: 100%;
      // height: 45px;
      top: 221px;
      left: 19px;
      border-radius: 8px 8px 0px 0px;
      background-color: #DAE3EC;
      text-align: center;
      margin-bottom: 10px;
      .text {
        width: 100%;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #242433;
      }
      .divider {
        font-size: 20px;
        font-weight: 500;
        color: #757595;
      }

    }
    .card-body{
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      .card-body-header {
        display: flex;
        margin: 0px 20px;
        padding-top: 20px;
        padding-bottom: 10px;
        .localtion {
          font-family: Inter;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #242433;
          padding-right: 10px;
        }
        .localtion-type {
          height: 26px;
          padding: 2px 16px 2px 16px;
          border-radius: 10px;
          gap: 4px;
          font-family: Commissioner;
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0.0135em;
          text-align: center;
          color: $color-primary;
          background-color: #E6E6F6;
        }
      }
      .card-body-item {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        border-top: 1px solid #F1F1F1;
        border-bottom: 1px solid #F1F1F1;
        .arrival-title {
          width: 100%;
          font-family: Inter;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #242433;
          margin-bottom: 10px;
        }
        .date-title {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #242433;
        }
        .date-value {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #5C5C7A;
        }
      }
      .card-timeline {
        padding: 20px;
      }

    }
  }
}

.disply-none {
  display: none;
}

.schedule-scroll {
  overflow-y: scroll;
  height: 500px;
}