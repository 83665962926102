.upload-form-container {
  cursor: move;
  background: white;
  z-index: 998;
  height: auto;
  width: 315px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px $color-grey;
  padding: 20px;
  position: fixed;
  top: 120px;
  left: 20px;

  .title {
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
  }

  .sub-title {
    font-family: $font-normal;
    font-size: 14px;
    text-align: left;
    line-height: 18px;
    margin: 5px 0;
  }

  .filterform {
    margin-top: 10px;
    label {
      font-family: $font-normal;
      font-size: 14px;
      text-align: left;
      line-height: 18px;
      font-weight: 600;

      input {
        margin-right: 5px;
      }
    }
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .filename {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 10px;

    span {
      display: block;
      font-family: $font-normal;
    }

    span:last-child {
      color: rgba(0, 0, 0, 0.4);
    }

    .name {
      width: calc(100% - 35px);
      display: inline-block;
      overflow-wrap: break-word;
    }

    .action {
      width: 20px;
      float: right;
    }

    .action-button {
      background: white;
      color: #826ab9;
      cursor: pointer;
    }

    .action-button:hover {
      color: #ef5d60;
    }
  }

  .removefiles {
    text-align: right;
    width: 100%;
    cursor: pointer;
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    i {
      margin: 2px 0.1rem 0 0;
    }
  }

  .add-more {
    color: #0041f0;
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: 0.04em;
    line-height: 20px;

    label {
      cursor: pointer;
      span {
        font-size: 18px;
        line-height: 0;
      }
    }

    #upload-file {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

  .buttons .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
  }

  .remainingFiles {
    color: rgba(0, 0, 0, 0.4);
    font-size: 12px;
    font-family: $font-normal;
    line-height: 16px;
    margin-top: 12px;
  }
}
