.fleet-summary-container {
  .title-container {
    width: 100%;
    display: flex;
    justify-content:space-between;
    flex-flow: row;
    .fleet-title {
      font-family: Inter;
      font-size: 26px;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: left;
      color: #242433;
      margin-bottom: 20px;
    }
    .planning-link {
      .link {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        cursor: pointer;
        color: #D0460B;
      }
    }
  }
  
  .fleet-summary-new-container {
    width: 100%;
    text-align: left;
    margin-top: 50px;
    .fleet-item {
      width: 100%;
      margin-bottom: 20px;
      text-align: left;
      display: flex;
      flex-flow: row;
      text-align: center;
      justify-content: left;
      align-items: center;
      .fleet-data {
         margin-left: 10px;
        .value {
          font-family: Inter;
          font-size: 30px;
          font-weight: 500;
          line-height: 38px;
          letter-spacing: 0em;
          text-align: left;
          color: #242433;
        }
        .title {
          font-family: Inter;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #5C5C7A;
        }
      }
    }
  }
  .date-selector {
    width: 140%;
  }
  .title-name {
    .widget-date-picker button {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #202529;
      border: none !important;
      margin: auto;
      padding: 0 1rem;
    }
  }
  .box-content {
    border: 1px solid #f0f0fa;
    margin: 1rem 6rem !important;
    text-align: center;
    width: 200px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    border-radius: 8px;

    .header {
      background-color: #f0f0fa;
      padding: 1rem;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
    }
    .body {
      padding: 1rem;
      .value {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
      }
      .text {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #6b6c70;
      }
    }
  }
  .button-action {
    background-color: transparent;
    font-weight: 600;
    font-size: 18px;
    border-radius: 0;
    color: #96968c;

    &.selected {
      color: #09010e;
      border-bottom: 3px solid #0000A1;
    }
  }
  .borrder-fleet {
    border: 1px solid #eaeaea;
    margin-top: 60px;
    margin-bottom: 10px;
  }
  .silder-bar {
    .bar {
      .title-bar {
        .title {
          color: '#202529';
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
        }
        .persent {
          text-align: right;
          width: 100%;
        }
      }
      .progress-content {
        position: relative;
        padding-top: 5px;
        .benchmark {
          position: absolute;
          width: 3px;
          height: 15px;
          margin-top: -4px;
          background: #6b6c70;
          margin-left: auto;
          margin-right: auto;
          z-index: 1;
          border-radius: 1px;
        }
        .progress {
          overflow: hidden;
          background-color: #e9ecef;
          position: relative;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .title-container {
      width: 100%;
      .fleet-title {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
    .date-selector {
      width: 100%;
    }
    .fleet-summary-new-container {
      margin-top: 30px;
      .fleet-item {
        .fleet-data {
          .value {
            font-family: Inter;
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            color: #242433;
          }
          .title {

            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #5C5C7A;
          }
        }
      }
    }
  }
}
