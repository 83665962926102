.map-container {
  .leaflet-popup {
    .map-tooltip-title {
      font-size: 14px;
      font-weight: 700;
    }
    .leaflet-popup-content {
      width: 450px !important;
      .mooring-buoy-id {
        .title-MB {
          color: #4a5496;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.03em;
          margin-top: -20px;
          margin-bottom: 15px;
        }
        .sub-title {
          margin-top: -15px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          position: absolute;
          right: 10px;
          height: 21px;
          .sub-title-detail {
            color: white;
            position: static;
            width: 69px;
            height: 21px;
            left: calc(50% - 69px / 2);
            top: calc(50% - 21px / 2);
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            align-items: center;
          }
        }
      }
      .next-arriving-vessel-hover {
        .view-log {
          color: #0000A1;
          position: absolute;
          right: 10px;
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 14px;
        }
        .title {
          font-size: 14px;
          line-height: 21px;
          font-weight: 600;
        }
        .empty-tab {
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

  .custom-leaflet-control-zoom {
    .custom-leaflet-control-zoom-common {
      margin: 4px;
      border-radius: 10px;
      width: 47px;
      height: 47px;
      font-weight: 300;
      color: #0000A1;
      border: none;
      cursor: pointer;
      &.active {
        background-color: #0000A1;
        color: #ffffff;
      }
    }
    .custom-leaflet-control-zoom-in {
      line-height: 45px;
      font-size: 40px;
    }
    .custom-leaflet-control-zoom-out {
      line-height: 40px;
      font-size: 50px;
    }
  }

  .legend-container {
    position: absolute;
    z-index: 9999;
    width: 100%;
    bottom: 25%;
    margin-left: 10px;
    .legend-title {
      margin-left: 5px;
      font-weight: bold;
    }
  }
  .map-tooltip-container {
    min-width: 270px;
    width: auto;
    .large-icon {
      left: -5px !important;
    }
    .map-tooltip-header {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #6b6c70;
      .location {
        width: 12px;
        margin-right: 5px;
      }
    }
    .map-location-header {
      align-items: center;
      gap: 5px;
      font-size: 14px;
      position: relative;
      left: -8px;
    }
    .map-tooltip-content {
      align-items: center;
      position: relative;
      left: -8px;
      .location-icon {
        position: relative;
        left: -3px;
        bottom: -2px;
      }
      .map-tooltip-title {
        font-size: 14px;
        font-weight: 700;
      }
      .with-image {
        margin-left: -25px;
      }
      .without-image {
        margin-left: 25px;
      }
    }
    .map-tooltip-subtitle {
      font-size: 14px;
      font-weight: 700;
      margin-left: 16px;
    }
    .map-container.general .leaflet-bottom .leaflet-control-zoom {
      bottom: 200px;
    }
    .map-tooltip-radiuslegend {
      position: absolute;
      bottom: -67px;
      left: -1px;
      background-color: #fff;
      box-shadow: 0 1px 3px #00000066;
      border: 1px solid #fff;
      border-radius: 3px;
      width: 100.9%;
      user-select: none;
      pointer-events: none;
      padding: 6px;
      padding-left: 14px;
      font-size: 12px;
      .radiuslegend-flexgap {
        gap: 8px;
      }
      .radiuslegend-title {
        font-weight: bold;
      }
      .radiuslegend-subtitle {
        margin-top: 8px;
      }
      .radiuslegend-roundicon {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin: 0;
        padding: 0;
        &.arrival-radius {
          background: #88c0e2;
        }
        &.safety-radius {
          background: #a5d7f0;
        }
      }
    }
    .popup-mb {
      .leaflet-popup-content-wrapper {
        width: auto;
        .leaflet-popup-content {
          width: auto;
        }
        .title-MB {
          color: #4a5496;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: -0.03em;
          margin-top: -20px;
          margin-bottom: 15px;
        }
        .sub-title {
          // margin-left: 35%;
          background: #ef5d60;
          margin-top: -15px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 8px;
          position: static;
          width: 120px;
          height: 21px;
          left: calc(50% - 85px / 2 + 5.5px);
          top: calc(50% - 21px / 2);
          .sub-title-detail {
            color: white;
            position: static;
            width: 69px;
            height: 21px;
            left: calc(50% - 69px / 2);
            top: calc(50% - 21px / 2);
            font-weight: 600;
            font-size: 16px;
            line-height: 21px;
            align-items: center;
          }
        }
        .title-right {
          color: #0000A1;
          margin-left: 25%;
          margin-bottom: 15px;
        }
        .next-arriving-vessel-hover {
          background-color: #fbfcff;
          margin-left: 5px;
          .title-next-arring-vessel {
            margin-bottom: 15px;
            .title {
              font-size: 14px;
              line-height: 21px;
              font-weight: 600;
              margin-top: 5px;
            }
            .title-right {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  
  .map-tooltip-location-name {
    font-weight: 600;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 8px;

    &::before {
      border: 0;
    }
  }

  .draw-vessels-container {
    border-top: solid 5px rgb(239, 93, 96);
    margin: -8px;
    width: 107%;
    height: auto;
    padding: 1rem;

    .draw-vessels-content {
      margin-left: 20px;
      .long-lat-vessel{
        min-width: 200px;
      }
      .draw-vessels-title {
        display: flex;
        align-items: center;
        margin-left: -27px;
        .title {
          color: #6b6c70;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        .blueVessel {
          width: 20px;
          margin-right: 10px;
        }
        .toggle-btn{
          padding: 0 !important;
          border: none !important;
          margin-left: auto;
          box-shadow: none !important;
        }
      }
      .title {
        font-family: $font-normal !important;
        font-weight: $fontweight-600;
        font-size: $fontsize-14;
        line-height: 16px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #000000;
        margin: 0;
      }
    }

    .inner {
      font-family: $font-normal;
      font-size: $fontsize-14;

      &.left-content {
        flex: 1;
      }

      &.value {
        text-align: right;
        font-weight: 600;
      }

      &.red {
        color: #ef5d60;
      }

      &.green {
        color: #2bcf9e;
      }

      &.grey {
        font-size: $fontsize-10;
        color: #b1bcca;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        // opacity: 0.4;
        font-weight: 700;
      }
    }
  }
  .filterDiv {
    .draw-vessels-title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .title {
        color: #6b6c70;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-left: 0;
      }
      .iconVessel {
        width: 20px;
        margin-left: 3px;
        margin-right: 7px;
      }
    }
    .title {
      font-size: 14px;
      margin-left: 30px;
    }
    .title-no-search {
      font-size: 14px;
    }
  }
  .vessel-position {
    margin-top: -33px;
    margin-left: -10px;
  }
  .actual-vessel-position {
    margin-top: -33px;
    margin-left: -10px;
  }
  .planned-vessel-position {
    opacity: 0.5;
    margin-top: -33px;
    margin-left: -10px;
  }
  .leaflet-top,
  .leaflet-bottom {
    position: absolute;
    z-index: 998;
    pointer-events: none;
  }
  &.general .leaflet-bottom .leaflet-control-zoom {
    bottom: 160px;
  }

  .leaflet-control-zoom-fullscreen {
    cursor: pointer;
    margin-bottom: 100px;

    i {
      margin: 0;
      color: #0000A1;
    }
  }

  .leaflet-control-attribution {
    visibility: hidden;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.blue-title {
  color: #4a5496;
}
