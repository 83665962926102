.breadcrumbvsl-page {
    margin-bottom: 16px !important;
    .section {
      color: #d0460b !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 21px !important;
      &.active {
        color: #6b6c70 !important;
        &:hover {
          color: #6b6c70 !important;
        }
      }
      &:hover {
        color: #0000a1 !important;
      }
    }
}

.addvsl-footer {
  .ui.button {
    padding: 0.75rem 3rem;
  }
  .btn-action:focus {
    background-color: #fff;
    color: #0000a1;
  }
  .btn-action-primary,
  .btn-action-primary:focus {
    background-color: #0000a1 !important;
    color: #fff !important;
  }
}

.addvsl {
  .label-title {
    line-height: 0px !important;
  }
  .grid {
    input {
      text-align: left !important;
    }
    .label-text {
      font-weight: 400;
    }
    .ui.label {
      min-width: 60px;
      text-align: left;
    }
    .info-icon {
      margin-top: 3px;
    }
  }
}

.addvsl-modal {
  .ui.list .list>.item, .ui.list>.item, ol.ui.list li, ul.ui.list li {
    line-height: 1.5em;
  }
}

.warn-red {
  svg path {
    fill: #C93626;
    ;
  }
}

.remove-arrow-input-number{
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}