.special-request-container {
  padding: 0 160px;
  padding-bottom: 50px;

  .button-demand {
    font-family: $font-normal !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
    padding: 16px 32px !important;
    border-radius: 4px !important;

    &.button-bg {
      background-color: #0000A1 !important;
      color: #fff !important;
    }
  }
 
  .demand-booking-form-container {
    // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    // // padding: 73px 96px 73px 96px;
    // border-radius: 10px;
    // margin-bottom: 80px;

    .main-form-label {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      margin-bottom: 45px;
    }

    .add-cargo-field {
      display: flex;
      align-items: center;
      margin-top: 45px;
      margin-bottom: 75px;
      cursor: pointer;
      .add-cargo-btn {
        color: #0000A1;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        margin-left: 12px;
      }
    }
  
    .demand-form-container {
      margin-top: 5px;
      
      .ui.input > input {
        font-size: 16px;
        line-height: 22.4px;
      }
  
      .main-label {
        font-weight: 600;
        font-size: 18px;
        color: #4B7093;
        line-height: 16px;
        margin-bottom: 24px;
      }
  
      .label-title {
        font-weight: 600;
        font-size: 14px;
        color: #5C5C7A;
        line-height: 14px;
      }
  
      .transport-form-field {
        width: 100%;
      }

      .transport-label-title {
        font-weight: 600;
        font-size: 14px;
        color: #5C5C7A;
        line-height: 14px;
        margin: 0 !important;
        margin-top: 20px !important;
        width: 100%;
      }
  
      .demand-form-field {
        width: 33.3%;
      }
      
      .demand-ddl {
        width: 100% !important;
        font-size: 16px !important;
        line-height: 22.4px !important;
      }

      .volume-ddl {
        font-size: 16px !important;
        font-weight: 400;
        line-height: 22.4px !important;
        border: 1px solid #ccc !important;
        color: #000000;
        background-color: #FFFFFF !important;
      }

      .delete-icon-container {
        cursor: pointer;
      }

      .volume-input {
        width: 45%;
        border-radius: 4px !important;
        height: 43px !important;
        text-align: left;
        .label{
          width: 64px;
          display: flex;
          justify-content: center;
          padding-top: 13px;
        }
      }
      .request-input {
        border-radius: 4px;
        width: 100%;
      }

      .demand-secondary-ddl {
        font-size: 16px !important;
        line-height: 22.4px !important;
        width: 85%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
  
      .radio-label {
        font-size: 16px !important;
        font-weight: 400px !important;
      }
  
      .input-field {
        border-radius: 4px !important;
        width: 100% !important;
      }
    }
    .demand-form-event{
      margin-top: 80px;
      .demand-form-button{
        button{
          font-weight: 600 !important;
          font-size: 16px;          
          padding: 12px 48px;
        }
        .btn-cancel{
          background: none !important;
          margin-right: 18px;
          color: #0000A1;
        }
        .btn-submit{
          background: #0000A1 !important;
          border-radius: 8px !important;
          color: #fff;
        }
      }

    }
  }
  
  .banner-container {
    padding: 16px 26px 16px 26px;
    background-color: #F9B262;
    border-radius: 10px;
    width: 100%;
    
    .annoucement-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
