.offshore-operation-section-container {
    .offshore-operation-section-content {
        width: 100%;

        .offshore-operation {
            .title {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            .content {
                margin-top: 30px;

                .row {
                    display: flex;
                    gap: 40px;
                    margin-bottom: 40px;
                }
            }
        }

        .critical-operation {
            .title {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            .content {
                margin-top: 30px;

                .row {
                    display: flex;
                    gap: 40px;
                    margin-bottom: 40px;
                }
            }
        }

        .dp-mode {
            .title {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }

            .content {
                margin-top: 30px;

                .row {
                    display: flex;
                    gap: 40px;
                    margin-bottom: 40px;
                }
            }
        }

        .accumulated-section {
            .content {
                .row {
                    display: flex;
                    gap: 40px;
                    margin-bottom: 40px;
                }
            }
        }

        .field {
            .error {
                background: #fbe5f1;
                border-color: #e41e54;
            }

            .label {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;

                &.fo-consumption {
                    margin-top: 18px;
                }

                &.daily-average-benchmark {
                    margin-top: 14px;
                }
            }

            .value-text {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;

                margin-top: 50px;
                margin-left: 10px;

                width: 300px;
            }

            .text {
                &.operations-total {
                    color: var(--independence-grey-dark-n-800-main-text, #242433);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                }
            }

            .dropdown {
                border-radius: 8px;
                border-color: #D9D9E3;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 46px;
                padding: 12px 16px;
                font-size: 14px;
                margin-top: 10px;
                background-color: #fff;

                &.fo-consumption {
                    text-transform: capitalize;
                    margin-top: 36px;
                    width: 282px;
                }
            }

            .input-field {

                //hide arrow   
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type=number] {
                    -moz-appearance: textfield;
                }

                input {
                    border-radius: 8px;
                    border-color: #D9D9E3;
                }

                &.operation-days {
                    margin-top: 20px;
                    width: 284px;
                }

                &.daily-average-benchmark {
                    margin-top: 15px !important;
                    width: 284px;
                }
            }

            .error-message {
                padding-top: 1em;
                color: 'red';
                font-weight: 600;
                display: flex;

                p {
                    padding: 0.3em 0;
                    width: 18em;
                }
            }

            .btn {
                width: 180px;
                height: 48px;
                border-radius: 8px;
                text-transform: capitalize;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;

                &.edit {
                    background: var(--duke-blue-p-300-main, #0000A1);
                }

                &.save {
                    background: var(--duke-blue-p-300-main, #0000A1);
                }

                &.button-bg {
                    background-color: #0000a1;
                    color: #fff !important;
                }
            }

            .popup {
                &.offshore-op-days {
                    margin-left: 110px;
                }
            }

            &.btn-container {
                margin-left: auto;
            }

            .not-support-text {
                margin-top: 30px;
                margin-left: 10px;
                font-weight: 500;
                font-size: 15px;
                font-style: italic;
            }

            .ui.input {
                font-family:serif;
                margin-top: 35px;
                input {
                    height: 46px;
                }
            }
        }
    }
}

.offshore-operations-popup-content {
    display: flex;
    width: 199px;
    padding: 16px;
    align-items: flex-start;
    gap: 4px;
    border-radius: 10px;
    background: rgba(59, 87, 115, 0.90);

    color: var(--Independence-Grey-Light-N10, #F9F9FB);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    text-align: left;
}