.plan-kpi-container {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0px -5px 20px 0px #ccc;
  transition: height 0.3s;
  left: 0px;
  right: 0px;
  transition: bottom 0.3s;

  .trigger-btn:focus {
    background-color: $color-white;
  }
  .trigger-btn:hover {
    background-color: $color-white;
  }
  .chart-container {
    width: 100%;
    text-align: center;
  }
  .chart-title-position {
    margin-top: -35px;
  }
  .export-container {
    min-width: 200px;
    margin-bottom: 15px;
    margin-top: 15px;
    border-left: 1px solid #ccc;
    justify-content: center;
    .export-text {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.04em;
      color: #0041f0;
      text-align: start;
    }
  }
  .share-btn {
    cursor: pointer;
  }

  .hide {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transform: rotate(-90deg);
    width: 40px;
    height: 40px;
    padding-left: 10px;
    padding-top: 15px;
    position: relative;
    left: 40%;
    top: -17px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 44%;
    transition: bottom 0.3s;
    color: $color-primary-2;
    justify-content: center;
  }

  .hide:hover {
    background-color: $color-primary-2;
    color: white;
    transform: rotate(90deg);
  }

  .hidebtn_Bottom {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transform: rotate(-90deg);
    width: 40px;
    height: 40px;
    padding-left: 10px;
    padding-top: 15px;
    position: relative;
    left: 87.2%;
    top: -17px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 44%;
    transition: bottom 0.3s;
    color: $color-primary-2;
    justify-content: center;
  }

  .hidebtn_Bottom:hover {
    background-color: $color-primary-2;
    color: white;
    transform: rotate(90deg);
  }

  .PlanKpiContent {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;

    .PlanKpi-col {
      width: 20%;
      height: 100%;
      padding-top: 15px;
      text-align: center;

      .legend-checkbox {
        margin: 1rem;
      }

      /*--------------------------KPI Bars*/
      .bar {
        .bartext {
          font-size: 11px;

          div:first-child {
            width: 59%;
            display: inline-block;
            text-align: left;
          }

          div:last-child {
            display: inline-block;
            width: 40%;
            text-align: right;
          }
        }
        .progress {
          overflow: hidden;
          background-color: #e9ecef;
          border-radius: 0.25rem;

          .progress-bar {
            &.color-0 {
              background-color: red;
            }
            &.color-1 {
              background-color: green;
            }
            &.color-2 {
              background-color: yellow;
            }
            &.color-3 {
              background-color: blue;
            }
          }
        }
      }

      .title {
        text-transform: uppercase;
        font-family: $font-normal;
        font-size: 11px;
        border-bottom: 1px solid black;
        margin-left: 10px;
        margin-right: 10px;
        padding-bottom: 4px;
        text-align: left;
        padding-left: 3px;
        margin-bottom: 10px;
      }

      .actions {
        margin-bottom: 10px;
        cursor: pointer;
        display: inline-block;

        img {
          width: 18px;
        }

        span {
          padding-left: 10px;
          font-family: $font-normal !important;
          font-weight: $fontweight-500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          color: #0041f0;
        }
      }
    }

    .PlanKpi-col:first-child {
      width: 10%;
    }

    /*-----------------Menu input thead KPI bar*/
    .PlanKpi-col:last-child {
      width: 10%;
      padding-left: 15px;
      color: #2c405a;
      line-height: 12px;
      text-align: left;
      padding-top: 35px;
      font-family: $font-normal;
    }
  }
}
