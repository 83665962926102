.custom-dropdown-option-container {
    display: flow-root;
    padding: 5px !important;

    .cdo-left {
        float: left;
    }

    .cdo-right {
        float: right;
    }

    .color_green {
        color: #2bcf9e;
    }

    .color_red {
        color: #ef5d60;
    }

    .color_yellow {
        color: #f99c39;
    }
}