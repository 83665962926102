.vod-landingpage {
  padding: 100px 195px 0px 140px;
  background-image: URL("/src/assets/images/vod-landing-background.svg");
  height: fit-content !important;
  min-height: 920px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto;
  background-size: 550px;
  background-color: #f9f9fb;
  background-position-y: bottom;

  .vod-main-container {
    height: fit-content !important;

    .vod-welcome-header {
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      span {
        color: #85a4c1;
      }
      .grey-header {
        color: #757595;
      }
      .black-header {
        color: #242433;
      }
    }

    .cards-container {
      width: 50%;
      height: fit-content !important;

      .vod-menu {
        flex-wrap: wrap;

        .content {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          margin: 1rem 0px 0px 0px;
          width: 243px;
          height: 140px;
          background: rgba(232, 232, 238, 0.5);
          border-radius: 12px;
          cursor: pointer;

          &:hover {
            background: #f0f0fa;
            box-shadow: 0px 8px 16px rgba(0, 0, 161, 0.2);
          }

          &.two {
            width: 500px;
          }

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #0000a1;
            text-align: center;
          }
        }
      }

      .quick-actions-container {
        padding: 30px;
        width: 100%;
        background-color: $color-white;
        //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        height: fit-content !important;
      }
    }

    .vod-background .ship {
      margin: 10rem -16rem;
    }
  }
}

.landingpage {
  padding: 140px 165px 50px 165px;

  &.has-vod {
    padding: 50px 165px 50px 165px;
  }

  .menu-main-container {
    position: relative;

    .cards-container {
      width: 50%;
      .quick-actions-container {
        padding: 20px;
        background-color: $color-white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        .chart-container {
          width: 100%;
          text-align: center;
          .chart-name {
            color: #6b6c70;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            display: flex;
            justify-content: center;
            margin-left: 15px;
            p {
              margin: 0 5px;
            }
            img {
              display: inline-block;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    // .latest-happening-cards {
    //   width: 50%;
    //   padding: 24px;
    //   height: fit-content;
    //   background-color: $color-white;
    //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    //   border-radius: 10px;

    //   .menu-cards-container {
    //     background-color: #f5f7fe;
    //     border-radius: 8px;
    //     padding: 20px 32px 20px 32px;
    //   }

    //   .menu-cards-title {
    //     color: #ef5d60;
    //     font-size: 12px;
    //     line-height: 12px;
    //     font-weight: 600;
    //   }

    //   .menu-cards-sub-title {
    //     color: #000000;
    //     font-size: 12px;
    //     line-height: 12px;
    //     font-weight: 600;
    //   }

    //   .menu-cards-date {
    //     color: #000000;
    //     font-size: 18px;
    //     line-height: 18px;
    //     font-weight: 600;
    //   }

    //   .x-circle-icon {
    //     cursor: pointer;
    //   }
    // }

    .menu-container {
      width: 100%;
      padding: 20px;
      background-color: $color-white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 10px;

      .shipment-container {
        background: #f5f7fe;
        height: 200px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        .clock-label-container {
          align-items: center;

          .time-title {
            color: #2bcf9e;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
          }
        }

        .time-sub-title {
          color: #6b6c70;
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
        }

        .date-time-label {
          color: #202529;
          font-size: 24px;
          line-height: 32px;
          font-weight: 600;
        }
      }

      .steps {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-bottom: 15px;

        .step {
          position: relative;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-basis: 0;
          align-items: center;

          .step-circle {
            width: 37px;
            height: 37px;
            display: flex;
            align-items: center;
            border-radius: 50%;
            font-weight: 500;
            font-size: 16px;
            justify-content: center;
            background: #ffffff;
            color: #0000a1;
            border: 2px solid #0000a1;
            z-index: 1;

            &.active {
              svg path {
                fill: #ffffff;
              }
            }
          }

          .step-label {
            font-weight: 400;
            font-size: 16px;
            margin-top: 16px;
            color: #bab3be;
          }
        }
        .step:not(:first-child)::before {
          height: 2px;
          width: 100%;
          bottom: 0;
          left: -50%;
          top: 16px;
          content: " ";
          display: block;
          position: absolute;
          background: $color-grey-inactive;
        }

        .step.is-active:not(:first-child)::before {
          background: #0000a1;
        }
        .step.is-active .step-circle {
          background: #0000a1;
          color: #ffffff;
        }

        .step.is-active .step-label {
          color: #202529;
        }
      }
    }

    .container-dimmer {
      opacity: 0.8;
      border-radius: 8px;
    }

    .happening-container-dimmer {
      border: none;
      box-shadow: inset 0px 0px 40px 40px rgba(255, 255, 255, 0.5);
    }

    .dimmer {
      background-color: rgba(255, 255, 255, 0.5);
    }

    .dimmer-message {
      font-size: 64px;
      line-height: 72px;
      font-weight: 600;
      align-self: center;
      color: #b455aa;
    }

    .visit-plt {
      font-size: 18px;
      font-weight: 600;
      color: $color-secondary-dark-blue;
    }

    .menu-title {
      font-size: 20px;
      font-weight: 600;
      color: $color-secondary-dark-blue;
      .time-date {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #202529;
      }
    }
    .menu-sub-title {
      font-size: 20px;
      font-weight: 600;
      color: $color-black;
    }

    .menu-button-container {
      width: 100px;
      .icon-bg {
        width: 91px;
        height: 91px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        .icon-position {
          margin-left: -7px;
        }
      }
    }
    .text-content {
      font-weight: 400;
      color: #202529;
      font-size: 16px;
      line-height: 24px;
    }
    .booking-container {
      padding-bottom: 20px;
      overflow: hidden;
      .booking-list-container {
        width: 344px;
        height: 228px;
        background-color: $color-white;
        padding: 24px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }
      .booking-status {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        width: 100px;
        height: 20px;
        padding: 3px 18px;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        color: $color-white;
      }
      .booking-id {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
      }
      .booking-title {
        font-weight: 600;
        font-size: 36px;
        line-height: 160%;
      }
      .booking-content-title {
        font-size: 12px;
        line-height: 16px;
      }
      .booking-content-value {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
      }
      .en-route {
        background-color: #0000a1;
      }
      .en-route-title {
        color: #0000a1;
      }
      .en-route-border {
        border-left: 4px solid #0000a1;
      }
      .in-progress {
        background-color: #f9b262;
      }
      .in-progress-title {
        color: #f9b262;
      }
      .in-progress-border {
        border-left: 4px solid #f9b262;
      }
      .finalised {
        background-color: #2bcf9e;
      }
      .finalised-title {
        color: #2bcf9e;
      }
      .finalised-border {
        border-left: 4px solid #2bcf9e;
      }
    }
  }

  .welcome-context {
    flex-basis: 100%;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 1rem;
  }
}

.newlandingpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--queen-blue-25, #ebf0f5);

  padding: 160px 90px 50px 90px;

  .welcome-container {
    margin-bottom: 30px;

    .text {
      color: var(--independence-grey-dark-n-800-main-text, #242433);
      /* Heading/Heading 4/Medium */
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px; /* 130.769% */
    }

    .name {
      color: var(--queen-blue-300-main, #4b7093);
      /* Heading/Heading 4/Medium */
      font-family: Inter;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
    }
  }

  .top-container {
    // position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;

    .icons-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;
      justify-content: flex-start;

      .icon-card {
        width: 233px;
        padding: 24px 16px;
        flex-direction: column;
        align-items: center;

        border-radius: 12px;
        border: 1px solid var(--independence-grey-light-n-30-lines-on-white-bg, #e8e8ee);
        background: var(--shades-white, #fff);

        .text-content {
          color: var(--duke-blue-p-300-main, #0000a1);
          /* Paragraph/Paragraph 2/Medium */
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px; /* 150% */
        }
      }
    }

    .other-sites-container {
      align-self: flex-start;
      margin-left: 18px;
      width: 214px;
      .main-title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
      .site {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;

        .site-link {
          display: flex;
          align-items: center;

          .title {
            color: var(--duke-blue-p-300-main, #0000a1);
            /* Paragraph/Paragraph 2/Medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */
          }

          .icon {
            margin-left: 4px;
            margin-top: 4px;
          }
        }
      }
    }

    .ship-image-container {
      position: relative;
      width: 400px;
      margin-left: 100px;
      // top: -70px;
      height: 120px;

      .image2 {
        position: absolute;
        top: -40px;
      }
      .image1 {
        position: absolute;
      }
      .image3 {
        position: absolute;
        top: -70px;
        left: 145px;
      }
      .image4 {
        position: absolute;
        top: 50px;
        left: 130px;
      }
    }
  }
  .fleet-container {
    width: 100%;
    // margin-top: 32px;
    // margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .fleet-performence-container {
      background-color: white;
      width: 60%;
      // margin-right: 2%;
      text-align: left;
      border-radius: 16px;
      padding: 32px;
    }
    .fleet-summery-container {
      background-color: white;
      width: 39%;
      border-radius: 16px;
      padding: 32px;
    }
  }
}

@media (max-width: 768px) {
  .newlandingpage {
    padding: 160px 50px 30px 50px !important;
    .top-container {
      width: 100%;
      .icons-container {
        width: 100%;
        .icon-card {
          width: 48%;
        }
      }
    }
    .other-sites-container {
      display: none;
    }
    .ship-image-container {
      display: none;
    }
    .fleet-container {
      width: 100%;
      margin-top: 32px;
      margin-bottom: 32px;
      display: block;
      justify-content: left;
      .fleet-performence-container {
        background-color: white;
        width: 100%;
        // margin-right: 2%;
        text-align: left;
        border-radius: 16px;
        padding: 32px;
        margin-bottom: 30px;
      }
      .fleet-summery-container {
        background-color: white;
        width: 100%;
        border-radius: 16px;
        padding: 32px;
      }
    }
  }
}
