.progress-standard-container {
    .progress-standard-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: '#202529';
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 10px;

        .progress-standard-title {
            color: #4A5496;
            font-weight: 600;
            font-size: 16px;
        }

        .progress-standard-percentage {
            // text-align: right;
            // width: 100%;
            margin-right: 10px;
        }
    }

    .progress-standard-content {
        position: relative;
        padding-top: 5px;

        .progress-standard-benchmark {
            position: absolute;
            width: 3px;
            height: 15px;
            margin-top: -4px;
            background: #6B6C70;
            margin-left: auto;
            margin-right: auto;
            z-index: 1;
            border-radius: 1px;
        }

        .progress-standard-bar-container {
            overflow: hidden;
            background-color: #e9ecef;
            position: relative;

            .progress-standard-bar {
                height: 7px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: #fff;
                text-align: center;
                white-space: nowrap;
                background-color: #007bff;
                transition: width 0.6s ease;
            }
        }
    }

    &.progress-standard-optimise {
        .progress-standard-header {
            .progress-standard-title {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #202529;
                padding: 5px 0;
            }

            .progress-standard-percentage {
                font-weight: 600;
                font-size: 16px;
                line-height: 21px;
                color: #202529;
                padding: 5px 0;
                margin-right: 0px;
            }
        }

        .progress-standard-content {
            padding-top: 0px;

            .progress-standard-benchmark {
                background: #d4d4d4;
                border-radius: 0;
            }
        }

    }
}