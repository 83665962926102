.setting-page {
  padding-top: 9rem;
  padding-left: 165px;
  padding-right: 165px;
  padding-bottom: 100px;

  .checked-category-container {
    flex-wrap: wrap;
    max-height: 100px;
    overflow-y: auto;

    .checked-category {
      background: #f0f0fa;
      border-radius: 20px;

      .category-text {
        font-weight: 600;
        font-size: 14px;
        color: #0000A1;
        margin: 5px;
      }

      div,
      i {
        margin: auto;
      }

      &.invert{
        background: #fff;
        cursor: pointer;
        
        .category-text{
          color: #0000A1;
        }
      }
    }
  }

  .large-text {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #202529;
  }

  .action-container {
    height: 40px;
    .search-container {
      border-radius: 4px;
      border: 1px solid #09010e;
      box-sizing: border-box;
    }

    .btn-new-user {
      background: #0000A1;
      border-radius: 4px;
      color: #ffffff;
      width: 260px;
    }
  }

  .btn-filter {
    background: #ffffff;
    border: 1px solid #0000A1;
    box-sizing: border-box;
    border-radius: 4px;
    color: #0000A1;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;

    &:hover,
    &:active,
    &:focus {
      background-color: #dae3ec;
      color: #0000A1;
    }

    .btn-filter-icon {
      margin-right: 10px;
    }
  }

  .setting-table {
    border: none;

    tr td {
      border: none;
    }

    thead tr th {
      background-color: #f0f0fa !important;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #202529;
      border: none !important;
    }

    tbody {
      tr td {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: #202529;

        .dropdown-edit {
          border: 1px solid #eaeaea;
        }
      }

      tr {
        &.row-even {
          background-color: #f5f7fe !important;
        }

        &.row-odd {
          background-color: transparent !important;
        }
      }

      .sub-rows {
        .sub-rows-header-cell {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
        }

        .sub-rows-header-cell-count {
          width: 22px;
          height: 22px;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #0000A1;
          background-color: #f0f0fa;
          display: inline-block;
          border-radius: 50%;
        }

        &.sub-rows-body {
          td {
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }

        &.sub-rows-last {
          td {
            padding-bottom: 10px;
          }
        }
      }

      .settings-page-chevron-container {
        text-align: center;
        vertical-align: middle;

        .settings-page-chevron {
          transform: rotate(180deg);

          &.expanded {
            transform: rotate(0deg);
          }
        }
      }

      // tr:nth-child(even) {
      //   background-color: #f5f7fe !important;
      // }
    }
  }
}

.settings-page-modal-container {
  .header {
    background-color: #f5f7fe !important;
    border: none !important;
  }

  .column-size {
    width: 30%;
    margin-right: 5% !important;

    &.column-size-2 {
      width: 65%;
    }

    &:nth-last-child(1) {
      margin-right: 0 !important;
    }
  }

  .header-title {
    color: #0000A1;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    max-height: 85vh;
    overflow-y: scroll;
  }

  .content-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .content-value {
    font-size: 16px;
    line-height: 24px;
  }

  .ui {
    &.selection {
      &.dropdown {
        &.multiple {
          .icon {
            &.delete {
              position: relative;
              right: 0;
            }
          }
        }
      }
    }

    &.input {
      input {
        border-color: #000;
      }
    }
  }

  .search-container {
    border-radius: 4px !important;
    width: 100% !important;
    min-height: 50px !important;
  }

  textarea {
    border: 1px solid #ccc;
  }

  .btn-cancel {
    background: #ffffff;
    border: 1px solid #0000A1;
    box-sizing: border-box;
    border-radius: 4px;
    color: #0000A1;
  }

  .btn-action {
    background: #0000A1;
    border-radius: 4px;
    color: #ffffff;
  }

  .user-id-header {
    background: #e0e1e8;
    border-radius: 8px 8px 0px 0px;
  }

  .delete-warning-msg {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 2rem;
  }
}

.filter-settings-container {
  width: 900px;
  height: 475px;
  text-align: left;

  .medium-text-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0.25rem;
  }

  .checkbox-container {
    height: 300px;
    overflow-y: auto;

    .checkbox-filter {
      flex: unset;

      input[type="checkbox"] + label::before {
        background-color: #ffffff;
      }

      input[type="checkbox"]:checked + label::after {
        background-color: #0000A1;
        color: #ffffff;
      }
    }

    .filter-not-found {
      font-size: 14px;
    }
  }

  .btn-reset {
    background: #ffffff;
    border: 1px solid #0000A1;
    box-sizing: border-box;
    border-radius: 4px;
    color: #0000A1;
  }

  .btn-cancel {
    color: #0000A1;
    border: none;
    background: transparent;
  }

  .btn-apply {
    background: #0000A1;
    border-radius: 4px;
    color: #ffffff;
  }

  .filter-search {
    border-radius: 4px !important;
    margin-bottom: 1rem;
  }

  .filter-settings-footer {
    border-top: 1px solid #A4A7B2;
    padding-top: 2rem;
  }
}
