.kpi-overview-merge-container {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px -5px 20px 0px #ccc;
  margin-bottom: -1px;
  display: flex;
  flex-wrap: wrap;

  .kpi-chart-containter {
    background-color: #FFF;
    padding: 10px 30px;
    width: 100%;
    border-top: solid 1px #D9D9E3;
    .header {
      color: #0000A1;
      align-items: center;
      justify-content: space-between;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      padding: 0;
      .detail {
          align-items: center;
          gap: 5px;
          .icon {
              cursor: pointer;
          }
          .icon-rotate {
              cursor: pointer;
              transform: rotate(180deg);
          }
      }
    }
  }

  .action-btn {
    height: 46px;
    width: 46px;
    margin-right: 0 !important;
    background: white;
    color: #0000A1 !important;
    text-align: center;
    font-size: 18px;
    line-height: 18px;
    border: none;
    outline: 0px;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;

    .icon {
      margin-right: 0 !important;
    }

    &:disabled {
      border: none !important;
    }
  }

  .kpi-overview {
    padding: 0px 5px 5px 0;
    flex: 1;

    .fueltrax-logo-container {
      margin-top: 50px;
    }

    .ascenz-logo-container {
      margin-top: 50px;
    }

    .enginei-logo-container {
      margin-top: 50px;
    }
  }

  .trigger-btn:focus {
    background-color: $color-white;
  }

  .trigger-btn:hover {
    background-color: $color-white;
  }

  .chart-container {
    width: 175px;
    text-align: center;

    .chart-name {
      color: #6b6c70;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      margin-left: 15px;

      p {
        margin: 0 5px;
      }

      img {
        display: inline-block;
        width: 16px;
        height: 16px;
      }
    }
  }

  .chart-title-position {
    margin-top: -35px;
  }

  .fleet-composition-cover {
    display: flex;
    width: 100%;

    .fleet-composition-tabs {
      background-color: #f5f7fe;
      display: flex;
      flex-wrap: wrap;
      width: 90%;
      padding-left: 90px;

      .fleet-composition-tab {
        display: flex;
        flex-direction: column-reverse;
        // align-items: center;
        padding: 10px 0;
        color: #000000;
        width: 14%;

        p {
          margin: 0 5px;
          font-weight: 600;

          &:first-child {
            font-size: 12px;
            line-height: 12px;
          }

          &:last-child {
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.03em;
            color: #6b6c70;
          }
        }
      }
    }

    .manage-vessels {
      background-color: #f5f7fe;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      color: #0000A1;

      i {
        margin-bottom: 10px;
        cursor: pointer;
      }

      a {
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .vessel-container {
    min-width: 200px;
    position: relative;

    .vessel-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      &.show-total-fleet {
        text-align: center;
        margin-top: 10px;
        left: 35%;
      }

      .vessel-value {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #000000;
        margin-top: 10px;

        .total-fleet {
          color: #6b6c70;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          text-align: left;
          p {
            color: #202529;
            font-size: 24px;
            line-height: 32px;
            text-align: center;

            &:last-child {
              color: #0000A1;
              font-weight: 600;
              font-size: 12px;
              line-height: 24px;
              cursor: pointer;
            }
          }
        }

        .explore-vessel-value-content p {
          margin-top: 1rem;
          color: #0000A1;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }

    .fleet-composition-handle {
      display: flex;
      width: 100%;
      left: 50%;
      color: #4a5496;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      justify-content: space-between;
      padding: 0 10px;
      height: 50px;
      transform: translateY(-35px);
      background: #f5f7fe;
      transition: all 0.3s;
      cursor: pointer;

      .arrow {
        width: 15px;
      }
    }
  }

  .emission-container {
    min-width: 175px;
    margin-bottom: 15px;
    margin-left: 20px;
    justify-content: center;

    .emission-value {
      display: inline-block;
      font-size: 24px;
      color: #30384b;
    }

    .emission-subs {
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #6b6c70;
      font-weight: 600;

      img {
        display: inline;
        width: 16px;
      }
    }
  }

  .export-container {
    min-width: 200px;
    margin-bottom: 15px;
    margin-top: 15px;
    border-left: 1px solid #ccc;
    justify-content: center;

    .export-text {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.04em;
      color: #0041f0;
      text-align: start;
    }
  }

  .share-btn {
    cursor: pointer;
  }
}


@media (max-width: 768px) {
  .fleet-composition-title {
    color: var(--neutrals-dark-almost-black, #202529);
    /* Subheading/Medium */
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 136.364% */
    text-align: center;
  }

  .pad-right-0_5 {
    font-size: 10px;
  }

  .kpi-item-wrapper {
    flex: 40%;

    &.fleet-composition {
      margin-left: 10px;
      margin-top: 35px;
    }

    .total-fleet {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10%;

      .total-fleet-number {
        font-size: 21px;
        font-weight: 600;
      }

      .total-fleet-text {
        font-size: 10px;
      }
    }

    .emission-value {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .emission-subs {
      display: flex;
      justify-content: center; 
      align-items: center
    }
  }
}