
.widget-date-picker {
    width: 40%;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-transform: none;
    letter-spacing: 0em;
    color: #242433;
    border: 1px solid #9f9fac;
    border-radius: 8px;
    margin-bottom: 20px;
    padding: 12px;
    cursor: pointer;
    .calender-icon {
        text-align: left;
        margin-right: 10px;
    }
    .calender-picker {
        width: 90%;
        display: flex;
        justify-content: space-between;

    }
}
@media (max-width: 768px) {

    .widget-date-picker{
        width: 100%;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
    }
}
