.demand-submitted-page {
    padding: 150px 80px 0px 80px;
    .page-title {
        font-size: 34px;
        font-weight: 500;
    }
    .page-subtitle {
        display: inline-flex;
        align-items: center;
        margin-top: 40px;
        .subtitle-icon {
            width: 32px;
            height: 32px;
        }
        .p-s-title {
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            color: #1C7D55;
        }
    }
    .page-alert {
        margin-top: 25px;
        font-size: 16px;
    }
    .page-booing-focal-alert, .page-iap-alert {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        .email a {
            color: #4B7093;        
        }
    }
    .text-bold {
        font-weight: 510
    }
    .hyperlink a {
        text-decoration: none;
        color: #D0460B;
    }
}