.demand-booking-page {

  .button-demand {
    font-family: $font-normal !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
    padding: 16px 32px !important;
    border-radius: 4px !important;

    &.button-bg {
      background-color: #0000A1 !important;
      color: #fff !important;
    }
  }
 
  .demand-booking-form-container {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding: 73px 96px 73px 96px;
    border-radius: 10px;
    margin-bottom: 80px;

    .main-form-label {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      margin-bottom: 45px;
    }

    .add-cargo-field {
      display: flex;
      align-items: center;
      cursor: pointer;
      .add-cargo-btn {
        color: #0000A1;
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        margin-left: 12px;
      }
    }
  
    .demand-form-container {
      margin-top: 40px;
      
      .ui.input > input {
        font-size: 16px;
        line-height: 22.4px;
      }
  
      .main-label {
        font-weight: 600;
        font-size: 16px;
        color: #000;
        line-height: 16px;
      }
  
      .label-title {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        line-height: 12px;
      }
  
      .transport-form-field {
        width: 100%;
      }

      .transport-label-title {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        line-height: 12px;
        width: 200px;
      }
  
      .demand-ddl {
        width: 100% !important;
        font-size: 16px !important;
        line-height: 22.4px !important;
      }

      .volume-ddl {
        font-size: 16px !important;
        font-weight: 400;
        line-height: 22.4px !important;
        border: 1px solid #ccc !important;
        color: #000000;
        background-color: #FFFFFF !important;
      }

      .delete-icon-container {
        position: relative;
        left: 88px;
        top: 10px;
        cursor: pointer;
      }

      .volume-input {
        border-radius: 4px !important;
        height: 43px !important;
        width: 100px;
      }
      .request-input {
        border-radius: 4px;
        width: 150px;
      }

      .demand-secondary-ddl {
        width: 200px !important;
        font-size: 16px !important;
        line-height: 22.4px !important;
      }
  
      .radio-label {
        font-size: 16px !important;
        font-weight: 400px !important;
      }
  
      .input-field {
        border-radius: 4px !important;
      }
    }
  }
  
  .banner-container {
    padding: 16px 26px 16px 26px;
    background-color: #F9B262;
    border-radius: 10px;
    width: 100%;
    
    .annoucement-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
