.planningpage {
  .filter-div {
    z-index: 997;
    background-color: $color-white;
    top: 130px;
    position: fixed;
    left: 0px;
    transition: left 0.3s;
    width: 200px;
    padding-top: 0;
    align-items: center;
    padding-left: 16px;
    height: 40px;
    cursor: pointer;
    .title {
      font-family: $font-normal !important;
      font-weight: $fontweight-600;
      font-size: $fontsize-14;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #000000;
    }
    .filterDiv {
      align-items: center;
    }
    .filter {
      .alpha-chevron {
        background: $color-white;
        z-index: 1001;
        top: 130px;
        position: fixed;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 250px;
        transition: left 0.3s;
      }
      .filter-container {
        z-index: 1001;
        background-color: $color-white;
        top: 115px;
        position: fixed;
        min-width: 250px;
        height: auto;
        left: 0px;
        transition: left 0.3s;

        .filter-content {
          width: 100%;
          height: auto;
          padding: 22px;

          .title {
            font-family: $font-normal !important;
            font-weight: $fontweight-600;
            font-size: $fontsize-14;
            line-height: 16px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #000000;
          }
          .filterform {
            font-family: $font-normal !important;
            font-weight: $fontweight-700;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: $color-black;
            flex-direction: column;
            .filterform-title {
              margin-bottom: 6px;
            }
          }
          .filterform:first-child {
            margin-top: 10px;
          }

          .button {
            display: flex;
            justify-content: center;
            width: 200px;
            button {
              width: 100%;
            }
          }
        }
      }
    }
    .chevron:hover {
      background-color: $color-primary;
      fill: $color-white;
    }
  }
}
