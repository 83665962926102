.new-ui-custom-slider {
    width: 100%;
    .slider-header {
      display: inline-flex;
      font-weight: 600;
      position: relative;
      width: 100%;

      .menu-title {
        color: #242433;
        /* Heading/Heading 4/Medium */
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px; /* 130.769% */

        padding-left: 20px;
        padding-top: 32px;
        padding-bottom: 32px;
      }
  
      .slider-header-detail {
        background-color: #0000A1 !important;
        margin-left: 24px;
        .slider-header-label {
          font-size: 14px;
          line-height: 14px;
          color: #6b6c70;
          margin-bottom: 8px;
        }
  
        .slider-header-title {
          font-size: 16px;
          line-height: 16px;
          color: #202529;
        }
      }
    }

    .slider-direction-container {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        margin-top: 56px;
        .slider-direction {
            width: 72px;
            height: 24px;
            // position: absolute;
            // top: 0;
            // right: 0;
            display: flex;
            justify-content: space-between;
      
            .slider-direction-item {
              display: flex;
              align-items: center;
              font-family: $font-normal !important;
              font-weight: normal;
              font-size: 14px;
              line-height: 21px;
            }
        }
      
        .chevron-right {
            transform: rotate(180deg);
        }
    }
  
    .slider-body {
      padding: 0 20px;
    }
  
    .slick-dots li {
      margin: 0;
  
      button:before {
        font-size: 8px !important;
      }
    }
  
    .slick-dots li.slick-active button:before,
    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
      color: #0000A1 !important;
    }
  }

@media (max-width: 768px) { 
  .new-ui-custom-slider {
    width: 100%;
    .slider-header {
      .menu-title {
        color: #242433;
        /* Paragraph/Paragraph 2/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */

        padding-top: 14px;
        padding-bottom: 20px;
      }
    }

    .slider-direction-container {
      margin-top: 32px;
      margin-right: 10px;
    }

    .slider-body {
      padding: 0 14px;
    }
  }
}
  