.period-picker {
  .overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 0, 0, 0);
    z-index: 10001;
  }

  .date-range-picker-custom {
    .place-holder {
      color: rgba(191, 191, 191, 0.87);
      font-weight: 400;
    }

    &.date-range-picker-custom-error {
      border-color: red !important;
    }
  }

  .period-picker-btn {
    background-color: transparent !important;
    color: #fff !important;
  }

  .period-picker-btn:hover {
    color: #fff !important;
  }

  i.icon,
  i.icons {
    font-size: 0.9em;
    margin: 0 -0.75rem 0 0;
  }

  .input-field {
    border-radius: 4px !important;
    font-size: 16px !important;
    line-height: 22.4px !important;
  }

  .month-chevron,
  .week-chevron {
    display: flex;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      // padding-left: 10px;
    }

    button {
      margin: 0;
      // padding-left: 10px;
      background-color: transparent;
      font-size: 14px;
      line-height: 16px;
      color: #202529;
    }
    button:hover {
      background-color: transparent;
    }
  }
}

.popup {
  .optimise-picker {
    background-color: #fff;
    .datetime-content {
      margin: 1rem;
      .datetime-text {
        float: left;
        text-align-last: start;
      }
      .inline {
        display: inline-flex;
        .labeled {
          .vertical {
            button {
              border: none;
              background-color: #fff;
            }
            Input {
              border-radius: 0;
              max-width: 5rem;

              &.input-ampm {
                cursor: default;
                pointer-events: none;
              }
              
              input {
                text-align:center;
              }
            }
            .visible {
              visibility: hidden !important;
            }
          }
        }
      }
    }
  }
}

.vtp-date-picker-select-time {
  border: none !important;
  border-radius: 10px !important;
  .optimise-picker {
    width: 406px;
    margin: 0;
    padding: 24px;
    flex-direction: column;
    border-radius: 10px !important;
    hr {
      margin: 24px 0;
    }
    .datetime-content {
      margin: 0;
      .select-date {
        gap: 94px;
      }
    }
    .rdrCalendarWrapper {
      width: 100%;
      .rdrMonth {
        width: 100%;
        padding: 0;
      }
      .rdrNextPrevButton, .rdrPprevButton {
        display: none;
      }
      .rdrMonthAndYearPickers {
        text-align: left;
        justify-content: start;
        margin-bottom: 14px;
        .rdrMonthPicker, .rdrYearPicker {          
          select {
            text-align: left;
            width: 130px;
            &:hover {
              background-color: #fff;
            }
          }
          margin: 0;
          margin-right: 16px;
          border: 1px solid #ccc;
          height: 46px;
          width: 130px;
          display: flex;
          align-items: center;
          font-size: 14px;
          justify-content: center;
          text-align: left;
          border-radius: 8px;         
        }
      }
    }
    .time-content {
      align-items: center;
      .selection {
        height: 46px;
        min-width: 0 !important;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #5C5C7A;
        justify-content: space-between;
        padding-left: 16px;
        border-radius: 8px;

        &.ui.upward.dropdown.visible {
          border-radius: 0  0  8px 8px !important;
        }

        .visible.menu.transition {
          border-radius: 8px 8px 0  0;
        }

        &:active, &:focus {
          border-radius: 8px;
        }
      }
      .two-dots-mark {
        font-size: 18px;
        height: 46px;
        line-height: 46px;
      }
      .time-title{
        margin-bottom: 4px;
      }
    }

    .action {
      margin-top: 36px;
      text-align: right;
      .btn {
        height: 34px;
        width: 89px;
        font-size: 12px;
        border-radius: 6px;
        margin: 0;
        border: 1px solid #0000A1;
      }
      
      .btn-cancel {
        margin-right: 16px;
        background: none;
        color: #0000A1;

        &:hover  {
          background-color: #0000A1;
          color: #fff;
        }
      }

      .btn-apply {
        background-color: #0000A1;
        color: #fff;
      }
    }
  }
}