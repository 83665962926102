.vod-voyage-planning-container {
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  margin: 14px;

  .vod-card-header {
    font-size: 26px;
    color: #242433;
    font-weight: 600;
  }

  .summary-container {
    flex-wrap: wrap;
    .info-content {
      width: 45%;
      .info-icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 10px;
        width: 72px;
        height: 72px;
        background: #dcf3d8;
        border-radius: 16px;
      }

      .info-detail {
        margin: auto 0;

        .value {
          font-weight: 500;
          font-size: 30px;
          line-height: 38px;
          color: #4b7093;
        }

        .type {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #242433;
        }
      }
    }
  }
}
