.fleet-composition-all {
    background-color: #FFF;
    width: 100%;

    .header {
        color: #0000A1;
        align-items: center;
        justify-content: space-between;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        padding: 10px 30px;
        .detail {
            align-items: center;
            gap: 5px;
            .icon {
                cursor: pointer;
            }
            .icon-rotate {
                cursor: pointer;
                transform: rotate(180deg);
            }
        }
    }
    .content {
        align-items: flex-start;
        justify-content: space-between;
        background-color: #F9F9FB;
        padding: 5px 30px;
        border-top: solid 1px #D9D9E3;
        &.flex-start{
            justify-content: start!important;
          }
        .box-content {
            background: #F9F9FB;
            border-radius: 4px;
            padding: 8px;
            height: 107px;
        }
        .box-title {
            color: #4A5496;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
        }
        .box-text {
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            padding: 10px 0;
            height: 70px;
            gap: 10px;
            .detail {
                align-items: center;
                display: grid;
                grid-template-columns: 40px auto;                
            }
            .number {
                font-weight: 600;
                font-size: 20px;
                color: #6B6C70;
            }
            .text {
                font-weight: 400;
                font-size: 14px;
                color: #202529;
            }
        }
    }
}