

.mooring-buoy-container {
  background-color: white;
  width: 100%;
  border-radius: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 32px;

    .menu-title {
      color: #242433;
      font-family: Inter;
      font-size: 26px;
      font-weight: 500;
      line-height: 34px;
      text-align: left;
      margin-bottom: 10px;
    }
    .widget-date-picker {
      width: 40%;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-transform: none;
      letter-spacing: 0em;
      color: #242433;
      border: 1px solid #9f9fac;
      border-radius: 8px;
      margin-bottom: 10px;
      padding: 12px;
      // justify-content: space-between;
      cursor: pointer;
      .calender-icon {
        text-align: left;
        margin-right: 10px;
      }
      .calender-picker {
        width: 90%;
        display: flex;
        justify-content: space-between;

      }
    }

    .mooring-buoy-id {
      margin: 40px 0;
      .mt-1 {
        margin-top: 20px;
      }
      .mooring-buoy-id-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        span {
          color: #6b6c70;
          margin-right: 2px;
        }
        b {
          color: #202529;
        }
      }
    }
    .next-arriving-vessel {
      color: #202529;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      margin-top: 40px;
      .arriving-title {
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: #242433;
      }
      .empty-tab {
        font-weight: normal;
        height: 100px;
        font-size: 14px;
        line-height: 24px;
        color: #202529;
    
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      .min-tab {
        min-height: 300px;
      }
      
      .paging-right {
        display: flex;
        justify-content: end;
      }
      .dotted-pagination {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top: -20px;
        margin-left: 14px;

      
        .dot {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin: 0 5px;
          background-color: #CACAD8;
          cursor: pointer;
        }
      
        .dot.active {
          background-color: #242433;
        }
      }
    }
    
    .no-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      .manage-button {
        color: #0000A1;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .manage-button-conntainer {
      width: 100%;
      text-align: right;

      .manage-button {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #D0460B;
      }
    }

    .next-schedule-conntainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      // text-align: right;
      .sub-title {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: right;
        color: #5C5C7A;
        padding-right: 10px;
        padding-top: 12px;
      }
      .dropdown-conmonent {
        min-width: 250px;
        display: flex;
        padding: 12px !important;
        // padding-bottom: -12px;
        border-radius: 8px;
        border: 1px solid #D9D9E3;
        gap: 12px;
        z-index: 199;

        .dropdown {
          padding-top: 2px;
          min-width: 200px;
          justify-content: space-between;
        }
        
      }
    }
    @media (max-width: 768px) {
      .menu-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .manage-button-conntainer {
        text-align: left;  
        .manage-button {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }  
      }
      .widget-date-picker{
        width: 100%;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
      }
      .next-schedule-conntainer{
        display: block;
        justify-content:left;
        margin-top: 10px;
        width: 100%;
        .dropdown-conmonent { 
          width: 100%;
          justify-content: space-between;
          z-index: 1999;
          .dropdown {
            padding-top: 2px;
            min-width: 95%;
            justify-content: space-between;
            z-index: 1999;
          }
        }
      }
    }
}