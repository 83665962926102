.create-vessel-schedule-page-v1 {
  height: calc(100vh - 90px);
  padding-top: 9rem;
  padding-left: 165px;
  padding-right: 165px;
  // padding-bottom: 162px;
  padding-bottom: 72px;

  &.publish-page{
    padding-bottom: 172px;
  }

  &.footer-expanded{
    padding-bottom: 350px;
  }

  .create-vessel-schedule-container {
    .header {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.03em;
    }

    .sub-header {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }

    .label-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #09010e;
    }

    .region-content {
      flex: 1;
    }

    .date-picker-content {
      margin: auto;
    }

    .next-button {
      align-self: end;
    }

    .selected-label {
      font-size: 16px;
      line-height: 24px;
    }

    .selected-value {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }

    .view-option-button {
      padding: 5px;
      background: #ffffff;
      border: 1px solid #0000A1;
      box-sizing: border-box;
      border-radius: 4px;

      &.selected {
        background: #dae3ec;
        border: 1px solid #460073;
      }
    }

    .view-toggle-container {
      margin-left: auto;
    }

    .tab-container {
      .ui {
        &.menu {
          margin: 0;
          background: #f5f7fe;
          border-bottom: 0;
          border-radius: 0px 8px 0px 0px;
          height: 49px;

          .item {
            padding: 0;
            border: none;
            margin-bottom: 0px;
          }

          .active {
            // padding: 0;
            border: none;

            .tab-selector {
              background-color: #fbfcff;
            }
          }
        }
      }

      .tab-content {
        width: 100%;
        min-height: 500px;
        // max-height: 580px;
        background: #fbfcff;
        border-radius: 0 0 16px 16px;
        // overflow-y: scroll;

        &.marine-tab-content {
          background: #fff;

          .scenario-schedule-planner-page{
            min-height: 500px;
            background: #fbfcff;
          }
        }

        .tab-content-message-box {
          height: 500px;

          p {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #202529;
          }
        }

        .mp-services-content {
          min-height: 500px;
          display: flex;

          .mp-services-message-box {
            width: 100%;
            justify-content: center;
            align-self: center;

            .mp-services-message-line {
              width: 100%;
              justify-content: center;
              align-self: center;

              p {
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
              }

              .text-yellow {
                color: #f99c39;
              }

              .text-purple {
                color: #0000A1 !important;
              }

              .text-green {
                color: #2BCF9E !important;
              }

              .mp-services-message-subtext {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
              }

              .mp-services-message-try-again {
                cursor: pointer;
              }
            }

            .loading {
              animation: spin 1s linear infinite;
            }

            @keyframes spin {
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }

          .scenario-model-page {
            width: 100%;
          }
        }
      }

      .tab-selector {
        width: 255px;
        padding: 12px 20px;
        border-right: 1px solid #a4a7b2;

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: #202529;
        }

        &.filler {
          width: 0;
          height: 49px;
          padding: 0;
          border: none;
        }
      }
    }

    .add-service-button {
      position: absolute;
      right: 0px;
      width: 175px;
      padding: 12px 20px;
      cursor: pointer;
      z-index: 2;

      &.disabled {
        cursor: not-allowed;

        p {
          color: #bab3be;
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #0000A1;
      }
    }

    .card-container {
      margin: 40px 0;

      .card-custom {
        padding: 3rem 1rem;
        margin-bottom: 17px;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
        background: #ffffff;
        box-shadow: inset 0px -1px 0px #e5e6dc;
        border-radius: 8px;

        .card-content {
          flex: 1;
          .card-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.03em;
            margin-bottom: 5px;
          }

          .card-status {
            span {
              font-size: 16px;
              font-weight: 600;
              margin: auto 0;
              &.red {
                color: #ef5d60;
              }

              &.green {
                color: #2bcf9e;
              }
            }
          }
        }

        .card-button {
          margin: auto;
          .button {
            border-radius: 4px;
          }
        }
      }
    }

    .confirm-schedule-content {
      .job-type-header {
        padding: 12px 24px;
        border-radius: 4px 4px 0 0;

        &.material-header {
          background-color: #fbe165;
        }

        &.people-header {
          background-color: #c4dfd7;
        }

        &.marine-header {
          background-color: #aed2f2;
        }

        .job-type-header-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
        }
      }

      .create-vessel-schedule-voyage-card {
        background: #fbfcff;
        margin-top: 8px;
        padding: 24px;
        border: 0;
        border-radius: 0;
        // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

        &.expanded {
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        }

        &:hover {
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        }

        .right-content {
          margin-left: auto;
        }

        .vessel-name {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          color: #b455aa;
        }

        .vessel-voyage-number {
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          color: #6b6c70;
        }

        .table-content {
          .table {
            background-color: transparent;
            margin-top: 24px;
            border: 0;
            border-collapse: collapse;
            table-layout: fixed;
          }

          thead {
            tr {
              border-top: 0px;
            }
          }

          th {
            background-color: transparent;
            color: #6b6c70;
          }

          tr {
            border-top: 1px solid rgba(34, 36, 38, 0.1);

            td {
              border-top: 0;
            }
          }

          .label-value {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
          }

          .location {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #460073;
            background: #f0f0fa;
            border-radius: 8px;
            padding: 5px 10px;
            margin: 5px 10px 5px 0;
            flex: 0 0 auto;
          }

          .arrow-location {
            color: #460073;
            margin: auto 10px auto 0;
            font-size: 16px;
          }

          .table-sequence-location {
            overflow-x: auto;
          }
        }
      }
    }

    .confirm-vessel-schedule-legend {
      position: fixed;
      left: 0px;
      bottom: 106px;
      width: 100%;
      background-color: #fff;
      padding: 27.5px 0px 27.5px 165px;
      border-radius: 0.28571429rem;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

      &.expanded{
        bottom: 260px;
      }
    }
  }
}

.create-vessel-schedule-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px 165px;
  background-color: #f5f7fe;
  z-index: 998;
}

.service-options-container {
  .service-options {
    width: 250px;
    padding: 15px 24px;
    cursor: pointer;

    &:nth-child(2) {
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #202529;
    }
  }
}

.modal-close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}