.new-ui-shipment-en-route-container {
    border-radius: 16px;
    background: var(--shades-white, #FFF);
    padding-bottom: 32px;

    .custom-slider .slider-body {
      padding: 0 !important;
    }
  
    .ui.tabular.menu {
      border-bottom: none;
      margin-bottom: 34px;
      overflow: auto;
      overflow-y: hidden;
      border-radius: 4px;

      .item {
        line-height: 18px;
        border: none;
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);

        /* Heading/Heading 6/Medium */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
      }
    }
  
    .ui.tabular.menu .active.item {
      border-bottom: 4px solid #242433;
      color: #000000;
    }
  
    .empty-tab {
      height: 400px;
      font-size: 18px;
      line-height: 24px;
      color: #202529;
  
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

@media (max-width: 768px) { 
  .new-ui-shipment-en-route-container {
    height: 644px;

    .ui.tabular.menu {
      margin-bottom: 20px;
      
      .item {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Caption/Medium */
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
      }
    }
  }
}
  