.iap-approval-page {
  padding: 165px 152px 84px;

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.03em;
    color: #202529;
    margin-bottom: 40px;
  }

  .reservation-card-container {
    cursor: pointer;
    .reservation-card-top {
      background: #eaeaea;
      border-radius: 8px 8px 0px 0px;
      position: relative;
      .reservation-label {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #242433;
        align-items: center;
        padding: 12px 16px;
      }
      .download-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        right: 24px;
        top: 8px;
      }
    }
    .reservation-card-bottom {
      padding: 16px 50px 27px 16px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 8px 8px;
      .voyage-label {
        color: #0000a1;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
      }

      .reservation-details-container {
        width: 100%;

        .reservation-location-section {
          width: 45%;

          .start-location-container {
            width: 50%;
          }

          .end-location-container {
            margin-left: 40px;
            margin-right: 30px;
            width: 50%;
          }
        }

        .reservation-details-section {
          width: 35%;

          .payload-details-container {
            width: 100%;

            .payload-content-container {
              align-items: center;
            }

            .payload-details {
              font-size: 16px;
              line-height: 16px;
              font-weight: 600;
              color: #202529;
            }
          }
        }

        .property-label {
          margin-left: 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        }

        .content-sub-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          &.active {
            color: #ffffff;
          }
        }

        .top-card-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          color: #6b6c70;
          margin-bottom: 10px;
        }

        .status-section {
          width: 25%;

          .reservation-status-container {
            align-items: center;

            &.delete {
              svg path {
                fill: #ef5d60;
              }
            }
            &.ready {
              svg path {
                fill: #2bcf9e;
              }
            }
            &.warning {
              svg path {
                fill: #f9b262;
              }
            }

            .reservation-status {
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;

              &.delete {
                color: #ef5d60;
              }

              &.ready {
                color: #2bcf9e;
              }

              &.warning {
                color: #f9b262;
              }
            }
          }
        }

        .content-date-sub-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          &.active {
            color: #ffffff;
          }
        }

        .content-label {
          color: #96968c;
          font-size: 12px;
          font-weight: 600;
          line-height: 12px;

          &.active {
            color: #ffffff;
          }
        }

        .arrow {
          position: relative;
          width: 200px;
          margin-top: 10px;
          height: 0;
          border-bottom: 2px solid #bab3be;
          &.active {
            border-bottom: 2px solid #ffffff;
          }
        }

        .arrow::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #bab3be;
          position: absolute;
          right: -10px;
          top: -4px;
        }

        .arrow.active::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #fff;
          position: absolute;
          right: -10px;
          top: -4px;
        }
      }

      .reservation-footer {
        height: 80px;
        background: #f5f7fe;
        margin: 34px -50px -27px -16px;
        padding: 16px 24px;
        display: flex;

        .reservation-saving {
          width: 76%;
          display: flex;
          align-items: center;
          padding-left: 58.5%;

          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #0000a1;

          .tag-icon {
            margin-right: 8px;
          }
        }

        .reservation-actions {
          display: flex;
          // justify-content: end;
          justify-content: flex-end;
          width: 38%;

          .ui.button {
            height: 48px;
            padding: 12px 32px;

            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            .ui.image {
              display: inline;
            }
          }

          .reject-button {
         
            margin-right: 16px;
          }

          .approve-button {
            background: #0000a1;
            border-radius: 4px;
            color: #fff;
            border: 1px solid #0000a1;
            .ui.image {
              margin-right: 12px;
            }
          }
        }
      }
    }
  }

  .ui.secondary.pointing.menu {
    border-bottom: none;
    margin-bottom: 34px;
    .item {
      color: #bab3be;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 2px solid #0000a1;
    color: #000000;
  }

  .empty-tab {
    height: 400px;
    font-size: 18px;
    line-height: 24px;
    color: #202529;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
