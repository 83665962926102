// ====================== CONTRACT LOG ============================
.vessel-management-page-new {
  #contract-log-page {
    .btn-add-contract {
      font-size: 16px;
      padding: 6px 1.5rem !important;
      line-height: inherit !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      &:hover svg path {
        color: #ffffff;
        background-color: #0000A1;
        stroke: #ffffff;
        fill: #ffffff;
      }
      p {
        line-height: 1.4285em !important;
      }
    }

    .checked-category-container {
      margin-top: 1rem;
      .btn-clear {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border: none;
        width: 250px;
        border-radius: 4px;
        color: #0000A1;
        background-color: #ffffff;
        padding: 7px 1.5rem !important;
        width: auto;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          background-color: #0000A1;
        }
      }
    }

    .contract-log {
      margin-top: 2rem;
      tbody {
        font-weight: 400;
        font-size: 14px;
        > tr {
          &:hover {
            td {
              i {
                color: #0000A1;
              }
            }
          }
          &.primary-contract {
            cursor: pointer;
            &:nth-child(4n-1) {
              background-color: #ffffff !important;
            }
            &:nth-child(4n-3) {
              background-color: #fbfcff !important;
            }
          }
          .comment {
            max-width: 280px !important;
          }

          &.row-edit {
            display: contents;
            &:nth-child(4n-2) {
              td {
                background: #fbfcff !important;
              }
            }
            &:nth-child(4n) {
              td {
                background: #ffffff !important;
              }
            }
            > tr {
              &.secondary-service-item {
                .empty-message {
                  padding: calc(2.5rem - 0.78571429em) 0 2.5rem 0;
                  font-size: 24px;
                  color: #939393;
                  text-align: center;
                }

                &:last-child {
                  td {
                    padding-bottom: 3rem;
                  }
                }
              }
              td {
                border: none;
              }
            }
          }
        }

        .action {
          text-align: center;
          i {
            color: #6b6c70;
          }
        }
        .title-sub-table {
          padding: 0.78571429em 0.78571429em;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;

          color: #4a5496;
        }
      }
      thead {
        th {
          background: #f5f7fe !important;
          border: none;
        }
      }
    }
  }
}
// ===================== END CONTRACT LOG ======================
