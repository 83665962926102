.full-screen-popup {
    position: fixed;
    z-index: 992;
    left: 0;
    top: 100px;
    width: 100%;
    height: calc(100% - 100px);
    background-color: white;
    overflow-y: auto;

  &::-webkit-scrollbar {
      width: 8px!important;
      background: none !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #CACAD8!important;
    }
    &::-webkit-scrollbar-track {
      background: none !important;
    }


  .content {
    padding: 40px;
    .title {
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: left;
      color: #202529;
    }
    .close-icon {
      cursor: pointer;
    }
  }
}

@media (min-width: 768px) { 
  .full-screen-popup {
    display: none;
  }
}