//rem calculations
$rem-base: 16px;
$rem-1: 1px / $rem-base * 1rem;
$rem-2: 2px / $rem-base * 1rem;
$rem-3: 3px / $rem-base * 1rem;
$rem-4: 4px / $rem-base * 1rem;
$rem-5: 5px / $rem-base * 1rem;
$rem-6: 6px / $rem-base * 1rem;
$rem-7: 7px / $rem-base * 1rem;
$rem-8: 8px / $rem-base * 1rem;
$rem-10: 10px / $rem-base * 1rem;
$rem-12: 12px / $rem-base * 1rem;
$rem-13: 13px / $rem-base * 1rem;
$rem-14: 14px / $rem-base * 1rem;
$rem-15: 15px / $rem-base * 1rem;
$rem-16: 16px / $rem-base * 1rem;
$rem-18: 18px / $rem-base * 1rem;
$rem-20: 20px / $rem-base * 1rem;
$rem-24: 24px / $rem-base * 1rem;
$rem-26: 26px / $rem-base * 1rem;
$rem-28: 28px / $rem-base * 1rem;
$rem-30: 30px / $rem-base * 1rem;
$rem-32: 32px / $rem-base * 1rem;
$rem-34: 34px / $rem-base * 1rem;
$rem-36: 36px / $rem-base * 1rem;
$rem-40: 40px / $rem-base * 1rem;
$rem-44: 44px / $rem-base * 1rem;
$rem-48: 48px / $rem-base * 1rem;
$rem-56: 56px / $rem-base * 1rem;
$rem-64: 64px / $rem-base * 1rem;
$rem-72: 72px / $rem-base * 1rem;
$rem-78: 78px / $rem-base * 1rem;
$rem-88: 88px / $rem-base * 1rem;
$rem-96: 96px / $rem-base * 1rem;
$rem-100: 100px / $rem-base * 1rem;
$rem-112: 112px / $rem-base * 1rem;
$rem-119: 119px / $rem-base * 1rem;
$rem-132: 132px / $rem-base * 1rem;
$rem-156: 156px / $rem-base * 1rem;
$rem-196: 196px / $rem-base * 1rem;
$rem-376: 376px / $rem-base * 1rem;


//spacing
$space-4: $rem-4;
$space-5: 0.3125rem;
$space-8: 0.5rem;
$space-10: 0.625rem;
$space-12: 0.75rem;
$space-15: 0.9375rem;
$space-16: 1rem;
$space-18: 1.125rem;
$space-20: 1.25rem;
$space-22: 1.375rem;
$space-24: 1.5rem;
$space-28: 1.75rem;
$space-26: 1.625rem;
$space-32: 2rem;
$space-34: 2.125rem;
$space-36: 2.25rem;
$space-40: 2.5rem;
$space-42: 2.625rem;
$space-48: 3rem;
$space-51: 3.1875rem;
$space-56: 3.5rem;
$space-60: 3.75rem;
$space-63: 3.9375rem;
$space-64: 4rem;
$space-66: 4.125rem;
$space-70: 4.375rem;
$space-72: 4.5rem;
$space-80: 5rem;
$space-92: 5.75rem;
$space-96: 6rem;
$space-124: 7.75rem;
$space-328: 20.5rem;
$space-88: 5.5rem;
$space-116: 7.25rem;
$space-136: 8.5rem;
$space-700: 43.75rem;

$space-156: $rem-156;

$flex-container: 8.75rem 1 0;