$people-service-color: #c4dfd7;
$material-service-color: #fbe165;
$marine-service-color: #aed2f2;
$supply-service-color: #f0aef2;
$other-service-color: #f2aeae;

$primary-color: #0000a1;

.card-container {
  background: #fff;
  border-width: 1px 1px 4px 1px;
  border-style: solid;
  width: 400px;
  padding: 20px 10px !important;
  border-radius: 4px;
  max-height: 222px;

  &.material-service {
    border-color: $material-service-color;
  }
  &.people-service {
    border-color: $people-service-color;
  }
  &.marine-service {
    border-color: $marine-service-color;
  }
  &.supply-service {
    border-color: $supply-service-color;
  }
  &.other-service {
    border-color: $other-service-color;
  }
  .card-header_container {
    .card-header {
      margin-bottom: 16px;
      .card-header_top {
        display: flex;
        justify-content: space-between;
        .title {
          color: #6b6c70;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }
        .card-header_action {
          min-width: 115px;
          button {
            border: none;
            color: $primary-color;
            font-weight: 600;
            font-size: 14px;
            background-color: transparent;
            padding: 5px 10px;
            margin-left: 3px;
            cursor: pointer;
          }
        }
      }
      .service_type {
        color: #202529;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .card-content_container {
    text-align: left;
    .card-content {
      margin-bottom: 8px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        &:first-child {
          color: #6b6c70;
        }
        &:last-child {
          color: #202529;
        }
      }
    }
  }
}
