.scenario-modelling-gantt-modal {

    .ui.disabled.dropdown,
    .ui.dropdown .menu>.disabled.item {
        cursor: default;
        pointer-events: none;
        background: 0.45 !important;
        opacity: 1 !important;
    }

    .ui.selection.dropdown {
        &.scenario-modelling-gantt-vessel-dropdown {
            background: #fff;
        }
    }

    .modal-header {
        padding: 32px !important;

        .label-header {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #09010e;
        }

        .modal-header-left {
            width: 55%;
        }

        .modal-header-right {
            margin-left: auto;
        }

        .modal-close-button {
            position: absolute;
            top: 14px;
            right: 14px;
        }

        .scenario-modelling-gantt-modal-title-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                overflow: visible;
            }
        }
    }

    .medium-text-black {
        font-weight: 100 !important;
    }

    .medium-text-bold {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #202529;
        padding: 5px 0;
    }

    .larger-medium-text-black {
        font-size: 16px;
        line-height: 21px;
        color: #202529;
        padding: 5px 0;
    }

    .larger-medium-text-bold {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: #202529;
        padding: 5px 0;
    }

    .modal-sub-header {
        padding: 24px 32px !important;

        .modal-sub-header-bar-container {
            width: 280px;
        }
    }

    .modal-header-input {
        width: 100% !important;
        height: 43px;
        background-color: #fff !important;
        border-radius: 4px;
        border: 1px solid #202529;
    }

    .bar {
        .bartext {
            div {
                display: inline-block;

                &:first-child {
                    width: 70%;
                    text-align: left;
                }

                &:last-child {
                    width: 30%;
                    text-align: right;
                }
            }
        }

        .progress-content {
            position: relative;

            .benchmark {
                position: absolute;
                width: 3px;
                height: 15px;
                margin-top: -4px;
                background: #d4d4d4;
                margin-left: auto;
                margin-right: auto;
                left: 30%;
                z-index: 1;
            }

            .progress {
                overflow: hidden;
                background-color: #e9ecef;
                position: relative;

                .progress-bar {
                    &.green {
                        background-color: #2bcf9e;
                    }

                    &.red {
                        background-color: #ef5d60;
                    }
                }
            }
        }
    }

    &.ui {
        .scrolling {
            &.content {
                height: calc(100vh - 377px);
            }
        }
    }

    .accordions-container {
        position: relative;
        // width: 80%;
        width: 100%;
        margin-top: 24px;
        // margin-left: 1rem;
        // will-change: unset;
    }

    .accordion-side-line {
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 35px;
        width: 2px;
        // background-color: #bab3be;

        &.material-last-expanded {
            // bottom: 595px;
            // bottom: 500px; //Sprint 11 Design
            bottom: 80px; //Sprint 12 Changes
        }

        &.people-last-expanded {
            bottom: 395px;
        }

        &.marine-last-expanded {
            bottom: 150px;
        }
    }

    .fixed-accordions-container {
        margin-left: 25px;

        .accordion {
            .accordion-header {
                background-color: #EBECF3;
            }
        }
    }

    .dragable-accordions-container {
        margin-left: 25px;
    }

    .accordion-holder {
        left: 25px !important;

        &:hover {
            .accordion-bullet {
                &.grey-circle {
                    height: 12px;
                    margin-top: 5px;
                    border-left: 0;
                    border-right: 0;
                    border-radius: 0;
                }
            }
        }
    }

    .accordion-bullet-container {
        position: relative;
        width: 22px;

        .accordion-bullet {
            position: relative;
            top: 16px;
            width: 22px;
            height: 22px;
            background-color: #fff;

            &.green-circle {
                border: 2px solid #2bcf9e;
                border-radius: 50%;
            }

            &.dark-blue-circle {
                border: 2px solid #4A5496;
                border-radius: 50%;
            }

            &.grey-circle {
                border: 2px solid #bab3be;
                border-radius: 50%;
            }

            &.red-circle {
                border: 2px solid #ef5d60;
                border-radius: 50%;
            }
        }

        .accordion-bullet-line {
            position: absolute;
            top: 0px;
            left: 10px;
            width: 2px;

            &.accordion-bullet-line-last {
                top: 16px;
                bottom: 0px;
                background-color: #fff;
            }
        }
    }

    .accordion {
        position: relative;
        // left: 1rem !important;
        width: 800px;
        margin: 0;
        // will-change: unset;

        .accordion-header {
            position: relative;
            width: 100%;
            padding: 16px;
            padding-right: 32px;
            background-color: #f5f7fe;
            border-radius: 8px 8px 0px 0px;

            // .accordion-delete-button {
            //     background-color: transparent;
            //     padding: 0;

            //     &.disabled {
            //         border-color: transparent;
            //     }
            // }

            &.header-expanded {
                padding: 16px 190px 16px 32px;
            }
        }

        .accordion-header-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
        }

        .accordion-header-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #4a5496;
            background: #ffffff;
            padding: 4px 8px;
            border: 1px solid #c4c8df;
            border-radius: 4px;
        }

        .accordion-header-subtitle {
            font-weight: normal;
            font-size: 14px;
            line-height: 26px;

            &.error {
                color: red;
            }

            .accordion-header-date {
                margin-right: 10px;
            }
        }

        .accordion-header-expand-toggle {
            margin-left: auto;
        }

        .accordion-header-expand-toggle-button {
            padding: 0;
            background: none;
        }

        .accordion-header-expand-icon {
            &.inverted {
                transform: rotate(180deg);
            }
        }

        .accordion-header-collapse-button-container {
            position: absolute;
            top: 36.5px;
            right: 32px;
        }

        .accordion-header-collapse-button {
            background-color: transparent;
            padding: 0;
        }

        .accordion-body {
            position: relative;
            width: 100%;
            padding: 0 32px;
            background-color: #fbfcff;

            &.hide {
                display: none;
            }
        }

        .accordion-body-section {
            position: relative;
            width: 100%;
            border-bottom: 1px solid #e0e1e8;
            padding: 32px 0;
            padding-right: 158px; //Sprint 11 Design

            &:last-child() {
                border: none;
            }

            &.accordion-body-footer-section {
                padding: 16px 0;
            }
        }

        .accordion-body-content-half {
            width: 50%;
        }

        .accordion-body-50 {
            width: 50%;
        }

        .accordion-body-content-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            margin-bottom: 8px;
        }

        .accordion-body-content-lg-label {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 24px;
        }

        .fixed-accordion-location-value {
            .fixed-accordion-location-value-text {
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                color: #202529;
            }

            .fixed-accordion-location-value-label {
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                color: #4a5496;
                background: #ffffff;
                padding: 4px 8px;
                border: 1px solid #c4c8df;
                border-radius: 4px;
            }
        }

        .accordion-input-long {
            width: 350px !important;
            height: 40px;
            border-radius: 4px;

            &.dropdown {
                background-color: #fff;
            }

            &.accordion-border-black-1 {
                border: 1px solid #202529;
            }

            &.accordion-border-red-1 {
                border: 1px solid red;
            }

            .date-range-picker-custom {
                width: 100%;
                height: 100%;
            }
        }

        .accordion-input-medium {
            width: 235px !important;
            height: 40px;
            border-radius: 4px;

            &.dropdown {
                background-color: #fff;
            }

            &.accordion-border-black-1 {
                border: 1px solid #202529;
            }

            &.accordion-border-red-1 {
                border: 1px solid red;
            }

            .date-range-picker-custom {
                width: 100%;
                height: 100%;
            }
        }

        .accordion-input-max {
            width: 100% !important;
            min-width: 100%;
            height: 40px;
            border-radius: 4px;

            &.dropdown {
                background-color: #fff;
            }

            &.accordion-border-black-1 {
                border: 1px solid #202529;
            }

            &.accordion-border-red-1 {
                border: 1px solid red;
            }

            .date-range-picker-custom {
                width: 100%;
                height: 100%;
            }
        }

        .accordion-under-deck-row {
            width: 100%;
            // margin-bottom: 16px;

            &:nth-child(2) {
                margin-bottom: 0;
            }
        }

        .accordion-under-deck-lg {
            width: 170px;
        }

        .accordion-under-deck-md {
            width: 95px;
        }

        .accordion-under-deck-sm {
            width: 20px;

            &.uom-container {
                align-self: center;
            }
        }

        .accordion-under-deck-delete-button {
            padding: 8px 0;
            background-color: transparent;
        }
    }

    .add-cargo-button {
        cursor: pointer;

        .add-cargo-button-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #0000A1;
        }
    }

    .accordion-body-delete-sequence-button {
        &.ui.secondary.button {
            background: transparent;
            color: #0000A1;
            padding: 0.75rem 0;
        }
    }

    .add-new-location-button {
        // margin-top: 24px;
        // margin-left: 70px;
        // margin: 24px 0 2rem 70px;
        margin: 16px 0 18px;
        cursor: pointer;

        &.hide-button {
            display: none;
        }

        .add-new-location-button-text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #0000A1;
        }
    }

    .cvs-sidebar-cargo-cap-message {
        position: relative;
        font-weight: 600;
        font-size: 0.9rem;
        margin-top: 0.75rem;
        margin-bottom: 1rem;
    }

    .modal-footer {
        position: absolute;
        bottom: 0px;
        width: 100%;
        background-color: #fff;
        padding: 24px;
        border-top: 1px solid #e0e1e8;

        .modal-footer-right {
            margin-left: auto;
        }

        .ui.button {
            &.primary {
                background: #0000A1;
                color: #fff;
            }

            &.secondary {
                background: #fff;
                color: #0000A1;

                &.disabled {
                    border-color: transparent;
                    color: #bab3be;
                }
            }
        }
    }
}

.cvs-sidebar-validation-notice-icon {
    padding: 0.75rem 0;
}

.cvs-sidebar-validation-notice-container {
    position: relative;
    width: 310px;
    padding: 1rem;

    .cvs-sidebar-validation-notice-header {
        font-weight: 800;
    }

    .cvs-sidebar-validation-notice-content {
        .cvs-sidebar-validation-notice-right {
            color: green
        }
    }

    .cvs-sidebar-validation-notice-left {
        text-align: left;
        width: 70%;
    }

    .cvs-sidebar-validation-notice-right {
        text-align: right;
        width: 30%;

        &.cvs-sidebar-validation-failed {
            color: red;
        }
    }
}