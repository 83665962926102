.upload-data-page {
  padding-top: 9rem;
  padding-left: 165px;
  padding-right: 165px;
  padding-bottom: 100px;

  .header {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.03em;
  }

  .upload-data-container {
    .left-content {
      flex: 1;
      width: 100%;
    }

    .right-content {
      margin: auto;
    }

    .label-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #09010e;
    }

    .label-text-grey {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #6b6c70;
    }

    .download-template {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #6b6c70;
      margin-top: 5px;
      cursor: pointer;
      text-align: right;
    }

    .status-file {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 600;
    }

    .Error {
      color: #C93626;
    }

    .Completed {
      color: #26A671;
    }

    .Processing {
      color: #6b6c70;
    }

    .label-text-purple {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000A1;
    }

    .label-text-red {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #ef5d60;
      word-break: break-word;
    }

    .label-bottom{
      .label-text-file-name{
        font-weight: 600;
      }

      .label-right-aligned{
        margin-left: auto;
      }
    }

    .dropdown {
      width: 100% !important;
    }

    .upload-icon svg path {
      fill: #a4a7b2;
    }

    .upload-content {
      width: 50%;
      border-right: 1px solid #eaeaea;

      .upload-file {
        background: #ffffff;
        border: 2px dashed #a4a7b2;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 3rem;
        text-align: center;

        &.active {
          border: 1px solid #0000A1;
          padding-bottom: 1rem;

          .upload-icon svg path {
            fill: #0000A1;
          }
        }

        &.msg {
          border: 1px solid #a4a7b2;
        }

        .label-text {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          color: #202529;
        }

        .label-text-red {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #ef5d60;
        }
      }
    }

    .upload-history-content {
      width: 50%;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .upload-history-header {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.03em;
        color: #5963a5;
      }

      .detail-container {
        overflow: hidden;
        overflow-y: scroll;
        height: 200px;

        .detail-card {
          margin: 1rem 1rem 1rem 0;
          padding: 1rem;
          background: #eaeaea;
          border-radius: 4px;

          .eye-icon,
          .download-icon {
            display: none;
          }
        }

        .detail-card:hover {
          background: #f0f0fa;

          .eye-icon,
          .download-icon {
            display: unset;
            cursor: pointer;
          }

          .download-icon svg path {
            fill: #0000A1;
          }
        }
      }
    }
    .btn-action {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000A1;
      background-color: #ffffff;
      border: 1px solid #0000A1;
      margin: 0 !important;
    }
    
    .btn-action:hover,
    .btn-action:hover svg path {
        color: #ffffff;
        background-color: #0000A1;
        stroke: #ffffff;
    }
    
    .btn-action-blue,
    .btn-action-blue:focus,
    .btn-action-blue:hover {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        background-color: #0000A1;
        border: 1px solid #0000A1;
        margin-left: 20px;
    }
  }
}
