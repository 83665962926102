  .header {
    padding-bottom: 20px;
  }
  .title {
    color: #4A5496;
    font-weight: $fontweight-600;
    font-size: $fontsize-16;
  }
  .filter-label {
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    font-size: $fontsize-12;
    line-height: 12px;
    letter-spacing: 0.04em;
    margin-bottom: $rem-8 !important;
  }

  .icon {
    i {
      float: right;
    }
  }


  .filter-action {
    display: flex;
    justify-content: space-between;
    max-width: 200px;

    button {
      width: 98px;
      &.btn-cancel {
        color: #A4A7B2;
        background-color: $color-white !important;
      }
      &.btn-apply {
        color: $color-primary;
        background-color: transparent;
        border: 1px solid $color-primary;
        &:hover {
          background-color: $color-primary;
          color: $color-white;
        }
      }
    }
  }


  .ui.dropdown.loading>i {
    position: absolute;
    top: 0;
    left: 45%;
  }

  @media (max-width: 768px) {
    .m-dropdown {
      width: 90% !important;
      align-self: center !important;
    }

    .header {
      padding-bottom: 0px;
      margin-left: 20px;
      margin-top: 20px;
    }

    .title {
      color: var(--neutrals-dark-almost-black, #202529);
      /* Paragraph/Paragraph 1/Medium */
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 144.444% */
    }

    .filter-label {
      margin-top: 20px;
      margin-left: 20px;
      color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
      /* Paragraph/Paragraph 3/Regular */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }

    .filter-action { 
      justify-content: flex-start;
      max-width: 100%;
      margin-top: 24px;
      margin-left: 20px;

      button { 
        &.btn-cancel {
          color: #0000A1;
          background-color: #FFFFFF;
          border-radius: 8px;
          border: 1px solid var(--duke-blue-p-300-main, #0000A1);
          width: 147px !important;
          margin-right: 16px;
        }

        &.btn-apply { 
          border-radius: 8px;
          color: #FFFFFF;
          background-color: #0000A1;
          width: 112px !important;
        }
      }
    }
  }