.timeline {
  position: fixed;
  bottom: 200px;
  left: 10px;
  transition: bottom 0.3s;
  width: calc(100% - 55px);
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 998;

  .rounded-circle {
    border-radius: 50% !important;
  }
}

.timeline .timeline_progress_bar {
  width: calc(100% - 100px);
  height: 65px;
  margin-left: 15px;
  cursor: pointer;
}

.timeline .playbtn {
  height: 46px;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  background: white;
  border: none;
  outline: 0px;
  color: #818181;
  display: flex;
  width: 46px;
  &.play {
    align-items: center;
    justify-content: center;
  }
  &.pause {
    align-items: center;
  }
}

.timeline .playbtn.play img {
  margin-left: 5px;
  margin-top: -2px;
}

.timeline .playbtn.play i {
  display: none;
}

.timeline .playbtn.play i:before {
  content: "\f04b";
}

.timeline .playbtn.pause img {
  display: none;
}

.timeline .playbtn.pause i:before {
  content: "\f04c";
  color: #0000A1;
}

.timeline .progress {
  border-radius: 0px;
  background-color: white;
  height: 7px;
}

.timeline .progress .progress-bar {
  background-color: #eaeaea;
}

#timeline_Lable {
  position: relative;
  margin-bottom: 7px;
  left: 0%;
  display: inline-block;
  transition: left 0.6s ease;
  width: 38px;
  background: #ffffff;
  padding: 4px;
  margin-left: -17px;
  font-family: $font-normal !important;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}

.progress-bar {
  height: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

.timeline {
  .timeline_progress_bar {
    .progress-input-range {
      input[type="range"] {
        padding: 0;
        background: #fff;
        border: solid 1px #82cfd0;
        height: 10px;
        width: 100%;
        -webkit-appearance: none;
      }

      input[type="range"]::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
      }
    }

    .label {
      font-family: $font-normal !important;

      ul {
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: space-around;
        width: 100%;

        li {
          padding-top: 10px;
          position: relative;
          float: left;
          cursor: pointer;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #000000;

          span i {
            margin-left: -12px;
          }
        }

        li::before {
          position: absolute;
          top: -9px;
          right: 0;
          left: 0;
          content: "";
          width: 1px;
          height: 8px;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
