.kpi-overview-container {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 160px;
  z-index: 998;
  box-shadow: 0px -5px 20px 0px #ccc;
  .trigger-btn:focus {
    background-color: $color-white;
  }
  .trigger-btn:hover {
    background-color: $color-white;
  }
  .chart-container {
    width: 100%;
    text-align: center;
    .chart-name {
      color: #6b6c70;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      justify-content: center;
      margin-left: 15px;
      p {
        margin: 0 5px;
      }
      img {
        display: inline-block;
        width: 16px;
        height: 16px;
      }
    }
  }
  .chart-title-position {
    margin-top: -35px;
  }
  .fleet-composition-tabs {
    position: absolute;
    height: 50px;
    z-index: -1;
    width: calc(100% - 200px);
    right: -12px;
    background: #f5f7fe;
    transition: all 0.3s;
    opacity: 0;
    display: flex;
    &.show {
      transform: translateY(-35px);
      opacity: 1;
    }
    .fleet-composition-tab {
      display: flex;
      align-items: center;
      padding: 0 15px;
      flex: 1;
      color: #000000;
      p {
        margin: 0 5px;
        font-weight: 600;
        &:first-child {
          font-size: 12px;
          line-height: 12px;
        }
        &:last-child {
          font-size: 24px;
          line-height: 32px;
          letter-spacing: -0.03em;
          color: #6b6c70;
        }
      }
    }
  }
  .vessel-container {
    min-width: 200px;
    background-color: #f5f7fe;
    position: relative;
    .vessel-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      &.show-total-fleet {
        margin-top: 10px;
        align-items: flex-start;
        left: 35%;
      }
      .vessel-value {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #000000;
        margin-top: 10px;

        .total-fleet {
          color: #6b6c70;
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          text-align: left;
          p {
            color: #202529;
            font-size: 24px;
            line-height: 32px;
            &:last-child {
              color: #0000A1;
              font-weight: 600;
              font-size: 12px;
              line-height: 24px;
              cursor: pointer;
            }
          }
        }

        .explore-vessel-value-content p {
          margin-top: 1rem;
          color: #0000A1;
          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          cursor: pointer;
        }
      }
    }
    .fleet-composition-handle {
      display: flex;
      width: 100%;
      left: 50%;
      color: #4a5496;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
      justify-content: space-between;
      padding: 0 10px;
      height: 50px;
      transform: translateY(-35px);
      background: #f5f7fe;
      transition: all 0.3s;
      cursor: pointer;
      .arrow {
        width: 15px;
      }
    }
  }
  .emission-container {
    min-width: 200px;
    margin-bottom: 15px;
    margin-left: 20px;
    justify-content: center;
    .emission-value {
      display: inline-block;
      font-size: 24px;
      color: #30384b;
    }
    .emission-subs {
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #6b6c70;
      font-weight: 600;
      img {
        display: inline;
        width: 16px;
      }
    }
  }
  .export-container {
    min-width: 200px;
    margin-bottom: 15px;
    margin-top: 15px;
    border-left: 1px solid #ccc;
    justify-content: center;
    .export-text {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 0.04em;
      color: #0041f0;
      text-align: start;
    }
  }
  .share-btn {
    cursor: pointer;
  }
}
