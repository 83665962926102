.ranger {
	font-family: $font-normal !important;
	font-weight: $fontweight-600;
  margin-bottom: 40px;

  .ranger-label {
    font-size: 14px;
    line-height: 21px;
  }

  .ranger-step-description {
    li {
      position: absolute;
      bottom: -12px;
      font-size: 12px;
      line-height: 16px;
      color: #6b6c70;
    }
  }
  .ranger-bar-container {
    position: relative;
    min-height: 42px;
    width: 100%;

    .ranger-bar {
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background: #eaeaea;
      position: absolute;
      top: 20px;

      .ranger-progress-wrapper {
        position: relative;
        height: 100%;
        width: 100%;

        .ranger-bar-progress {
          height: 100%;
          width: 100%;
          background: linear-gradient(90deg, #4B7093 11.62%, #4B7093 37.27%, #4B7093 62.95%, #4B7093 88.63%);
        }

        .ranger-bar-back {
          height: 100%;
          background: #eaeaea;
          position: absolute;
          top: 0;
          right: 0;
        }

        .ranger-divider {
          display: flex;
          position: absolute;
          top: -4px;
          li {
            border-left: 1px solid #6b6c70;
          }

          li:last-child {
            border-right: 1px solid #6b6c70;
          }
        }
      }
    }

    .ranger-point {
      background-color: cornflowerblue;
      // width: 26px;
      // height: 26px;
      border-radius: 13px;

      position: absolute;
      top: 9px;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
        display: none;
      }
    }
  }
}
