@mixin justify-flex-content {
  display: flex;
  justify-content: space-between;
}

.book-marine-page {
  // padding: 0 3rem 0 3rem !important;

  .ui.disabled.dropdown,.ui.dropdown .menu>.disabled.item{
    cursor:default;
    pointer-events:none;
    background:.45 !important;
    opacity: 1 !important;
  }

  .header {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.03em;
    color: #202529;
  }

  .sub-header {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .header-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #202529;
    text-transform: capitalize;
    float: left;
  }

  .inline-header {
    display: "inline-flex" !important;
  }

  .right-aligned-content {
    justify-content: flex-end;
  }

  .book-a-vessel-container {
    padding: 0 3rem 3rem;
    background-color: #FBFCFF;
    border-radius: 0px 0px 4px 4px;

    .header-right {
      float: right !important;
    }

    .inline-flex-container {
      display: inline-flex;
    }

    .flex-form {
      @include justify-flex-content();
    }

    .vessel-form {
      padding-left: 2rem;
    }

    .book-a-vessel-input-container {
      .input-label {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #6b6c70;
        margin-bottom: 5px;
      }

      .book-a-vessel {
        width: 370px;
        min-height: 43px;
        min-width: 220px;
      }

      .period-picker {
        .date-range-picker-custom {
          width: -webkit-fill-available;
          min-height: 43px;
          .flex {
            display: contents;
            span {
              float: left;
            }
            .icon {
              float: right;
            }
          }
        }
      }

      .radio-option-content {
        width: 1100px;
        .radio {
          width: "25%";
          label {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #202529;
          }

          input[type="radio"]:checked ~ label:before {
            border-color: #0000A1;
          }

          input[type="radio"]:checked ~ label:after {
            background-color: #0000A1 !important;
          }
        }
      }

      .book-a-vessel-load-input {
        border-radius: 0.28571429rem;
        border: 1px solid #ccc;

        &.error {
          background-color: $color-primary-light;
          border-color: $color-highlight-red;

          &::placeholder {
            color: #9f3a38;
          }
        }
      }
    }

    .book-a-vessel-input-auto-container {
      width: 100%;
      @extend .book-a-vessel-input-container;
      .radio-option-content {
        width: 100%;
        .radio-flex-content {
          @include justify-flex-content();
          .radio-item {
            width: auto;
          }
        }
      }
      .dropdown-auto-content {
        width: 100%;
      }

      .book-a-vessel-multiselect {
        .icon {
          position: relative;
          right: 0;
          margin-right: 0!important;
        }
      }
    }
  }

  .book-marine-gantt-container{
    position: relative;
    margin-top: 20px;

    .book-marine-gantt-title{
      position: absolute;
      left: 3rem;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #202529;
    }
  }
}
