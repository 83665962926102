.deletevesselschedulepage {
  width: 100%;
  padding: 12vh 30px 15px;
  .dropdown-container {
    width: 400px !important;
  }

  .headerDiv {
    margin-bottom: 15px;
    clear: both;
    padding-top: 10px;
    display: flex;
    align-items: center;
  }

  .title {
    font-family: $font-normal;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000000;
    width: 26%;
    float: left;
  }

  .tripSelection {
    width: 59%;
    text-align: center;
  }

  .tripSelection select {
    font-size: $fontsize-10;
    line-height: 12px;
    color: #000000;
    font-family: $font-normal;
    width: 200px;
    padding: 6px;
    border-radius: 0;
  }

  .btnDiv {
    width: 15%;
    text-align: center;

    span {
      margin-left: 1rem;
    }
  }

  .btnDiv .btn {
    padding: 6px 32px;
  }

  .actions {
    cursor: pointer;
  }

  .actions span {
    font-family: $font-normal !important;
    font-weight: $fontweight-500;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #0041f0;
  }

  .actions img {
    width: 14px;
  }

  #form1,
  body,
  #pdfBody {
    background: $color-white !important;
  }

  .header {
    box-shadow: 0px 6px 8px rgba(45, 30, 78, 0.05);
    padding-bottom: 8px;
  }

  .header .logo {
    font-size: 16px;
    line-height: 20px;
  }

  .header {
    top: 12px;
  }

  .header .menu a {
    font-size: 11px;
    line-height: 16px;
  }

  .back img {
    width: 18px;
    height: 18px;
  }

  .back {
    top: 18px;
    cursor: pointer;
    width: 200px;
  }

  .header .userText span,
  .app-btn-new {
    font-size: 11px;
  }

  .header .userText .profile {
    font-size: $fontsize-10;
  }

  .header .logo div {
    height: 22px;
  }

  .form-control {
    height: calc(1.25em + 0.75rem + 2px);
  }

  .header .user .userimg {
    background: #eaf0ff;
    height: 30px;
    width: 30px;
  }

  .header .user .userimg img {
    height: 16px;
    width: 16px;
  }

  .footerDiv {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px 30px;
    border-top: 1px solid #e6e6dc;
    box-shadow: 0px -2px 8px rgba(45, 30, 78, 0.05);
    background: $color-white;
  }

  .table-bordered {
    border-top: none !important;
    border-right: none !important;
    border-bottom: none !important;
  }

  .table-bordered td,
  .table-bordered th,
  .table thead th {
    border: 1px solid #e6e6dc;
  }

  .table-bordered thead td,
  .table-bordered thead th {
    border-bottom-width: 1px;
  }

  .table td,
  .table input {
    font-size: $fontsize-12;
    line-height: 16px;
    color: #000000;
    font-family: $font-normal;
  }

  .affectedLoc {
    background: #ffafb7 !important;
  }

  .tableDiv {
    max-height: 65vh;
    overflow-y: auto;
  }

  .table th,
  .table td,
  .table input {
    font-size: $fontsize-10;
  }

  .mycustom-select-wrapper {
    position: relative;
    user-select: none;
    width: 59%;
  }

  .mycustom-select {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
    border-radius: 3px;
    width: 40%;
  }

  .mycustom-select__trigger {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: $fontsize-10;
    font-weight: 300;
    color: #3b3b3b;
    height: fit-content;
    line-height: 27px;
    background: $color-white;
    cursor: pointer;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #394a6d;
    border-radius: 3px;
  }

  .mycustom-options {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #394a6d;
    border-top: 0;
    background: #fff;
    transition: all 0.5s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 2;
  }

  .mycustom-select.open .mycustom-options {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  .mycustom-option {
    position: relative;
    display: block;
    padding: 0 20px 0 20px;
    font-size: $fontsize-10;
    font-weight: 300;
    color: #3b3b3b;
    line-height: 30px;
    cursor: pointer;
    transition: all 0.5s;
  }

  .mycustom-option:hover {
    cursor: pointer;
    background-color: #b2b2b2;
  }

  .mycustom-option.selected {
    color: $color-white;
    background-color: #305c91;
  }

  .dropdownOptionTag {
    color: #0000A1;
    padding: 2px 4px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #0000A1;
    border-radius: 4px;
  }
}
