.view-alert-container {
    width: 1440px;
    min-height: 700px;
    top: 150px;

    border-radius: 16px;
    background: #FFF;

    .title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px; 

        margin-bottom: 30px;
    }

    .section {
        display: flex;
        flex-direction: column;
        margin-left: 32px;

        .sub-title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; 
        }

        &.last {
            margin-top: 130px;
        }

        .fields {
            display: flex;
            margin-top: 20px;

            .field {
                display: flex;
                flex-direction: column;

                .label {
                    color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; 

                    margin-bottom: 3px;

                    .required-asterisk {
                        color: red;
                        padding-left: 0.5rem;
                    }
                }

                .value-text {
                    line-height: 22px;
                    overflow-wrap: break-word;
                    font-size: 16px;

                    &.statuss {
                        color: #242433;
                        font-weight: 600;
                    }

                    &.remarks {
                        color: #242433;
                        font-weight: 600;
                    }
                }

                &.category {
                    width: 284px;
                }

                &.statuss {
                    margin-left: 20px;
                    width: 284px;
                }

                &.percentage {
                    width: 284px;
                }

                &.volume {
                    margin-left: 20px;
                    width: 284px;
                }

                &.vessel {
                    width: 611px;
                }

                &.email {
                    width: 611px;
                }

                &.remarks {
                    width: 611px;
                }

                &.btn-list {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                    margin-left: auto;
                    margin-right: 30px;

                    .btn-back-to-system-alert {
                        width: 234px;
                        border-radius: 8px !important;
                        text-transform: capitalize;
                    }

                    .btn-remove-alert {
                        width: 187px;
                        border-radius: 8px !important;
                        text-transform: capitalize;
                    }

                    .btn-edit-alert {
                        width: 180px;
                        border-radius: 8px !important;
                        text-transform: capitalize;
                        &.button-bg {
                            background-color: #0000a1;
                            color: #fff !important;
                        }
                    }
                }

                &.actions {
                    p {
                        color: var(--shades-independence-grey-dark-n-800-main-text, #242433);
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; 

                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}