.warning-modal-optimise-page{
  position: fixed;
  width: 100%;
  top: 0 !important;
  height: 100%;
  z-index: 10000;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  .warning-modal-optimise-container {
    width: 540px !important;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 1.5rem;
    background-color: #fff;
    .warning-modal-title {
      display: flex;
      align-items: center;
      gap: 15px;
      span {
        font-size: 26px;
        font-weight: 600;
      }
    }
  
    .warning-modal-content {
      margin-top: 40px;
      font-size: 16px;
    }
    .warning-modal-action {
      margin-top: 70px;
      text-align: right;
    }
    .warning-modal-button {
      border: 1px solid #0000a1 !important;
      background-color: #fff;
      color: #0000a1 !important;
  
      &:hover {
        background-color: #0000a1 !important;
        color: #fff !important;
      }
    }
    .d3-optimization-message {
      padding: 0 8px;
      line-height: 25px;
      .content-message {
        color: #ff0101;
      }
    }
  }
}
