.vessel-review-page {
    padding: 125px 165px 200px;
    background-color: #f9f9fb;
}
.vessel-review-page {
    .main-header {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #202529;
        padding-bottom: 1rem;
    }

    .title-lightblue {
        color: #4B7093 !important;
    }

    .sub-route {
        color: #5C5C7A;
    }

    .sub-textgrey {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #5C5C7A;
    }

    .section-title {
        color: #4B7093;
        font-weight: 500;
        font-size: 22px;
        line-height: 40px;
    }

    .content-voyage-route {
        margin-top: 12px;
        padding: 16px;
        background: #EBF0F5;
        border-radius: 8px 8px 0px 0px;
        line-height: 35px;
        align-items: center;
    }

    .voyage-groups {
        display: inline-flex;
        gap: 5px;
        align-items: center;
        margin-left: 5px;
    }

    .voyage-detail {
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 5px;

        .arrow-icon {
            margin-top: 8px;
        }
    }

    .footer {
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        background-color: #fff;
        padding: 25px 165px;
        border-radius: 0.28571429rem;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
        z-index: 999;

        .footer-btns {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
        }

        .btn {
            font-weight: 600;
            font-size: 16px;
            border: 1px solid #0000a1;
            padding: 12px 48px;
            margin-left: 10px;
            span {
                line-height: 22px;
            }
            border-radius: 8px;
        }

        .btn-action2 {
            color: #0000a1 !important;
            background-color: #ffffff;
            &:hover {
                color: white !important;
                background-color: #0000a1;
                svg path {
                    stroke: #ffffff;
                }
            }
        }

        .btn-action3 {
            color: #ffffff !important;
            background-color: #0000a1 !important;
        }

        .btn-action4 {
            font-weight: 600;
            font-size: 16px;
            border: 1px solid #CACAD8;
            padding: 12px 48px;
            margin-left: 10px;
            span {
                line-height: 22px;
            }
            border-radius: 8px;
            background-color: #CACAD8;
            color:black;
        }
    }
}

.vessel-review-table {
    margin: 0 !important;


    thead th {
        background: #DAE3EC !important;
    }

    td {
        padding: 0 !important;
        vertical-align: bottom !important;
    }

    .rows-normal {
        padding: 12px !important;
    }

    .table-subtitle {
        background: #F9F9FB;
        font-weight: bold;
        padding: 15px 12px;
        height: 44px;
    }

    .div-rows {
        height: 44px;
        padding: 15px 12px;
        display: flex;
        align-items: center;
    }

    .updated {
        background: #E8E8EE;
    }

    .withline {
        border-bottom: 1px solid #E8E8EE;
    }
}

.content-updated-schedule {
    margin-top: 80px;

    .card-wrapper {
        display: flex;
        position: relative;
        align-items: baseline;

        &:after {
            content: "";
            position: absolute;
            left: 5px;
            top: 70px;
            height: 0;
            border: 1px dashed #D9D9E3;
        }

        .sched-card {
            margin: 8px 0 20px;
            background: #fff;
            padding: 40px 0px 30px 30px;
            border-radius: 8px;
            flex: 1;
        }

        &:not(:last-child) {
            &:after {
                height: 98%;
            }
        }
    }

    .step-circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;
        z-index: 2;
        background: #FFFFFF;
        border: 1px solid #4b7093;
    }

    .step-circle:after {
        content: ' ';
        border-radius: 50%;
        border: 1px solid #4b7093;
        background: #fff;
        width: 10px;
        height: 10px;
        display: block;
        position: relative;
    }


    .label-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #5c5c7a;
        /*need these for step circle*/
        float: left;
        clear: left;
        width: 100%;
    }

    .label-bold-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #242433;
    }
}