.user-rating {
  z-index: 9997;
  position: fixed;
  bottom: 9%;
  right: 2%;
}

.user-rating-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #cccccc;
}
.user-rating-active {
  background-color: #0000a1;
}
.user-rating-popup-container {
  z-index: 9997;
  position: fixed;
  bottom: 2.5%;
  right: 5%;
  overflow-y: hidden;
  width: 324px;
  height: 321px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #cccccc;
  .content-header {
    width: 100%;
    background-color: #0000a1;
    display: flex;
    justify-content: space-between;
    .content-header-text {
      color: #ffffff;
      font-family: Inter;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
    .content-header-icon {
      cursor: pointer;
    }
  }
  .content-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: #242433;
  }
  .comment-title {
    font-weight: 400;
    font-size: 14px;
  }
  .feedback-btn {
    cursor: pointer;
    color: #ffffff;
    background-color: #0000a1;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border-radius: 4px;
  }
  .cancel-btn {
    cursor: pointer;
    color: #0000a1;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
  }
  .complete-icon {
    cursor: pointer;
  }
  .complete-title {
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
  }
  .slick-slider {
    display: flex;
    height: 100%;
    .slick-list {
      .slick-track {
        height: 100%;
        .slick-slide {
          display: flex;
          height: 100%;
          .slider-body {
            display: flex !important;
            flex-flow: column;
            justify-content: space-between;
            height: 100%;
            padding-top: 15px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.scale-rating {
  display: block;
  text-align: center;
  font-weight: 500;
  margin-left: -5px;
  button {
    border: none;
    width: 25px;
    height: 25px;
    padding: 0;
    color: black;
    font-size: 11px;
    background-color: rgb(225, 118, 233);
    border-radius: none;
    text-align: center;
    background-color: #eeeeee;
    cursor: pointer;
  }

  button:nth-child(2n - 2) {
    background-color: #e6e6e6;
  }

  button:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  button:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  button:hover {
    background-color: #0000a1;
    color: #ffffff;
  }

  button:focus {
    background-color: #0000a1;
    color: #ffffff;
  }
}

@media only screen and (max-width: 768px) {
  .user-rating-popup-container {
    right: 80px;
  }
}
