.legend-div {
  z-index: 997;
  background-color: $color-white;
  top: 130px;
  position: fixed;
  right: 0px;
  transition: left 0.3s;
  width: 200px;
  padding-top: 0;
  align-items: center;
  padding-left: 16px;
  height: 40px;
  cursor: pointer;
  .title {
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    font-size: $fontsize-14;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $color-black;
  }
  .legendDiv {
    align-items: center;
  }
  .legend {
    .alpha-chevron {
      background: $color-white;
      z-index: 1001;
      top: 130px;
      position: fixed;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      right: 235px;
      transition: left 0.3s;
    }
    .legend-container {
      z-index: 997;
      background-color: $color-white;
      top: 100px;
      position: fixed;
      min-width: 250px;
      height: auto;
      right: 0px;
      transition: left 0.3s;
      padding: 22px;

      .legend-content {
        width: 100%;
        height: auto;

        .title {
          font-family: $font-normal !important;
          font-weight: $fontweight-600;
          font-size: $fontsize-14;
          line-height: 16px;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          color: $color-black;
        }

        .toggle-text {
          font-family: $font-normal;
          font-style: normal;
          font-weight: normal;
          font-size: $fontsize-12;
          line-height: 16px;
          color: $color-black;
        }

        .legend-item {
          width: 17.5px;
          height: 17.5px;
        }

        .hide {
          display: none;
        }
      }
    }
  }
  .chevron:hover {
    background-color: $color-primary;
    fill: $color-white;
  }
}
