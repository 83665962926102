.time-line {
  $size: 46px;

  bottom: 195px;
  transition: bottom 0.3s;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 998;

  height: $size;

  background: #f0f0fa;

  .action-btn {
    height: $size;
    width: $size;
    margin-right: 0 !important;

    background: #dae3ec;
    color: #dae3ec;

    text-align: center;
    font-size: 18px;
    line-height: 18px;

    border: none;
    outline: 0px;
    border-radius: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      background: #dae3ec !important;
    }
    &.play {
      i {
        display: none;
      }

      i:before {
        content: "\f04b";
      }
    }

    &.pause {
      align-items: center;

      img {
        display: none;
      }

      i {
        margin-right: 0 !important;
      }

      i:before {
        content: "\f04c";
        color: #0000A1;
      }
    }

    &.mobile-pause {
      i:before {
        content: "\f04c";
        color: #242433;
      }
    }

    &.speed {
      background-color: transparent !important;
      color: $color-white !important;

      position: absolute;
      right: 0;
    }

    &.mobile-color-update {
      background: #F9F9FB;
      color: #F9F9FB;
      &:focus {
        background: #F9F9FB !important;
      }
    }
  }

  .timeline-bar {
    z-index: 1000;
    width: calc(100% - #{$size});
    height: $size;
    color: $color-white;
    background-color: #dae3ec;

    display: flex;

    .timeline-progress-bar {
      width: calc(100% - #{calc(#{$rem-15} + #{$size})});
      margin: $rem-6 $rem-15;
      position: relative;

      #timeline_Lable {
        padding: 0;
      }

      .progress-input-range {
        position: absolute;
        bottom: 15px;
        width: 100%;

        input[type="range"] {
          cursor: pointer;

          padding: 0;
          height: 3px;
          width: 100%;

          background-color: #f5f7fe;
          -webkit-appearance: none;
        }

        input[type="range"]::-webkit-slider-thumb {
          width: 10px;
          -webkit-appearance: none;
        }
      }

      .label {
        font-size: 13px;
        color: #6B6C70;
        font-weight: 600;
        li {
          span {
            overflow: hidden;
          }
        }

        li::before {
          content: "";
          width: 1px;
          height: $rem-8;
          background-color: #6B6C70;
          display: inline-block;
          position: absolute;
          bottom: 12px;
          z-index: 1001;
        }
      }
    }

    &.mobile-timeline-color-update {
      align-items: center;
      background: #F9F9FB;
    }
    
  }

  .speed-control {
    $speed-heigt: 155px;
    height: $speed-heigt;
    z-index: 999;
    background-color: $color-white;
    width: $rem-96;
    padding: $rem-8;

    position: absolute;
    top: -$speed-heigt;
    right: 0;

    display: flex;
    flex-flow: column;
    text-align: left;

    .checkbox:not(.checkbox:last-child) {
      margin-bottom: $rem-16;
    }

    .ui {
      &.checkbox label:before {
        display: none !important;
      }

      &.checkbox input:checked ~ label:after {
        color: $color-grey;
      }

      &.checkbox label {
        padding-left: $rem-24 !important;
        font-size: $fontsize-12;
      }
    }
  }

  .hide {
    display: none !important;
  }
}
