.file-content {
  border-radius: 4px;
  padding: 1rem 2rem;
  background:  #F0F0F4;

  &.active {
    border-color: #0000a1;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    margin-top: -1px;
  }

  .demand-file-table-container {
    max-height: 500px;
    overflow-y: scroll;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

    .break-line {
      font-weight: normal;
      font-size: 12px;
      padding: 5px;
      color: #242433;

      &.main {
        font-weight: 600;
        font-size: 14px;
      }
    }

    table {
      thead {
        tr th {
          background: #DAE3EC;
          position: sticky;
          top: 0;
        }
      }
      tbody {
        tr:nth-child(2n) {
          background-color: #F9F9FB;
        }
      }
      td {
        border: none;
      }
    }
    
    .file-preview {
      color: #4B7093;
      margin-top: 24px;
    }
  }
}

