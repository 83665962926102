.route-selection-container {
  cursor: move;
  z-index: 998;
  height: auto;
  width: 170px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px $color-grey;
  border-top: 4px solid #3421ab;
  text-align: left;
  border-radius: 5px;
  position: fixed;
  top: 40%;
  left: 60%;

  .title {
    font-family: $font-normal;
    font-size: 16px;
    font-weight: bold;
  }
}
