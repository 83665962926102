.reservation-view-page {
  @for $i from 0 through 100 {
    $step: $i;

    .text-black-#{$step} {
      font-size: $step * 1px;
    }

    .text-black-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
    }

    .text-blue-#{$step} {
      font-size: $step * 1px;
      color: #4a5496;
    }

    .text-blue-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #4a5496;
    }

    .text-purple-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #0000a1;
    }

    .text-purple-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #0000a1;
    }

    .text-grey-#{$step} {
      font-size: $step * 1px;
      color: #6b6c70;
    }

    .text-grey-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #6b6c70;
    }

    .text-red-#{$step} {
      font-size: $step * 1px;
      color: #ef5d60;
    }

    .text-red-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #ef5d60;
    }
  }

  padding-top: 128px;
  
  .reservation-list {
    padding-left: 165px;
    padding-right: 165px;
    padding-bottom: 100px;
  }

  .left-content {
    flex: 1;
  }

  .reserve-space-container {
    background-color: #fff;
    padding: 24px;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin-top: 18px;
    margin-bottom: 18px;
    border-radius: 10px;
    cursor: pointer;

    .reserve-space-title {
      color: #5963a5;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 24px;
    }

    .navigation-menu-container {
      background-color: #f5f7fe;
      border: 1px solid #c4c8df;
      border-radius: 4px;
      align-items: center;
      padding: 8px 24px;

      .navigation-menu-title {
        color: #000;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .ui.secondary.pointing.menu {
    margin-bottom: 34px;
    border-bottom: 4px solid #e8e8ee;
  }

  .ui.secondary.pointing.menu .item {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: #bab3be;
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 4px solid #242433;
    color: #000000;
    margin-bottom: -4px;
  }

  .booking-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background-color: #ffffff;
    border: 1px solid #0000a1;
  }

  .btn-action:hover,
  .btn-action:hover svg path {
    color: #ffffff;
    background-color: #0000a1;
    stroke: #ffffff;
  }

  .table-content-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .search-filter-reservations {
    margin-left: auto;
    padding-top: 5px;
    display: flex;

    .search-input {
      border-radius: 8px 0 0 8px;
      border-style: none !important;
      width: 320px;
    }

    .filter-reservations {
      border: 1px solid #ccc;
      border-left: none;
      border-radius: 0 8px 8px 0;
      background-color: transparent;
      color: #0000a1;
      font-weight: 600;
      font-size: 16px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      p {
        margin-left: 5px;
      }
    }
  }

  .reservation-view-page-footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding: 1rem 165px;
    border-radius: 0.28571429rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

    .download-button {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000a1;
      margin: auto 2rem !important;

      svg path {
        stroke: #0000a1;
      }
    }

    .pagination {
      flex-direction: row;
      align-items: center;
      .ui.pagination.menu .active.item {
        border-radius: 50%;
        background-color: #242433;
        font-weight: bold;
      }
    }
  }
}

.finalised {
  padding-top: 9rem;
}

.modal-title {
  align-items: center;

  .modal-icon {
    svg path {
      fill: #000000;
    }
    &.error-color {
      svg path {
        fill: #c93626 !important;
      }
    }
  }
}

.cancel-reservation-modal {
  .content {
    padding: 0 !important;
    .modal-title {
      background: #fef2f1;
      border-radius: 10px 10px 0px 0px;
      margin: 0 !important;
      padding: 27px;

      .modal-icon {
        height: 32px;
        svg path {
          fill: #c93626 !important;
        }
      }
      .modal-large-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
        color: #c93626;
      }
    }
    .modal-body {
      padding: 32px 40px 40px !important;
      .btn-ghost {
        border-color: transparent !important;
      }
    }
  }
}

.filter-reservations-popup {
  text-align: left;
  width: 560px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #eaeaea;
  padding: 24px !important;

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #4a5496;
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #202529;
    padding-top: 5px;
  }

  .filter-reservations-popup-body {
    padding: 18px 8px;
    margin-top: 1px;
    .row {
      padding: 15px 0 !important;

      h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #202529;
      }

      .filter-dropdown {
        margin-top: 5px;
        width: 100%;
      }
    }

    .ui.selection.dropdown {
      position: relative;
      border: 1px solid #202529;

      .icon {
        position: absolute;
        right: 5px;
      }
    }

    .period-picker {
      margin-top: 5px;

      .ui.input > input {
        border: 1px solid #202529;
        // border-radius: 4px;
      }

      .icon {
        color: #202529;
        opacity: 1 !important;
      }
    }
  }

  .no-border {
    border: none;
  }
}

.popup-download-type {
  text-align: left;
  padding: 20px 15px;
  width: 200px;

  .select-file {
    font-size: 14px;
    font-weight: bold;
  }

  .checkbox-options {
    display: flex;
    flex-direction: column;
    margin: 10px 5px;

    .checkbox {
      margin: 5px 0;
    }
  }

  .cover-download-button {
    text-align: center;

    button {
      padding: 10px 20px;
      border-radius: 3px;
      border: none;
      background-color: #0000a1;
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        background-color: gray;
      }
    }
  }
}

.reservation-card-container {
  .no-data-content {
    margin: 1rem auto;

    div {
      text-align: center;
      background-color: #f5f7fe;
      padding: 1rem;
      font-weight: 600;
      font-size: 20px;
      border-radius: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    }
  }

  .sort-by-container {
    margin: 56px 0;
    justify-content: space-between;

    .sort-by-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #202529;
    }

    .sort-by-icon {
      &.asce svg {
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      &.desc svg {
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .radio-option-content {
      .radio {
        margin-right: 3rem;

        label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #202529;
        }

        input[type="radio"]:checked ~ label:before {
          border-color: #0000a1;
        }

        input[type="radio"]:checked ~ label:after {
          background-color: #0000a1 !important;
        }
      }
    }

    .voyage-filter-container {     
      .voyage-filter-text {
        color: #5C5C7A;
        font-size: 14px;
        font-weight: normal;
      }
      .status-dropdown {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        width: 206px;
        border-radius: 8px;
        color: #242433;
        font-size: 14px;
      }
    }
  }
}
