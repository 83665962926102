
.layout-container {
  .desktop-layout {
    display: none; 
  }
  .mobile-layout {
    display: block;
  }

  @media only screen and (min-width: 768px) {
    .desktop-layout {
      display: block; 
    }
    .mobile-layout {
      display: none; 
    }
  }
}
