.notification-menu-container {
  display: flex;
  align-items: center;
  .ui.button {
    background-color: transparent;
    color: #fff;
  }
  .none {
    display: none;
  }
  .new-notification-pink {
    position: absolute;
    // margin-top: 2px;
    top: 28px;
    margin-left: 10px;
    z-index: 1;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    background-color: #E74C3B;
    border: 2px solid #E74C3B;
    color: #FFFFFF;
    font-size: x-small;
    padding: 3px 2px;
  }
  .new-notification-red {
    position: absolute;
    top: 25px;
    margin-left: 19px;
    z-index: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #D0460B;
  }
  

  .notification-dimmer {
    // background: rgba(0, 0, 0, 0.25);
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // z-index: 999;
    .notification-action {
      display: flex;
      margin-top: 16px;
      padding: 0 3px 0 15px;
      justify-content: space-between;
      .notification-dropdown{
        width: unset !important;
        min-width: 9rem !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        border-right: 1px solid rgba(34,36,38,.15) !important;
        line-height: 24px !important;
        .notification-button-group {
          margin-right: 5px;
          .ui.dropdown {
            border-left: 1px solid white !important;
          }
        }
      }
      .notification-checkbox-all {
        margin-left: 6px;
        margin-right: 6px;
      }
    }

    .notification-list { //for sidebar report incident
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      width: 400px;
      height: 100%;
      background-color: white;
      position: fixed;
      top: 0px;
      right: 0px;
      overflow-y: auto;
      transition: all 1st ease;
      z-index: 999;
      cursor: pointer;
      overflow: hidden;
      &:hover {
        overflow: auto;
      }
      .notification-header-cover{
        position: fixed;
        z-index: 900;
        background-color: white;
      }
      .notification-list-body {
        position: relative;
      }
      .notification-checkbox {
        &.checked {
          label {
            &::before {
              background-color: #0000A1;
              border-radius: 2px;
            }
            &::after {
              color: white;
            }
          }
        }
      }
      &.ddl-sidebar {
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important; 
      }

      .notification-heading {
        padding: 18px 20px 4px 20px;
      }

      .notification-top {
        align-items: center;
        .notification-label {
          font-size: 16px;
          line-height: 24px;
          color: #000;
          font-weight: 600;
        }
        .close-icon {
          margin-left: 150px;
        }
        
      }

      .sidebar-label {
        font-size: 16px;
        text-transform: uppercase;
        line-height: 24px;
        color: #000;
        font-weight: 600;
        text-align: left;
      }

      .sidebar-sub-title {
        font-size: 14px;
        line-height: 24px;
        // color: #000;
        font-weight: 400;
        text-align: left;
      }

      .sidebar-secondary-sub-title {
        font-size: 14px;
        line-height: 24px;
        color: #000;
        font-weight: 400;
        text-align: left;
        margin-left: 38px;
      }

      .sidebar-details-container {
        background-color: #fff3cd;
        border-color: #ffeeba;
        .sidebar-details {
          color: #856404;
          font-size: 16px;
          line-height: 32px;
          font-weight: 600;
          padding: 12px 20px;
          text-align: left;
        }
      }
      .sidebar-input-label {
        font-size: 14px;
        line-height: 24px;
        color: #000;
        font-weight: 600;
        text-transform: uppercase;
        text-align: left;
      }

      .btn-input-right {
        border-radius: 0px 12px 12px 0px;
        border: 0.1px solid #6c757d;
        margin-left: 0;
      }

      .ddl-sidebar {
        background-color: initial;
        color: $color-black;
        width: 200px;
        padding: 8px;
        font-family: $font-normal;
        font-size: $fontsize-12;
        line-height: 16px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        margin-right: 0;
        border-right: none;
      }

      .sidebar-btn-submit {
        display: flex;
      }

      .search-input-field {
        border-radius: 4px !important;
        margin-left: 8px;
        margin-right: 6px;
        width: 370px !important;
        align-self: center;
      }

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #2bcf9e;
        margin-top: 8px;
        padding-right: 14px;

        &.error {
          background-color: #ef5d60;
        }
      }

      .notification-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        &.unread {
          font-weight: 600;
        }
        .mobile-notification-title {
          font-family: Inter;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: left;
          color: #242433;
          font-weight: 400;
          &.unread {
            font-weight: 700;
          }
        }
        .mobile-notification-sub-title {
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          color: #242433;
        }
      }

      .notification-item-action {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 362px;
        
        .notification-item-action_flag{
          margin-top: 30px;
        }
      }

      .notification-subtitle {
        font-size: 12px;
        font-weight: 400;
        // line-height: 24px;
        text-align: left;

        .btn-see {
          font-size: 14px;
          color: #0000A1;

          &:hover {
            font-weight: bold;
          }
        }

      }

      .mobile-subtitile-color {
        color: #4B7093;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
      .mobile-subtitile-btn {
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: right;
      }
      .mobile-subtitile-date {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #5C5C7A;
      }
      .selected-reason {
        font-size: 12px;
        line-height: 15px;
        padding-top: 10px;
        margin-bottom: 10px;
        border-top: 1px solid rgba(34, 36, 38, 0.15);

        .reason {
          width: calc(100% - 50px);
          display: inline-block;
          text-align: left;
        }

        .action {
          width: 20px;
          float: right;
        }

        .action-button {
          background: white;
          color: #826ab9;
          cursor: pointer;
        }

        .action-button:hover {
          color: #ef5d60;
        }
      }
    }

    
  }

  @media (max-width: 768px) { 
    .notification-dimmer {
      width: 100%;
      text-align: left;
      height: 100%;
      .notification-list {
        height:80%;
        margin-top: 180px;
        width: 100%;
        overflow-y: auto;
        .notification-list-body {
          max-height: 580px;
          overflow-y: auto;
        }

      }

    }
  }

  .clear-float {
    clear: both;
  }
}

.popup-notification {
  height: 800px;
  max-width: 500px !important;
  margin-top: 90px;
  border-radius: 8px !important;
  border: 1px solid #D9D9E3 !important;
  box-shadow: 0px 20px 24px rgba(0, 0, 161, 0.06) !important;
  .notification-dimmer {
    height: 100%;
    min-width: 500px;
    .notification-itemline {
      padding: 15px 0px 10px 10px;
    }
    .notification-list {
      .notification-header-cover {
        padding: 20px 20px 20px 20px;
        border-bottom: 1px solid #D9D9E3;
          .notification-label-section {
            font-size: 22px;
            font-weight: 600;
          }
      }
      .notification-list-body {
        height: 100%;
      }
    }
  }
  .notification-list {
    .ship-to-ship-item {
      display: flex;
      gap: 1rem;
      margin-left: 1rem;
      color: black;
    }
    .notification-title {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      color: #5C5C7A;
      &.unread {
        font-weight: 600;
        color: #242433;
      }
    }
    .notification-subtitle {
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      color: #5C5C7A;
      &.unread {
        color: #242433;
      }
      .btn-see {
        font-weight: bold;
        color: #0000A1;
      }
    }
    .notification-time {
      color: #5C5C7A;
      font-size: 12px;
      text-align: left;
    }
  }
}

.height-mini {
  height: 220px !important;
}

.rate-modal-body {
  .rate-modal-title {
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
  }

  .rate-modal-sub-title {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  .trip-details {
    margin-top: auto;
  }

  .planned-column {
    margin-top: 38px;
  }

  .rate-content-label {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    width: 64%;
  }

  .rate-content-details {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }
  .rate-trip-title {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .close-icon-modal {
    margin-left: auto !important;
    cursor: pointer;
  }

  .leaflet-control-attribution{
    visibility: hidden;
  }
  .flag-icon {
    svg{
      path{
        stroke: #0000A1;
      }
    }
  }
  .platform-position {
    margin-top: -35px !important;
    margin-left: -12px !important;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.notification-footer{
  height: 65px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 25px;
  font-weight: bold;
  font-size: 16px;
}
.notification-footer a {
  color: #D0460B;
}

@media (max-width: 768px) {
  .rate-completed-trip-container {
    .row-1 {
      display: flex;
      gap: 12px;
      align-items: center;

      margin-top: 18px;

      .rate-modal-sub-title {
        color: var(--duke-blue-p-300-main, #0000A1);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
      }
    }

    .row-2 {
      border-radius: 8px;
      background: #F3F6FD;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

      margin-top: 30px;
      padding-top: 20px;
      padding-bottom: 20px;

      .planned-label {
        color: var(--independence-grey-dark-n-800-main-text, #242433) !important;
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important; /* 157.143% */

        margin-left: 15px;
        margin-bottom: 5px;
      }

      .actual-label {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        margin-left: 15px;
        margin-top: 12px;

        margin-bottom: 5px;
      }

      .container {
        display: flex;
        justify-content: space-between;

        .title {
          color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
          /* Paragraph/Paragraph 3/Regular */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */

          margin-left: 15px;
          margin-right: 15px;
        }

        .value {
          color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
          /* Paragraph/Paragraph 3/Regular */
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 157.143% */

          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }

    .row-3 {
      margin-top: 30px;

      .rating-title {
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        margin-bottom: 7px;
      }
    }

    .row-4 {
      margin-top: 30px;

      .comment-title {
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        margin-bottom: 5px;
      }

      .comment-box {
        border-radius: 8px !important;
        border: 1px solid var(--independence-grey-light-n-40, #D9D9E3) !important;
        background: var(--shades-white, #FFF) !important;
      }
    }

    .row-5 {
      .btn-container {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 20px;

        .btn-cancel {
            border-radius: 6px;
            border: 1px solid var(--duke-blue-p-300-main, #0000A1);
            background: var(--shades-white, #FFF);

            width: 89px;

            .value {
                color: var(--duke-blue-p-300-main, #0000A1);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 150% */
            }
        }

        .btn-submit {
            border-radius: 6px;
            background: var(--duke-blue-p-300-main, #0000A1) !important;

            width: 89px;

            .value {
                color: var(--shades-white, #FFF);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 150% */
            }
        }
      }
    }
  }
}