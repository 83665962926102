.scenario-schedule-planner-page {
    width: 100%;
    padding: 32px 10px 10px 10px;

    .left-content{
        margin-left: 32px;
    }

    .right-content {
        margin-right: 40px;
        margin-left: auto;
        color: #0000A1;
    }

    .title-text{
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
    }

    .status-label{
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        background-color: #4545e5f7;
        padding: 2px 12px;
        border-radius: 6px;
    }

    .scenario-schedule-planner-button{
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        cursor: pointer;
        .plus {
            margin: auto;
        }
    }

    .link-divider{
        width: 1px;
        background-color: #E0E1E8;
    }

    .schedule-planner-legend {
        padding: 27.5px 17px;
        background-color: #FBFCFF;

        .legend-icon {
            width: 18px;
            height: 17px;
            margin-right: 0.6rem;
        }

        .legend-label {
            margin-right: 1.8rem;
        }
    }
}
