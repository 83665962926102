.weather-forecast-section {
    gap: 12px;
    padding-bottom: 16px;
    border-bottom: 1px solid #E0E1E8;
    .weather-forecast-header {
        .title {
            font-size: 14px;
            color: #5C5C7A;
            font-weight: 400;
        }
    }
    .weather-forecast-body {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 20px;
        width: 80%;
        margin-top: 20px;
        .weather-forecast-item {
            padding: 16px 24px;
            background-color: #F0F0FA;
            border-radius: 8px;

            &-label {
                color: #0000A1;
                font-weight: 500;
                margin-bottom: 10px;
            }
        }
    }
}