.top-header-container {
  position: absolute;
  width: 100%;
  z-index: 999;
  padding: 0px 150px;
  // height: 100px;
  height: 80p;
  background-color: transparent;
  // box-shadow: 0px 1px 7px 0px $color-grey-light;
  // display: flex;
  align-items: center;
  // background-image: linear-gradient(92.42deg, #f9b262 -90.63%, #0000A1 33.4%, #460073 173.98%);
  background: linear-gradient(90.17deg, #000061 8.1%, #04043A 85.94%);
  background: #242433;

  &.home {
    // height: 100px;
    height: 80px;
  }
  
  .width-33 {
    width: 33.3%;
  }

  .logo-wrapper {
    width: 30px;
    text-align: left;
  }

  .header-menu-wrapper {
    text-align: right;
    margin-right: 0 !important;    
  }

  .header-menu-right {
    & *:not(:last-child) {
      margin-right: 0 !important;
    }
  }

  .header-d3-status {
    position: relative;
    border: 2px solid #FFFF;
    border-radius: 100%;
    width: 27px;
    height: 27px;
    cursor: pointer;
    user-select: none;
    span {
      font-size: 9px;
      font-weight: bold;
      color: #FFFF;
      width: 27px;
    }
    .header-d3-status-total {
      position: absolute;
      background-color: #D0460B;
      right: -8px;
      top: -3px;
      width: 12px;
      height: 12px;
      border-radius: 100%;
    }
  }

  .title-container {
    width: 160px;
    background: transparent;
    height: 100%;
    border-right: 0.5px solid #d9d9d9;
    justify-content: center;
  }

  .secondary-title-container {
    justify-content: center;
    .secondary-title-text {
      color: #ffffff;
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
      font-style: italic;
      letter-spacing: 2px;
    }
  }

  .welcome-context {
    flex-basis: 100%;
    color: $color-white;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }
}

.mobile-top-header-container {
  position: absolute;
  width: 100%;
  z-index: 999;
  padding: 0px 15px;
  height: 100px;
  background-color: transparent;
  // box-shadow: 0px 1px 7px 0px $color-grey-light;
  // display: flex;
  align-items: center;
  // background-image: linear-gradient(92.42deg, #f9b262 -90.63%, #0000A1 33.4%, #460073 173.98%);
  background: linear-gradient(90.17deg, #000061 8.1%, #04043A 85.94%);
  background: #242433;

  &.home {
    height: 100px;
  }
  
  .mobile-width-33 {
    width: 33.3%;
  }

  .mobile-logo-wrapper {
    width: 30px;
    text-align: left;
  }

  .mobile-header-menu-wrapper {
    text-align: right;
    margin-right: 0 !important;
  }

  .mobile-title-container {
    width: 160px;
    background: transparent;
    height: 100%;
    border-right: 0.5px solid #d9d9d9;
    justify-content: center;
  }

  .mobile-secondary-title-container {
    justify-content: center;
    .mobile-secondary-title-text {
      color: #ffffff;
      font-size: 24px;
      font-weight: 700;
      line-height: 18px;
      font-style: italic;
      letter-spacing: 2px;
    }
  }

  .mobile-welcome-context {
    flex-basis: 100%;
    color: $color-white;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .mobile-header-title {
    color: white; 
    font-size: 20px; 
  }
}
