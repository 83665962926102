.voyage-details-page {
    margin-top: 40px;
    .breadcrumb {
        display: flex;
        align-items: center;
        .orange-text {
            color: #D0460B;
            cursor: pointer;
        }
        .arrow-right-icon {
            margin-top: 4px;
        }
        .voyage-id {
            color: #242433;
        }
    }
    .page-title {
        font-size: 34px;
        font-weight: 600;
        color: #242433;
        margin-top: 8px;
        .title-details {
            color: #4B7093;
            margin-left: 5px;
        }
    }
    .voyage-route-section {
        margin-top: 40px;
        .section-title {
            font-size: 16px;
            font-weight: 600;
            color: #242433;
        }
        .voyage-route-container {
            display: flex;
            margin-top: 16px;
            .title-container {
                .title {
                    text-align: right;
                    color: #5C5C7A;
                    font-size: 16px;
                    font-weight: 500;
                    margin: 8px 0;
                }
            }
            .route-card-container {
                display: flex;
                flex-wrap: wrap;
                .card-item {
                    display: flex;
                    gap: 6px;
                    margin-bottom: 8px;
                    .route-arrow-icon {
                        margin: 6px 0 0 8px;
                    }
                    .blank {
                        width: 26px;
                    }
                    .route-card {
                        width: 165px;
                        background-color: #E8E8EE;
                        border-radius: 8px;
                        padding: 4px;
                        .route-location {
                            text-align: center;
                            padding: 2px;
                            font-size: 16px;
                            font-weight: 600;
                            color: #242433;
                        }
                        .route-time {
                            padding: 4px;
                            background-color: #FFFFFF;
                            border-radius: 8px;
                            height: 62px;
                            text-align: center;
                            .time-data {
                                margin: 8px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .card-section {
        margin-top: 40px;
        .filter-container {
            display: flex;
            justify-content: space-between;
            .dropdown-group {
                display: flex;
                gap: 24px;
                .dropdown-container {
                    .dropdown-title {
                        margin-bottom: 4px;
                        color: #5C5C7A;
                    }
                    .search-dropdown {
                        width: 250px;
                        height: 45px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .voyage-card-container {
            margin-top: 32px;
        }
    }
    .pagination {
        align-items: center;
        position: relative;
        float: right;
        a.active {
            border-radius: 50% !important;
            background-color: #242433 !important;
        }
    }
}