.onboarding-content{
    &.no-padding {
        padding: 0 !important;
    }

    .header {
        display: flex;
        height: 88px;
        padding: 26.5px 27px 26.5px 32px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 172px;
        align-self: stretch;
        background-color: #EBF0F5;
        .header-text{
            color: var(--queen-blue-300-main, #4B7093);
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px;
        }
        .header-icon{
            width: 35px;
            height: 35px;
            cursor: pointer;
        }
    }

    .content{
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 30px;
        height: 332px;
        align-self: stretch;
        border-radius: 0px 0px 10px 10px;
        background: var(--shades-white, #FFF);

        .info{
            display: inline-flex;
            height: 60px;
            width: 380px;
            padding-right: 40px;
            padding-bottom: 20px;
            align-items: center;
            flex-shrink: 0;
            color: #000000;
            .info-text{
                color: #000;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
            }
        }

        .status{
            height: 211px;
            flex-shrink: 0;
            border-radius: 8px;
            background: #F9F9F9;
            .status-wrapper{
                height: 198px;
                padding: 11px 29.109px 12px 11px;
                align-items: center;
                flex-shrink: 0;
                .status-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .text{
                        color: var(--alert-error-r-300-main, #E74C3B);
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 35px;
                    }
                    .complete-icon{
                        width: 19px;
                        height: 19px;
                    }
                }
            }

        }


    }
}

.onboarding-status {
    padding-right: 0px !important;
    width: 500px !important;
  }

  



  
