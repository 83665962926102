.schedule-planner-page {
  padding-top: 9rem;
  padding-left: 165px;
  padding-right: 165px;
  padding-bottom: 100px;
  .schedule-planner-container {
    .left-content {
      flex: 1;
    }

    .right-content {
      margin: auto;
    }

    .title-text {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      letter-spacing: -0.03em;
      color: #202529;
    }

    .label-text {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: #09010e;
    }

    .view-option-button {
      padding: 5px;
      background: #ffffff;
      border: 1px solid #0000A1;
      box-sizing: border-box;
      border-radius: 4px;

      &.selected {
        background: #dae3ec;
        border: 1px solid #460073;
      }
    }

    .schedule-planner-legend{
      padding: 27.5px 17px;
      border-radius: 0.28571429rem;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);

      .legend-icon{
        width: 18px;
        height: 17px;
        margin-right: .6rem;
      }

      .legend-label{
        margin-right: 1.8rem;
      }
    }
  }
}

.edit-modal-content {
  padding: 16px 16px;

  .edit-modal-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: #202529;
  }

  .edit-modal-subtitle {
    color: #b455aa;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-transform: capitalize;
  }

  .modal-label {
    color: #6b6c70;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }

  .modal-field {
    color: #202529;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
  }

  .modal-label-dark {
    color: #09010e;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }

  .modal-label-red {
    color: red;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }

  .modal-divider {
    border: 1px solid #eaeaea;
  }
}
