.date-time-container {
    display: flex;
    align-items: center;
    z-index: 990;
    position: absolute;
    height: 51px;
    top: 120px;
    left: 20px;
    border-radius: 5px;
    opacity: 0.8;
    background: #242433;

    .value {
        color: #FFF;
        /* Paragraph/Paragraph 3/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */

        margin-left: 19px;
        margin-right: 19px;
    }
}