.delete-alert-modal {
    .header {
        background: var(--Queen-Blue-25, #EBF0F5) !important;

        p {
            color: var(--queen-blue-300-main, #4B7093);
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px; 
        }
    }

    .content {
        p {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;

            margin-bottom: 80px;
        }

        .btn-list {
            display: flex;
            justify-content: flex-end;
            gap: 20px;
            margin-bottom: 17px;

            .btn-cancel {
                width: 150px;
                border-radius: 8px !important;
                text-transform: capitalize;
            }

            .btn-remove-alert {
                width: 201px;
                border-radius: 8px !important;
                text-transform: capitalize;

                &.button-bg {
                    background-color: #0000a1;
                    color: #fff !important;
                }
            }
        }
    }
}