.alert-table-container {
    .title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px; 

        margin-top: 30px;
        margin-left: 32px;
    }

    .options-list-container {
        display: flex;
        margin-top: 32px;
        margin-bottom: 32px;
        .btn-create-new {
            margin-left: 16px;
            width: 149px;
            border-radius: 8px !important;
            text-transform: capitalize !important;
            &.button-bg {
                background-color: #0000a1;
                color: #fff !important;
            }
        }

        .rows-selector {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 50px;
        }

        .pagination-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: 40px;

            .text {
                span {
                    color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 22px;
                }
            }
        }
    }

    .table-container {
        margin-left: 34px;
        margin-right: 34px;
        .empty-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 100px;
            .empty-image {

            }
            .text {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
            .btn-create-new {
                margin-top: 15px;
                margin-left: 3px;
                width: 149px;
                border-radius: 8px !important;
                text-transform: capitalize;
                &.button-bg {
                    background-color: #0000a1;
                    color: #fff !important;
                }
            }
        }
    }

    .status {
        display: flex;
        padding: 2px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 10px;
        
        &.active {
            background: var(--Alert-Success-G50, #D5F6E8);

            p {
                color: var(--Alert-Success-G500, #1C7D55);
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; 
            }
        }

        &.inactive {
            background: var(--independence-grey-light-n-30-lines-on-white-bg, #E8E8EE);

            p {
                color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
            }
        }
    }

    .ui.table thead th {
        height: 54px;
        background: var(--Queen-Blue-50, #DAE3EC);
        vertical-align: middle;
    }

    .ui.celled.table tr td, .ui.celled.table tr th {
        border-left: none;
    }
}