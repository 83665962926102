.view-report-details-container {
    // width: 310px;
    // height: 281px;
    flex-shrink: 0;

    border-radius: 8px;
    background: #F3F6FD;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);

    margin-top: 20px;

    .container-1 {
        display: flex;
        justify-content: space-between;
        margin-left: 15px;
        margin-bottom: 2px;

        .re-and-desc-title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */

            margin-top: 15px;
        }

        .edit-btn {
            color: var(--duke-blue-p-300-main, #0000A1);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */

            margin-top: 15px;
            margin-right: 15px;
        }
    }

    .re-and-desc {
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        /* Paragraph/Paragraph 3/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */

        margin-left: 15px;
    }

    .comment-title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        margin-top: 20px;
        margin-left: 15px;
    }

    .comment-value {
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        /* Paragraph/Paragraph 3/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */

        margin-left: 15px;
    }

    .submission-details-title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Paragraph/Paragraph 3/Medium */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */

        margin-top: 20px;
        margin-left: 15px;
    }

    .email {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Paragraph/Paragraph 3/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */

        margin-left: 15px;
    }

    .time {
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        /* Paragraph/Paragraph 3/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */

        margin-left: 15px;
        padding-bottom: 20px;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .add-report-btn {
        border-radius: 8px;
        background: var(--duke-blue-p-300-main, #0000A1);
        margin-top: 23px;
        .text {
            color: var(--shades-white, #FFF);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px; /* 157.143% */
        }
    }
}