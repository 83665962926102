.vod-upload-file-container {
  border-radius: 8px;

  &.active {
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    margin-bottom: -1px;
    background: #ffffff;
  }

  .file-name-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #242433;
  }

  .file-size-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #757595;
  }

  .try-again-text,
  .remove-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #0000a1;
  }

  .upload-file-content {
    .upload-file {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 3rem;
      text-align: center;
      border: 2px dashed #a4a7b2;

      a.text-purple-bold-16 {
        text-decoration: underline;
      }

      .upload-icon {
        margin-bottom: 10px;
      }

      .drag-drop-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #242433;
      }

      .browse-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #d0460b;
      }

      .success-message {
        color: #26a671;
        font-weight: 500;
        font-size: 16px;
      }

      .error-message-title {
        color: #c93626;
        font-weight: 500;
        font-size: 16px;
      }

      .error-message-content {
        color: #e74c3b;
        font-weight: 400;
        font-size: 16px;
      }

      &.active {
        padding-bottom: 1rem;

        .upload-icon svg path {
          fill: #0000a1;
        }
      }

      &.msg {
        border: 1px solid #a4a7b2;
      }
    }

    .download-template {
      .notice-text {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #757595;
        flex: 1;
      }

      .download-button {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #6b6c70;
        cursor: pointer;

        svg path {
          stroke: #6b6c70;
        }
      }
    }
  }
}
