.carbon-calculator-page {
    padding-top: 200px;

    .carbon-calculator-tab {
        .menu {
            padding-top: 45px;
            margin-left: 45px;
            border-radius: 31px;
            .item {
                height: 46px;
                width: 178px;
                background-color: #eeeeee;
                color: #0000a1;
                text-align: center;
                border-radius: 23px;
                padding-left: 3.5rem;
                margin-left: 10px;
                font-size: 16px;
            }
            .active {
                color: #ffffff;
                background-color: #0000a1;
            }
        }
        .table-view, .graph-view {
            border: unset;
            box-shadow: unset;
        }
    }

    .custom-line {
        background-color: #e0dddd;
        margin-top: 60px;
        width: 94%;
        margin-left: 3%;
        margin-right: 3%;
    }

    .wrapper-background {
        background-color: #002a53;
        height: 990px;
        margin-top: 80px;
        padding: 77px;

        .border-style {
            background: #ffffff;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
            border-radius: 31px;
            margin: auto;
        }
    }

    .graph-wrapper-background {
        background-color: #f3f3f3;
        padding: 200px 80px;

        .carbon-graph-detail {
            color: #0000a1;
            font-size: 34px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 60px;
            margin-top: -100px;
        }

        .graph-style {
            background: #ffffff;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.13);
            border-radius: 31px;
            margin: auto;
            height: 93%;
        }
    }

    .ui-container {
        display: flex;
    }

    .ui-container-g-btn {
        padding-bottom: 60px;
        text-align: center;

        .back-button {
            background-color: #ffffff;
            font-size: 16px;
            color: #0000a1;
            font-weight: 300;
            border-radius: 10px;
            margin-top: 40px;
            width: 100px;
            height: 40px;
            border: 1px solid #0000a1;
        }

        .new-calculation-button {
            background-color: #0000a1;
            font-size: 16px;
            color: #ffffff;
            font-weight: 300;
            border-radius: 10px;
            margin-top: 40px;
            margin-left: 5px;
            width: 168px;
            height: 40px;
            border: 1px solid #0000a1;
        }

        .export-result-button {
            background-color: #0000a1;
            font-size: 16px;
            color: #ffffff;
            font-weight: 300;
            border-radius: 10px;
            margin-top: 40px;
            margin-left: 5px;
            width: 145px;
            height: 40px;
            border: 1px solid #0000a1;
        }
    }
}
