.allocate-vessel-page {
  padding: 130px 160px 100px 160px;

  @for $i from 0 through 100 {
    $step: $i;

    .text-black-#{$step} {
      font-size: $step * 1px;
    }

    .text-black-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
    }

    .text-blue-#{$step} {
      font-size: $step * 1px;
      color: #4a5496;
    }

    .text-blue-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #4a5496;
    }

    .text-purple-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #0000a1;
    }

    .text-purple-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #0000a1;
    }

    .text-grey-#{$step} {
      font-size: $step * 1px;
      color: #6b6c70;
    }

    .text-grey-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #6b6c70;
    }

    .text-orange-bold-#{$step} {
      font-weight: 600;
      font-size: $step * 1px;
      color: #D0460B;
    }
  }

  .allocation-title_search-input {
    display: flex;
    justify-content: space-around;
    .search-filter-reservations {
      margin-left: auto;
      padding-top: 5px;
      display: flex;
      position: relative;
      .search-input {
        border-radius: 8px;
        border-style: none !important;
        width: 320px;
      }
      img.icon {
        width: 20px;
        position: relative;
        right: 30px;
      }
    }
  } 

  .ui.secondary.pointing.menu {
    margin-bottom: 34px;
    border-bottom: 4px solid #F0F0F4; 
  }

  .ui.secondary.pointing.menu .item {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: #bab3be;
  }

  .ui.secondary.pointing.menu .active.item {
    border-color: #242433;
    color: #000000;
    border-width: 4px;
    margin-bottom: -4px;
  }

  .card-container {
    width: 100%;
    border: none;
    .no-data-content {
      margin: 1rem auto;

      div {
        text-align: center;
        background-color: #f5f7fe;
        padding: 1rem;
        font-weight: 600;
        font-size: 20px;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      }
    }
  }

  .card-content {
    cursor: pointer;
    &.Material {
      .card-content-top, .card-content-bottom {
        border-left: 8px solid #FBE86A;
      }
    }

    &.Passengers {
      .card-content-top, .card-content-bottom {
        border-left: 8px solid #C0E2E8;
      }
    }
    .card-content-top {
      border-radius: 8px 8px 0px 0px;
      padding: 12px 24px;
      background: #F0F0F4;
      

      .type-icon {
        svg {
          height: 24px;
          width: 24px;
        }
      }
    }

    .card-content-bottom {
      padding: 32px 24px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 8px 8px;

      .location {
        font-weight: 600;
        font-size: 16px;
        margin: 5px 10px 5px 0;
      }

      .arrow-location {
        color: #460073;
        margin: auto 0;
        margin-right: 10px;
        font-size: 16px;
      }
      
      .content_info_details {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .mar-right-5 {
          margin-right: 70px;
        }
      }

      .deck-utilization_container {
        display: flex;
        justify-content: space-between;
        .deck-utilization_item {
          width: 30%;
        }
      }
    }
  }

  .allocate-vessel-page-footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding: 1rem 165px;
    border-radius: 0.28571429rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }

  .view-details-container {
    .nav-header {
      cursor: pointer;
    }

    .confirm-reallocate {
      font-size: 16px;
      line-height: 24px;
      .confirm-text {
        font-weight: 400;
        color: #242433;
      }
      .reallocate-text {
        color: #D0460B;
        font-weight: 500;
        margin-left: 5px;
        cursor: pointer;
      }
    }

    .right-arrow {
      margin-top: 2px;
      margin-inline: 4px;
    }

    .download-button {
      cursor: pointer;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000a1;
      margin: auto 2rem !important;

      svg path {
        stroke: #0000a1;
      }
    }

    .view-details-footer {
      position: fixed;
      left: 0px;
      bottom: 0px;
      width: 100%;
      background-color: #fff;
      padding: 1rem 165px;
      border-radius: 0.28571429rem;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    }
  }
}
