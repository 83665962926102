.reservation-page-container {
    .available-capacity {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #4b7093;
    }
    @for $i from 0 through 100 {
        $step: $i;

        .text-black-#{$step} {
            font-size: $step * 1px;
        }

        .text-black-bold-#{$step} {
            font-weight: 600;
            font-size: $step * 1px;
        }

        .text-blue-#{$step} {
            font-size: $step * 1px;
            color: #4A5496;
        }

        .text-blue-bold-#{$step} {
            font-weight: 600;
            font-size: $step * 1px;
            color: #4A5496;
        }

        .text-purple-#{$step} {
            font-weight: 600;
            font-size: $step * 1px;
            color: #0000A1;
        }

        .text-purple-bold-#{$step} {
            font-weight: 600;
            font-size: $step * 1px;
            color: #0000A1;
        }

        .text-grey-#{$step} {
            font-size: $step * 1px;
            color: #6B6C70;
        }

        .text-grey-bold-#{$step} {
            font-weight: 600;
            font-size: $step * 1px;
            color: #6B6C70;
        }
    }

    .radio-option-content {
        .radio {
            margin-right: 3rem;

            label {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                color: #202529;
            }

            input[type='radio']:checked~label:before {
                border-color: #0000A1;
            }

            input[type='radio']:checked~label:after {
                background-color: #0000A1 !important;
            }
        }
    }

    .no-data-content {
        padding: 2rem 160px;
        font-weight: 500;
        font-size: 16px;
        padding-top: 40px; 
        background-color: #f9f9fb;    
        height: 70vh;
    }

    .card-reservation-list {
        padding: 0px 80px 80px 80px
    }
}