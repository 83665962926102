.header-menu-top-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  // overflow-y: hidden;
  // overflow-x: auto;

  a {
    &:last-child{
      .menu-item {
        margin-right: 0px !important;
      }
    }
  }

  .ui.dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
    // width: 115px;
    width: 150px;
    height: 83px;
    // font-weight: 400;
    // font-size: 16px;
    // color: #FFFFFF;

    color: var(--independence-grey-medium-n-60, #BCBCCD);

    /* Paragraph/Paragraph 2/Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    
    &.active {
      background-color: #85A4C1;
      border-bottom: 4px solid #72CE63;
    }
    i.icon {
      display: none;
    }
    .menu {
      width: 262px;
      top: 83px;
      padding: 10px;
      background: #242433;
      border-radius: 0px 0px 10px 10px;
      a {
        font-size: 16px;
        color: #FFFFFF !important;
        font-weight: bold;       
      }
      .dropdown-item {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        width: 260px;
        height: 46px;
      }
      .dropdown-item:hover {
        background-color: #85A4C1;
        border-radius: 6px;
      }
      #sub-menu{
        top:-23% !important;
        left: 102% !important;
      }
    }
  }
  .ui.dropdown:hover {
    background-color: #85A4C1;
    border-bottom: 4px solid #72CE63;
  }

  .menu-item {
    display: inline-block;
    height: 100px;
    width: 115px;
    margin-right: $rem-48;
    justify-content: center;
    line-height: 100px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    color: $color-black;
    border: none;
    background-color: transparent;
    text-align: center;
    padding: 0px;
    padding-bottom: $rem-4;
    white-space: nowrap;
    color: #ffffff;
    opacity: 0.75;

    span {
      margin-right: 5px;
    }

    &:hover {
      opacity: 1;
    }

    &.disabled {
      cursor: initial;
    }

    &.dark-header {
      color: #ffffff;
      &:hover {
        background-color: #85A4C1;
        border-bottom: 4px solid #72CE63;
      }
    }

    &.dark-header-active {
      opacity: 1;
    }
    .visable-dot {
      margin-top: -9px;
    }
  }
}

.menu-options-cover {
  text-align: left;
  a {
    cursor: pointer;
    padding: 5px 10px;
    color: black;
    display: block;
    &:hover {
      background-color: rgba(128, 128, 128, 0.144);
    }
  }
}
.fetch-state-container {
  margin-right: 0 !important;
  &.d3-status-update {
    padding: 15px 0px 10px 10px;
    .d3-status-update-title {
      font-size: 16px;
      font-weight: 600;
      color: #242433;
    }
    .d3-status-update-message {
      color: #5C5C7A;
      margin-top: 14px;
    }
  }
}


.mobile-sidebar-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  // overflow-y: hidden;
  // overflow-x: auto;

  a {
    &:last-child{
      .menu-item {
        margin-right: 0px !important;
      }
    }
  }

  .ui.mobile-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px;
    width: 115px;
    height: 100px;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    &.active {
      background-color: #383851;
      border-bottom: 4px solid #72CE63;
    }
    i.icon {
      // display: none;
    }
    .menu {
      width: inherit;
      top: 101px;
      padding: 10px;
      background: #383851;
      border-radius: 0px 0px 10px 10px;
      font-weight: 500;
      a {
        .mobile-dropdown-item {
          display: flex;
          align-items: center;
          padding: 12px 16px;
          width: 230px;
          height: 46px;
        }
        .mobile-dropdown-item:hover {
          background-color: #383851;
          border-radius: 6px;
        }
        color: #FFFFFF;
        
      }
    }
  }
  .ui.mobile-dropdown:hover {
    background-color: #383851;
    border-bottom: 4px solid #72CE63;
  }

  .menu-item {
    display: inline-block;
    height: 100px;
    width: 115px;
    margin-right: $rem-48;
    justify-content: center;
    line-height: 100px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    color: $color-black;
    border: none;
    background-color: transparent;
    text-align: center;
    padding: 0px;
    padding-bottom: $rem-4;
    white-space: nowrap;
    color: #ffffff;
    opacity: 0.75;

    span {
      margin-right: 5px;
    }

    &:hover {
      opacity: 1;
    }

    &.disabled {
      cursor: initial;
    }

    &.dark-header {
      color: #ffffff;
      &:hover {
        background-color: #383851;
        border-bottom: 4px solid #72CE63;
      }
    }

    &.dark-header-active {
      opacity: 1;
    }
    .visable-dot {
      margin-top: -9px;
    }
  }

  .sidebar-container {
    display: flex;
    flex-direction: column;

    .mobile-dropdown {
      width: 100%; 
      font-size: 18px;
      font-weight: 700;
    }

    .mobile-profile-menu {
      display: flex;
      justify-content: left;
      margin-top: 30px;
      margin-left: 30px;
      align-items: center;

      .display_name {
        color: #ffffff;
        font-size: 22px;
      }
    }

    .mobile-close-button-icon {
      color: white;
      margin-top: 15px;
      margin-right: 15px;
      cursor: pointer;
      font-size: 40px;
      font-weight: 350;
    }

    .mobile-sidebar-divider {
      margin-top: 30px;
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.schedule-d3-voyage-status {
  position: absolute;
  z-index: 999;
  top: 90px;
  right: 165px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  .main-status {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: #FFFF;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    padding: 14px 27px;
    border-radius: 30px;
    span {
      font-weight: 600;
      color: #2aa773;
    }
    &:hover {
      background-color: #242433;
      span {
        color: #FFF !important;
      }
      svg {
        path {
          fill: white;
        }
      }
    }
  }
  .close-popup {
    span {
      font-weight: 600;
    }
    background-color: #FFFF;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    // transition: all 0.2s;
    &:hover {
      background-color: #242433;
      span {
        color: #FFF !important;
      }
      svg {
        path {
          stroke: white;
          fill: white;
        }
      }
    }
  }

  
  .total-message {
    position: absolute;
    background-color: #E74C3B;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    top: -5px;
    border-radius: 100%;
    span {
      color: #FFFF;
    }
  }
}