.search-pannel-container {
    position: absolute;
    z-index: 1;
    top: 300px;
    left: 500px;
    width: 638px;
    height: 588px;
    border-radius: 8px;
    background: var(--Shades-White, #FFF);
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 161, 0.08);

    .title {
        color: var(--queen-blue-300-main, #4B7093);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
    }

    .form {
        display: flex;
        flex-direction: column;
        margin: 24px;

        .section {
            display: flex;
            flex-direction: column;

            margin-bottom: 40px;

            &.btn-list {
                margin-bottom: 30px;
            }
    
            .form-fields {
                display: flex;

                &.btn-list {
                    margin-left: auto;
                    margin-right: 0px;
                }

                .form-field {
                    display: flex;
                    flex-direction: column;

                    margin-left: 8px;
                    margin-right: 8px;
    
                    label {
                        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; 
                    }
    
                    &.action-by {
                        width: 282px;
                        margin-top: 2px;
                    }
    
                    &.category {
                        width: 282px;
                    }

                    &.percentage {
                        width: 282px;
                    }

                    &.volume {
                        width: 282px;
                    }

                    &.created-date {
                        width: 282px;
                    }

                    &.updated-date {
                        width: 282px;
                    }

                    &._status {
                        width: 282px;
                    }

                    .btn-search {
                        margin-left: 0px;
                        width: 112px;
                        &.button-bg {
                            background-color: #0000a1;
                            color: #fff !important;
                        }
                        border-radius: 8px;
                    }
    
                    .btn-cancel {
                        margin-right: 0px;
                        width: 112px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}