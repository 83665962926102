.dropdown-menu.alpha-dropdown-menu {
	border: none;
	box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.2);
	background-color: #ffffff;
	opacity: 0;
	transition: all .2s ease-out;
	&.show {
		opacity: 1;
	}
}

.dropdown-menu {
	z-index: 89 !important;
}
