.vod-seller-booking-page {
  padding: 165px 100px 100px 100px;

  .ui.secondary.pointing.menu {
    margin-bottom: 34px;
    border-bottom: 4px solid #e8e8ee;
  }

  .ui.secondary.pointing.menu .item {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: #bab3be;
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 4px solid #242433;
    color: #000000;
    margin-bottom: -4px;
  }

  .filter-option-content {
    margin-bottom: 2rem;
    .radio-option-content {
      flex: 1;
      margin: auto;
      .radio {
        margin-right: 3rem;

        label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #202529;
        }

        input[type="radio"]:checked ~ label:before {
          border-color: #0000a1;
        }

        input[type="radio"]:checked ~ label:after {
          background-color: #0000a1 !important;
        }
      }
    }

    .filter-type-content {
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px !important;
        color: #202529 !important;
        margin: auto 1rem;
      }

      .dropdown-type {
        font-style: normal;
        font-weight: normal;
        font-size: 16px !important;
        color: #202529 !important;
        padding: 1rem;

        i {
          float: right;
        }
      }
    }
  }

  .search-input {
    width: 416px !important;
    height: 46px;
    // border: 1px solid #d9d9e3;
    border-radius: 8px !important;
  }

  .header-text {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #242433;
  }

  .sub-header-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5c5c7a;
  }

  .label-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4b7093;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: #ffffff;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-action:hover {
    color: #ffffff;
    background: #0000a1;
  }

  .btn-action-primary,
  .btn-action-primary:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background: #0000a1;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-text,
  .btn-text:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: transparent;
    border: none;
  }

  .vod-seller-booking-footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding: 27.5px 110px;
    border-radius: 0.28571429rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    align-items: center;
    z-index: 2;

    .ui.pagination.menu .active.item {
      border-radius: 50%;
      background-color: #242433;
      font-weight: bold;
    }
  }
}

.vessel-list-response-modal {
  border-radius: 10px !important;

  .content {
    padding: 0 !important;
    overflow: hidden !important;

    .content-info {
      &.error .header-text {
        color: #c93626;
        background: #fef2f1;
      }

      &.success .header-text {
        color: #1c7d55;
        background: #eafaf4;
      }

      .header-text {
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
        padding: 24px 32px;
        margin-bottom: 0 !important;
      }

      .detail-part {
        padding: 24px 32px;
        .label-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #242433;
        }
      }
    }

    .btn-action {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000a1;
      background: #ffffff;
      border: 1px solid #0000a1;
      border-radius: 8px;
    }

    .btn-action:hover {
      color: #ffffff;
      background: #0000a1;
    }
  }
}

.modal-seller-approve-reject-pending-sharing-vessel {

  .modal-header-approve {
    display: flex;
    flex-direction: column;
    background: #EAFAF4;
    padding: 24px 32px;
    height: 88px;
    justify-content: center;
    .modal-header-text-approve {
      font-weight: 500;
      font-size: 26px;
      color: #1C7D55;
    }
  }
  .modal-header-reject {
    display: flex;
    flex-direction: column;
    background: #FEF2F1;
    padding: 24px 32px;
    height: 88px;
    justify-content: center;
    .modal-header-text-reject {
      font-weight: 500;
      font-size: 26px;
      color: #C93626;
    }
  }
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 30px 0px;
      border-radius: 0px 0px 10px 10px;
      .terms-condition {
        font-weight: 400;
        font-size: 12px;
        margin: 10px 0px ;
        .terms-condition-text{
          text-align: justify;
        }
      }
    }

}
