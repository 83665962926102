.search-box {
  display: flex;
  flex-direction: row;
  // background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: center;
  background-color: $color-grey-lighter;
  // transition: background-color 0.5s;

  .icon {
    margin-right: 0 !important;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &.cancel-icon {
      color: #474747;
      width: 0;
      opacity: 0;
      transition: all 0.5s ease-in-out;
    }
  }

  .search-input-wrapper {
    width: 0;
    transition: all 0.5s ease-in-out;

    input {
      border: none !important;
      padding: 0 !important;
      transition: all 0.5s ease-in-out;
    }

    .input {
      background-color: transparent !important;
    }
  }

  &.active {
    background-color: $color-white !important;

    .search-input-wrapper {
      width: 250px;

      input {
        padding: 9.5px 14px 9.5px 10px !important;
      }
    }
  }
}


@media (max-width: 768px) {
  .search-box {
    &.active {
      .search-input-wrapper {
        width: 275px;
      }
    }
  }
}
