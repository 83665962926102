.vod-booking-details-page {
  padding: 165px 100px 100px 100px;

  .nav-header {
    // margin: 0 auto;

    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #242433;

    span {
      color: #d0460b;
      cursor: pointer;
    }

    svg {
      margin-top: 2px;
      margin-right: 10px;
    }
  }

  .vessel-name-hyperlink {
    color: #0000A1 !important;
  }

  .icon-status {
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    margin: auto 1rem;

    &.yellow {
      svg path {
        fill: #9e7700;
      }

      span {
        color: #9e7700;
      }
    }

    &.green {
      svg path {
        fill: #1c7d55;
      }

      span {
        color: #1c7d55;
      }
    }

    &.red {
      svg path {
        fill: #c93626;
      }

      span {
        color: #c93626;
      }
    }

    &.grey {
      svg path {
        fill: #4b7093;
      }

      span {
        color: #4b7093;
      }
    }

    span {
      margin: auto;
    }
  }

  .header-text {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #242433;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: #ffffff;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-action:hover {
    color: #ffffff;
    background: #0000a1;
  }

  .btn-action-primary,
  .btn-action-primary:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background: #0000a1;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-text,
  .btn-text:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: transparent;
    border: none;
  }

  .label-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4b7093;
  }

  .label-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #5c5c7a;
  }

  .label-bold-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242433;
  }

  .label-bold-text-big {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #242433;
  }

  .estimated-price {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #242433;
  }

  .estimated-savings {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1C7D55
    ;
  }

  .estimated-savings-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1C7D55;
  }

  .grid {
    margin-left: -5px;
  }

  .div-border-top {
    border-top: 1px solid #e8e8ee;
    padding: 2rem 0;

    .lowest-price {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      gap: 4px;
      width: 131px;
      background: #d5f6e8;
      border-radius: 10px;
      color: #1c7d55;
    }
  }

  .special-status span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1c7d55;
    background: #d5f6e8;
    border-radius: 10px;
    padding: 0.5rem;
  }

  .route-content {
    margin: auto 0;
    width: 80%;
    flex-wrap: wrap;
    .location {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      margin-bottom: 0.5rem;
      width: 99px;
      height: 30px;
      background: #ebf0f5;
      border-radius: 10px;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #3b5773;
    }

    .arrow-location {
      margin: auto 0;
      margin-right: 10px;
    }
  }

  .arrow-double-icon {
    &.up {
      transform: rotate(180deg);
    }
  }

  .table-schedule {
    thead tr:first-child th {
      background-color: #dae3ec;
      border-right: 1px solid #ffffff;
    }

    thead tr th {
      background-color: #ebf0f5;
      border: 1px solid #ffffff;
    }
  }
}
