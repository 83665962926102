.my-svg-icon-white {
  fill: white !important;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.button-content {
  display: flex;
  justify-content: center;
}