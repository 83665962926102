.average-fuel-price-summary-widget-container {
    border-radius: 6.5px;
    background-color: #FFFFFF;
    margin-bottom: 40px;
    height: auto;
    padding-bottom: 20px;
    max-width: 100%;

    .header-title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px;

        padding-top: 45px;
        padding-bottom: 10px;
        margin-left: 45px;
    }

    .row {
        display: flex;
        flex-wrap: wrap;
    }

    .region-selection-container {
        margin-top: 30px;
        margin-left: 48px;
        margin-bottom: 30px;
    }

    .date-selection-container {
        margin-left: 45px;
    }

    .line-chart-container {
        margin: 20px;
        width: 96%;
        height: auto;

        .title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            text-align: center;
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px; 
        }

        .custom-legend {
            display: flex;

            margin-left: 40px;
            margin-bottom: 20px;

            .key {
                margin-right: 16px;
                color: #242433;
                font-size: 16px;
            }

            .legend {
                display: flex;
                width: auto;
                margin-right: 20px;

                .icon {
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    margin-top: 1px;
                }

                .name {
                    margin-left: 12px;
                    font-size: 16px;
                }
            }
        }
    }
}

.date-selection {
    display: flex;
    gap: 39px;

    .label {
        color: #5C5C7A;
    }

    .dropdown {
        border-radius: 8px !important;
        border-color: #D9D9E3 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        height: 46px !important;
        width: 250px !important;
        padding: 12px 16px !important;
        font-size: 14px !important;
        margin-top: 10px !important;
    }

    .input-field {
        input {
          border-radius: 8px !important;
          margin-top: 10px !important;
          border-color: #D9D9E3 !important;
          height: 46px !important;
          width: 250px !important;
        }
    }

    .year-picker-trigger {
        .place-holder {
          color: rgba(191, 191, 191, 0.87);
          font-weight: 400;
        }
    
        &.year-picker-error {
          border-color: red !important;
        }
    }
}

.react-datepicker {
    .react-datepicker__year--container {
        .react-datepicker__year {
            width: 300px;
            height: 200px;
            .react-datepicker__year-wrapper {
                margin-left: auto;
                margin-right: auto;
                width: 250px;
                height: 200px;

                div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}