.dashboard-summary {
    padding-top: 138px;
    padding-left: 165px;
    padding-right: 165px;
    padding-bottom: 70px;
    background-color: #EBF0F5;
    overflow: auto;

    .title {
        color: var(--independence-grey-dark-n-800-main-text, #242433);
        font-family: Inter;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 34px; 
        margin-top: 30px;
        margin-bottom: 50px;
    }
}