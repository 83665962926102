.vod-seller-booking-card-container {
  border-radius: 8px;
  box-sizing: border-box;
  border-left: 8px solid #fbe86a;

  .reservation-status,
  .icon-status {
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    margin: auto 1rem;

    &.yellow {
      svg path {
        fill: #9e7700;
      }

      span {
        color: #9e7700;
      }
    }

    &.green {
      svg path {
        fill: #1c7d55;
      }

      span {
        color: #1c7d55;
      }
    }

    &.red {
      svg path {
        fill: #c93626;
      }

      span {
        color: #c93626;
      }
    }

    &.grey {
      svg path {
        fill: #4b7093;
      }

      span {
        color: #4b7093;
      }
    }

    span {
      margin: auto;
    }
  }

  .card-top:hover {
    background: #fff3b3;
  }

  .card-top {
    background: #eaeaea;
    border-radius: 0px 8px 0px 0px;
    position: relative;
    align-items: center;

    .tag-group {
      font-weight: 600;
    }

    .card-label {
      flex: 1;
      cursor: pointer;
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      padding: 12px 16px;
      color: #0000a1;

      .vessel-name {
        border-right: 1px solid #adadc2;
      }

      .bold-text {
        margin: auto;
        font-weight: 600;
      }

      svg {
        height: 24px;
        width: 24px;
      }
    }

    .div-toggle {
      margin: auto 2rem;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #4b7093;
        margin: auto 1rem;
      }

      .ui.toggle.checkbox label:after {
        background-color: #f9f9fb !important;
        border: 1px solid #d9d9e3 !important;
      }

      .ui.toggle.checkbox label:before {
        background-color: #cacad8 !important;
        border: none !important;
      }

      .ui.checked.toggle.checkbox label:before {
        background-color: #0000a1 !important;
        border: none !important;
      }
    }
  }
  .card-bottom {
    padding-bottom: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 8px 0px;

    .label-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #5c5c7a;
    }

    .label-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #242433;
    }

    .route-content {
      flex-wrap: wrap;
      .location {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #0000a1;
        background: #e6e6f6;
        border-radius: 10px;
        padding: 8px;
        margin: 5px 10px 5px 0;
        white-space: nowrap;
      }

      .arrow-location {
        // color: #5c5c7a;
        // margin: auto 0;
        // margin-right: 10px;
        // font-size: 16px;
        margin: auto 0;
        margin-right: 10px;
      }
    }

    .arrow-double-icon {
      &.up {
        transform: rotate(180deg);
      }
    }

    .grid {
      flex: 1;
      margin: 1rem;
      padding: 1rem;

      &.details {
        background: #f9f9fb;
        border-radius: 8px;
      }

      .four.column.row .column:not(:last-child) {
        margin-right: 3rem;
      }
    }

    .table-schedule,
    .table-reservation {
      thead tr:first-child th {
        background-color: #dae3ec;
        border-right: 1px solid #ffffff;
      }

      thead tr th {
        background-color: #ebf0f5;
        border: 1px solid #ffffff;
      }

      tbody tr.seller {
        background-color: #f0f0f4;
      }

      .voyage-sharing-schedule-variance-button {
        padding: 0px !important;
        margin-top: 1em;
      }
    }
  }
}

.modal-main-unlist {
  .modal-header {
    font-weight: 600;
    color: #c93626;
    background-color: #fef2f1;
    height: 70px;
    .modal-header-text {
      font-weight: 600;
      color: #c93626;
      font-size: 20px;
      line-height: 40px;
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    .vessel-name {
      font-weight: 600;
    }
    .terms-conditions {
      font-weight: 600;
      color: #c93626;
    }
  }

  .cancel-btn {
    font-weight: 600;
    font-size: 16px;
    color: $color-primary !important;
    background: $color-white !important;
    width: 130px;
    height: 45px;
  }
}

.modal-approve-reject {
  .modal-header-reject {
    font-weight: 600;
    background-color: #fef2f1;
    height: 70px;
    .modal-header-text-reject {
      font-weight: 600;
      color: #c93626;
      font-size: 20px;
      line-height: 30px;
      padding: 20px;
    }
  }
  .modal-header-approve {
    font-weight: 600;
    background-color: #EAFAF4;
    height: 70px;
    .modal-header-text-approve {
      font-weight: 600;
      color: #1C7D55;
      font-size: 20px;
      line-height: 30px;
      padding: 20px;
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    .vessel-name {
      font-weight: 600;
    }
    .terms-conditions {
      font-weight: 600;
      color: #c93626;
    }
  }
  .cancel-btn {
    font-weight: 600;
    font-size: 16px;
    color: $color-primary !important;
    background: $color-white !important;
    width: 130px;
    height: 45px;
  }
}

.modal-confirmation {
  .modal-header {
    font-weight: 600;
    background-color: #ebf0f5 !important;
    height: 70px;
    .modal-header-text {
      font-weight: 600;
      color: #4b7093;
      font-size: 20px;
      line-height: 40px;
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    .terms-conditions {
      font-weight: 600;
      color: #c93626;
    }
  }
  .content-info {
    &.error .header-text {
      color: #c93626;
      background: #fef2f1;
      border-radius: 10px 10px 0px 0px;
    }
    &.success .header-text {
      color: #1c7d55;
      background: #eafaf4;
      border-radius: 10px 10px 0px 0px;
    }
    .header-text {
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      padding: 14px 30px;
      margin-bottom: 0 !important;
    }
    .detail-part {
      padding: 34px 32px;
      .label-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #242433;
      }
      .label-bold-text {
        font-weight: bold;
      }
    }
  }

  .btn-action {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #0000a1;
    background: #ffffff;
    border: 1px solid #0000a1;
    border-radius: 6px;
  }

  .btn-action:hover {
    color: #ffffff;
    background: #0000a1;
  }

  .cancel-btn {
    font-weight: 600;
    font-size: 14px;
    color: $color-primary !important;
    background: $color-white !important;
  }
}

.modal-schedule-variance-voyage-sharing {
  border-radius: 10px !important;
  .content-title { color: #5C5C7A }
  .modal-header {
    background: #EBF0F5;
    
    .header-div {
      font-weight: 500;
      font-size: 26px;
      height: 50px;
      padding: 0px 10px 0px ;
     .icon {
        color: #4B7093;
       
      }
    }
    .modal-header-item {
      color: #4B7093;
      align-items: center;
     
    }
    
  }
  .modal-content {
    .table-schedule {
        thead tr:first-child th {
          background-color: #DAE3EC;
        }

        thead tr th {
          background: #FFFFFF;
          border: 1px solid #ffffff;
          color: #242433;
        }
      }
    }
}
