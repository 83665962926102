.vessel-management-page {
  padding: 128px 165px 20px;

  .left-content {
    flex: 1;
  }

  .right-content {
    margin: auto;
  }

  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  .medium-text-black {
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-large-text-black {
    font-size: 18px;
    line-height: 18px;
  }

  .medium-large-text-black-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .medium-text-purple {
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .medium-text-blue-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4a5496;
  }

  .btn-text-purple-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    background-color: #ffffff;
    border: 1px solid #0000A1;
  }

  .btn-action:hover,
  .btn-action:hover svg path {
    color: #ffffff;
    background-color: #0000A1;
    stroke: #ffffff;
  }

  .btn-back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    border: none;
    width: 250px;
    border-radius: 4px;
    background-color: #ffffff;
    width: auto;
    padding: 7px 1.5rem !important;
  }

  .btn-back:hover {
    color: #ffffff;
    background-color: #0000A1;
  }

  table tr td {
    i {
      color: #0000A1;
      cursor: pointer;
    }
    .subcontent-cell {
      margin-bottom: 16px;
    }
  }

  .page-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .filter-content {
    .btn-action {
      height: 45px;
      margin: 1rem auto;
    }

    .grid {
      margin: 1rem 0;
      padding: 2rem;
      background: #f5f7fe;
      border-radius: 4px;

      &.control-log-grid,
      &.maintenance-log-grid {
        width: 80%;
        .vessel-management-period-picker {
          button {
            width: 100%;
            text-align: left;
            padding-bottom: 11px;
            padding-top: 11px;
          }
        }
      }

      .filter-dropdown {
        width: 100% !important;
        background-color: #ffffff !important;

        input,
        .text {
          font-size: 15px;
        }

        i {
          font-size: 25px;
          padding: 0.5rem 0;
          float: right;
        }
      }

      .checked-category-container {
        flex-wrap: wrap;
        max-height: 100px;
        overflow-y: auto;

        .checked-category {
          background: #f0f0fa;
          border-radius: 20px;

          .category-text {
            font-weight: 600;
            font-size: 14px;
            color: #eaeaea;
            margin: 5px;
          }

          div,
          i {
            margin: auto;
          }
        }
      }
    }
  }

  .vessel-table-container,
  .vessel-details-page {
    .current-contract-detail div {
      margin: auto 0;
    }

    .ui.secondary.pointing.menu {
      border-bottom: none;
      margin-bottom: 34px;
      .item {
        color: #bab3be;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
      }
    }

    .ui.secondary.pointing.menu .active.item {
      border-bottom: 2px solid #0000A1;
      color: #000000;
    }

    table {
      border: none;
      border-collapse: collapse;

      .sub-header th {
        border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
      }

      thead th {
        background-color: #f0f0fa !important;
        vertical-align: bottom;

        &.dark-background {
          background-color: #dae3ec !important;
        }
      }

      tbody {
        tr:nth-child(2n) {
          background-color: #ffffff !important;
        }
        tr:nth-child(4n-1) {
          background-color: #f5f7fe !important;
        }
        tr:nth-child(4n) {
          background-color: #f5f7fe !important;
        }

        tr .maintenance-day {
          font-weight: 700;
          font-size: 14px;

          &.red {
            color: #ef5d60;
          }
          &.yellow {
            color: #f9b262;
          }
          &.green {
            color: #2bcf9e;
          }
        }

        .row {
          height: 60px;
          &.expand {
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          }

          td {
            padding-top: 22px;
          }
        }

        .row-edit {
          &.expand {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }

          .content {
            padding: 1rem 6rem;

            .sub-table-content {
              padding: 1rem;

              table tr {
                th,
                td {
                  border: none;
                  font-size: 14px;
                  background-color: #ffffff !important;
                }

                th {
                  font-weight: 600;
                  line-height: 16px;
                }

                td {
                  line-height: 24px;
                  border-bottom: 0.5px solid #e0e1e8;
                }
              }
            }
          }
        }
      }
    }
  }

  .edit-contract-container {
    table {
      border: none;
      border-collapse: collapse;

      thead th {
        background-color: #f0f0fa !important;
      }

      tbody {
        tr:nth-child(even) {
          background-color: #ffffff !important;
        }
        tr:nth-child(odd) {
          background-color: #f5f7fe !important;
        }

        tr td {
          i {
            margin: auto 0.5rem;
          }
        }
      }
    }
  }

  .edit-contract-container {
    .add-content {
      border: 1px solid #0000A1;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .vessel-management-period-picker,
    .vessel-management-dropdown {
      display: flex;
    justify-content: space-between;
      width: 100% !important;
      button{
      width: 100% !important;
      >div{
        justify-content: space-between;
      }
      }
      input,
      .text {
        font-size: 14px;
        line-height: 24px;
        color: #202529 !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      i {
        font-size: 25px;
        padding: 0.3rem 0;
        float: right;
      }
    }

    .vessel-management-period-picker input,
    .vessel-management-period-picker button,
    .vessel-management-dropdown {
      border: 1px solid #202529 !important;
    }

    .vessel-management-period-picker button {
      font-weight: normal;
      padding: 0 1rem;
      height: 43px;
      i {
        margin-right: unset;
      }
    }

    .vessel-management-input {
      border: 1px solid #202529;
      border-radius: 4px;
    }
  }
}

.modal-vessel-management {
  .left-content {
    flex: 1;
  }

  .right-content {
    margin: auto;
  }

  .grid {
    margin: auto;
    overflow-y: scroll;
    max-height: 550px;
    width: 100%;
  }

  .large-text-bold {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 1rem;
  }

  .medium-text-black {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-large-text-black-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .medium-text-blue-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4a5496;
  }
  .vessel-management-dropdown {
    &.show-invalid {
      border: 3px solid red !important;
    }
  }
  .vessel-management-period-picker,
  .vessel-management-dropdown {
    &.show-invalid {
      &.dropdown {
        padding: 6.5px !important;
      }
      button {
        border: 3px solid red !important;
      }
    }
    &.dropdown {
      width: 100% !important;
    }

    button {
      width: 100% !important;
      > div {
        justify-content: space-between;
      }
    }
    input,
    .text {
      font-size: 14px;
      line-height: 24px;
      color: #202529 !important;
    }

    i {
      font-size: 25px;
      padding: 0.3rem 0;
      float: right;
    }
  }

  .vessel-management-period-picker input,
  .vessel-management-period-picker button,
  .vessel-management-dropdown {
    border: 1px solid #202529 !important;
  }

  .vessel-management-period-picker button {
    font-weight: normal;
    padding: 0 1rem;
    height: 43px;
    i {
      margin-right: unset;
    }
  }

  .vessel-management-input {
    border: 1px solid #202529;
    border-radius: 4px !important;
    &.show-invalid {
      input {
        border: 3px solid red !important;
      }
    }
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    background-color: #ffffff;
    border: 1px solid #0000A1;
  }

  .btn-action:hover,
  .btn-action:hover svg path {
    color: #ffffff;
    background-color: #0000A1;
    stroke: #ffffff;
  }

  .delete-icon {
    display: flex;
    height: 100%;
    i {
      color: #0000A1;
      cursor: pointer;
      align-self: flex-end;
      margin-bottom: 1rem;
    }
  }
}
