.voyage-card-container-page {
    padding: 5px 160px 20rem 160px;
    background-color: #F9F9FB;
    .btn-action {
        cursor: pointer;
        background-color: transparent;

        &.disabled {
            border: none !important;
            cursor: default;

            svg {
                stroke: #979797;
            }
        }
    }

    .sort-by-container {
        margin: 2rem 0 1rem 0;

        .sort-by-text {
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            color: #202529;
        }

        .sort-by-icon {
            cursor: pointer;

            &.asce svg {
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            &.desc svg {
                -ms-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }

    .voyage-card-container:hover {
        .voyage-card-header {
            background-color: #DAEEF1 !important;
            border: 1.5px solid #DAEEF1 !important; 
        }
        &.voyage-card-material{
            .voyage-card-header {              
                background-color: #FFF3B3 !important;
                border: 1.5px solid #FFF3B3 !important; 
            }            
        } 
    }

    .voyage-card-container {
        background: #FFFFFF;
        border: 1px solid #F0F0F4;
        border-left: 8px solid #C0E2E8;
        border-radius: 8px;

        &.edited {            
            .voyage-card-header {              
                background-color: #DAEEF1;
                border: 1.5px solid #DAEEF1;
                
            }
        }

        &.voyage-card-material{
            border-left: 8px solid #FBE86A;      
            &.edited {            
                .voyage-card-header {
                    background-color: #FFF3B3;
                    border: 1.5px solid #FFF3B3; 
                }
            }            
        }        

        .voyage-card-header {
            border: 1.5px solid #F0F0F4;
            background-color: #F0F0F4;
            font-weight: 500;
            font-size: 18px;
            line-height: 16px;
            color: #242433;
            padding: 16px 24px;                   
            overflow: hidden;
            justify-content: space-between;
            .voyage-id {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 7px;
                span {
                    margin-left: 5px;
                    font-weight: 600;
                    fill: #242433;
                }
            }
            .voyage-route {
                font-size: 14px;
                font-weight: 400;
                gap: 5px;
                align-items: center;
                .arrow-right-icon {
                    height: 20px;
                    margin-top: -10px;
                }
                .voyage-group {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    .voyage-detail {
                        font-size: 14px;
                        font-weight: 600;
                        display: flex;
                        align-items: center;
                        gap: 5px; 
                        svg path {
                            stroke: #9E9EB7;                            
                        }
                    }
                }
                
            }
            .discount-box {
                font-size: 16px;
                font-weight: 500;
                margin-right: 26px;
                display: flex;
            }
            svg {
                width: 20px;
                height: 20px;
                margin-top: 5px;
                path {
                    stroke: #242433;
                }
            }
            .savings-label {
                line-height: 20px;
                margin-left: 6px;
                font-weight: 500;
                font-size: 18px;
                stroke: #242433;
                margin-top: 5px;
            }
            .type-vessel{
                height: 32px;
                margin-right: 26px; 
                font-weight: 500;
                font-size: 16px;
                color: #1C7D55;
                background: #D5F6E8;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 4px 16px;
                gap: 4px;
                width: 175px;
                height: 32px;
                background: #D5F6E8;
                border-radius: 10px;
            }
            .drilling-vessel {
                font-weight: 500;
                font-size: 16px;
                color: #242433;
            }
        }

        .highlighted {
            &.Material-highlighted{
                background-color: #FFF3B3;
            }

            &.Passengers-highlighted{
                background-color: #DAEEF1;
            }
        }

        .voyage-card-content {   
            align-items: center;
            gap: 10%;
            .text-gray-bold{
                color: #686887;
                font-weight: 600;
            }
            .location-title {
                margin-bottom: 16px;
            }
            .date-time {
                margin-bottom: 12px;
            }

            .backload-deck{
                display: flex;
                background-color: #F9F9FB;
                padding: 16px 40px;
                border-radius: 8px;
                gap: 4rem;
                
                .text-blue {
                    color:#4B7093;
                }
            }  
            
            .date {
                display: flex;
                align-items: center;
                gap: 0.5rem;

                span {
                    font-weight: 900;
                    color: #BCBCCD;
                }
            }

            .arrow::after {
                content: '>';
                font-size: 40px;
                font-weight: 600;
                color: #6B6C70;
                position: absolute;
                right: -3px;
                top: -19px;
            }

            .arrow.active::after {
                content: '';
                width: 0;
                height: 0;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 10px solid #fff;
                position: absolute;
                right: -10px;
                top: -4px;
            }

            
        }
        /* Saving cost */
        .saving-info{
            .saving-title {
                color: #5C5C7A;
                margin-bottom: 5px;
            }
            .fact-value {
                font-size: 16px;
                font-weight: 600;
                color: #242433;
                margin-right: 5px;
            }
            .old-value {
                font-size: 14px;
                color: #5C5C7A;
            }
            .saving {
                color: #4B7093;
                font-weight: 600;
                margin-top: 5px;
            }
        }

        .voyage-input-container {
            align-items: center;

            .voyage-field {
                border-radius: 4px;
            }

            .field-number {
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                color: #202529;
            }

            .warning-input input {
                border-color: #E74C3B;
            }
        }

        .warning-text {
            color: #E74C3B;
            font-size: 12px;
        }

        .edit-deck-container {
            background: #F0F0F4;
            .btn-action {
                background: none;
            }
            &:nth-child(even){
                margin-top: 4px;
            }
            /* Saving cost */
            .saving-info{
                .saving-title {
                    color: #5C5C7A;
                    margin-bottom: 5px;
                }
                .fact-value {
                    font-size: 16px;
                    font-weight: 600;
                    color: #242433;
                    margin-right: 5px;
                }
                .old-value {
                    font-size: 14px;
                    color: #5C5C7A;
                }
                .saving {
                    color: #4B7093;
                    font-weight: 600;
                    margin-top: 5px;
                }
            }
        }

        .edit-deck-container:hover {
            background: #E6DCFF;            
            // .btn-action {
            //     background: #E6DCFF;
            // }
        }

        .deck-container-page {
            .input-side-label {
                font-size: 16px;
                line-height: 12px;
                font-weight: 600;
                color: #242433;
                margin: auto;               
            }
            .dropdown-side-label{
                font-weight: 600;
                line-height: 12px;
                font-size: 14px;
                margin: auto;
                color: #5C5C7A;
            }
            .text-color-grey{
                color: #5C5C7A;
                font-weight: 400;
            }

            div.text-color-grey{
                margin-bottom: 10px;
            }

            .add-load-content {
                background: #F5F7FE;
                .payload-tab-input {
                    width: 80%;
                    border-radius: 4px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px;
                    margin-right: 0px;
                }

                .warning-input {
                    border: solid 1px red;
                }

                .warning {
                    color: red;
                    margin-top: 10px;
                    font-size: 12px;
                }

                .payload-tab-ddl {
                    width: 62px;
                    display: flex;
                    justify-content: space-between;
                    background-color: white;
                    border: 1px solid #ccc;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                    border-left: 0px solid #fff;
                }
                .demand-deck-category{
                    justify-content: space-between;
                    width: 20%;
                    height: 38px;
                    background-color: #fff;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                }
                .view-calculation-deck{
                    font-size: 12px;
                    color: #686887;
                }
            }
            .btn-edit-voyage{
                text-align: right;
                margin-top: 10px;
                &.btn-add{
                    .btn-action{
                        &:hover{
                            background-color: #0000A1 !important;
                            color: #fff !important;
                        }
                    }
                }
                button{
                    border: 1px solid #0000A1;
                    border-radius: 8px;
                    color: #0000A1 !important;
                    padding: 12px 48px;
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: capitalize;
                    background: none;                    
                }
                .button-bg{
                    background-color: #0000A1;
                    color: #fff !important;
                }
                .btn-action{
                    &.button-bg-none{
                        border: none;
                    }
                  
                }
            }
        }
        .passenger-container-page{
            background-color: #F9F9FB;
            padding: 24px;
        }
    }

    .bottom-alert-content {
        align-items: center;

        .notice-icon {
            svg path {
                fill: #6B6C70;
            }
        }

        .warning-bottom-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #6B6C70;
        }
    }
}