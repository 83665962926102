.active-log-viewer {
    text-align: left !important;
    width: 100%;

    .wrapper {
        .reason-card {
            font-weight: 400;
            font-size: 12px;
            width: fit-content;

            div {
                padding: 4px 12px;
            }

            .reason-card-title {
                color: #ffffff;
            }

            .reason-card-body {
                background-color: #ffffff;
                border-radius: 0 0 4px 4px;
            }

            .border-radius {
                border-radius: 4px;
            }

            .border-top-radius {
                border-radius: 4px 4px 0 0;
            }

            .color-red {
                background-color: #d37272;
            }

            .color-orange {
                background-color: #f9b262;
            }

            .color-purple {
                background-color: #999;
            }
            .self-comment {
                background-color: #6B6BC8;
            }
            .system-comment {
                background-color: #648BB0;
            }
        }

        .log-detail {
            background-color: #ebecf3;
            padding-top: 13px;
            padding-left: 25px;
            padding-bottom: 17px;
            margin-bottom: 12px;
            border-left: 4px solid #6b6c70;
            position: relative;

            .log-description {
                font-weight: 600;
                font-size: 12px;
                margin-bottom: 2px;
            }

            .log-time {
                font-size: 8px;
                font-weight: 300;
                margin-bottom: 9px;
            }

            .delete-log-icon {
                position: absolute;
                right: 10px;
                font-size: 15px !important;
            }
        }

        @media (max-width: 768px) { 
            .log-detail {
                background-color: #F0F0FA;
                width: 100%;
                padding-top: 13px;
                padding-left: 25px;
                padding-bottom: 17px;
                margin-bottom: 12px;
                // border-left: none;
                position: relative;
                &.self-comment {
                    border-left: 4px solid #0000A1;
                }
                &.system-comment {
                    border-left: 4px solid #4B7093;
                }
                .log-description {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    &.self-comment {
                        color: #0000A1;
                    }
                    &.system-comment {
                        color: #4B7093;
                    }
                }
                .log-time {
                    font-family: Inter;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #5C5C7A;
                }
            }
            .reason-card {
                font-family: Inter;
                font-size: 10px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                &.self-comment {
                    background-color: #6B6BC8;
                }
                &.system-comment {
                    background-color: #648BB0;
                }
            }
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
            border-radius: 4px;
            background-color: $color-white;
        }

        &::-webkit-scrollbar {
            width: 4px !important;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #C4C4C4;
        }
    }

    .title {
        font-size: 14px;
        font-weight: 600;
    }
}

.mt {
    margin-top: 9px;
}

.log-viewer-scrollable {
    overflow-y: scroll;
    max-height: 340px;
}

.button-primary {
    border: 1px solid  $color-primary !important;
    color: $color-primary !important;
    background: $color-white !important;
    &:hover, &:focus{
        background: $color-primary !important;
        color: $color-white !important;
      }
}

@media (max-width: 768px) { 
    .active-log-viewer {
        .title {
            color: var(--queen-blue-300-main, #4B7093) !important;
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 22px !important; /* 157.143% */
        }
    }
}
