table {
  &.fixed {
    table-layout: fixed;
  }
  &.bordered {
    > tbody > tr,
    > thead > tr,
    > tr {
      > th,
      > td {
        &:not(:last-child) {
          border-right: 1px solid $color-grey;
        }
      }

      &:not(:last-child) {
        > th,
        > td {
          border-bottom: 1px solid $color-grey;
        }
      }
    }
  }
}
.ReactTable.alpha-react-table-border.alpha-react-table {
  border: 1px solid $color-grey;
}
.ReactTable .rt-table .rt-thead.-header {
  box-shadow: none;
  border-bottom: 1px solid $color-grey;
  background-color: #fbfbfb;
}
.alpha-react-table.ReactTable {
  border: none;
  &.wrap-td {
    .rt-tbody .rt-td {
      white-space: normal;
    }
  }
  input {
    border: none;
  }
  // border-radius: $rem-8;
  .rt-thead .rt-th,
  .rt-tbody .rt-td {
    font-size: $rem-12;
    padding: $rem-20;
    text-align: left;
    border: none;
    .text-teal {
      color: $color-grey-light;
    }
    .text-orange-yellow {
      color: $color-highlight-orange;
    }
    .text-red {
      color: $color-highlight-red;
    }
  }
  .rt-td {
    overflow: initial;
  }
  .rt-thead .rt-th {
    color: $color-grey-light;
    font-family: $font-normal;
    font-weight: $fontweight-600;
    display: flex;
    &.-sort-desc,
    &.-sort-asc {
      box-shadow: none;
    }
    &.-cursor-pointer {
      display: flex;
      &::before,
      &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
      }
      &::before {
        order: 1;
        margin-left: $rem-12;
        content: "\f0d7";
        opacity: 0.3;
        margin-top: 0.2rem;
      }
      &::after {
        order: 2;
        content: "\f0d8";
        opacity: 0.3;
        margin-left: -0.46rem;
        margin-top: -0.1rem;
      }
    }
    &.-sort-asc::after {
      opacity: 1;
    }
    &.-sort-desc::before {
      opacity: 1;
    }
  }
  .icon {
    margin-right: $rem-12;
    height: $rem-16;
  }
  .text-breakline {
    white-space: normal;
  }
  &.border {
    > .rt-table {
      > .rt-thead > .rt-tr > .rt-th {
        border-right: 1px solid #cccccc;
        background: #f8f8f8;
        &:nth-last-child(1) {
          border-right: none;
        }
      }
      > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td {
        border-right: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        &:nth-last-child(1) {
          border-right: none;
        }
      }
    }
  }
  &.outline {
    > .rt-table {
      > .rt-thead > .rt-tr > .rt-th:not(:last-child) {
        border-right: 1px solid #cccccc;
        background: #f8f8f8;
      }
      > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:not(:last-child) {
        border-right: 1px solid #cccccc;
      }
      > .rt-tbody > .rt-tr-group:not(:last-child) {
        border-bottom: 1px solid #cccccc;
      }
    }
  }
  &.center {
    > .rt-table {
      > .rt-thead > .rt-tr > .rt-th {
        justify-content: center;
      }
      > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td {
        text-align: center;
      }
    }
  }
}

.ReactTable.alpha-react-table.header-icon-right {
  .rt-thead .rt-th {
    &.-cursor-pointer {
      &::before,
      &::after {
        position: absolute;
        right: 1rem;
      }
    }
  }
}

.ReactTable.alpha-react-table.no-data {
  .rt-thead {
    border-bottom: none;
    background: transparent;
  }
  .rt-tbody {
    .rt-tr-group {
      border-bottom: none;
    }
  }
}

.ReactTable-no-data {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  margin: auto;
  display: flex;
  color: $color-grey;
  flex-direction: column;
  text-align: center;
}

td {
  &.bottom {
    vertical-align: bottom;
  }
  &.middle {
    vertical-align: middle;
  }
  &.top {
    vertical-align: top;
  }
}
