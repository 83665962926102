.vod-updates-card {
  .custom-slider .slider-body {
    padding: 0 !important;
  }
  .menu-title {
    font-size: 26px;
    line-height: 28px;
    color: #242433;
  }
  .box-content {
    border: 1px solid #E8E8EE;
    padding: 10px;
    border-radius: 12px;
    height: fit-content;
    .body {
      padding: 1rem;
      min-height: 130px;
      .value {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #242433;
      }
      .text {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #202529;
      }
    }
    .footer {
      padding: 0px 1rem 5px 1rem;
      bottom: 0;
      .blue-registration-button {
        color: #ffffff;
        background: #0000A1;
        padding: 12px 35px;
      }
      .white-button {
        border: 1px solid #0000A1;
        color: #0000A1;
        padding: 12px 35px;
        background: #FFFFFF;
        &:hover {
          background: #0000A1;
          color: #ffffff;
        }
      }
      .review-button {
        padding: 12px 35px;
        color: #0000A1;
        background-color: #FFFFFF;
        &:hover {
          background-color: #FFFFFF;
        }
      }
    }
  }
}