.bookinglistpage {
  padding-top: 128px;
  padding-left: 165px;
  padding-right: 165px;
  padding-bottom: 20px;

  .reserve-space-container {
    background-color: #fff;
    padding: 24px;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    margin-top: 18px;
    margin-bottom: 18px;
    border-radius: 10px;
    cursor: pointer;

    .reserve-space-title {
      color: #5963a5;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 24px;
    }
    .navigation-menu-container {
      background-color: #f5f7fe;
      border: 1px solid #c4c8df;
      border-radius: 4px;
      align-items: center;
      padding: 8px 24px;

      .navigation-menu-title {
        color: #000;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .ui.secondary.pointing.menu {
    border-bottom: none;
    margin-bottom: 34px;
    .search-filter-reservations {
      margin-left: auto;
      padding-top: 5px;
      display: flex;
      .search-input {
        border-radius: 8px 0 0 8px;
        border-style: none !important;
        width: 320px;
      }
      .filter-reservations {
        border: 1px solid #ccc;
        border-left: none;
        border-radius: 0 8px 8px 0;
        background-color: transparent;
        color: #0000A1;
        font-weight: 600;
        font-size: 16px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        p {
          margin-left: 5px;
        }
      }
    }
  }
  .ui.secondary.pointing.menu .item {
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    color: #bab3be;
  }

  .booking-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .table-content-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .ui.secondary.pointing.menu .active.item {
    border-color: #0000A1;
    color: #000000;
  }

  .reservation-card-container {
    cursor: pointer;
    .reservation-card-top {
      background: #eaeaea;
      border-radius: 8px 8px 0px 0px;
      position: relative;
      .reservation-label {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #242433;
        align-items: center;
        padding: 12px 16px;
        .visable-dot{
          margin-top: -23px;
          margin-left: -11px;
        }
      }
      .download-icon {
        height: 24px;
        width: 24px;
        position: absolute;
        right: 24px;
        top: 8px;
      }
    }
    .reservation-card-bottom {
      padding: 16px 50px 27px 16px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 8px 8px;
      .voyage-label {
        color: #0000A1;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
      }

      .reservation-details-container {
        width: 100%;

        .reservation-location-section {
          width: 45%;

          .start-location-container {
            width: 50%;
          }

          .end-location-container {
            margin-left: 40px;
            margin-right: 30px;
            width: 50%;
          }
        }

        .reservation-details-section {
          width: 35%;

          .payload-details-container {
            width: 100%;

            .payload-content-container {
              align-items: center;
            }

            .payload-details {
              font-size: 16px;
              line-height: 16px;
              font-weight: 600;
              color: #202529;
            }
          }
        }

        .property-label {
          margin-left: 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        }

        .content-sub-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          &.active {
            color: #ffffff;
          }
        }

        .top-card-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          color: #6b6c70;
          margin-bottom: 10px;
        }

        .status-section {
          width: 20%;

          .reservation-status-container {
            align-items: center;

            &.delete {
              svg path {
                fill: #ef5d60;
              }
            }
            &.ready {
              svg path {
                fill: #2bcf9e;
              }
            }
            &.warning {
              svg path {
                fill: #f9b262;
              }
            }

            .reservation-status {
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;

              &.delete {
                color: #ef5d60;
              }

              &.ready {
                color: #2bcf9e;
              }

              &.warning {
                color: #f9b262;
              }
            }
          }
        }

        .content-date-sub-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          &.active {
            color: #ffffff;
          }
        }

        .content-label {
          color: #96968c;
          font-size: 12px;
          font-weight: 600;
          line-height: 12px;

          &.active {
            color: #ffffff;
          }
        }

        .arrow {
          position: relative;
          width: 200px;
          margin-top: 10px;
          height: 0;
          border-bottom: 2px solid #bab3be;
          &.active {
            border-bottom: 2px solid #ffffff;
          }
        }

        .arrow::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #bab3be;
          position: absolute;
          right: -10px;
          top: -4px;
        }

        .arrow.active::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #fff;
          position: absolute;
          right: -10px;
          top: -4px;
        }
      }
    }
  }
}
.finalised {
  padding-top: 9rem;
}

.modal-title {
  align-items: center;

  .modal-icon {
    svg path {
      fill: #000000;
    }
  }
}

.filter-reservations-popup {
  text-align: left;
  width: 560px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: 1px solid #eaeaea;
  padding: 20px;
  padding-bottom: 48px;
  
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    color: #4a5496;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #202529;
    padding-top: 5px;
  }
  .filter-reservations-popup-body {
    padding: 18px 8px;
    .row {
      padding: 15px 0 !important;
      h4 {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #202529;
      }
      .filter-dropdown {
        margin-top: 5px;
        width: 100%;
      }
    }

    .ui.selection.dropdown {
      position: relative;
      border: 1px solid #202529;

      .icon {
        position: absolute;
        right: 5px;
      }
    }

    .period-picker {
      margin-top: 5px;

      .ui.input > input {
        border: 1px solid #202529;
        // border-radius: 4px;
      }

      .icon {
        color: #202529;
        opacity: 1 !important;
      }
    }
  }

  .no-border {
    border: none;
  }
}

.popup-download-type {
  text-align: left;
  padding: 20px 15px;
  width: 200px;
  .select-file {
    font-size: 14px;
    font-weight: bold;
  }
  .checkbox-options {
    display: flex;
    flex-direction: column;
    margin: 10px 5px;
    .checkbox {
      margin: 5px 0;
    }
  }
  .cover-download-button {
    text-align: center;
    button {
      padding: 10px 20px;
      border-radius: 3px;
      border: none;
      background-color: #0000A1;
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
        background-color: gray;
      }
    }
  }
}