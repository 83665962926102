.select-date-popup {
  max-width: 768px;

  .popup-header {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding-top: 32px !important;
    padding-left: 32px !important;
    border-bottom: 0 !important;
    font-size: 24px !important;
    font-weight: 600 !important;
  }
  .close-icon {
    cursor: pointer;
  }
  .popup-content {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .wrapper {
    position: relative;
    padding: 25px 22px;
    background-color: #f5f7fe !important;
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .checkbox-container {
    flex: 1;
    max-width: 65%;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .date-checkbox {
    margin-bottom: 12px;
    flex-basis: 33.33%;
  }
  .change-month {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-self: flex-end;
    max-width: 35%;

    .btn-prev-month,
    .btn-next-month {
      cursor: pointer;
    }
    .btn-next-month {
      margin-left: 16px;
      transform: rotate(180deg);
    }
    .btn-prev-month > path,
    .btn-next-month > path {
      fill: $color-primary;
    }
    .btn-prev-month.disabled,
    .btn-next-month.disabled {
      cursor: default;
    }
    .btn-prev-month.disabled > path,
    .btn-next-month.disabled > path {
      opacity: 0.7;
      fill: $color-grey;
    }
  }
  // .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
  //   content: url('../../../../../assets/images/checked.svg');
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   width: 17px;
  //   height: 17px;
  // }
  .select-date-container {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  .select-date-picker {
    width: 100%;
    background-color: transparent;
    .rdrMonthAndYearWrapper {
      display: none;
    }
    .rdrMonth {
      background: #fff;
    }
    .rdrMonth:first-child {
      margin-right: 8px;
    }
    .rdrMonthName {
      font-size: 16px;
      font-weight: 700;
      color: $color-black;
    }
  }
  .popup-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 0 !important;
  }
  .button.btn-primary.download-button {
    height: 48px;
    width: 143px;
    border-radius: 4px;
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    font-size: 16px;
    text-transform: capitalize;
    color: $color-white;
    background-color: $color-primary;
    &:disabled {
      color: $color-primary;
      background-color: $color-white-grey;
    }
  }
  .button.btn-primary.cancel-button {
    height: 48px;
    width: 116px;
    align-items: center;
    border-radius: 4px;
    font-family: $font-normal !important;
    font-weight: $fontweight-600;
    font-size: 14px;
    text-transform: capitalize;
  }
}

@media (max-width: 768px) {
  .select-date-popup {
    width: 360px !important;

    .popup-header {
      .header-title {
        color: var(--neutrals-dark-almost-black, #202529);
        /* Paragraph/Paragraph 2/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        margin-left: 10px;
        margin-top: 16px;
      }
    }

    .select-date-container {
      justify-content: center;
    }

    .row {
      justify-content: center;
    }

    .wrapper {
      background-color: white !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .select-date-dropdown-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .date-range-title {
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        /* Paragraph/Paragraph 3/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }
    }

    .rdrDateDisplayWrapper {
      background-color: white !important;
    }

    .rdrMonth {
      padding-bottom: 0px !important;
    }

    .popup-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      // border-top: 0 !important;
      border-radius: 0px 0px 10px 10px;
      background: var(--duke-blue-p-25, #F0F0FA);

      .date-selected {
        align-self: flex-start;
        margin-left: 16px;

        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        /* Paragraph/Paragraph 3/Regular */
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
      }

      .date-range {
        align-self: flex-start;
        margin-left: 16px;
        margin-bottom: 16px;

        color: var(--independence-grey-dark-n-800-main-text, #242433);
        /* Paragraph/Paragraph 2/Medium */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }

      .btn-list {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        gap: 10px;
        margin-left: 16px;
        margin-bottom: 16px;
      }
    }

    .button.btn-primary.download-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      width: 157px;
    }
  }
}
