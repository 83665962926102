@media (min-resolution: 120dpi) {
    .create-vessel-schedule-page {
        height: calc((166.309vh * 0.775) - 90px);
    }

    .scenario-modelling-gantt-modal {
        &.ui {
            .scrolling {
                &.content {
                    height: calc((166.309vh * 0.775) - 377px);
                    max-height: none;
                }
            }
        }
    }

    .gantt-sidebar-modal {
        &.ui {
            .scrolling {
                &.content {
                    height: calc((166.309vh * 0.775) - 440px);
                    max-height: none;
                }
            }

            .kpi-hidden {
                .scrolling {
                    &.content {
                        height: calc((166.309vh * 0.775) - 335px);
                    }
                }
            }

            .add-voyage-container {
                .scrolling {
                    &.content {
                        height: calc((166.309vh * 0.775) - 360px);
                    }
                }
            }
        }
    }
}