.create-vessel-schedule-page {
    position: relative;
    top: 100px;
    min-height: calc(100vh - 100px);
    background-color: #F9F9FB;
    // overflow: auto;

    .cvs-body {
        position: relative;
        width: calc(100% - 330px);
        // height: 100%;
        // height: calc(100vh - 190px);
        margin: auto;
        padding-top: 55px;
        // padding-bottom: 90px;

        &.cvs-body-with-footer-default {
            padding-bottom: 90px;
        }

        &.cvs-body-with-footer-prePub {
            padding-bottom: 125px;

            &.cvs-body-with-footer-prePub-expanded {
                padding-bottom: 300px;
            }
        }

        &.cvs-body-with-draft-footer {
            padding-bottom: 90px;
        }
    }

    .cvs-body-title {
        position: relative;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -.03em;
    }

    .cvs-body-dropdown-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #09010e;

        &.cvs-body-dropdown-label-lg {
            font-size: 18px;
        }
    }

    .cvs-body-content {
        position: relative;
        width: 100%;
        // min-height: calc(100vh - 450px);
        min-height: calc(100vh - 360px);
        // height: 100vh;
        background-color: #FFF;
        border-radius: 16px;

        &.cvs-body-content-default {
            display: flex;
            justify-content: center;
            align-items: center;

            .cvs-body-content-default-msg {
                font-weight: 400;
                font-size: 16px;

                .cvs-body-content-default-msg-row {
                    justify-content: center;
                    align-items: center;
                }
            }

            .cvs-body-content-rotating-icon {
                animation: spin 1s linear infinite;
            }

            .cvs-body-content-text-bold {
                font-weight: 600;
            }

            .cvs-body-content-text-red {
                color: #C93626;
            }

            .cvs-body-content-text-sm {
                font-size: 14px;
            }
        }

        // &.cvs-body-content-w-message {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;

        //     .cvs-body-content-w-message-msg {
        //         font-weight: 400;
        //         font-size: 16px;
        //         // text-align: center;

        //         .cvs-body-content-w-message-msg-row {
        //             justify-content: center;
        //             align-items: center;

        //             &.cvs-body-content-text-red {
        //                 color: #C93626;
        //             }
        //         }
        //     }
        // }
    }

    .cvs-footer {
        // position: sticky;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 90px;
        background-color: #F5F7FE;
        padding: 0 165px;
        transition: bottom .1s linear;
        z-index: 999;

        &.cvs-footer-disabled {
            bottom: -100px;
        }

        &.cvs-footer-prePub {
            height: 106px;

            &.cvs-footer-prePub-expanded {
                height: 275px;
            }
        }
        &.new-csv-footer {
            width: unset;
            background-color: unset;
            padding: unset !important;
            right: 80px;
            bottom: 6px;
            height: 42px;
            font-size: 14px;
        }
        .new-opt-primary {
            height: 42px;
            width: 191px;
            font-size: 14px !important;
            padding: unset !important;
        }
    }

    .cvs-csc-view-toggle-button {
        padding: 5px !important;
        background: #ffffff;
        border: 1px solid #0000A1;
        box-sizing: border-box;
        border-radius: 4px;

        &.selected {
            background: #dae3ec;
            border: 1px solid #460073;
        }
    }

    .cvs-confirm-schedule-content {
        .cvs-csc-voyage-card {
            margin-bottom: 1rem;
        }

        .cvs-csc-voyage-card-header {
            padding: 12px 24px;
            border-radius: 4px 4px 0 0;

            &.material-header {
                background-color: #fbe165;
            }

            &.marine-header {
                background-color: #aed2f2;
            }

            .cvs-csc-voyage-card-header-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
            }
        }

        .cvs-csc-voyage-card-body {
            background: #fbfcff;
            margin-top: 8px;
            padding: 24px;
            border: 0;
            border-radius: 0;

            &.expanded,
            &:hover {
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            }

            .cvs-csc-vcb-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                color: #b455aa;
            }

            .cvs-csc-vcb-voyage-count {
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                color: #6b6c70;
            }

            .cvs-csc-vcb-content-row {
                margin-top: 24px;

                .table {
                    background-color: transparent;
                    margin-top: 24px;
                    border: 0;
                    border-collapse: collapse;
                    table-layout: fixed;
                }

                th {
                    background-color: transparent;
                    color: #6b6c70;
                }

                .cvs-csc-vcb-label {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 24px;
                }

                .cvs-csc-vcb-location-pill {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    color: #460073;
                    background: #f0f0fa;
                    border-radius: 8px;
                    padding: 5px 10px;
                    margin: 5px 10px 5px 0;
                }

                .csv-csc-vcb-location-arrow {
                    color: #460073;
                    margin: auto 10px auto 0;
                    font-size: 16px;
                }
            }
        }
    }
}