.advanced-search-popup {
  width: 560px;
  padding: 0 20px;
  text-align: left;

  .advanced-search-title {
    margin-top: 18px;
    padding-left: 0.5rem;
    margin-bottom: 2rem;
  }

  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  .medium-text-black {
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-large-text-black {
    font-size: 18px;
    line-height: 18px;
  }

  .medium-large-text-black-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .medium-text-purple {
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .medium-text-blue-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4a5496;
  }

  .btn-text-purple-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .button-group-action {
    text-align: right;
    .btn-action {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #0000A1;
      background-color: #ffffff;
      border: 1px solid #0000A1;
      padding: 12px 32px;
      margin: 1rem auto;
      &:hover {
        color: #0000A1;
        background: none;
      }

      &.btn-apply,
      &.btn-apply:hover {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border-radius: 4px;
        color: #ffffff;
        border: 1px solid transparent;

        background-color: #0000A1;
        margin-left: 10px;
      }
    }
  }

  .grid {
    margin: 5px 0;
    border-radius: 4px;
    .row {
      min-height: 75px;
    }

    .dropdown-checkbox-custom {
      text-align: left;
      // position: absolute;
      // z-index: 0;
      .filter-search {
        input {
          z-index: 0 !important;
        }
      }
      .checkbox-container {
      }
    }

    .vessel-management-period-picker {
      .ui.button {
        width: 100% !important;
        text-align: left;
        padding-bottom: 11px;
        padding-top: 11px;
        padding-left: 12px;
      }
    }

    .filter-dropdown {
      width: 100% !important;
      background-color: #ffffff !important;

      input,
      .text {
        font-size: 15px;
      }

      i {
        font-size: 25px;
        padding: 0.5rem 0;
        float: right;
      }
    }
  }
}
