.secondary-service-group {
  padding: 1rem 0;
  border-bottom: 1px rgb(110, 108, 108) solid;
  &:last-child {
    border-bottom: none;
  }
  .remove-btn{
    width: 4rem;
    margin-top: 1.5rem;
    color: #0000A1;
    cursor: pointer;
  }
}
