.new-ui-confirmed-reservation-container {
    border-radius: 16px;
    background: var(--shades-white, #FFF);
    padding-bottom: 32px;
    margin-bottom: 32px;

    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Heading/Heading 4/Medium */
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px; /* 130.769% */

            margin-top: 32px;
            margin-left: 22px;
        }

        .btn-view-all {
            color: var(--sinopia-orange-o-300-main, #D0460B);
            /* Paragraph/Paragraph 2/Medium */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 150% */

            margin-top: 32px;
            margin-right: 32px;
        }
    }

    .material-radio {
      color: var(--independence-grey-dark-n-800-main-text, #242433);
      /* Paragraph/Paragraph 2/Regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      // line-height: 24px; /* 150% */

      margin-top: 15px;
      margin-bottom: 32px;
      margin-right: 32px;
    }

    .passenger-radio {
      color: var(--independence-grey-dark-n-800-main-text, #242433);
      /* Paragraph/Paragraph 2/Regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      // line-height: 24px; /* 150% */

      margin-top: 15px;
      margin-bottom: 32px;
    }

    .ui.checkbox input[type=radio] {
      margin-top: 10px !important;
    }

    .custom-slider .slider-body {
      padding: 0 !important;
    }
  
    .ui.tabular.menu {
      border-bottom: none;
      margin-bottom: 34px;
      overflow: auto;
      overflow-y: hidden;
      border-radius: 4px;

      .item {
        line-height: 18px;
        border: none;
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);

        /* Heading/Heading 6/Medium */
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 133.333% */
      }
    }
  
    .ui.tabular.menu .active.item {
      border-bottom: 4px solid #242433;
      color: #000000;
    }
  
    .empty-tab {
      height: 400px;
      font-size: 18px;
      line-height: 24px;
      color: #202529;
  
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  