.mainexplorepage .group-boxes {
	z-index: 998;
	top: 120px;
	position: fixed;
	transition: left 0.3s;
	width: 200px;
	padding-top: 0;
	padding-left: 0px;
	align-items: center;
	height: 40px;

	display: flex;
	justify-content: start;

	.box-action {
		display: flex;
		width: 100%;
		height: 100%;

		.period-picker {
			.date-range-picker-custom {
				height: 100%;
				margin-right: 10px;
				min-width: 210px;
				i {
					margin-left: auto;
				}
			}
		}
		.action {
			background-color: $color-white;
			width: auto !important;
			height: 100%;
			margin-right: 10px;
			padding: 0 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;

			&.filter {
				border-left: 1px solid #EAEAEA;
				.icon {
					margin-right: 0;
				}
			}
			.icon {
				width: 20px;
				height: 20px;
				margin-right: 5px;
			}

			span {
				font-weight: $fontweight-600;
				font-size: $fontsize-14;
				color: $color-primary;
			}
		}
		.d-none{
			display: none!important;
		}
	}
}
.legend {
	width: 300px;
}