.add-comment {
    .avatar-comment {
      display: flex;
      margin-top: $rem-20;
    }
    .avatar {
      display: inline-block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      font-weight: 600;
      font-size: 24px;
      margin-right: $rem-16;
    }
    .comment-text {
      font-family: Inter;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #242433;
    }
    .text-area {
      width: 100%;
      border-radius: 8px;
      font-size: 14pxpx;
      font-weight: 400;
      padding: 15px;
      resize: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .mobile-responsive-commnet-submission {
      width: 100%;
      display: flex;
      justify-content: right;
      .custom-cancle-button {
        padding: 10px 32px 10px 32px;
        border-radius: 8px;
        border: 1px solid #0000A1;
        gap: 8px;
        color: #0000A1;
        background-color: #ffffff;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 600
      }
      .custom-submit-button {
        padding: 10px 32px 10px 32px;
        border-radius: 8px;
        gap: 8px;
        color: #FFFFFF;
        background-color: #0000A1;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .submission {
      .submission-title {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #242433;
        margin-bottom: 10px;
      }
      .submission-user {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #242433;
      }
      .submission-time {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #5C5C7A;
      }
    }
}