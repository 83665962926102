.app {
  font-family: $font-normal;
  height: 100%;

  .main-container {
    padding: 24px 36px;

    & > .section {
      margin-bottom: 24px;

      &.with-relative{
        position: relative;
      }
    }
  }
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

//TODO: still need?

.summary-header {
  display: flex;
  flex-direction: row;
  padding-top: 17px;
  padding-bottom: 17px;
  height: 75px;
  width: 100%;
}
.Graph {
  height: 100%;
  position: relative;
}
