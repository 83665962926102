$people-service-color: #c4dfd7;
$material-service-color: #fbe165;
$marine-service-color: #aed2f2;
$supply-service-color: #f0aef2;
$other-service-color: #f2aeae;

.vessel-management-page-new {
    padding: 128px 165px 20px;

    .breadcrum-page {
        .section {
            color: #0000A1;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;

            &.active {
                color: #6b6c70;

                &:hover {
                    color: #6b6c70 !important;
                }
            }

            &:hover {
                color: #0000A1 !important;
            }
        }
    }

    .search-filter-container {
        display: flex;
        align-items: center;
        border: none !important;

        .action {
            padding: 5.5px 15px;
            border: 1px solid #ccc;
            border-left: none;
            border-radius: 0 4px 4px 0;
            cursor: pointer;

            &.isFiltering {
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    top: 3px;
                    right: 8px;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    background: #0000A1;
                }
            }
        }
    }

    .left-content {
        flex: 1;

        .page-title {
            margin-top: 15px;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 44px;
            color: #202529;

            .vessel-name {
                color: #1f5886 !important;
            }
        }

        .mmsi-id {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */
            /* Neutrals/Dark/ Almost Black */
            color: #202529;
        }
    }

    .right-content {
        display: flex;
        margin: auto;
        align-items: center;
        gap: 10px;

        .label {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        .ui.checkbox.toggle input:checked ~ label:before {
            background: #5cb85c !important;
        }
    }

    .medium-small-text-black-bold {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
    }

    .medium-text-black {
        font-weight: normal;
        font-size: 16px;
        line-height: 16px;
    }

    .medium-text-black-bold {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
    }

    .medium-large-text-black {
        font-size: 18px;
        line-height: 18px;
    }

    .medium-large-text-black-bold {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
    }

    .medium-text-purple {
        font-size: 16px;
        line-height: 24px;
        color: #0000A1;
        cursor: pointer;
    }

    .medium-text-blue-bold {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #4a5496;
    }

    .btn-text-purple-bold {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0000A1;
        cursor: pointer;
    }

    .btn-action {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0000A1;
        background-color: #ffffff;
        border: 1px solid #0000A1;
        margin: 0 !important;
    }

    .btn-action:hover,
    .btn-action:hover svg path {
        color: #ffffff;
        background-color: #0000A1;
        stroke: #ffffff;
    }

    .btn-action-blue,
    .btn-action-blue:focus,
    .btn-action-blue:hover {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        background-color: #0000A1;
        border: 1px solid #0000A1;
        margin-left: 20px;
    }

    .btn-back {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0000A1;
        border: none;
        width: 250px;
        border-radius: 4px;
        background-color: #ffffff;
        width: auto;
        padding: 7px 1.5rem !important;
    }

    .btn-back:hover {
        color: #ffffff;
        background-color: #0000A1;
    }

    table tr td {
        i {
            color: #0000A1;
            cursor: pointer;
        }

        .subcontent-cell {
            margin-bottom: 16px;
        }
    }

    .page-title {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;

        &-child {
            color: #4b5596;
        }
    }

    .filter-content {
        justify-content: space-between;
        align-items: center;

        .btn-action {
            height: 45px;
            margin: 1rem auto;
        }

        .grid {
            margin: 1rem 0;
            padding: 2rem;
            background: #f5f7fe;
            border-radius: 4px;

            &.control-log-grid,
            &.maintenance-log-grid {
                width: 80%;

                .vessel-management-period-picker {
                    button {
                        width: 100%;
                        text-align: left;
                        padding-bottom: 11px;
                        padding-top: 11px;
                    }
                }
            }

            .filter-dropdown {
                width: 100% !important;
                background-color: #ffffff !important;

                input,
                .text {
                    font-size: 15px;
                }

                i {
                    font-size: 25px;
                    padding: 0.5rem 0;
                    float: right;
                }
            }

            .checked-category-container {
                flex-wrap: wrap;
                max-height: 100px;
                overflow-y: auto;

                .checked-category {
                    background: #f0f0fa;
                    border-radius: 20px;

                    .category-text {
                        font-weight: 600;
                        font-size: 14px;
                        color: #eaeaea;
                        margin: 5px;
                    }

                    div,
                    i {
                        margin: auto;
                    }
                }
            }
        }
    }

    .vessel-table-container,
    .vessel-details-page {
        .current-contract-detail div {
            margin: auto 0;
        }

        .ui.secondary.pointing.menu {
            border-bottom: none;

            .item {
                color: #bab3be;
                font-size: 18px;
                font-weight: 600;
                line-height: 18px;
            }
        }

        .ui.secondary.pointing.menu .active.item {
            border-bottom: 2px solid #0000A1;
            color: #000000;
        }

        table {
            border: none;
            border-collapse: collapse;

            .sub-header {
                th:nth-child(1) {
                    border-left: 1px solid #eaeaea !important;
                }

                th:nth-child(5),
                th:last-child {
                    border-right: 1px solid #eaeaea !important;
                }
                // border-left: 1px solid rgba(34, 36, 38, 0.15) !important;
            }

            thead th {
                background-color: #f5f7fe !important;
                border: none !important;
                vertical-align: bottom;

                &.sorted {
                    min-width: 110px;
                    padding-right: 25px;
                }

                &.dark-background {
                    background-color: #dae3ec !important;
                    border-left: 1px solid #eaeaea !important;
                    border-right: 1px solid #eaeaea !important;
                }
            }

            tbody {
                tr:nth-child(2n) {
                    background-color: #ffffff !important;
                }

                tr:nth-child(4n-1) {
                    background-color: #fbfcff;
                }

                tr:nth-child(4n) {
                    background-color: #fbfcff;
                }

                tr .maintenance-day {
                    font-weight: 700;
                    font-size: 14px;

                    &.red {
                        color: #ef5d60;
                    }

                    &.yellow {
                        color: #f9b262;
                    }

                    &.green {
                        color: #2bcf9e;
                    }
                }

                td {
                    border: none;
                }

                .row {
                    height: 60px;

                    &.expand {
                        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    }

                    td {
                        padding-top: 22px;
                    }
                }

                .row-edit {
                    display: contents;

                    &.expand {
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }

                    td:nth-child(2) {
                        border: 1px solid #eaeaea;
                        border-bottom: none;
                        border-top: none;
                    }

                    td:nth-child(3) {
                        border-right: 1px solid #eaeaea;
                        vertical-align: bottom;
                    }

                    .navigate-action {
                        padding-bottom: 30px;

                        span {
                            margin: 0 10px;
                            color: $color-primary;
                            cursor: pointer;
                            font-weight: bold;
                        }
                    }

                    .content {
                        .sub-table-content {
                            padding: 1rem;

                            table tr {
                                th,
                                td {
                                    border: none;
                                    font-size: 14px;
                                    background-color: #ffffff !important;
                                }

                                th {
                                    font-weight: 600;
                                    line-height: 16px;
                                }

                                td {
                                    line-height: 24px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .edit-contract-container {
        .status-tag {
            margin-top: 12px;
            font-weight: 600;
            font-size: 14px;

            &.expired {
                background: #f0a2a2;
                color: red;
            }

            &.ongoing {
                background: #fff3cd;
                color: #90682f;
            }
        }

        table {
            border: none;
            border-collapse: collapse;

            thead th {
                border: none;
                background-color: #f0f0fa !important;
            }

            tbody {
                tr:nth-child(odd) {
                    background-color: #ffffff !important;
                }

                tr:nth-child(even) {
                    background-color: #fbfcff !important;
                }

                tr td {
                    border: none;

                    i {
                        margin: auto 0.5rem;
                    }
                }
            }
        }

        .radio-option-content {
            .radio {
                margin-right: 3rem;

                label {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    color: #202529;
                }

                input[type="radio"]:checked ~ label:before {
                    border-color: #0000a1;
                }

                input[type="radio"]:checked ~ label:after {
                    background-color: #0000a1 !important;
                }
            }
        }

        .contract-info {
            background-color: #f5f7fe;
            padding: 24px 40px 32px;
            border-radius: 4px 4px 0px 0px;
            justify-content: space-between;
            min-height: 134px;

            &.material-service {
                box-shadow: 0px 4px 0px $material-service-color;
            }

            &.people-service {
                box-shadow: 0px 4px 0px $people-service-color;
            }

            &.marine-service {
                box-shadow: 0px 4px 0px $marine-service-color;
            }

            &.supply-service {
                box-shadow: 0px 4px 0px $supply-service-color;
            }

            &.other-service {
                box-shadow: 0px 4px 0px $other-service-color;
            }

            .contract-info_label {
                margin-bottom: 4px;
                line-height: 21px;
            }

            .contract-info_value {
                line-height: 24px;
            }

            .service-name {
                max-width: 25%;

                .contract-info_value {
                    display: flex;
                    gap: 5px;

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .btn-secondary-service {
            text-align: right;
            margin: 0.7rem 0;
        }

        .large-text-bold {
            font-size: 24px;
            font-weight: 600;
        }

        .event-icon {
            path {
                transition: all 0.2s;
            }

            &:hover path {
                stroke: #0000a1;
            }

            cursor: pointer;
        }
    }

    .edit-contract-container {
        .control-group {
            display: flex;
            align-items: center;
            justify-content: end;

            .control-group_options {
                display: flex;
                gap: 20px;
                margin-right: 64px;

                .radio {
                    margin-right: 0 !important;
                }
            }
        }

        .add-content {
            border: 1px solid #0000A1;
            box-sizing: border-box;
            border-radius: 4px;
        }

        .vessel-management-period-picker,
        .vessel-management-dropdown {
            display: flex;
            justify-content: space-between;
            width: 100% !important;

            button {
                width: 100% !important;

                > div {
                    justify-content: space-between;
                }
            }

            input,
            .text {
                font-size: 14px;
                line-height: 24px;
                color: #202529 !important;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            i {
                font-size: 25px;
                padding: 0.3rem 0;
                float: right;
            }
        }

        .vessel-management-period-picker input,
        .vessel-management-period-picker button,
        .vessel-management-dropdown {
            border: 1px solid #202529 !important;
        }

        .vessel-management-period-picker button {
            font-weight: normal;
            padding: 0 1rem;
            height: 43px;
            width: 100%;

            i {
                margin-right: unset;
            }
        }

        .vessel-management-input {
            border: 1px solid #202529;
            border-radius: 4px;
        }
    }

    .checked-category-container {
        flex-wrap: wrap;
        max-width: 80%;
        align-items: start;

        .button-clear-filter {
            width: 130px;
        }

        .checked-category-icons {
            display: flex !important;
            flex-wrap: wrap;
            flex: 1;

            .checked-category {
                background: #f0f0fa;
                border-radius: 4px;
                margin: 0 5px;

                .category-text {
                    font-weight: 600;
                    font-size: 14px;
                    color: #0000A1;
                    margin: 5px;
                }

                i {
                    margin: auto;
                    cursor: pointer;
                    padding-left: 5px;
                }
            }
        }
    }
}

.row-table-new {
  &:hover .vessel-detail-icon {
    color: #0000A1 !important;
  }
  td:nth-child(4) {
    border-left: 1px solid #eaeaea !important;
  }
  td:nth-child(8),
  td:nth-child(11) {
    border-right: 1px solid #eaeaea !important;
  }
}

.modal-vessel-management-new {
  .left-content {
    flex: 1;
  }

  .right-content {
    margin: auto;
  }

  .grid {
    margin: auto;
    width: 100%;
  }

  .large-text-bold {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  .medium-small-text-black-bold {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0.5rem;
    text-align: left;
  }

  .medium-small-text-black {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 0.5rem;
  }
  .medium-text-black {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-large-text-black-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .medium-text-blue-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4a5496;
  }
  .vessel-management-dropdown {
    &.show-invalid {
      border: 3px solid red !important;
    }
  }
  .vessel-management-period-picker button,
  .vessel-management-dropdown {
    height: 40px;
    margin-bottom: 10px;
  }
  .vessel-management-period-picker,
  .vessel-management-dropdown {
    &.show-invalid {
      &.dropdown {
        padding: 6.5px !important;
      }
      button {
        border: 3px solid red !important;
      }
    }
    &.dropdown {
      width: 100% !important;
    }

    button {
      width: 100% !important;
      > div {
        justify-content: space-between;
      }
    }
    input,
    .text {
      font-size: 14px;
      line-height: 24px;
      color: #202529 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      font-size: 25px;
      padding: 0.3rem 0;
      float: right;
    }
  }

  .vessel-management-period-picker input,
  .vessel-management-period-picker button,
  .vessel-management-dropdown {
    border: 1px solid #202529 !important;
  }

  .vessel-management-period-picker button {
    font-weight: normal;
    padding: 0 1rem;
    i {
      margin-right: unset;
    }
  }

  .vessel-management-input {
    border: 1px solid #202529;
    border-radius: 4px !important;
    &.show-invalid {
      input {
        border: 3px solid red !important;
      }
    }
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    background-color: #ffffff;
    border: 1px solid #0000A1;
  }

  .btn-action-new {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1 !important;
    background-color: #ffffff !important;
    padding-left: 0 !important;
  }

  .btn-action:hover,
  .btn-action:hover svg path {
    color: #ffffff;
    background-color: #0000A1;
    stroke: #ffffff;
  }

  .delete-icon {
    display: flex;
    height: 100%;
    i {
      color: #0000A1;
      cursor: pointer;
      align-self: flex-end;
      margin-bottom: 1.5rem;
    }
  }
}

//====================  MODAL CONTRACT ========================
.modal-vessel-management-new {
  &.modal-contract {
    .title-modal {
      margin: -2rem;
      padding: 2rem;
      background: #f5f7fe;
    }
    .title-service {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #4a5496;
      margin-bottom: 1.3rem;
      padding-top: 0.5rem;
    }
    .grid {
      max-height: unset;
      overflow: unset;
      &.primary-grid {
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        margin-bottom: 3rem;
        padding-right: 5rem;
      }
    }
    .title-modal {
      color: #202529;
      .vessel-name {
        color: #1f5886 !important;
      }
    }
    .comment-column {
      flex: 1;
      textarea {
        padding: 12px 14px;
        width: 100%;
        max-width: 100%;
      }
    }
    .vessel-management-dropdown {
      display: flex;
      justify-content: space-between;
      background-color: #ffffff !important;
    }
    .secondary-service {
      .secondary-service-list {
        padding: 16px 8px !important;
        background: #f5f7fe;
        border-radius: 4px 0px 0px 4px;
      }
      .add-secondary {
        background: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #0000A1;
        padding: 0.75rem 0;
      }
    }
  }
}

.modal-contract-detail {
  .content {
    padding: 48px !important;
    .contract-id {
      margin-top: 40px;
      margin-bottom: 28px;
      p {
        font-weight: 600;
        margin-top: 8px;
      }
    }
  }
  .modal-title {
    font-size: 24px !important;
    font-weight: 600;
    gap: 16px;
    margin-bottom: 40px;
    span {
      color: #4a5496;
    }
  }
  .modal-actions {
    margin-top: 56px;
    .button-demand {
      padding: 8px 24px !important;
      height: 40px;
    }
  }
  .modal-children {
    .date-range-picker-custom {
      width: 100%;
      font-weight: 400;
      padding-left: 12px;
      & div.flex {
        justify-content: space-between !important;
      }
    }
    .vessel-management-dropdown {
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
  .grid-contract-detail,
  .modal-add-secondary-service {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    row-gap: 27px;
    .ui.selection.dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .comment {
    margin-top: 24px;
    input {
      border-radius: 5px !important;
    }
  }
}
//====================  END MODAL CONTRACT ========================

//==================== MODAL MAINTENANCE ==========================
.modal-vessel-management-new.modal-maintenance {
  .modal-header {
    background-color: #f5f7fe;
    padding-left: 30px !important;
  }
  .modal-maintenance-item {
    &:nth-child(even) {
      background-color: #f5f7fe;
      padding: 14px 0 8px 0;
      margin-bottom: 10px;
    }
  }
}

.modal-warning {
  .modal-title-medium {
    font-size: 24px;
    font-weight: 600px;
  }
  .modal-warning-content {
    padding: 48px !important;
    .button-demand {
      height: 40px;
      line-height: 40px;
      padding: 8px 24px !important;
    }
  }
  .warning-message {
    p {
      font-size: 16px;
      font-weight: 400;

      b {
        font-weight: 600;
      }

      &:first-child {
        margin-top: 40px;
      }

      &:last-child {
        margin-top: 30px;
      }
    }
  }
}

.filter-content-new {
  width: 560px;
  height: 350px;
  padding: 0 20px;
  text-align: left;

  .advanced-search-title {
    margin-top: 18px;
  }

  .button-group-action {
    text-align: right;
  }

  .btn-action {
    height: 45px;
    margin: 1rem auto;
  }

  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  .medium-text-black {
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-large-text-black {
    font-size: 18px;
    line-height: 18px;
  }

  .medium-large-text-black-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .medium-text-purple {
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .medium-text-blue-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4a5496;
  }

  .btn-text-purple-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    background-color: #ffffff;
    border: 1px solid #0000A1;
  }

  .btn-action:hover,
  .btn-action:hover svg path {
    color: #ffffff;
    background-color: #0000A1;
    stroke: #ffffff;
  }

  .btn-back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    border: none;
    width: 250px;
    border-radius: 4px;
    background-color: #ffffff;
    width: auto;
    padding: 7px 1.5rem !important;
  }

  .btn-back:hover {
    color: #ffffff;
    background-color: #0000A1;
  }

  .grid {
    margin: 5px 0;
    border-radius: 4px;
    height: 100%;
    .row {
      height: 20%;
    }

    .dropdown-checkbox-custom {
      text-align: left;
      // position: absolute;
      // z-index: 0;
      .filter-search {
        input {
          z-index: 0 !important;
        }
      }
      .checkbox-container {
      }
    }

    .vessel-management-period-picker {
      .ui.button {
        width: 100% !important;
        text-align: left;
        padding-bottom: 11px;
        padding-top: 11px;
        padding-left: 12px;
      }
    }

    .filter-dropdown {
      width: 100% !important;
      background-color: #ffffff !important;

      input,
      .text {
        font-size: 15px;
      }

      i {
        font-size: 25px;
        padding: 0.5rem 0;
        float: right;
      }
    }
  }
}

.ui.sortable.table {
  thead th {
    position: relative;
    background: #dae3ec;
    &.ascending:after {
      content: "";
      position: absolute;
      background-image: URL('/src/assets/images/arrow-up.svg');
      width: 16px;
      height: 14px;
      transform: translateY(-1px);
    }
    &.descending:after {
      content: "";
      position: absolute;  
      background-image: URL('/src/assets/images/arrow-down.svg');
      width: 16px;
      height: 14px;
      transform: translateY(-1px);
    }
  }
  &#secondary-service-table {
    th {
      border: none !important;
    }
  }
}
