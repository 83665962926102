.vod-vessel-list-card-container {
  border-radius: 8px;
  box-sizing: border-box;
  border-left: 8px solid #fbe86a;

  .icon-status {
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    margin: auto 1rem;

    &.yellow {
      svg path {
        fill: #9e7700;
      }

      span {
        color: #9e7700;
      }
    }

    &.red {
      span {
        color: #c93626;
      }
    }

    span {
      margin: auto;
    }
  }

  .card-top:hover {
    background: #fff3b3;
  }

  .card-top {
    background: #eaeaea;
    border-radius: 0px 8px 0px 0px;
    position: relative;
    align-items: center;

    .tag-group {
      font-weight: 600;
    }

    .card-label {
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      padding: 12px 16px;
      color: #0000a1;

      .vessel-name {
        border-right: 1px solid #adadc2;
      }

      .bold-text {
        margin: auto;
        font-weight: 600;
      }

      svg {
        height: 24px;
        width: 24px;
      }
    }

    .div-toggle {
      margin: auto 2rem;

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #4b7093;
        margin: auto 1rem;
      }

      .ui.toggle.checkbox label:after {
        background-color: #f9f9fb !important;
        border: 1px solid #d9d9e3 !important;
      }

      .ui.toggle.checkbox label:before {
        background-color: #cacad8 !important;
        border: none !important;
      }

      .ui.checked.toggle.checkbox label:before {
        background-color: #0000a1 !important;
        border: none !important;
      }
    }
  }
  .card-bottom {
    padding-bottom: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 8px 0px;

    .label-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #5c5c7a;
    }

    .label-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #242433;
    }

    .delete-trash:hover {
      stroke: #242433;
    }

    .route-content {
      flex-wrap: wrap;
      .location {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #0000a1;
        background: #e6e6f6;
        border-radius: 10px;
        padding: 8px;
        margin: 5px 10px 5px 0;
        white-space: nowrap;
      }

      .arrow-location {
        // color: #5c5c7a;
        // margin: auto 0;
        // margin-right: 10px;
        // font-size: 16px;
        margin: auto 0;
        margin-right: 10px;
      }
    }

    .arrow-double-icon {
      &.up {
        transform: rotate(180deg);
      }
    }

    .grid {
      flex: 1;
      margin: 1rem;
      padding: 1rem;

      &.details {
        background: #f9f9fb;
        border-radius: 8px;
      }

      .four.column.row .column:not(:last-child) {
        margin-right: 3rem;
      }
    }

    .table-schedule,
    .table-reservation {
      thead tr:first-child th {
        background-color: #dae3ec;
        border-right: 1px solid #ffffff;
      }

      thead tr th {
        background-color: #ebf0f5;
        border: 1px solid #ffffff;
      }

      tbody tr.seller {
        background-color: #f0f0f4;
      }
    }
  }
}
