
  .ml-1 {
    margin-left: 1rem !important;
  }

  .legend-item {
    display: flex !important;
    align-items: center;

    & img {
      width: 20px;
    }
  }
  .checkbox-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 9px;
    height: 275px;
    padding: 0 28px 0 25px;
    margin: 0 -25px;
    & > div {
      display: contents !important;
    }
    &::-webkit-scrollbar {
      position: relative;
      transform: translateX(30px);
      width: 4px !important;
      background-color: #ffffff;
    }
    &-webkit-scrollbar-thumb {
      background-color: #ffffff;
    }
    &-webkit-scrollbar-track {
      background-color: #807f7f;
    }
  }
  .ui {

    &.show-all.checkbox input~label::before {
      left: 85%;
      top: $rem-2;
      width: $rem-16;
      height: $rem-16;
    }

    &.show-all.checkbox input:checked~label::after {
      left: 84%;
      color: $color-primary;
    }


    &.show-all.checkbox label {
      font-size: $fontsize-12 !important;
      margin-left: 8rem;
    }
  }


@media (max-width: 768px) {
  .checkbox-list {
    height: auto;
    gap: 8px;
  }

  .ui {
    &.show-all.checkbox label {
      margin-left: 16rem;
      color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
      /* Paragraph/Paragraph 2/Regular */
      font-family: Inter;
      font-size: 16px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    &.show-all.checkbox input~label::before {
      top: 18%;
      width: 18px;
      height: 18px;
    }

    &.show-all.checkbox input:checked~label::after {
      top: 5%;
    }
  }

  .legend-item {
    & span {
      font-size: 14px;
      font-weight: 400;
    }

    & img {
      width: 24px;
      height: 36px;
    }
  }
}