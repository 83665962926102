.vod-voyage-completion-page {
  padding: 165px 100px 100px 100px;

  .header-text {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #242433;
  }

  .sub-header-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #242433;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: #ffffff;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-action:hover {
    color: #ffffff;
    background: #0000a1;
  }

  .btn-text {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #0000a1;
  }

  .btn-action-primary,
  .btn-action-primary:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background: #0000a1;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .custom-table {
    .title-table {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #5c5c7a;
    }

    table {
      thead tr:first-child th {
        background-color: #dae3ec;
        border-right: 1px solid #ffffff;
      }

      thead tr th {
        background-color: #ebf0f5;
        border: 1px solid #ffffff;
      }

      tbody tr.seller {
        background-color: #f0f0f4;
      }

      tbody tr td {
        input {
          background: #ffffff;
          border: 1px solid #e1e1e1;
          border-radius: 8px;
        }

        .icon-trash {
          cursor: pointer;
        }

        .icon-trash:hover svg path {
          fill: #0000a1;
        }
      }
    }
  }
}
