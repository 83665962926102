.vod-booking-card-container {
  border-radius: 8px;
  box-sizing: border-box;
  border-left: 8px solid #fbe86a;

  .icon-status {
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    margin: auto 1rem;

    &.yellow {
      svg path {
        fill: #9e7700;
      }

      span {
        color: #9e7700;
      }
    }

    &.green {
      svg path {
        fill: #1c7d55;
      }

      span {
        color: #1c7d55;
      }
    }

    &.red {
      svg path {
        fill: #c93626;
      }

      span {
        color: #c93626;
      }
    }

    &.grey {
      svg path {
        fill: #4b7093;
      }

      span {
        color: #4b7093;
      }
    }

    span {
      margin: auto;
    }
  }

  .card-top {
    background: #eaeaea;
    border-radius: 0px 8px 0px 0px;
    position: relative;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;

    .special-status {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1c7d55;
      background: #d5f6e8;
      border-radius: 10px;
      padding: 0.5rem;
    }

    .card-label {
      font-size: 16px;
      line-height: 16px;
      align-items: center;
      color: #0000a1;

      .voyage-id {
        border-left: 1px solid #adadc2;
      }

      .bold-text {
        margin: auto;
        font-weight: 600;
      }

      svg {
        height: 24px;
        width: 24px;
        transform: rotate(180deg);
      }
    }
  }

  .card-top:hover {
    background: #fff3b3;
  }
  .card-bottom {
    padding: 1rem 1.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 8px 0px;

    .label-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #5c5c7a;
    }

    .label-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #242433;
    }

    .estimated-price {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: #242433;
    }

    .route-content {
      margin: auto 0;
      flex-wrap: wrap;
      .location {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        height: 30px;
        background: #ebf0f5;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        color: #3b5773;
      }

      .arrow-location {
        margin: auto 0;
        margin-right: 10px;
      }
    }

    .arrow-double-icon {
      &.up {
        transform: rotate(180deg);
      }
    }

    .table-schedule {
      thead tr:first-child th {
        background-color: #dae3ec;
        border-right: 1px solid #ffffff;
      }

      thead tr th {
        background-color: #ebf0f5;
        border: 1px solid #ffffff;
      }
    }
  }
}
