$max-width: 720px;

.modal-dialog {
	max-width: $max-width;
	.modal-header {
		padding: $rem-20;
		h5 {
			font-size: $rem-20;
		}
	}
}