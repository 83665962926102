.breadcrumb {
    display: flex;

    margin-left: 33px;
    margin-top: 13px;

    .item {
        display: flex;
        align-items: center;
        .value {
            color: var(--shades-independence-grey-dark-n-300-form-labels, #5C5C7A);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

            &.current {
                color: var(--duke-blue-p-300-main, #0000A1);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; 
            }

            &.clickable {
                cursor: pointer;
            }
        }

        .arrow-right-icon {
            margin-top: 4px;
            margin-left: 5px;
            margin-right: 5px;
            color: #5c5c7a;
        }
    }
}