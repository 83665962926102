.new-iap-approval-page {
  padding: 165px 152px 84px;

  .title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.03em;
    color: #202529;
    margin-bottom: 40px;
  }

  .reservation-card-container {
    border-radius: 8px;
    box-sizing: border-box;
    cursor: pointer;

    &.Material {
      border-left: 8px solid #fbe86a;
    }

    &.Passenger {
      border-left: 8px solid #c0e2e8;
    }
    .reservation-card-top {
      background: #f0f0f4;
      border-radius: 0px 8px 0px 0px;
      position: relative;
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .reservation-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #242433;
        align-items: center;

        .text-bold {
          font-weight: 600;
          font-size: 16px;
        }

        .line {
          color: #9e9eb7;
          font-size: 24px;
          height: 24px;
        }
      }

      .reservation-requestor {
        margin-top: 10px;
        font-size: 12px;
        color: #5c5c7a;
        display: flex;
        align-items: center;
        gap: 3px;
        .line {
          color: #5c5c7a;
          font-size: 18px;
          height: 18px;
          font-weight: lighter;
        }
      }

      .reservation-note {
        font-weight: 600;
        font-size: 14px;

        .special-request {
          background: #ffffff;
          color: #4b7093;
          padding: 4px 12px;
          border: 1px solid #dae3ec;
          border-radius: 10px;
          line-height: 22px;
          margin-left: 16px;
        }
      }

      .expired {
        font-weight: 600;
        color: #c93626;
      }
    }
    .reservation-card-bottom {
      padding: 24px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 8px 0px;
      .voyage-label {
        color: #0000a1;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
      }

      .reservation-details-container {
        width: 100%;
        align-items: center;
        margin-top: -2px;

        .reservation-location-section {
          width: 65%;

          .start-location-container {
            width: 50%;
          }

          .end-location-container {
            margin-left: 40px;
            margin-right: 30px;
            width: 50%;
          }
        }

        .reservation-details-container {
          width: 65%;
        }

        .payload-capacity {
          font-size: 14px;
          color: #5c5c7a;
          margin-bottom: 8px;
        }

        .reservation-details-section {
          width: 100%;
          background-color: #f9f9fb;
          padding: 16px 40px;

          .payload-details-container {
            width: 100%;

            .payload-content-container {
              align-items: center;
            }

            .payload-details {
              font-size: 14px;
              line-height: 16px;
              font-weight: 500;
              color: #202529;
            }

            .text-blue {
              color: #4b7093;
            }
          }
        }

        .property-label {
          margin-left: 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        }

        .content-sub-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;

          &.active {
            color: #ffffff;
          }

          .time-content {
            color: #686887;
          }
        }

        .top-card-label {
          font-size: 14px;
          line-height: 12px;
          color: #5c5c7a;
        }

        .upper-detail {
          font-weight: 600;
          font-size: 14px;
          color: #242433;
        }

        .under-detail {
          font-weight: 600;
          font-size: 14px;
          color: #242433;
        }

        .content-date-sub-title {
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          &.active {
            color: #ffffff;
          }
        }

        .content-label {
          color: #96968c;
          font-size: 12px;
          font-weight: 600;
          line-height: 12px;

          &.active {
            color: #ffffff;
          }
        }

        .arrow::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #bab3be;
          position: absolute;
          right: -10px;
          top: -4px;
        }

        .arrow.active::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #fff;
          position: absolute;
          right: -10px;
          top: -4px;
        }
      }

      .reservation-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 2rem;

        .reservation-status-container {
          align-items: center;

          &.delete {
            svg path {
              fill: #9f2b1e;
            }
          }
          &.ready {
            svg path {
              fill: #2bcf9e;
            }
          }
          &.warning {
            svg path {
              fill: #c79500;
            }
          }

          .reservation-status {
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;

            &.delete {
              color: #9f2b1e;
            }

            &.ready {
              color: #4b7093;
            }

            &.warning {
              color: #c79500;
            }

            &.confirmed {
              color: #1c7d55;
            }
          }
        }

        .reservation-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .reservation-saving {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #242433;
            padding: 0;
            margin-right: 3rem;
            .tag-icon {
              margin-right: 8px;
            }
          }

          .ui.button {
            height: 48px;
            padding: 12px 32px;
            margin-right: 1.5rem;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            .ui.image {
              display: inline;
            }
          }

          .approve-button {
            background: #0000a1;
            border-radius: 8px;
            color: #fff;
            border: 1px solid #0000a1;
          }

          .download-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .ui.secondary.pointing.menu {
    margin-bottom: 34px;
    border-bottom: 4px solid #f0f0f4;
    .item {
      color: #bab3be;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 4px solid #242433;
    color: #000000;
    margin-bottom: -4px;
  }

  .empty-tab {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #202529;
  }
}
