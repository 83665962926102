.dropdown-list-item {
    display: flow-root;
    padding: 5px !important;
    .float-left {
        float: left;
    }
    .opacity_45{
        opacity: 0.45;
    }
    .opacity_1 {
        opacity: 1;
    }
    .float-right {
        float: right;
        .border-none {
            border: none;
        }
        .button {
            .btn-primary {
                padding: 0;
                text-transform: capitalize;
                border: none;
                &:hover {
                    background: #e0e1e2;
                    color: #0000A1;
                    border: none;
                    transform: scale(1.1);
                }
            }
        }
    }
}