// this file is where you add all miscellaneous classes eg. traffic light classes.

hr {
  background-color: $color-primary-light;
  border: 0.3px solid $color-primary-light;
  opacity: 0.7;
}

.chevron-arrow-right {
  display: inline-block;
  border-right: 2px solid $color-primary;
  border-bottom: 2px solid $color-primary;
  width: 8px;
  height: 8px;
  transform: rotate(-45deg);
  margin-left: 2px;
}

.padding-left-20 {
  @include padding-left(20);
}
.padding-bottom-20 {
  @include padding-bottom(20);
}

//#region Special made for scrollbar inside our beloved textarea. only in chrome! :(
.styled-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}
.styled-scrollbar::-webkit-scrollbar {
  width: 5px;
}
.styled-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-primary;
}
//#endregion