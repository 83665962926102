.modal-edit-voyage-optimise{
    padding: 73px 96px;
    width: 1054px !important;
    border-radius: 10px !important;
    overflow-y: auto;
    max-height: 80vh;

    .modal-optimise-title{
        font-size: 36px;
        font-weight: 700;
    }

    .modal-optimise-warning{
        color: #09010E;
        font-size: 16px;
        margin-top: 16px;
    }

    .modal-optimise-label{
        font-size: 18px;
        font-weight: 700;
        margin-top: 24px;
    }

    .material-group{
        display: flex;
        gap: 16px;
        width: 100% !important;
        .priority-location-material{
            width: 47%;
        }
        .priority-location-label, .priority-location-input {
            display: flex !important;
            gap: 16px;
            align-items: center;
        } 
        .berthing-time{
            width: 40%;
        }
    }

    .priority-location-group {
        .selection {
            height: 46px;
            min-width: 0 !important;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: #5C5C7A;
            justify-content: space-between;
            padding-left: 16px;
            border-radius: 8px;
        }
        
        .period-picker {
            input {
                height: 43.43px;
                border-radius: 8px;
                border: 1px solid rgba(34,36,38,.15);
                cursor: pointer;
            }
        }

        .priority-location-item {
            margin-top: 24px;
            margin-bottom: 19px;
            .priority-location-label, .priority-location-input {
                display: grid;
                grid-template-columns:  47% 0.5% 16.5%;
                gap: 16px;
                align-items: center;
            }
            .priority-location-label{
                font-size: 14px;
                font-weight: 700;
            }
            .priority-location-input{
                margin-top: 8px;
                position: relative;
                .selection {
                    width: 100%;
                    height: 40px !important;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 16px;
                    padding-right: 16px;
                    color: #202529;
                }
                .delete-button{
                    svg{
                        cursor: pointer;
                        path{
                            stroke: #0000A1;
                        }
                    }
                }
                
                .disable-delete{
                    svg{
                        cursor: pointer;
                        path{
                            stroke: gray;
                        }
                    }
                }
            }
        }
    }

    .modal-optimise-add-more{
        display: flex;
        align-items: center;
        width: auto;
        background: none;
        border: none;
        padding: 0;
        gap: 8px; 
        margin-top: 43px;
        margin-bottom: 24px;
        cursor: pointer;
        .add-more-icon{
            background-color: #FFD9D9;
            height: 24px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                height: 16px;
                width: 16px;
                stroke: #0000A1;
            }
        }
        .info-icon {
            height: 14px;
            transform: scale(1.2);
            margin-left: 15px;
        }  
        span{
            font-size: 16px;
            font-weight: 700;
            color: #0000A1;
        }
    }

    .modal-optimise-footer{
        margin-top: 32px;
        .btn{
            height: 48px;
            font-size: 16px;
            font-weight: 700px;
        }
        .btn-confirm{
            background-color: #0000A1;
            color: #FFF;
        }
        .btn-cancel{
            background-color: #FFF;
            color: #0000A1;
            border: 1px solid #0000A1;
            margin-left: 16px;

            &:hover {
                background-color: #0000A1;
                color: #FFF;
            }
        }
    }
}

.add-more-info {
    left: -10px !important;
    .vessel-tooltip {
        width: 500px;
    }
}