.confirm-demand-page {
  padding: 0 160px;
  padding-top: 170px;
  background-color: #F9F9FB;
}

  .button-demand {
    font-family: $font-normal !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
    padding: 16px 32px !important;
    border-radius: 4px !important;

    &.button-bg {
      background-color: #0000A1 !important;
      color: #fff !important;
    }
  }
  .modal-select-iap-planner {
    background: #FFFFFF;
    border-radius: 0px 0px 10px 10px;
    width: 720px !important; 
    .content{
      padding: 0 !important;
      overflow: hidden !important;

      .modal-title {
        background: #FEF8E6;
        border-radius: 10px 10px 0px;
        height: 88px;
        margin-bottom: 0 !important;
        .icon {
          transform: scale(1) translateY(4px);
          margin-left: 32px;
        }
        .name {
          color: #9E7700;
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 34px;
        }
      }
      .modal-content {
        height: 344px;

        .body {
          margin: 32px 40px 0 40px;
          p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
          .title-dropdown {
            margin-top: 16px;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
          }
          .search-dropdown {
            display: flex;
            justify-content: space-between;
            width: 385px !important;
            align-items: center;
            margin-top: 4px !important;
            .icon {
              transform: scale(1.1) translateY(0px);
            }
          }
          .last-content {
            margin-top: 40px;
          }
        }

        .g-button {
          margin-right: 40px;
          margin-top: 40px;
          .button-demand {
            width: 158px;
            height: 48px;
          }
        }
      }
    }
  }
  .confirm-demand-modal{
    border-radius: 10px !important;
    .error-modal {
      &.modal-title {
        background-color: #f6d5d5 !important;
        .modal-confirm-title {
          color: #ff0000 !important;
        }
      }
    }
    &.ui.modal>:first-child:not(.icon){
      border-radius: 10px !important;
    }
    .content{
      padding: 0 !important;
      overflow: hidden !important;

      .modal-title{
        background-color: #D5F6E8;
        padding: 24px 32px;
        margin-bottom: 0 !important;
      }
    }
    .modal-confirm-title{
      font-size: 26px;
      color: #135338 !important;
    }

    .modal-confirm-content{
      padding: 32px 40px;
    }

    .iap-ddl {
      width: 400px !important;
      font-size: 14px !important;    
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      padding-left: 16px !important;
      .dropdown.icon{
        margin-top: 4px !important;
      }
    }
  }
  .modal-coloured-bold {
    color: #0000A1;
    font-weight: 600;
  }

  .modal-label-field {
    font-weight: 600;
    color: #202529;
    font-size: 14px;
    line-height: 14px;
  }
