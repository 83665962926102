.legend-components {
    .legend-title {
        font-weight: 600;
    }

    .square {
        width: 12px;
        height: 12px;
    }
    .circle {
        width: 12px;
        height: 12px;
        border-radius: 20px;
    }
}

.legend-bottom {
    z-index: 994;
}
