.vtp-container {
    position: relative;
    .freeze-voyage{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #ffffff66;
        z-index: 100;
    }
    .vtp-side-line {
        position: absolute;
        top: 20px;
        bottom: 22px;
        left: 35px;
        width: 2px;
        background-color: #BAB3BE;
    }
}