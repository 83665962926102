.schedule-overview-page {
  background-color: #f5f7fe;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999;
  transition-timing-function: ease-out;
  transition: width 5s;

  &.invisible {
    height: 0;
  }

  .left-content {
    flex: 1;
  }

  .hide {
    background-color: #f5f7fe;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    width: auto;
    height: 40px;
    position: absolute;
    left: 65px;
    top: -40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 44%;
    transition: bottom 0.3s;
    justify-content: center;
    white-space: nowrap;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
    font-size: 14px;
    color: #242433;

    .alpha-chevron {
      margin: 0 !important;
      transform: rotate(0deg);
    }
  }

  .hide:hover {
    background-color: #eaeaea;
  }

  .hidebtn_Bottom {
    background-color: #f5f7fe;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    width: auto;
    height: 40px;
    position: absolute;
    left: 65px;
    top: -41px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 44%;
    transition: bottom 0.3s;
    justify-content: center;
    white-space: nowrap;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
    font-size: 14px;
    color: #242433;
  }

  .hidebtn_Bottom:hover {
    background-color: #eaeaea;
  }

  .schedule-overview-container {
    padding: 1rem 5rem;
    .label-title {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: #242433;
    }

    .schedule-overview-content {
      .label-header {
        font-size: 12px;
        line-height: 12px;
        font-weight: 600;
        color: #242433;
      }

      .label-small-header {
        font-size: 10px;
        line-height: 16px;
        color: #242433;
      }

      .label-value {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #242433;
      }

      .content-divider {
        width: 10px;
        border-right: 1px solid #ecece5;
        margin-right: 24px;
      }

      .bar {
        margin-bottom: 1rem;
        .bartext {
          font-size: 11px;

          div:first-child {
            width: 70%;
            display: inline-block;
            text-align: left;
          }

          div:last-child {
            display: inline-block;
            width: 30%;
            text-align: right;
          }
        }
        .progress-content {
          position: relative;
          .benchmark {
            position: absolute;
            width: 3px;
            height: 15px;
            margin-top: -4px;
            background: #d4d4d4;
            margin-left: auto;
            margin-right: auto;
            z-index: 1;
          }
          .progress {
            overflow: hidden;
            background-color: #e9ecef;
            position: relative;

            .progress-bar {
              &.green {
                background-color: #2bcf9e;
              }

              &.red {
                background-color: #ef5d60;
              }
            }
          }
        }
      }
    }

    .button-content {
      button {
        font-family: $font-normal !important;
        font-weight: $fontweight-600;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 16px;
        padding: 16px 32px;
        border-radius: 4px;

        &.button-bg {
          background-color: #0000A1;
          color: #fff;
        }
      }
    }
  }
}
