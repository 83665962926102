.schedule-icon {
  margin-top: 5px;
}

.schedule-icon-popup {
  .popup-content {
    background-color: #f0f8ff;
    width: 250px;
    text-align: center;

    .bold-text {
      font-weight: bold;
    }
  }
}
