// default theme(light)
:root {
  --primaryBackground: #{$color-white};
  --secondaryBackground: #{$color-white};

  --leftNavBackground: #e2e4e7;
  --inputPrimaryBackground: #{$color-white};
  --tableHeaderBackground: #f5f5f5;

  --primaryTextColor: #{$color-black};
  --secondaryTextColor: #323436;
  --thirdTextColor: #999a9c;
  --fourthTextColor: #{$color-black};
  --mainValueColor: #323436;

  --lighterTextColor: #999a9c;

  --primary: #00a19c;
  --primaryVariant: #31b4af;

  --secondary: #{$color-black};
  --secondaryVariant: #{$color-black};

  --primaryBorder: #cccccc;
  --secondaryBorder: #333;

  //Insight
  --insightTitle: #323232;

  //Icons
  --primaryIcon: #{$color-black};
  --secondaryIcon: #424449;

  --primary: #de1782;
  --secondary: #004a97;
}
