.add-rating-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .rating-container {
        .rating-desc {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */

            margin-bottom: 10px;
        }
    }

    .comment-container {
        margin-top: 15px;

        .comment {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
        }

        .add-comment {
            color: #000;
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            margin-top: 10px;

            width: 100%;
        }
    }

    .submition-details-container {
        margin-top: 20px;
        .text {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
        }

        .email {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */

            margin-top: 5px;
        }

        .time {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
        }
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 20px;
        .btn-cancel {
            border-radius: 8px;
            border: 1px solid var(--duke-blue-p-300-main, #0000A1);
            background: var(--shades-white, #FFF);

            width: 106px;
            height: 42px;

            span {
                color: var(--duke-blue-p-300-main, #0000A1);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 157.143% */
            }
        }

        .btn-save {
            border-radius: 8px;
            background: var(--duke-blue-p-300-main, #0000A1);

            width: 99px;
            height: 42px;

            span {
                color: var(--shades-white, #FFF);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 157.143% */
            }
        }
    }
}