::-webkit-scrollbar {
  width: $rem-10;
  height: $rem-10;
  padding-right: $rem-4;
}

body::-webkit-scrollbar {
  background-color: $color-grey;
}

::-webkit-scrollbar-thumb {
  background-color: transparentize($color: $color-grey-light, $amount: 0.2);
  border-radius: $rem-4;
  opacity: 0.5;
  transition: 200ms all ease-in;
  &:hover {
    background-color: $color-grey-light;
    opacity: 1;
  }
}

.scroll {
  overflow: auto;
}
.scroll-x {
  overflow-x: auto;
}
.scroll-y {
  overflow-y: auto;
}
.xscroll {
  overflow: hidden;
}
.xscroll-x {
  overflow-x: hidden !important;
}
.xscroll-y {
  overflow-y: hidden !important;
}
