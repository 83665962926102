.location-information {
  padding: 165px 100px 100px 100px;
  .grid {
    .grid-input {
      border-radius: 4px !important;
    }

    .grid-input-st {
      border-radius: 4px !important;
      width: 100px;
    }
    .grid-dropdown {
      width: 100% !important;
    }
    .date-special-request {
      button {
        height: 43.42px;
        width: 100%;
        .flex {
          justify-content: space-between;
        }
      }
    }
    .three.column.row .column {
      padding-right: 3rem;
    }
    .header-text {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      color: #242433;
    }
    .header-text-title {
      color: #4b7093 !important;
    }
    .subheader {
      padding: 80px 20px 50px 20px;
    }
  }

  .text-blue-title {
    color: #4b7093;
    font-size: 18px;
    font-weight: 600;
  }
  .label-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #5c5c7a;
  }
  .label-bold-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242433;
    &.modify-search {
      color: #0000a1;
      cursor: pointer;
    }
  }
  .label-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4b7093;
  }
  .div-border-top {
    border-top: 1px solid #e8e8ee;
    padding-top: 4rem;
  }

  .btn-text,
  .btn-text:focus,
  .btn-text:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: transparent;
    border: none;
  }

  .input-remarks {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    width: 416px;
    height: 144px;
  }

  .crane-trash-icon {
    flex: 1;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .crane-trash-icon:hover svg path {
    stroke: #242433;
    cursor: pointer;
  }

  .certificate-trash-icon:hover,
  .file-action:hover {
    cursor: pointer;
  }
  .file-action {
    margin-top: -3px;
  }

  .btn-action-primary,
  .btn-action-primary:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background: #0000a1;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background-color: #ffffff;
    border: 1px solid #0000a1;
    float: right;
    &:hover {
      color: white;
      background-color: #0000a1;
      svg path {
        stroke: #ffffff;
      }
    }
  }
  .breadcrum-page {
    .section {
      color: #d0460b;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      &.active {
        color: #6b6c70;
        &:hover {
          color: #6b6c70 !important;
        }
      }
      &:hover {
        color: #0000a1 !important;
      }
    }
  }

  // .file-upload-section {
  //   margin-top: -5px;
  // }

  // .crane-trash-icon:hover {
  //   cursor: pointer;
  // }

  // .certificate-trash-icon {
  //   flex: 1;
  //   align-self: center;
  //   text-align: end;
  // }

  // .certificate-trash-icon:hover svg path {
  //   stroke: #242433;
  //   cursor: pointer;
  // }

  // .certificate-dropdown {
  //   width: 100% !important;
  // }

  .crane-details {
    thead tr:first-child th {
      background-color: #dae3ec;
      border-right: 1px solid #ffffff;
    }

    thead tr th {
      background-color: #ebf0f5;
      border: 1px solid #ffffff;
    }

    tbody tr.seller {
      background-color: #f0f0f4;
    }

    .voyage-sharing-schedule-variance-button {
      padding: 0px !important;
      margin-top: 1em;
    }
  }

  .vessel-certificate-content {
    background: #f9f9fb;
    border-radius: 16px;
    padding: 2rem;
    margin: 2rem;
    width: 956px;
    .fill-content {
      margin-bottom: 2rem;
      padding-right: 2rem;

      &.single-column {
        width: 60%;
        padding-right: 1rem;
      }
    }

    .certificate-input {
      border-radius: 4px !important;
    }

    // .certificate-dropdown {
    //   width: 100% !important;
    // }

    .certificate-trash-icon {
      flex: 1;
      align-self: center;
      text-align: end;
    }

    .certificate-trash-icon:hover svg path {
      stroke: #242433;
    }
  }
}

.table-file-content {
  width: 100%;

  table {
    tr:hover {
      .svg {
        height: 35px;
        transform: rotate(180deg);
        color: #0000a1;
        padding-top: 5px;
      }
    }

    .svg {
      height: 35px;
      transform: rotate(180deg);
      color: #ffffff;
      padding-top: 5px;
    }
  }
}

.dot {
  height: 8px;
  width: 8px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}
