.vod-seller-vessel-registration-page {
  padding: 165px 100px 100px 100px;
  .vr-header-text {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #242433;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: #ffffff;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-action:hover {
    color: #ffffff;
    background: #0000a1;
  }

  .btn-action-primary,
  .btn-action-primary:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background: #0000a1;
    border: 1px solid #0000a1;
    border-radius: 8px;
  }

  .btn-text,
  .btn-text:hover {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000a1;
    background: transparent;
    border: none;
  }

  .label-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4b7093;
  }

  .label-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #5c5c7a;
  }

  .label-bold-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242433;

    &.download {
      color: #0000a1;
      cursor: pointer;
    }
  }

  .grid {
    margin-left: -5px;
    margin-bottom: 2rem;
    flex: 1;

    &.vessel-certification {
      background: #f9f9fb;
      padding: 2rem;
    }

    .certificate-trash-icon {
      align-self: center;
      text-align: end;
    }

    .certificate-trash-icon:hover svg path {
      stroke: #242433;
    }

    .three.column.row .column {
      padding-right: 3rem;
    }

    .grid-input {
      border-radius: 4px !important;
    }

    .grid-dropdown {
      width: 100% !important;
    }
  }

  .div-border-top {
    border-top: 1px solid #e8e8ee;
    padding-top: 4rem;
  }

  .info-icon {
    svg path {
      fill: #5c5c7a;
    }
    cursor: pointer;
    width: 12px;
    height: 12px;
    transform: translate(-7px, -1px);
    margin: auto 0;
  }

  .vessel-certificate-content {
    background: #f9f9fb;
    border-radius: 16px;
    padding: 2rem;
    margin: 2rem;
    .fill-content {
      width: 30%;
      margin-bottom: 2rem;
      padding-right: 2rem;

      &.single-column {
        width: 60%;
        padding-right: 1rem;
      }

      .certificate-input {
        border-radius: 4px !important;
      }

      .certificate-dropdown {
        width: 100% !important;
      }
    }

    .certificate-trash-icon {
      flex: 1;
      align-self: center;
      text-align: end;
    }

    .certificate-trash-icon:hover svg path {
      stroke: #242433;
    }
  }
}

.vessel-registration-modal {
  border-radius: 10px !important;

  .content {
    padding: 0 !important;
    overflow: hidden !important;

    .content-info {
      &.error .header-text {
        color: #c93626;
        background: #fef2f1;
      }

      &.success .header-text {
        color: #1c7d55;
        background: #eafaf4;
      }

      .header-text {
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
        padding: 1rem 1rem 0.5rem 1rem;

        .icon-header svg {
          width: 32px;
          height: 32px;
        }
      }

      .detail-part {
        padding: 24px 32px;
        .label-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #242433;
        }
      }
    }

    .btn-action {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #0000a1;
      background: #ffffff;
      border: 1px solid #0000a1;
      border-radius: 8px;
    }

    .btn-action:hover {
      color: #ffffff;
      background: #0000a1;
    }
  }
}
