.support-desk {
  z-index: 9997;
  position: fixed;
  bottom: 2.5%;
  right: 2%;
}
.support-desk-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #FFFFFF; 
  border-radius: 50%;
  box-shadow: 0px 0px 10px #CCCCCC;
}

.support-active {
  background-color: #0000A1; 
}

.support-backdrop {
  position: fixed;
  top: 0;
  z-index: 9900;
  width: 100%;
  height: 100vh;
}

.rate-support-desk {
  z-index: 9997;
  position: fixed;
  bottom: 4.5%;
  right: 2%;
}

.virtus-support-desk {
  z-index: 9997;
  position: fixed;
  bottom: 6.5%;
  right: 2%;
}