.modal-demand-data-status-container {
    position: fixed;
    width: 100%;
    top: 0 !important;
    height: 100%;
    z-index: 10000;
    left: 0;
    background-color: rgba(90, 90, 90, 0.3);


    .modal-demand-data-status-content {
        width: 556px !important;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
        background-color: #fff;
        overflow: hidden;

        .content {
            padding: 0 !important;
            border-radius: 10px !important;
            margin: 0 !important;
            

            .modal-header {
                justify-content: start !important;
                padding: 24px 35px;
                
                &.success{
                    background: var(--alert-success-g-25, #EAFAF4);
                    .title {
                        font-size: 26px;
                        color: #1C7D55;
                        line-height: 34px;
                    }
                }
                &.empty-data{
                    background: var(--alert-error-r-25, #FEF2F1);
                    .title {
                        font-size: 26px;
                        color: #C93626;
                        line-height: 34px;
                    }
                }
            }

            .modal-content {
                padding: 32px 40px;
                color: #242433;
                font-size: 16px;
                line-height: 24px;
            }

            .modal-actions {
                gap: 8px;
                padding: 32px 40px;
                justify-content: flex-end;

                .btn {
                    font-size: 16px;
                    color: #0000A1 !important;
                    border-radius: 10px;
                    padding: 12px 48px;
                    font-weight: 600;
                    line-height: 24px;
                    text-transform: none;
                }

                .btn-maybe-later {
                    background-color: #0000A1;
                    color: #FFF !important;
                }

                .btn-continue {
                    &:hover {
                        color: #FFF !important;
                    }
                }
            }
        }
    }
}