.past-schedule-container {
    width: 100% !important;
    margin-top: 20px;
    margin-left: -5px;
    .download-div {
        width: 100%;
        text-align: left;
        margin: 20px 0;
        .custom-blue-button {
            height: auto;
            width: auto;
            padding: 10px 32px 10px 32px;
            border-radius: 8px;
            gap: 8px;
            background-color: $color-primary;
            color: $color-white;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            text-transform: none;
        }
    }
    .list-voyages {
        width: 100% !important;
        margin-top: 10px;
        .list-voyage-item {
            width: 100% !important;
            border-radius: 8px;
            padding: 20px;
            background-color: #DAE3EC;
            margin-bottom: 15px;
            .list-voyage-name {
                font-family: Inter;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #242433;
                margin-bottom: 10px;
            }
            .list-voyage-id {
                font-family: Inter;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #242433;
            }
        }
    }
    .schedule-title {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin: 20px 0;
        color: #242433;
    }
}

.voyage-modal {
    padding: 8px;
    border-radius: 8px;
    .voyage-modal-header {
        padding: 5px;
        .title {
            font-family: Inter;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            color: #202529;
        }
    }
    .voyage-modal-content {
        width: 100%;
    }
}