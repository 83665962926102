.Mitigation {
    font-family: 'IBM Plex Sans';
    margin-bottom: 20px;
}

.MitiMain {
    display: flex;
    flex-direction: row;
}

.MitiRight {
    flex: 10;
    padding-right: 10px;
    padding-bottom: 20px;
}

.headerText {
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 21px;
    color: #000000;
}

.subMenu {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.popupDetails {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: '934px';
    color: black;
}

.ui.segment {
    font-size: 16px
}

.gray {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.56);
    margin-bottom: 5px;
}

.innerTable {
    border-left: none !important;
    border-bottom: 1px solid #BBDEF7;
}