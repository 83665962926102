.request-overview-container {
  background-color: #F9F9FB;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  // padding: 20px 152px;
  z-index: 999;
  transition-timing-function: ease-out;
  transition: width 5s;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

  &.bg-white{
    background: #ffffff;
  }

  .button-cancel{
    border: none !important;
    background: none !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    margin-right: 40px;
    &:hover{
      color: #0000A1 !important;
    }
  }

  .button-confirm{
    border-radius: 10px !important;
    padding: 15px 46px !important;
  }

  .request-summary-header{
    padding: 16px 160px;
  }

  .request-summary-label {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    // color: #f5f7fe;
    color: #0000A1;
    display: flex;
    align-items: center;
  }

  .request-summary-chevron {
    display: flex;
    cursor: pointer;
    margin: auto 0;

    svg {
      transform: scale(1.6);
      path{
        stroke: #0000A1;
        stroke-width: 12%;
      }
    }
  }

  .request-summary-header-total-voyage {
    display: flex;
    align-items: center;
    color: #5C5C7A;
    font-size: 14px;
    font-weight: 400;

    .total-voyage-value {
      margin-left: 12px;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
      border-radius: 50%;
      background-color: #E6DCFF;
      color: #460073;
      font-weight: 600;
      font-size: 14px;
      // line-height: 24px;
      text-align: center;
      // padding: 8px 0;
      padding: 5px 0;
    }
  }

  .request-summary-header-chkbox {
    display: flex;
    align-items: center;
  }

  .request-summary-header-buttons {
    margin-left: auto;
  }

  .request-container {
    padding: 0px 160px 40px 160px;
    .Material-summary-container {
      width: 100%;

      .deck-column {
        width: 20%;
      }
    }

    .passengers-summary-container {
      width: 80%;
      margin-right: 120px;

      .total-seats-column {
        margin-left: 72px;
      }
    }

    .request-summary-title {
      // font-size: 12px;
      // line-height: 12px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      color: #5C5C7A;
    }

    .request-summary-sub-title {
      // font-size: 12px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 600;
      // color: #f5f7fe;
      color: #242433;
    }

    .content-divider {
      width: 10px;
      border-right: 1px solid #ecece5;
      margin-right: 24px;
    }
  }

  .request-footer {
    background-color: #fff;
    padding: 16px 160px;
  }

  .request-summary-divider {
    height: 0px;
    border: 1px solid #eaeaea;
    margin: 40px 0px 24px;
  }

  .request-summary-footer-chkbox {
    display: flex;
    align-items: center;
  }

  .request-summary-footer-status {
    display: flex;
    align-items: center;
    padding-left: 32px;
  }

  .ui {
    &.checkbox {
      font-size: 16px;
    }
  }
}

.button-demand {
  font-family: 'Open Sans' !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  padding: 16px 32px !important;
  border-radius: 4px !important;

  &.button-bg {
    background-color: #0000A1 !important;
    color: #fff !important;
  }
}