.toast-advanced {
    margin-top: 75px;
    max-width: 700px;
    .toast-advanced-alert {
        border-radius: 10px;
        color: #1C7D55 !important;
        background: #EAFAF4 !important;
        padding: 24px;
        box-shadow: 0px 20px 24px 0px #0000A10F !important;
    }
}