.user-guide-page {
    padding: 200px;
    font-weight: 500;
    color: #242433;
    font-size: 34px;

    .input-olv{
        margin-top: 100px;
    } 

    .download-white {
        margin-left: 10px;
    }

    .download-user-guide {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        background-color: #0000A1;
        font-weight: 600;
        width: 222px;
        height: 42px;
        font-size: 14px;
        border: 1px solid #0000A1 !important;
        border-radius: 5px;
        margin-top: 10px;
    }

    .button-content {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }

    .button-text {
        margin: 0px;
        margin-left: 15px;
        cursor: pointer;
    }

    .preview-user-guide {
        color: #0000A1;
        background-color: #FFFFFF;
        font-weight: 600;
        width: 222px;
        height: 42px;
        font-size: 14px;
        border-radius: 5px;
        border: 1px solid #0000A1 !important;
        margin-top: 10px;
    }
}