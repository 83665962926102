.track-voyage-container {
  background: #ffffff;
  border-radius: 8px;

  .large-text-black-bold {
    font-weight: 500;
    font-size: 26px;
    line-height: 28px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .voyage-status-bar {
    .div-status {
      width: 25%;
      height: 60px;
      position: relative;
      padding: 1.5rem 0;
      align-items: center;
      justify-content: center;

      &.completed {
        background: #f0f0fa;
        color: #eaeaea;
        i,
        svg path {
          color: #eaeaea;
          stroke: #eaeaea;
        }
      }

      &.current {
        background: #eaeaea;
        color: #ffffff;
        i,
        svg path {
          color: #ffffff;
          stroke: #ffffff;
        }
      }

      &.in_progress {
        background: #eaeaea;
        i,
        svg path {
          color: #6b6c70;
          stroke: #6b6c70;
        }
      }
    }

    .div-status:not(:first-child) {
      margin: auto 1rem;
    }
    .div-status:not(:first-child):after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 10px solid white;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
    }

    .completed:not(:last-child):before {
      content: '';
      position: absolute;
      right: -10px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 10px solid #f0f0fa;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
    }

    .current:not(:last-child):before {
      content: '';
      position: absolute;
      right: -10px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 10px solid #eaeaea;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
    }

    .in_progress:not(:last-child):before {
      content: '';
      position: absolute;
      right: -10px;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 10px solid #eaeaea;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
    }
  }

  .steps-custom { 
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1300px;
    margin: 0 auto 47px auto;
    path {
      fill: #FFFFFF;
    }
    .step {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 0;
      align-items: center;

      .step-check {
        width: 24px;
        height: 24px;
        margin-top: 5px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        font-weight: 500;
        font-size: 16px;
        justify-content: center;
        background: #FFFFFF;
        color: #2FD08D;
        border: 3px solid #CACAD8;
        z-index: 1;
        svg {
          width: 80%;
          height: 80%;
          margin-left: 2px;
        }
      }

      .step-title {
        font-weight: 400;
        font-size: 16px;
        margin-top: 16px;
        color: #BAB3BE;
      }
      .step-detail {
        font-size: 12px;
        color:#BCBCCD;
        margin-top: 4px;
      }
    }

    .step:not(:first-child)::before {
      height: 2px;
      width: 100%;
      bottom: 0;
      left: -50%;
      top: 16px;
      content: ' ';
      display: block;
      position: absolute;
      background: #BAB3BE;
    }

    .step.is-active:not(:first-child)::before {
      background: #2FD08D;
    }
    .step.is-active .step-check {
      border: 3px solid #2FD08D;
      background: #2FD08D;
      color: #FFFFFF;
    }

    .step.is-active .step-title {
      color: #202529;
    }

    .step.is-current:not(:first-child)::before {
      background: #2FD08D;
    }
    .step.is-current .step-check {
      border: 3px solid #2FD08D;
      background: #FFFFFF;
      color: #FFFFFF;
    }

    .step.is-current .step-title {
      color: #202529;
    }
  }

  table {
    // width: 100%;
    // border-spacing: 0;
    thead,
    tbody,
    tr,
    th,
    td {
      display: block;
    }

    thead tr {
      width: 100%;
    }

    tr:after {
      content: '';
      display: block;
      visibility: hidden;
      clear: both;
    }

    tbody td,
    thead th {
      float: left;
    }

    thead th {
      background-color: #DAE3EC !important;
    }

    tbody {
      tr {
        height: 50px !important;
        display: flex;
        align-items: center;
      }

      tr:nth-child(2n)  {
        background-color: #ffffff !important;
      }
      tr:nth-child(even)  {
        background-color: #F9F9FB !important;
      }
      tr td{
        border-top: none !important;
        vertical-align: middle;
      }
      
    }
    .first {
      width: 5%;
    }
    .second {
      width: 14%;
    }
    .third,
    .fourth {
      width: 11%;
    }
    .fifth, .sixth, .seventh {
      width: 16%;
    }
    .eightth {
      width: 11%;
    }
    .container {
      // width: 97px;
      text-align: center;
      border-radius: 10px;
      margin-top: -5px;
    }
    .status-tag {
      border-radius: 10px;
      padding: 5px 16px;
      font-size: 14px;
      font-weight: 600;
    }
    .red {
      color: #C93626;
      background-color: #FCE2DF;
    }
    .green {
      color: #1C7D55;
      background-color: #D5F6E8;
    }
    .yellow {
      color: #C79500;
      background-color: #FDF1CE;
    }
    .blue {
      color: #3B5773;
      background-color: #EBF0F5;
    }
  }

  .btn-more-less{
    gap: 8px;
    color: #0000A1;
    font-weight: 600;
    font-size: 14px;
    margin-top: 34px;
    justify-content: flex-end;
    align-items: center;
    margin-right: 40px;
    cursor: pointer;
  }
}
