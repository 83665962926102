.mobile-back-button {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 20px;
}