.md-past-voyage {
  width: 85% !important;

  .d-actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .p-relative-waiting {
    position: relative;
  }

  .disable-title {
    opacity: 0.35;
  }
}