.period-picker-my-reservation {
    padding: 32px !important;
    text-align: left;
    .period-picker {
        margin-top: 30px;
        position: relative;
        .rdrMonthAndYearWrapper{
            padding-top: 0;
            height: 40px;
        }
        .rdrDateDisplay {
            margin: 0.833em 0;
            .rdrDateInput:first-child::before {
                content: 'Start Date';
                position: absolute;
                top: -24px;
                left: 0;
                font-size: 14px;
                color: #5C5C7A;
            }
            .rdrDateInput:last-child::before {
                content: 'End Date';
                position: absolute;
                top: -24px;
                left: 0;
                font-size: 14px;
                color: #5C5C7A;
            }
        }
        .rdrDateDisplayWrapper {
            background: none;
            .rdrDateInput {
                position: relative;
                border-radius: 8px;
                box-shadow: none;
                border: 1px solid #D9D9E3;
                text-align: left;
                padding-left: 34px;
                &::after {
                    content: '';
                    position: absolute;
                    z-index: 100;
                    left: 10px;
                    top: 7px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .calendar-icon {
            position: absolute;
            z-index: 100;
            gap:302px;
            left: 14px;
            top: 18px;        
        }
        &.disable-calendar {
           span {
            border-color: #fff;
           }
           span::after{
            background: none;
           }
            .rdrInRange, .rdrStartEdge, .rdrEndEdge {
                background: none;
            }
            .rdrDayNumber {
                span {
                    color: #d5dce0;
                }
            }
            .rdrMonthAndYearWrapper {
                &::after {
                    content: '';
                    position: absolute;
                    z-index: 100;
                    background-color: rgb(255, 255, 255);
                    opacity: 0.4;
                    width: 100%;
                    height: 40px;
                }
            }
            .rdrDay, input {
                cursor: context-menu !important;
            }
        }
    }
    .button-group {
        .btn {
            background: none;
            color: #0000A1;
        }
        .btn-apply {
            border: 1px solid #0000A1;
            border-radius: 8px;
            padding-left: 30px;
            padding-right: 30px;
            margin-left: 10px;
            &:hover {
                background-color: #0000A1;
                color: #fff;
            }
        }
    }
}

.date-range-my-reservation {
    border: 1px solid #D9D9E3;
    color: #242433;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 17px;
    cursor: pointer;
    &.voyage-date-range {
        justify-content: space-between;
        width: 334px;
    }
    &.start-date {
        gap: 13px;
        color: #757595;
    }
}