.track-vessel-side-panel-container {
    position: relative;
    display: inline-flex; 
    flex-direction: column;
    z-index: 990;
    top: 180px;
    left: 78%;
}

.track-vessel-side-panel-item {
    background-color: white; 
    width: 50px; 
    height: 50px; 
    margin: 20px; 
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; 
    border-radius: 25%;

    &.settings_1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.active {
        background-color: #0000A1;
        margin-bottom: 0px;
    }

    &.inactive {
        background-color: white;
        margin-bottom: 0px;
    }

    &.settings_1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.search_normal {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.calender {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.location {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.layer {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.ship {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.more {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // &.show {
        
    // }

    // &.hidden {
    //     display: none;
    // }
}