.nowrap {
  white-space: nowrap;
}
.white-space-normal {
  white-space: normal;
}
.line-break-anywhere {
  line-break: anywhere;
}

.left {
  text-align: left !important;
}
.center,
.text-center {
  text-align: center;
}
.right {
  text-align: right;
}
.normal {
  font-family: $font-normal !important;
  font-weight: normal !important;
  &.italic {
    font-family: $font-normal !important;
    font-style: italic;
  }
}
.light {
  font-family: $font-light !important;
  &.italic {
    font-family: $font-italic !important;
  }
}
.semibold {
	font-family: $font-normal !important;
	font-weight: $fontweight-600;
  &.italic {
      font-style: italic;
  }
}
.bold {
  font-weight: 700;
  &.italic {
    font-family: $font-normal !important;
  }
}
.italic {
  font-family: $font-normal !important;
  font-style: italic;
}

@for $i from 0 through 4 {
  .text-#{$i} {
    font-size: $i * 1rem;
    line-height: $i * 1rem;
  }
  .text-#{$i}_5 {
    font-size: ($i + 0.5) * 1rem;
    line-height: ($i + 0.5) * 1rem;
  }
  .text-#{$i}_25 {
    font-size: ($i + 0.25) * 1rem;
    line-height: ($i + 0.25) * 1rem;
  }
  .text-#{$i}_75 {
    font-size: ($i + 0.75) * 1rem;
    line-height: ($i + 0.75) * 1rem;
  }
}

$colors: (
  primary: #0000A1,
  dark-grey: #4f4f4f,
  freeform: #e95258,
  black: #333333,
  red: #e75552,
  teal: #009e8e,
  white: #ffffff,
  pinkish-grey: #cccccc,
  warm-grey: #999999,
  brownish-grey: #666666,
  white-grey: #ececec,
  yellow: #ffbe47,
  sand-brown: #ceb888,
  shade-white-light: #fffaff,
  yellow-white: #f2f1f6,
  brand: #666666,
  background: #fbfbfb,
  darkteal: #04656c,
  amber: #ffbe4f,
  mineral-grey: #9db6b8,
  teal-02: #ebf8f6,
  pink: #de1782,
  pastel-red: #e75552,
  grey: #cccccc,
  error-input: #ffe4e6,
  snow: #f8f8f8,
  content-background: #fbfbfb,
  purple-dark: #58478d,
  purple-dark-light: #7261a7,
  dark-blue: #1c2c73,
  warm-blue: #615e9b,
  warm-green: #009e8e,
  green: #009e8e,
  orange-yellow: #ffbe4f,
  gray-light: #c7c7c7,
  sand-brown-light: #e0b25c,
  dark-gray: #f9f8fb,
  purple: #524f8c,
  purple-light: #edecf2,
  yolk: #dab164,
  error-cell: #ffe4e6,
  teal-1_0: #009e8e,
  teal-0_7-hover: #4ab9ad,
  teal-0_7: #7dcbc2,
  teal-0_4: #99d8d2,
  teal-0_2: #ebf8f6,
  smokey-fog: #ececec,
  light-snow: #fbfbfb,
  blush-red: #e75552,
  fresh-mint: #76dd9f,
  sun-kiss-orange: #ffbe4f,
  mineral-grey-priority-1: #a6c0c2,
  dark-ocean-priority-2: #0275af,
  sand-castle-priority-3: #ceb888,
  aqua-marine-priority-4: #5cb4de,
  living-coral-priority-5: #ff7f69,
  tropical-vine-priority-6: #266a71,
  pink-dusk-priority-7: #f6b3b1,
  dark-copper-priority-8: #96504c,
  sea-bliss-priority-9: #acc5f0,
  glowing-skyline-priority-10: #3e5ae8,
  little-leaf-priority-11: #89af4b,
  soil-brown-priority-12: #513b2e,
  the-horizon-priority-13: #728ddb,
  winter-sakura-priority-14: #e0c0db,
  green-beach-priority-15: #2bc1ca,
  error: #ffe4e6,
  unavailable: #f8f8f8,
);

@each $label, $color in $colors {
  .text-#{$label} {
    color: $color !important;
  }
  .bg-#{$label} {
    background-color: $color !important;
    @if (lightness($color) < 50) {
      color: white;
    }
  }
}
