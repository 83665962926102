.unauthorised-access-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.titleArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #242433;
  font-weight: 700;
  font-size: 34px;
}

.messageArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
  color: #242433;
  font-weight: 500;
  font-size: 18px;
}
