.search-voyage-container {
    padding: 130px 160px 30px 160px;
    // background: #F5F7FE;
    .search-dropdown {
        width: 85% !important;
        font-size: 16px!important;
        line-height: 22.4px!important;
    }
    .radio-option-content{
        .radio-option-item{
            background: #F9F9FB;
            border-radius: 8px;
            width: 308px !important;
            padding: 16px 24px;
            box-sizing: border-box;
            color: #5C5C7A;
            &.active{
                background-color: #F0F0F4;
                color: #242433;
            }
        }

        .radio input[type='radio']:checked ~ label:before {
            border-color: #242433;
        }

        .radio input[type='radio']:checked ~ label:after {
            background-color: #242433 !important;
        }
    }
    .grid {
        .text-blue-title {
            color: #4B7093;
            font-size: 18px;
            font-weight: 600;
        }

        .text-title-dropdown {
            font-size: 14px;
            font-weight: 600;
            color: #5C5C7A;
        }
        
        .search-dropdown {
            display: flex;
            justify-content: space-between;
        }
    }
        
    .modify-search{
        color: #0000A1;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        height: 100%;
        align-items: center;
        width: 50%;
        margin-left: 80px;
    }
    .sort-by-container{
        position: relative;
        .sort-by-text{
            // width: 100%;
            font-weight: 400;
            color: #5C5C7A;
        }
        .search-dropdown{
            font-size: 14px !important;  
            width: 250px !important;  
            & > .text{
                margin-right: 20px;  
                width: 200px; 
                margin-left: 30px;                 
            }
            & .search{
                margin-left: 25px
            }
        }
        .sort-by-icon{
            position: absolute;
            left: 70px;
            top: 9px;
        }
    }
    .btn-search-voyages{
        border-radius: 8px !important;
    }
    .date-special-request{
        button{
            height: 43.42px;
            width: 100%;
            .flex{
                justify-content: space-between;
            }
        }
    }
    .dropdown-loading{
        i.icon{
            top: -10px !important;
            left: 0;
        }
    }
}