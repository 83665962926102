.region {
    display: flex;
    height: 42px;
    padding: 23px 61px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--Duke-Blue-P50, #E6E6F6);
    cursor: pointer;

    &.selected {
        background: var(--Duke-Blue-P100, #6B6BC8);
    }

    .name {
        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; 

        &.selected {
            color: var(--Shades-White, #FFF);
        }
    }
}

.region-selection {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}