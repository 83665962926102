.editable-modal {
  width: 85% !important;
}
.editableTable {
  input {
    font-size: $fontsize-12;
    border: 1px solid #979797;
    width: auto;
    padding: 9.5px 5px;
    border-radius: 5px;
    &.disabled {
      background-color: #979797;
      opacity: 0.6;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .delete-icon {
    cursor: pointer;
    font-size: 18px;
    transition: all 0.2s;
    padding-left: 15px;
    color: #0000A1;
    &.disabled {
      cursor: not-allowed;
      color: #979797;
    }
  }
  .reason-card {
    margin-bottom: 2px;
  }
}
.table-header {
  position: sticky !important;
  top: 0;
  z-index: 999 !important;
}
.warning-action {
  .button-custom {
    margin-right: 10px !important;
  }
  .vessel-name {
    margin-left: 5px;
    color: #465793;
  }
  .scrollable-table {
    overflow: auto;
    height: 500px;
    font-size: 14px;
    .noti-chevron {
      cursor: pointer;
      width: 20px;
    }

    .btn-action {
      padding: 4px 12px;
      color: #fff;
      background: #0000A1;
      border-radius: 4px;

      border: 1px solid #0000A1;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      &:disabled {
        background-color: #bab3be;
        border: 1px solid #bab3be;
        cursor: default;
      }
    }

    .cancel {
      color: #0000A1;
      background: #ffffff;
    }

    .div-report-actions {
      display: flex;
      align-items: end;

      div:last-child {
        margin-left: 10px;
      }
    }

    .time-span {
      color: #a4a7b2;
    }

    .ui.selection.dropdown {
      position: relative;
      border: 1px solid #a4a7b2;
      width: 100%;
      font-size: 14px;
      display: block !important;

      .icon {
        position: absolute;
        right: 5px;
      }
    }

    .mb-18 {
      margin-bottom: 18px !important;
    }

    .d-block {
    }
  }
}

.no-border-table {
  border: none !important;
  td,
  th {
    border: none;
  }
  .past-va-positive-color {
    background: #fbfcff;
  }
}

.img-test {
  color: #0000A1;
}
