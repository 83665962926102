.vod-approval-registration {
  padding: 165px 100px 100px 100px;
  .vod-registration-details {
    .button-container {
      .back-button {
        padding: 12px 48px;
        margin-right: 30px;
      }
      .reject-registration-button {
        color: #0000A1;
        background-color: #ffffff;
        border: solid 1px #0000A1;
        padding: 12px 48px;
      }
      .approve-registration-button {
        color: #ffffff;
        background: #0000A1;
        padding: 12px 48px;
      }
    }
    .details-header {
      .breadcrumb-orange {
        color: #D0460B;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
      }
      .breadcrumb-blue {
        color: #242433;
        font-size: 14px;
      }
      .vessel-title-black {
        font-size: 34px;
        color: #242433;
      }
      .vessel-title-blue {
        font-size: 34px;
        color: #4B7093;
      }
      .right-arrow {
        color: #242433;
        margin-top: -3px;
      }
    }
    .details-content {
      .content-title {
        flex: 1 1 0;
        font-size: 18px;
        font-weight: 600;
        color: #4B7093;
      }
      .content-column {
        //padding: 20px;
        flex: 3 1 0;
        .certificate-card {
          padding: 40px;
          border-radius: 16px;
          background: #f1f1f1;
        }
        .inner-content-column {
          flex: 1 1 0;
          .label-title {
            font-size: 14px;
            color: #5C5C7A;
          }
          .label-details {
            font-size: 16px;
            color: #242433;
            font-weight: 600;
            &.certificate-file {
              color: #0000A1;
              cursor: pointer;
            }
            .download-icon {
              margin-top: -6px;
              margin-left: 10px;
            }
          }
        }
      }
      hr {
        width: 100%;
        border: 1px solid #d9d9e3;
      }
    }
  }
  .header {
    font-size: 34px;
    color: #242433;
  }
  .filter-option-content {
    margin-bottom: 2rem;
    .radio-option-content {
      flex: 1;
      margin: auto;
      .radio {
        margin-right: 3rem;
        label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #202529;
        }
        input[type="radio"]:checked ~ label:before {
          border-color: #0000a1;
        }
        input[type="radio"]:checked ~ label:after {
          background-color: #0000a1 !important;
        }
      }
    }
  }
  .table-file-content {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    table {
      th {
        background-color: #dae3ec;
        font-weight: 600;
        padding: 1em 1em;
      }
      td {
        background: #FFFFFF;
        padding: 1em 1em;
      }
      td:first-child {
        padding: 1em 2em;
      }
      td:last-child {
        padding-right: 2em;
      }
      tr:hover {
        .svg {
          height: 35px;
          transform: rotate(180deg);
          color: #0000A1;
          padding-top: 5px;
        }
      }
      .svg {
        height: 35px;
        transform: rotate(180deg);
        color: #FFFFFF;
        padding-top: 5px;
      }
    }
  }
  .approval-registration-pagination-footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding: 27.5px 110px;
    border-radius: 0.28571429rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    align-items: center;
    .ui.pagination.menu .active.item {
      border-radius: 50%;
      background-color: #242433;
      font-weight: bold;
    }
  }
}

.vod-approval-registration-modal {
  .registration-button-container {
    .submit-button {
      border: 1px solid #0000A1;
      color: #0000A1;
      border-radius: 8px;
      padding: 12px 48px;
      background: #FFFFFF;
      &:hover {
        background: #FFFFFF;
      }
    }
    .cancel-button {
      padding: 12px 48px;
      color: #0000A1;
      background-color: #FFFFFF;
      &:hover {
        background-color: #FFFFFF;
      }
    }
  }
  .approve-modal-header {
    background: #EAFAF4;
    padding: 24px 32px;
    border-radius: 10px 10px 0px 0px;
    .modal-header-text {
      font-size: 26px;
      color: #1C7D55;
    }
  }
  .reject-modal-header {
    background: #FEF2F1;
    padding: 24px 32px;
    border-radius: 10px 10px 0px 0px;
    .modal-header-text {
      font-size: 26px;
      color: #C93626;
    }
  }
  .label-bold-text {
    font-weight: 600;
  }
  .grid-input {
    border-radius: 4px !important;
  }
}
