.edit-report-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .dropdown-container {
        .re-and-desc {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */

            margin-bottom: 10px;
        }

        .reason-dropdown {
            margin-bottom: 10px;

            height: 46px !important;
            padding: 16px 16px !important;
            border-radius: 8px !important;
            border: 1px solid #D9D9E3 !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            gap: 12px !important;
            color: #242433 !important;
            text-transform: none !important;

            width: 100% !important;
        }

        .description-dropdown {
            margin-bottom: 10px;

            height: 46px !important;
            padding: 16px 16px !important;
            border-radius: 8px !important;
            border: 1px solid #D9D9E3 !important;
            font-weight: 400 !important;
            font-size: 14px !important;
            gap: 12px !important;
            color: #242433 !important;
            text-transform: none !important;

            width: 100% !important;
        }
    }

    .comment-container {
        margin-top: 15px;

        .comment {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
        }

        .edit-comment {
            color: #000;
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
            margin-top: 10px;

            width: 100%;
        }
    }

    .submition-details-container {
        margin-top: 20px;
        .text {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
        }

        .email {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */

            margin-top: 5px;
        }

        .time {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px; /* 157.143% */
        }
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-top: 20px;
        .btn-delete {
            border-radius: 8px;
            border: 1px solid var(--duke-blue-p-300-main, #0000A1);
            background: var(--shades-white, #FFF);

            width: 106px;
            height: 42px;

            span {
                color: var(--duke-blue-p-300-main, #0000A1);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 157.143% */
            }
        }

        .btn-save {
            border-radius: 8px;
            background: var(--duke-blue-p-300-main, #0000A1);

            width: 99px;
            height: 42px;

            span {
                color: var(--shades-white, #FFF);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px; /* 157.143% */
            }
        }
    }
}

// .ui.selection.dropdown {
//     // width: 100% !important;
//     // height: 20px !important; 
// }