.book-vessel-page {
  .header {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.03em;
    color: #202529;
  }

  .sub-header {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .header-small {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #202529;
    text-transform: capitalize;
  }

  .right-aligned-content {
    justify-content: flex-end;
  }

  .book-a-vessel-container {
    .book-a-vessel-input-container {
      width: 33%;

      .input-label {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #6b6c70;
        margin-bottom: 5px;
      }

      .book-a-vessel {
        width: 370px;
        min-height: 43px;
      }

      .book-a-vessel-load-input {
        border-radius: 0.28571429rem;
        border: 1px solid #ccc;

        &.error {
          background-color: $color-primary-light;
          border-color: $color-highlight-red;

          &::placeholder {
            color: #9f3a38;
          }
        }
      }
    }
  }
}
