.fuel-consumption-summary-widget-container {
    border-radius: 6.5px;
    background-color: #FFFFFF;
    margin-bottom: 40px;
    height: max-content;
    max-width: 100%;
    overflow: auto;

    .row {
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between
    }

    .content {
        display: flex;
        gap: 11px;

        .card-containerr {
            width: 300px;
            height: 140px;
            border-radius: 6.5px;
            background: var(--Alert-Error-R10, #F5FBF3);
            position: relative;
            .title {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-top: 25px;
                margin-bottom: 0px;
                margin-left: 30px;
            }

            .value {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 34px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px; 
                margin-left: 30px;
            }

            .popup-container {
                position: absolute;
                right: 5px;
                top:5px;
            }
            @media screen and (min-width: 1440px) and (max-width: 1440px) and (min-height: 900px) and (max-height: 900px) {
                width: 220px;
              }
        }
        
    }

    .region-selection-container {
        margin-top: 30px;
        margin-left: 48px;
        margin-bottom: 30px;
    }

    .date-selection-container {
        margin-left: 45px;
    }

    .fuel-consumption-container, .co2-summary-container {
        margin: 40px 0 0 40px;
        flex: 1 1 5%;

        .title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px; 
        }
    }

    .fuel-consumtion-compliance-chart-container, .speed-compliance-chart-container {
        margin: 40px;
        flex: 1 1 10%;
        width: 380px;
        height: fit-content;

    .details {
        display: flex;
        gap: 70px;
        justify-content: center;
        margin-left: 0px;
        margin-top: 20px;
        flex-wrap: wrap;

        .comply, .non-comply {
            .value {
                color: var(--independence-grey-dark-n-800-main-text, #242433);
                font-family: Inter;
                font-size: 34px;
                font-style: normal;
                font-weight: 500;
                line-height: 40px; 
            }

            .label {
                display: flex;
                align-items: center;
                gap: 8px;

                .icon {

                }
                .text {
                    color: var(--independence-grey-dark-n-800-main-text, #242433);
                    text-align: center;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px; 
                }
            }
        }
    }
    }
}

.popup-content {
    width: 199px;
    padding: 16px;
    border-radius: 10px;
    background: rgba(59, 87, 115, 0.90);

    .title {
        color: var(--Shades-White, #FFF);
        text-align: left;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; 
    }

    .description {
        color: var(--Independence-Grey-Light-N10, #F9F9FB);
        text-align: left;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; 
    }
}

.date-selection {
    display: flex;
    gap: 39px;

    .label {
        color: #5C5C7A;
    }

    .dropdown {
        border-radius: 8px !important;
        border-color: #D9D9E3 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        height: 46px !important;
        width: 250px !important;
        padding: 12px 16px !important;
        font-size: 14px !important;
        margin-top: 10px !important;
    }

    .input-field {
        input {
          border-radius: 8px !important;
          margin-top: 10px !important;
          border-color: #D9D9E3 !important;
          height: 46px !important;
          width: 250px !important;
          cursor: pointer;
          caret-color: transparent;
        }
    }

    .year-picker-trigger {
        .place-holder {
          color: rgba(191, 191, 191, 0.87);
          font-weight: 400;
        }
    
        &.year-picker-error {
          border-color: red !important;
        }
    }
}

.nav{
    cursor: pointer;
}