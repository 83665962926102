.flex {
	display: flex;
	flex-direction: row;
	&:not(.nospace) > *:not(:last-child) {
		margin-right: $rem-16;
	}
	&.column {
		flex-direction: column;
		&:not(.nospace) > *:not(:last-child) {
			margin-right: 0;
			margin-bottom: $rem-16;
		}
	}
	&.center {
		align-items: center;
	}
	&.center-self {
		align-self: center;
	}
	&.align-self-end {
		align-self: flex-end;
	}
	&.align-self-stretch {
		align-self: stretch;
	}
	&.justify-center {
		justify-content: center;
	}
	&.space-between {
		justify-content: space-between;
	}
	&.space-around {
		justify-content: space-around;
	}
	&.space-evenly {
		justify-content: space-evenly;
	}
	&.end {
		justify-content: flex-end;
	}
	&.start {
		justify-content: flex-start;
	}
	&.align-start {
		align-items: flex-start;
	}
	&.stretch {
		align-items: stretch;
	}
	&.wrap {
		flex-wrap: wrap;
	}
	&.start-items {
		align-items: flex-start;
	}
}
.grow {
	flex-grow: 1;
}
.block {
	display: block;
}
.width-100 {
	width: 100%;
	box-sizing: content-box;
}
.height-100 {
	height: 100%;
	box-sizing: content-box;
}