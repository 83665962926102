.reservation-en-route-container {
  padding-bottom: 24px;


  .custom-slider .slider-body {
    padding: 0 !important;
  }

  .ui.secondary.pointing.menu {
    border-bottom: none;
    margin-bottom: 34px;
    .item {
      color: #bab3be;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
    }
  }

  .ui.secondary.pointing.menu .active.item {
    border-bottom: 2px solid #0000A1;
    color: #000000;
  }

  .empty-tab {
    height: 400px;
    font-size: 18px;
    line-height: 24px;
    color: #202529;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search-filter-reservations {
    margin-left: auto;
    padding-top: 5px;
    display: flex;
    .search-input {
      border-radius: 4px 0 0 4px;
      border-style: none !important;
      width: 75%;
      height: 40px;
    }
    .filter-reservations {
      border: 1px solid #ccc;
      border-left: none;
      border-radius: 0 4px 4px 0;
      background-color: transparent;
      color: #0000A1;
      font-weight: 600;
      font-size: 16px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      width: 25%;
      justify-content: center;
      cursor: pointer;
      p {
        margin-left: 5px;
      }
    }
  }
}