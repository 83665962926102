.track-voyage {
  position: fixed;
  z-index: 991;
  top: 120px;
  left: 20px;

  .backdrop {}

  .track-div {
    align-items: center;
    flex-direction: column;
    background-color: #f5f7fe !important;
    transition: left 0.3s;
    padding: 16px 24px;
    min-width: 330px;
    min-height: 40px;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 4px;

    .trackDiv {
      align-items: center;
      margin-right: 0 !important;

      .title {
        font-family: $font-normal !important;
        font-weight: $fontweight-600;
        font-size: 18px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #000000;
      }
    }
    .track-content {
      width: 100%;
      height: auto;
      padding-top: 2.5rem !important;
      .trackform {
        font-family: $font-normal !important;
        font-weight: $fontweight-600;
        font-weight: $fontweight-700;
        font-size: 14px;
        line-height: 12px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $color-black;
        flex-direction: column;
        margin-bottom: 1.3rem !important;
        .trackform-title {
          margin-bottom: 6px;
          font-weight: normal;
          font-size: 14px !important;
          text-transform: none;
        }
        & > .dropdown {
          width: 100% !important;
          .icon {
            width: 100% !important;
            text-align: end !important;
            margin-right: 0 !important;
            font-size: 16px !important;
          }
          &.loading {
            .icon {
              top: 0 !important;
              left: 0 !important;
              height: 100% !important;
              padding: 0 !important;
            }
          }
        }
      }
      .button {
        text-align: end;
        margin-top: 1rem;
        .track-button {
          font-size: 14px !important;
          margin: 0;
          padding: 11px 24px !important;
          font-family: "Open Sans" !important;
          text-transform: capitalize !important;
          font-weight: 600 !important;
          border-radius: 4px !important;
          &.button-bg {
            background-color: $color-primary !important;
            color: #fff !important;
            text-transform: none !important;
          }
        }
      }
    }
  }
  .button-execute {
    font-size: 14px !important;
    padding: 11px 24px !important;
    font-family: "Open Sans" !important;
    text-transform: capitalize !important;
    font-weight: 600 !important;
    border-radius: 4px !important;
    &.button-bg {
      background-color: #0000A1 !important;
      color: #fff !important;
      text-transform: none !important;
    }
  }
}

@media (max-width: 768px) {
  .track-voyage {
    position: fixed;
    top:auto;
    bottom: 80px;
    left: 25px;
    z-index: 991;
    .track-div {
      z-index: 995;
      position: fixed;
      top: 200px;
      left: 0;
      width: calc(100% - 80px);
      margin: 0 40px;
      min-width: auto;
      transition:bottom 0.3s;
      filter: none;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      .trackDiv {
        .title {
          font-family: Inter;
          font-size: 22px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          text-transform: none;
          color: #202529;

        }
      }
      .track-content{
        .trackform {
          .trackform-title {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #5C5C7A;

          }
        }
        .button {
          .track-button {
            width: 100%;
            text-align: center;
            padding: 10px, 32px, 10px, 32px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            background: $color-primary;
            color: $color-white;
            border-radius: 8px;
          }
        }
      }
    }

    .backdrop {
      position: fixed;
      z-index: 499;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .button-execute {
      margin: 4px;
    }
  }
}
