$people-service-color: #c4dfd7;
$material-service-color: #fbe165;
$marine-service-color: #aed2f2;
$supply-service-color: #f0aef2;
$other-service-color: #f2aeae;


$secondary-color: #6b6c70;
$height-timeline-bar: 20px;
$size-icon-box: 45px;
$height-timeline-popup: 100px;
$height-timeline: 25px;
$height-popup: 222px;

#timeline {
  overflow-x: auto;
  overflow-y:clip;
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 1rem 2rem;
  transition: all 1s ease-in-out;
  padding-bottom: $height-popup + 56px;

  &::-webkit-scrollbar {
    height: 8px;
    background-color: #ffffff;
  }
  &-webkit-scrollbar-thumb {
    background-color: #ffffff;
    box-shadow: none !important;
  }
  &-webkit-scrollbar-track {
    box-shadow: none !important;
    background-color: #ffffff;
  }

  .label-primary-date {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #202529;
    width: max-content;
    &.start-date {
      transform: translateX(calc(50% - 1rem));
    }
    &.end-date {
      min-width: fit-content;
      margin-right: auto;
      transform: translateX(calc(-50% + 1rem));
    }
    .value {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .primary-service-info {
    margin-right: 8px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #6b6c70;
      width: max-content;
    }
    .service-name {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      width: max-content;
    }
    .icon-box {
      margin: 8px 0;
      padding: 8px;
      width: $size-icon-box;
      height: $size-icon-box;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.material-service {
        background: $material-service-color;
      }
      &.people-service {
        background: $people-service-color;
      }
      &.marine-service {
        background: $marine-service-color;
      }
      &.supply-service {
        background: $supply-service-color;
      }
      &.other-service {
        background: $other-service-color;
      }
      svg {
        width: 24px;
        path {
          stroke: #202529;
        }
      }
    }
  }

  .timline-bar {
    display: flex;
    position: relative;
    height: $height-timeline-bar;
    width: fit-content;
    border-radius: 4px;
    margin: 0 8px;
    margin-bottom: 16px + 8px + $size-icon-box/2;

    &.material-service {
      background: $material-service-color;
      position: relative;
      .month-item {
        &:not(:first-child) {
          &::before {
            content: "";
            height: $height-timeline-bar;
            width: 1px;
            top: 3px;
            left: -1px;
            position: absolute;
            background: $material-service-color;
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        right: -8px;
        top: 0;
        transform: translateX(100%);
        width: $height-timeline-bar;
        height: $height-timeline-bar;
        border-radius: 50%;
        background: $material-service-color;
      }
    }
    &.people-service {
      background: $people-service-color;
      position: relative;
      .month-item {
        &:not(:first-child) {
          &::before {
            content: "";
            height: $height-timeline-bar;
            width: 1px;
            top: 3px;
            left: -1px;
            position: absolute;
            background: $people-service-color;
          }
        }
      }
      &::after {
        content: "";
        position: absolute;
        right: -8px;
        top: 0;
        transform: translateX(100%);
        width: $height-timeline-bar;
        height: $height-timeline-bar;
        border-radius: 50%;
        background: $people-service-color;
      }
    }
    &.marine-service {
      background: $marine-service-color;
      position: relative;
      .month-item {
        &:not(:first-child) {
          &::before {
            content: "";
            height: $height-timeline-bar;
            width: 1px;
            top: 3px;
            left: -1px;
            position: absolute;
            background: $marine-service-color;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        right: -8px;
        top: 0;
        transform: translateX(100%);
        width: $height-timeline-bar;
        height: $height-timeline-bar;
        border-radius: 50%;
        background: $marine-service-color;
      }
    }
    &.supply-service {
      background: $supply-service-color;
      position: relative;
      .month-item {
        &:not(:first-child) {
          &::before {
            content: "";
            height: $height-timeline-bar;
            width: 1px;
            top: 3px;
            left: -1px;
            position: absolute;
            background: $supply-service-color;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        right: -8px;
        top: 0;
        transform: translateX(100%);
        width: $height-timeline-bar;
        height: $height-timeline-bar;
        border-radius: 50%;
        background: $supply-service-color;
      }
    }
    &.other-service {
      background: $other-service-color;
      position: relative;
      .month-item {
        &:not(:first-child) {
          &::before {
            content: "";
            height: $height-timeline-bar;
            width: 1px;
            top: 3px;
            left: -1px;
            position: absolute;
            background: $other-service-color;
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        right: -8px;
        top: 0;
        transform: translateX(100%);
        width: $height-timeline-bar;
        height: $height-timeline-bar;
        border-radius: 50%;
        background: $other-service-color;
      }
    }
    .month-item {
      position: relative;
      height: $height-timeline-bar + 6px;
      border-left: 1px solid #a4a7b2;
      transform: translateY(-3px);
      &:first-child {
        border: none;
        > .month-box {
          > .label-date {
            display: none;
          }
        }
      }
      .month-box {
        position: relative;

        .label-date {
          position: absolute;
          font-size: 12px;
          color: $secondary-color;
          text-align: center;
          min-width: 50px;
          top: -1.5rem;
          transform: translateX(-22px);
        }
      }
    }
    .secondary-item {
      position: absolute;
      top: 0;
      bottom: 0;
      border-radius: 4px;
      cursor: pointer;
      &.material-service {
        background: $material-service-color;
      }
      &.people-service {
        background: $people-service-color;
      }
      &.marine-service {
        background: $marine-service-color;
      }
      &.supply-service {
        background: $supply-service-color;
      }
      &.other-service {
        background: $other-service-color;
      }
    }
  }
}

.contract-popup {
  position: absolute;
  &.direction-up{
    transform: translate(-50%, calc(-100% - 70px));
    img {
      margin-top: 7px !important;
      position: absolute !important;
      display: block;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.direction-down{
    transform: translateX(-50%) translateY(calc(45%));
    img {
      margin-top: 7px !important;
      position: absolute !important;
      display: block;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}