.active-log-reason, .active-log-comment {
    .label-text {
        font-weight: 600;
        color: #5A5A5A;
        float: left;
    }

    .close-method-icon {
        position: absolute;
        right: 20px;
    }

    .reason-dropdown {
        width: 100% !important;
    }
    .ui.selection.dropdown > .dropdown.icon, .ui.selection.dropdown > .search.icon {
        line-height: 0;
        margin-top: -3px;
        margin-right: -10px;
        padding: 3px;
        color: rgba(0, 0, 0, 0.54)
    }
}

.mt {
    margin-top: 12px;
}

@media (max-width: 768px) {
    .add-reason-container {
        .title {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A) !important;
            /* Paragraph/Paragraph 3/Regular */
            font-family: Inter !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 22px !important; /* 157.143% */
        }

        .reason-dropdown {
            padding-top: 15px;
        }
    }

    .comment-container {
        margin-top: 20px;

        .comment-title {
            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A) !important;
            /* Paragraph/Paragraph 3/Medium */
            font-family: Inter !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 22px !important; /* 157.143% */
        } 

        .comment-box {
            border-radius: 8px !important;
            border: 1px solid var(--independence-grey-light-n-40, #D9D9E3) !important;
            background: var(--shades-white, #FFF) !important;
        }
    }

    .btn-container {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 20px;

        .btn-cancel {
            border-radius: 6px;
            border: 1px solid var(--duke-blue-p-300-main, #0000A1);
            background: var(--shades-white, #FFF);

            .value {
                color: var(--duke-blue-p-300-main, #0000A1);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 150% */
            }
        }

        .btn-submit {
            border-radius: 6px;
            background: var(--duke-blue-p-300-main, #0000A1) !important;

            .value {
                color: var(--shades-white, #FFF);
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px; /* 150% */
            }
        }
    }
}