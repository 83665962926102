.voyage-detail-box {
  position: fixed;
  z-index: 992;
  top: 120px;
  left: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 4px;
  width: 568px;
  .left-box {
    background: #f5f7fe;
  }
  .right-box {
    background: #fff;
  }
  .header-box {
    min-width: 400px;
    margin: 0;

    .left-box {
      padding: 16px 0 0 24px !important;
      .header-box--title {
        font-family: "Open Sans", sans-serif !important;
        font-weight: 600;
        font-size: 16px !important;
        letter-spacing: 0.04em;
        color: #000000;
      }
    }
    .right-box {
      text-align: end;
      padding: 16px 24px 0 0 !important;
      .close-icon {
        cursor: pointer;
      }
    }
  }
  .footer {
    overflow: hidden;
    height: 74px;
    .right-box {
      border-top: solid #E8E8EE 1px;
      display: flex !important;
      flex-direction: column;
      justify-content: space-around;
    }
    .left-box {
      display: flex !important;
      flex-direction: column;
      justify-content: space-around !important;
      .back-btn {
        font-size: 12px;
        color: #0000A1;
        background: transparent;
        margin-left: -50px;
      }
    }
  }
  .content-box {
    margin: 0 !important;
    .field-group {
      margin-top: 25px !important;
      .label-field {
        margin-bottom: 8px;
        font-size: 12px;
      }
      .value-field {
        font-size: 12px;
        font-weight: bolder;
      }
    }
    .left-box {
      padding: 0 24px 16px 24px !important;
      display: flex !important;
      flex-direction: column;
      justify-content: space-between;
    }
    .right-box {
      padding-right: 24px !important;
      .voyage-description {
        display: flex !important;
        flex-wrap: wrap;
        align-content: flex-start;
        padding-bottom: 24px;
        overflow-y: auto;
        max-height: 45vh;
        &::-webkit-scrollbar {
          width: 8px!important;
          background: none !important;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #CACAD8!important;
        }
        &::-webkit-scrollbar-track {
          background: none !important;
        }
        .field-group {
          text-align: left;
          &.col-6 {
            width: 50%;
          }
          &.col-12 {
            width: 100%;
          }
          .value-field {
            .next-locaton--time {
              color: #6b6c70;
              margin-left: 5px;
            }
            &.rating {
              display: flex;
              align-items: center;
            }
            .count-rating {
              color: #6b6c70;
              margin: 0 5px;
              font-weight: 400;
            }
            .rating-btn {
              color: #0000A1;
              cursor: pointer;
            }
          }
        }
      }
      
      .btn-container {
        display: flex;
        justify-content: space-around;

        .current-schedule {
          background-color: #0000A1;
          color: #ffffff;
          border-radius: 6px;
        }
  
        .past-schedule {
          background-color: #ffffff;
          color: #0000A1;
          border-radius: 6px;
          border: 1px solid #0000A1;
        }
      }
      }
    }
  }

@media (max-width: 768px) { 
  .voyage-detail-box {
    display: none;
  }
}

.mobile-live-location-popup {
  position: fixed;
  z-index: 991;
  top: 52.5%;
  border-radius: 4px;
  width: 60%;
  left: 5%;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  text-align: center;
  .triangle-icon {
    margin-top: -95px;
    margin-left: 60%;
    color: rgba(255, 255, 255, 0.9);
  }
  .vessel-container {
    margin-bottom: 20px;
    
    .title-container {
      min-width: 100%;
  
      .left-box {
        display: flex;
        .vessel-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .vessel-title {
          font-family: Inter;
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: right;
          color: #000000;
        }
      }
      .right-box {
        text-align: end;
        .close-icon {
          cursor: pointer;
        }
      }
    }
    .vessel-details {
      margin-left: 25px;
      margin-top: 10px;
      text-align: left;
      .vessel-details-text {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        text-align: left;
        color: #000000;
      }
    }
  }
  .live-loacation-btn {
    width: 100%; 
    background-color: #0000A1;
    color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    padding: 10px 24px;
    gap: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
} 

.mobile-voyage-detail-box {
  margin-top: 30px !important;

  .field-group {
    margin-bottom: 10px!important;
    margin-top: 10px;
    .label-field {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #757595;
    }
    .value-field {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #242433;
    }
  }
  .btn-container {
    display: flex;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    margin-top: 10px;

    .current-schedule {
      width: 45% ; 
      background-color: #0000A1;
      color: #ffffff;
      border-radius: 8px;
      font-weight: 600;
      padding: 10px 24px;
      gap: 8px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
    }
    .past-schedule {
      width: 45%;
      background-color: #FFFFFF;
      color: #0000A1;
      border-radius: 8px;
      border: 1px solid #0000A1;
      padding: 10px 24px;
      gap: 8px;
      font-family: Inter;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
    }
  }
  .live-loacation-btn {
    width: 100%; 
    background-color: #0000A1;
    color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    padding: 10px 24px;
    gap: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
}
.current-voyage {
  background-color: $color-blue-lighter;
  padding-bottom: $rem-20;
  border-collapse: separate;

  span {
    display: block;
    font-size: $fontsize-12 !important;
  }

  .font-bold {
    font-weight: $fontweight-600;
  }

  .label {
    color: $color-warm-grey;
    margin-bottom: $rem-4;
  }

  tr {
    td:first-child() {
      display: flex;
      flex-direction: column;

      span {
        margin-bottom: -$rem-8;
      }

      .icon {
        margin-left: $rem-10;
        background-color: $color-white;
      }
    }

    td:last-child() {
      display: flex;
      flex-direction: column;
      align-items: end;

      border-collapse: collapse;

      span {
        margin-bottom: $rem-16;
      }
    }
  }

  svg.close > line {
    stroke: $color-primary;
  }

  .action {
    color: $color-primary;
    background-color: transparent;
    text-align: end;

    padding-right: 0 !important;
    padding-bottom: 0;
  }
}

.current-schedule-modal {
  width: 85% !important;
}
