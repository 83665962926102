.explorepage {
  .temp-map-container {
    width: 100%;
    height: 100%;
    position: absolute;
    
  }
  .search-container {
    margin-top: 6rem;
    width: 100%;
    justify-content: center;
    z-index: 999;
  }
  .input-unit {
    width: 400px;
    margin-left: 2rem;
  }
  .kpi-overview {
    z-index: 998;
    background-color: #ffffff;
    top: 180px;
    position: fixed;
    left: 0px;
    transition: left 0.3s;
    width: 200px;
    padding-top: 0;
    align-items: center;
    padding-left: 16px;
    height: 40px;
    cursor: pointer;
    .title {
      font-family: $font-normal !important;
      font-weight: $fontweight-600;
      font-size: $fontsize-14;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #000000;
    }
    .content-align {
      align-items: center;
    }
  }
  .timeline-speed {
    z-index: 700;
    background-color: #ffffff;
    top: 280px;
    position: fixed;
    left: 0px;
    transition: left 0.3s;
    width: 200px;
    padding-top: 0;
    align-items: center;
    padding-left: 16px;
    height: 100px;
    cursor: pointer;
    .title {
      font-family: $font-normal !important;
      font-weight: $fontweight-600;
      font-size: $fontsize-14;
      line-height: 16px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #000000;
    }
  }
}
.actual-vessel-position {
  margin-top: -33px;
  margin-left: -10px;
}
.planned-vessel-position {
  opacity: 0.5;
  margin-top: -33px;
  margin-left: -10px;
}
