.search-container {
    display: flex;
    justify-content: center;
    margin-left: 40px;
    padding-left: 8px;
    padding-right: 8px;

    border-radius: 8px;
    border: 1px solid var(--Independence-Grey-Light-N40, #D9D9E3);
    background: var(--Shades-White, #FFF);

    width: 420px;

    .icon {
        cursor: pointer;
    }

    .search-input {
        width: 350px;
        .ui.input > input {
            border: 0px;
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}