.schedule-container {
  width: 100% !important;
  margin-left: -5px;
  .schedule-title {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin: 20px 0;
    color: #242433;
  }
  .schedule-content {
    width: 100%;
    margin-top: 20px;
  }
}
