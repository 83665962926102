@mixin hover-add-voyage {
  visibility: visible;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  display: flex;
  color: #0000a1;
  font-weight: 800;
}

.vessel-schedule-gantt-container {

  .left-content {
    flex: 1;
  }

  .right-content {
    margin: auto;
  }

  .day-title,
  .vessel-title {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 200px;
    width: 202px;
    height: 72px;
    // padding: 8px;
    background: #f5f7fe;

    margin-right: 1px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #6c696f;
    // text-transform: capitalize;

    &.error {
      background: #fee7e7;
    }

    .vessel-title-href {
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #202529;

      &:hover {
        color: #0000a1;
      }

      .vessel-title-href-filler {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    span {
      font-weight: normal;
      font-size: 10px;
      margin: 0 5px;
    }
  }


  .footer-voyage {
    position: relative;
    width: 100%;
    height: 10vh;
    background-color: #e6dcff;
    cursor: pointer;
    z-index: 2;

    &:hover {
      background-color: #e6dcff;
    }

    @include hover-add-voyage;
  }
}

.gantt-sidebar-modal {
  width: 50% !important;

  .ui.disabled.dropdown,
  .ui.dropdown .menu > .disabled.item {
    cursor: default;
    pointer-events: none;
    background: 0.45 !important;
    opacity: 1 !important;
  }

  &.gantt-sidebar-vessel-unavail-modal {
    .modal-header {
      padding: 28px 28px 3px 28px !important;
    }

    .gantt-sidebar-modal-dropdown {
      background-color: #fff !important;
    }
  }

  .modal-contanier {
    .modal-header {
      &.Material {
        border: 4px solid #fbe165;
        border-bottom: 1px solid #fbe165;
        background-color: #fbe165;
      }

      &.People {
        border: 4px solid #c4dfd7;
        border-bottom: 1px solid #c4dfd7;
        background-color: #c4dfd7;
      }

      &.Marine {
        border: 4px solid #aed2f2;
        border-bottom: 1px solid #aed2f2;
        background-color: #aed2f2;
      }

      &.vessel-unavailable-error {
        border: 4px solid #f8d7da;
        border-bottom: 1px solid #f8d7da;
        background-color: #f8d7da;
      }
    }
  }

  .modal-header {
    padding: 32px 32px 0 32px !important;

    .label-header {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      color: #09010e;
    }

    .modal-header-right {
      margin-left: auto;
    }

    .modal-close-button {
      position: absolute;
      top: 14px;
      right: 14px;
    }
  }

  .medium-text-black {
    font-weight: 400 !important;
  }

  .medium-text-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  .larger-medium-text-black {
    font-size: 16px;
    line-height: 21px;
    color: #202529;
    padding: 5px 0;
  }

  .larger-medium-text-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #202529;
    padding: 5px 0;
  }

  .modal-sub-header {
    padding: 24 32px !important;

    .modal-sub-header-bar-container {
      width: 280px;
    }
  }

  .asterisk-header {
    padding: 10px 32px !important;
  }

  .bar {
    .bartext {
      div {
        display: inline-block;

        &:first-child {
          width: 70%;
          text-align: left;
        }

        &:last-child {
          width: 30%;
          text-align: right;
        }
      }
    }

    .progress-content {
      position: relative;

      .benchmark {
        position: absolute;
        width: 3px;
        height: 15px;
        margin-top: -4px;
        background: #d4d4d4;
        margin-left: auto;
        margin-right: auto;
        left: 30%;
        z-index: 1;
      }

      .progress {
        overflow: hidden;
        background-color: #e9ecef;
        position: relative;

        .progress-bar {
          &.green {
            background-color: #2bcf9e;
          }

          &.red {
            background-color: #ef5d60;
          }
        }
      }
    }
  }

  .modal-content-header {
    margin: 24px 24px 22.5px 40px;

    .modal-content-header-half {
      width: 50%;

      &.modal-content-header-half-right {
        text-align: right;
      }
    }

    .modal-content-header-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6b6c70;
    }
  }

  &.ui {
    .add-voyage-container {
      .scrolling {
        &.content {
          height: calc(100vh - 360px);
        }
      }
    }

    .kpi-hidden {
      .scrolling {
        &.content {
          height: calc(100vh - 335px);
        }
      }
    }

    .unavailable-voyage-modal-container {
      .scrolling {
        &.content {
          height: calc(100vh - 379px);
        }
      }
    }

    .scrolling {
      &.content {
        height: calc(100vh - 440px);
      }
    }
  }

  .accordions-container {
    position: relative;
    width: 100%;
  }

  .accordion-side-line {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 35px;
    width: 2px;
  }

  .dragable-accordions-container {
    margin-left: 25px;
  }

  .fixed-accordions-container {
    margin-left: 25px;

    .accordion {
      .accordion-header {
        background-color: #ebecf3;
      }
    }
  }

  .accordion-row {
    left: 25px !important;
    // margin: 0px 8px;
    //Disable Grey Bullet hover effect
    // &:hover{
    //     .accordion-bullet{
    //         &.grey-bullet{
    //           height: 12px;
    //           margin-top: 21px;
    //           border-left: 0;
    //           border-right: 0;
    //           border-radius: 0;
    //         }
    //     }
    // }
  }

  .accordion-bullet-container {
    position: relative;
    width: 22px;

    .accordion-bullet {
      position: relative;
      top: 16px;
      width: 22px;
      height: 22px;
      background-color: #fff;
      border: 2px solid #fff;
      border-radius: 50%;

      &.dark-blue-circle {
        border-color: #4a5496;

        .accordion-bullet-inner {
          &.accordion-bullet-inner-departed {
            background-color: #4a5496;
          }
        }
      }

      &.grey-circle {
        border-color: #bab3be;
      }

      .accordion-bullet-inner {
        position: relative;
        width: 18px;
        height: 18px;
        background-color: #fff;
        margin: auto;
        border: 3px solid #fff;
        border-radius: 50%;

        &.accordion-bullet-inner-departed {
          background-color: #eaeaea;
        }
      }
    }

    .accordion-bullet-line {
      position: absolute;
      top: 0px;
      left: 10px;
      width: 2px;

      &.top-accordion-bullet-line {
        top: 38px;
        bottom: 0px;
      }

      &.mid-accordion-bullet-line {
        bottom: 0;
      }

      &.accordion-bullet-line-botless {
        height: 38px;
      }

      &.last-accordion-bullet-line {
        top: 16px;
        bottom: 0px;
        background-color: #fff !important;
      }

      &.last-accordion-bullet-line-2 {
        top: 0px;
        height: 16px;
        background-color: #bab3be;
      }

      &.accordion-bullet-line-departed {
        background-color: #bab3be;
      }
    }
  }

  .accordion {
    position: relative;
    left: 1rem !important;
    width: 800px;
    // will-change: unset;

    .accordion-header {
      position: relative;
      width: 100%;
      padding: 16px;
      background-color: #f5f7fe;
      border-radius: 8px 8px 0px 0px;

      &.location-visited {
        background-color: #fff8f8;
      }
    }

    .accordion-header-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      align-self: center;
    }

    .accordion-header-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #4a5496;
      background: #ffffff;
      padding: 4px 8px;
      border: 1px solid #c4c8df;
      border-radius: 4px;
      min-inline-size: max-content;
    }

    .accordion-header-subtitle {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      width: 100%;

      &.error {
        color: #ef5d60;
      }
    }

    .accordion-right {
      width: 100%;
      margin-left: auto;
      float: right;
      text-align: end;
      align-items: center;
      align-self: center;

      img {
        margin-right: 5px;
      }

      .error {
        color: #ef5d60;
      }
    }

    .accordion-header-expand-toggle {
      margin-left: auto;
    }

    .accordion-header-expand-toggle-button {
      &.button {
        padding: 0;
        background: none;
        background-color: transparent;
      }
    }

    .accordion-header-expand-icon {
      &.inverted {
        transform: rotate(180deg);
      }
    }

    .accordion-body {
      position: relative;
      width: 100%;
      padding: 0 32px;
      background-color: #fbfcff;

      &.hide {
        display: none;
      }
    }

    .accordion-head-body-section {
      position: relative;
      width: 100%;
      padding: 32px 0;

      &:last-child() {
        border: none;
      }
    }

    .accordion-body-section {
      position: relative;
      width: 100%;
      // border-bottom: 1px solid #e0e1e8;
      padding: 32px 0;

      &:last-child() {
        border: none;
      }
    }

    .accordion-alert-section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;

      .alert-item {
        background: #fff3f3;
        border-radius: 8px;
        position: static;
        width: 735px;
        height: 85px;
        left: 32px;
        top: 126px;
        font-size: 14px;
        padding: 1rem;

        .alert-item-header {
          font-weight: 600;
          line-height: 14px;
          color: #ef5d60;
          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 8px;
        }

        .align-middle {
          vertical-align: middle !important;
        }

        .alert-image {
          padding: 5px;
        }

        .alert-item-body {
          font-weight: 400;
          line-height: 21px;
        }
      }
    }

    .accordion-body-content-half {
      width: 50%;
    }

    .accordion-body-50 {
      width: 50%;
    }

    .accordion-body-content-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 8px;
    }

    .accordion-body-content-lg-label {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 24px;
    }

    .accordion-input-long {
      width: 350px !important;
      height: 40px;
      border-radius: 4px;

      &.accordion-border-black-1 {
        border: 1px solid #202529;
      }

      &.accordion-border-red-1 {
        border: 1px solid red;
      }
    }

    .accordion-input-medium {
      width: 235px !important;
      height: 40px;
      border-radius: 4px;

      &.accordion-border-black-1 {
        border: 1px solid #202529;
      }
    }

    .accordion-input-max {
      width: 100% !important;
      min-width: 100%;
      height: 40px;
      border-radius: 4px;

      &.accordion-border-black-1 {
        border: 1px solid #202529;
      }
    }

    .accordion-under-deck-row {
      width: 100%;
      margin-bottom: 16px;

      &:nth-child(2) {
        margin-bottom: 0;
      }
    }

    .accordion-under-deck-lg {
      width: 170px;
    }

    .accordion-under-deck-md {
      width: 95px;
    }

    .accordion-under-deck-sm {
      width: 20px;
    }
  }

  .accordion-speed-data-row {
    position: relative;
    left: -16px;
    margin-top: 11.5px;

    .accordion-speed-data-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #6b6c70;

      .accordion-speed-data-diff {
        color: #a33e3b;
      }
    }
  }

  .modal-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding: 24px;
    border-top: 1px solid #e0e1e8;

    .modal-footer-right {
      margin-left: auto;
    }

    .ui.button {
      &.primary {
        background: #0000a1;
        color: #fff;
      }

      &.secondary {
        background: #fff;
        color: #0000a1;

        &.disabled {
          border-color: transparent;
          color: #bab3be;
        }
      }
    }
  }
}

.ui {
  &.page {
    &.modals {
      z-index: 9998;
    }
  }

  &.popup {
    &.visible {
      z-index: 9998;
    }
  }
}
