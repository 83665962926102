.reservation-details-page {
  .breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .orange-text {
      cursor: pointer;
      color: #d0460b;
    }
    .arrow-right-icon {
      height: 18.9px;
    }
    .orange-icon {
      svg path {
        fill: #d0460b;
      }
    }
    .voyage-id {
      display: flex;
      align-items: center;
    }
  }
  .reservation-detail {
    fill: #242433;
    font-weight: 500;
    font-size: 34px;
    margin-bottom: 40px;
  }
  .reservation-id {
    color: #4b7093;
  }
  .container-title {
    font-weight: 600;
    font-size: 26px;
    margin: 2rem 1rem;
  }
  .reservation-card-content {
    .reservation-card-top {
      padding: 16px 24px;
      .voyage-route {
        margin-top: 7px;
        gap: 5px;
        .arrow-right {
          margin-top: -5px;
          scale: 0.7;
        }
        .voyage-group {
          display: flex;
          font-weight: 600;
          .voyage-detail {
            display: flex;
            gap: 5px;
            svg path {
              transform: scale(0.7);
              stroke: #9e9eb7;
            }
          }
        }
      }
    }
    .reservation-card-bottom {
      .reservation-details-container {
        .location-title {
          margin-top: -24px;
          margin-bottom: 16px;
        }
        .date-time {
          margin-bottom: 8px;
        }
      }
    }
    .reservation-label {
      padding: 16px !important;
    }
    .btn-ghost {
      border-color: transparent !important;
    }
  }
  .finalised-tab {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 32px;
    .tab-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      .header-name {
        font-size: 26px;
        font-weight: 500;
        .text-blue {
          color: #4b7093;
        }
      }
      .header-suggest {
        color: #5c5c7a;
      }
      .header-button {
        color: #d0460b;
        cursor: pointer;
      }
    }
  }

  .reservation-load-information-container {
    background: #ffffff;
    padding: 10px 0px 64px;
    border-radius: 8px;
    margin: 3rem 0;
    .table {
      .deck-header {
        background-color: #dae3ec;
        padding: 10px 16px 5.8px 16px !important;
        width: 16.67%;
        .header-title {
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          .sort-icon {
            height: 14px;
            transform: translateX(2px);
          }
          .hidden {
            display: none;
          }
        }

        .dropdown {
          display: flex;
          justify-content: space-between;
          min-width: 0;
          border: none;
          box-shadow: none;
          padding: 10px 0 0 0;
          margin-top: 13px;
          input {
            padding: 10px 0;
            width: 90%;
          }

          i.icon.angle.down:before {
            margin-left: 0.6rem;
          }
        }
      }
      .deck-item {
        padding-left: 0;
        vertical-align: middle;
      }
      .body-item {
        padding: 0.5rem 0 0.5rem 1rem;
        align-items: center;
        .circle {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
        .green {
          background-color: #2fd08d;
        }
        .red {
          background-color: #e74c3b;
        }
        .deck-header-dropdown {
          display: flex;
          justify-content: space-between;
          width: 60px;
          padding: 5px;
          align-items: center;
          border: solid 1px #d9d9e3;
          border-radius: 4px;
        }
      }
    }
    .pagination-section {
      margin-top: 50px;
      position: relative;
      float: right;
      b {
        font-weight: 600;
      }
      .pagination {
        margin: 0 48px;
        .active {
          border-radius: 50% !important;
          background-color: #242433 !important;
        }
      }
      .pagination-btn {
        margin: 0 10px;
        color: #5c5c7a;
      }
    }
  }

  .visit-plt {
    font-size: 14px;
    font-weight: 400;
    color: #5c5c7a;
    margin: 34px 0;
    .alignright {
      color: #0000a1;
      font-weight: 600;
      margin-left: -2px;
      cursor: pointer;
    }
  }
}

.voyage-detail-page-modal {
  width: 670px !important;
  .header {
    height: 88px !important;
    background-color: #fef2f1 !important;

    .title {
      color: #e74c3b;
      font-size: 26px;
      font-weight: 500;
      line-height: 44px;
    }
  }
  .content {
    height: 296px !important;
    padding: 32px 40px 48px !important;
    div {
      font-size: 16px;
    }
    .grey {
      color: #5c5c7a;
    }
    .button-container {
      margin-top: 100px;
      float: right;
      .cancel {
        margin-right: 50px;
        color: #0000a1;
        font-weight: 600;
        cursor: pointer;
      }
      .confirm {
        color: #0000a1;
        font-weight: 600;
        border: solid 1px;
        background: none;
        border-radius: 8px;
      }
      .confirm:hover {
        background-color: #0000a1;
        color: white;
      }
    }
  }
}
