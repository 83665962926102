.new-ui-performace-rewards-container {
    border-radius: 16px;
    background: var(--shades-white, #FFF);
    padding-bottom: 52px;
    margin-bottom: 32px;

    .header-container {
        display: flex;
        align-items: center;

        margin-top: 32px;

        .title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Heading/Heading 4/Medium */
            font-family: Inter;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px; /* 130.769% */

            margin-top: 32px;
            margin-left: 22px;
        }

        .sub-title {
            color: var(--independence-grey-dark-n-800-main-text, #242433);
            /* Paragraph/Paragraph 2/Regular */
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */

            margin-top: 35px;
            margin-left: 16px;
        }
    }

    .body {
        display: flex;
        gap: 40px;
        justify-content: space-between;

        margin-top: 32px;

        .table-container {
            margin-left: 22px;
            width: 50%;
            max-height: 400px;
            scroll-behavior: auto;
            overflow-y:auto;
            .table-main {
                padding: 5px;
                padding-right:  10px;
                .table-header {
                    height: 78px;
                    background: #DAE3EC;
                    z-index: 198 !important;
                    .table-header-title {
                        margin: 0 auto;
                        text-align: left;
                        vertical-align: middle;
                        background-color: #DAE3EC;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #262626;
                        cursor: pointer;
                        .table-header-title-with-icon {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                        }
                        .table-header-sub-title {
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #5C5C7A;
                        }
                    }
                }
                .table-body {
                    height: 70px;
                    background: white;
                    cursor: pointer;
                    .active-cell {
                        height: 100%;
                        background: #F0F0FA;
                    }
                    .table-cell-title {
                        margin: 0 auto;
                        text-align: left;
                        vertical-align: middle;
                        font-family: Inter;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #242433;
                        .table-cell-sub-title {
                            font-family: Inter;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 22px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #5C5C7A;
                        }
                    }
                }
                
            }
        }

        .performance-container {
            display: flex;
            margin-right: 22px;

            width: 50%;
            height: 424px;

            border-radius: 4px;
            border: 1px solid var(--independence-grey-light-n-40, #D9D9E3);
            background: var(--shades-white, #FFF);

            /* Drop Shadow (Light Background)/Large */
            box-shadow: 0px 32px 64px 0px rgba(0, 0, 161, 0.08);

            .new-ui-custom-slider .slider-direction-container {
                margin-top: 2px;
                margin-right: 0px;
            }

            .testtes {
                display: flex !important;
            }

            .overall-score-container {
                display: flex;
                flex-direction: column;

                width: 45%;

                margin-top: 24px;
                margin-left: 24px;

                .title {
                    color: var(--queen-blue-300-main, #4B7093);
                    /* Paragraph/Paragraph 2/Medium */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 150% */

                    margin-bottom: 28px;
                }

                .item {
                    display: flex;

                    margin-bottom: 40px;

                    .details {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        margin-left: 16px;

                        .value {
                            color: var(--mantis-green-p-500, #409B31);
                            /* Heading/Heading 3/Medium */
                            font-family: Inter;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 38px; /* 126.667% */
                        }

                        .text {
                            color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                            /* Paragraph/Paragraph 2/Regular */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px; /* 150% */
                        }
                    }
                }
            }

            .your-performance-container {
                display: flex;
                flex-direction: column;

                width: 55%;

                margin-top: 24px;
                margin-right: 24px;
                margin-left: 32px;

                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    margin-bottom: 10px;

                    .ranger-label-container {
                        display: flex;

                        color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
                        /* Paragraph/Paragraph 3/Regular */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px; /* 157.143% */
                    
                        .ranger-label-toogle {
                          margin-left: 4px;
                          margin-top: 2px;
                        }
                      }
                }

                .title {
                    color: var(--queen-blue-300-main, #4B7093);
                    /* Paragraph/Paragraph 2/Medium */
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 150% */

                    margin-bottom: 28px;
                }
            }
        }
    }
}