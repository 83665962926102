.ui.header {
  font-family: 'IBM Plex Sans';
}

.ui.small.header {
  letter-spacing: 0.15em;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

h5.ui.header {
  font-weight: normal;
  font-size: 32px;
  line-height: 42px;
  padding: 19px 16px;
}


#bg-text {
  color: grey;
  font-size: 76px;
  transform: rotate(300deg);
  -webkit-transform: rotate(332deg);
}

.sidebarClass {
  color: white;
}

.guideMeThorugh {
  background-color: #7030a0;
  color: white;
  font-size: 10px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 50px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  text-transform: uppercase;
}

.customButtonDashboard {
  background-color: rgba(81, 28, 120, 1) !important;
  color: rgba(255, 255, 255, 1) !important;
  border-radius: 25px !important;
  width: 300px;
  height: 100px;
  opacity: '0.6' !important;
  text-decoration: underline !important;
}

.customButtonCIDashboard {
  background-color: white !important;
  color: #7030a0 !important;
  border-radius: 25px !important;
  width: 400px;
  height: 200px;
}

.impNote {
  color: #D8000C !important;
  background-color: #FFD2D2 !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.required {
  color: #D8000C !important;
}

/* ################ */

.agreeButton {
  background-color: #7030a0 !important;
  color: white !important;
  height: 80px !important;
  width: 120px !important;
  border-radius: 25px !important;
}

.disAgreeButton {
  background-color: #d8d8d8 !important;
  color: black !important;
  height: 80px !important;
  width: 120px !important;
  border-radius: 25px !important;
}

.bannerHeader {
  background-color: #7030a0 !important;
  color: white !important;
  border-radius: 15px !important;
  text-align: center;
  font-size: 16px !important;
}

.secondaryDiv {
  width: 1440px !important;
  height: 100% !important;
  position: relative !important;
  margin: auto !important;
}

.dashboardCard {
  width: 100% !important;
  min-height: 150px !important;
}

.dashboardCardDeputy {
  background-color: #f2f5fa !important;
  opacity: 0.5 !important;
}

.dashboardCardButton {
  color: white !important;
  background-color: #702765 !important;
  border-radius: 10px !important;
  font-size: 14px;
}

.dashboardCardMeta {
  color: #04081A !important;
  font-size: 14px !important;
  opacity: 0.4 !important;
}

.dashboardCardHeader {
  color: #0f216d !important;
  font-size: 24px !important;
}

.dashboardCardTitle {
  color: #565C66 !important;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

.dashboardBackButton {
  color: white !important;
  background-color: #ACB8CC !important;
  border-radius: 10px !important;
  font-size: 14px;
}

.progressBar {
  border-radius: '20' !important;
  background-color: #F2F5FA !important;
}

.progressBar {
  background-color: grey !important;
}

.MuiLinearProgress-root {
  height: 20px !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #702765 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #A43947 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #A43947 !important;
}

.MuiStepConnector-lineHorizontal {
  border-top-style: solid !important;
  border-top-width: 3px !important;
}

.dashboardDeputyPcard {
  color: white !important;
  background-color: #e64602 !important;
  border-radius: 50px !important;
  font-size: 18px;
  text-align: center;
}

.dashboardCardHeaderDeputy {
  color: #0f216d !important;
  font-size: 16px !important;
}

.MuiAppBar-colorPrimary {
  color: black !important;
  background-color: white !important;
}

.radioLabel {
  color: white;
  font-size: 24px;
  line-height: 31px;
  padding-right: 30px;
  cursor: pointer;
}

.container2:hover .overlay2 {
  opacity: 1;
}

.overlay2 {
  position: absolute;
  top: 15px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: white;
}

.custom-control-input {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: white;
}

.searchBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--secondary);
  padding: 30px 40px 30px 40px;
}

.uppercase {
  text-transform: uppercase;
}