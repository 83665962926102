.search-input-wrapper {
  position: relative;
  .results {
    width: 400px !important;
    max-height: 426px;
    overflow-y: scroll;
  }

  &.vessel-management-search {
    width: 380px;
    input {
      border-radius: 4px 0 0 4px !important;
    }
  }
}

