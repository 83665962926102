.settings-page {
  padding-top: 160px;
  margin: auto;
  width: 80%;
  
  .title {
    font-size: 34px;
    font-weight: 500;
    color: #242433;
  }

  .settings-page-content {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .upload-section {
      width: 60%;
      .sub-title {
        font-size: 18px;
        font-weight: 500;
        color: #4B7093;
      }

      .upload-file-area { 
        background-color: #F9F9FB;
        border-radius: 16px;
        padding: 32px;
        margin-top: 15px;

        .header {
          font-weight: 500;
          color: #5C5C7A;
        }

        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px;
          .upload-instruction {
            color: #757595;
            margin-top: 10px;
          }
          .upload-btn {
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            width: 140px;
            height: 40px;
            text-align: center;
            background-color: #0000A1;
            color: #ffffff;
          }
        }

        .drop-area {
          display: flex;
          justify-content: space-around;
          align-items: center;
          border-radius: 2px;
          border: dashed 2px #E8E8EE;
          background-color: #FFFFFF;
          margin-top: 10px;
          height: 196px;

          .error {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
          }

          .upload-icon {
            display: flex;
            justify-content: space-around;
          }

          .instruction {
            margin-top: 10px;
            font-size: 16px;
            color: #242433;
          }

          .browse {
            color: #D0460B;
            font-size: 16px;
            font-weight: 500;
          }

          .attach-message {
            display: flex;
            justify-content: center;
            flex-direction: column;
          }

          .message-title {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-left: 50px;

          }

          .success-message {
            font-size: 18px;
            font-weight: 500;
            color: #26A671;
          }

          .file-size {
            font-weight: 500;
          }

          .remove-btn {
            text-align: center;
            cursor: pointer;
            font-weight: 600;
            color: #0000A1;
          }

          .error-message {
            display: flex;
            align-items: center;
            gap: 10px;
          }

          .error-message-title {
            font-size: 18px;
            font-weight: 500;
            color: #C93626;
          }
        }
      }
    }

    .upload-history {
      width: 30%;
      .upload-title {
        font-weight: 500;
        font-size: 18px;
        color: #4B7093;
      }

      .user-guide-item {
        margin-bottom: 30px;
        border-radius: 16px;
        padding: 32px;
        background-color: #F9F9FB;
        margin-top: 15px;
      }

      .row {
        line-height: 24px;

        .data {
          font-size: 16px;
          font-weight: 600;
          color: #242433;
        }
      }

      .file-name {
        font-size: 16px;
        font-weight: 500;
        color: #5C5C7A;
      }

      .uploaded-by {
        font-size: 16px;
        font-weight: 500;
        color: #5C5C7A;

      }

      .uploaded-date {
        font-size: 16px;
        font-weight: 500;
        color: #5C5C7A;
      }
    }
  }
}