.dashboard-page {
    padding-top: 105px;
}

.dashboard-dimensions {
    min-height: 800px;
    min-width: 1920px;
}


  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .dashboard-dimensions {
      height: 80vh;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .dashboard-dimensions {
      height: 94vh;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .dashboard-dimensions {
      height: 88vh;
    }
  }
  