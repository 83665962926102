.scenario-model-page {
  padding: 0 3rem !important;

  .header-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #202529;
    text-transform: capitalize;
    float: left;
  }

  .header-right {
    float: right !important;
  }

  .title-text {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.03em;
    color: #202529;
  }

  .label-header {
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #6b6c70 !important;
    margin-bottom: 5px;
  }

  .label-value {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #202529;
    text-transform: capitalize;
  }

  .period-selected {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .scenario-model-main {
    .scenario-model-container {
      .scenario-model-content {
        width: 50%;
        cursor: pointer;
        border: 1px solid #cccccc;
        border-radius: 8px;
        background-color: #ffffff;
        margin-bottom: 2rem;
  
        &.selected {
          border-color: #0000A1 #0000A1 #ffffff #0000A1;
          border-bottom-left-radius: 1px;
          border-bottom-right-radius: 1px;
          margin-bottom: -1px;
  
          .header-content {
            .header-content-title {
              background-color: #f5f7fe;
            }
          }
        }
        .highlighted-item {
          position: relative;
        }
        .highlighted-overlay {

          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;
          height: 330px;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.205);
          z-index: 2;
        }

        .floating-box {
          display: flex;
          // flex-direction: column;
          padding: 32px 16px;
          background-color: white;
          border-radius: 16px;
          color: #242433;
          font-size: 16px;
          width: 70%;
        }
  
        .header-content {
          .header-content-title {
            border-radius: 8px 8px 0 0;
            background-color: #fbfcff;
          }
  
          input[type='radio']:checked ~ label:before {
            border-color: #0000A1;
          }
  
          input[type='radio']:checked ~ label:after {
            background-color: #0000A1;
          }
  
          .bar {
            .bartext {
              font-size: 11px;
  
              div:first-child {
                width: 70%;
                display: inline-block;
                text-align: left;
              }
  
              div:last-child {
                display: inline-block;
                width: 30%;
                text-align: right;
              }
            }
  
            .progress {
              overflow: hidden;
              background-color: #e9ecef;
  
              .progress-bar {
                background-color: #2bcf9e;
              }
            }
          }
        }
  
        .leaflet-container {
          z-index: 0;
        }
  
        .vessel-content {
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
          padding: 1rem;
          display: contents;
  
          .card-detail {
            border-radius: 8px;
            background-color: #f5f7fe;
          }
  
          .vessel-name {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #b455aa;
            margin-bottom: 5px;
            text-transform: capitalize;
          }
  
          .location {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #460073;
            background: #f0f0fa;
            border-radius: 8px;
            padding: 5px;
            margin: 5px 10px 5px 0;
          }
  
          .arrow-location {
            color: #460073;
            margin: auto 0;
            margin-right: 10px;
            font-size: 16px;
          }
        }
  
        .vessel-content:last-child {
          border-radius: 0 0 8px 8px;
        }
  
        &:not(.selected) .leaflet-map-pane {
          filter: grayscale(100%);
        }
      }
    }

    .scenario-model-detail {
      width: 100%;
      cursor: pointer;
      border: 1px solid #cccccc;
      border-radius: 8px;
      background-color: #ffffff;

      .scenario-model-map {
        .timeline{
          position: relative;
          left: 0;
            bottom: 0;
        }
      }

      &.selected {
        // border-color: #ffffff #0000A1 #0000A1 #0000A1;
        border-color: #0000A1;
        border-top-left-radius: 1px;
        border-top-right-radius: 1px;
        margin-top: -1px;

        .header-content {
          .header-content-title {
            background-color: #f5f7fe;
          }
        }
      }
      .scenario-model-map {
        position: relative;
        padding: 2rem 2rem 0 2rem;
        margin-bottom: 4rem;
        .timeline {
          position: static;
          //transition: bottom 0.3s;
          right: 0;
          //transform: translate(0,220%);
          top: 0
        }
      }
    }
  }
}

.fullscreen {
  right: 0 !important;
}

.modals {
  .modal {
    .content {
      .tab-container {
        .menu {
          margin-top: -1rem;
          .item {
            font-size: 18px;
            font-weight: 600;
          }
          .active {
            text-decoration: underline;
            text-decoration-color: #0000A1;
            text-decoration-thickness: 3px;
          }
        }
        .segment {
          border: none;
          .legend-scenario {
            margin-left: 2rem;
          }
          .scenario-pop-timeline {
            position: relative;
            padding-bottom: 5rem;
            .timeline {
              position: static;
              // transition: bottom 0.3s;
              right: 0;
              // transform: translate(0,300%);
              // top: 0
            }
          }
          .flex-form {
            position: absolute;
            z-index: 500;
            width: 100%;
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.4);
            padding: 1% 0 2% 1%;
            align-items: center;
            .book-a-vessel-input-container {
              margin-right: 20%;
              margin-left: 1rem;
              .toggle {
                label {
                  font: inherit;
                  font-family: "Open Sans", sans-serif;
                  font-size: initial;
                  font-weight: 600;
                  font-size: 14px;
                }
              }
              .input-label {
                display: inline;
                font-weight: 600;
                font-size: 14px;
              }
              .dropdown-checkbox-custom {
                width: 20%;
                display: inline;
              }
            }
          }
        }
      }
    }
  }
}
