.custom-small-modal {
    padding: 8px;
    border-radius: 8px;
    .custom-small-modal-header {
        padding: 5px;
        .title {
            font-family: Inter;
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: left;
            color: #202529;
        }
        .close-icon {
            cursor: pointer;
          }
    }
    .custom-small-modal-header {
        width: 100%;
    }
}