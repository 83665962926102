.demand-booking-page {
  padding-bottom: 50px;
  .button-demand {
    font-family: $font-normal !important;
    text-transform: capitalize !important;
    font-size: 16px !important;
    line-height: 16px !important;
    font-weight: $fontweight-600 !important;
    padding: 16px 32px !important;
    border-radius: 4px !important;

    &.button-bg {
      background-color: #0000A1 !important;
      color: #fff !important;
    }
  }
  .sort-by-container {
    margin: 3rem 0 1rem 0;

    .sort-by-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #202529;
    }

    .sort-by-icon {
      cursor: pointer;

      &.asce svg {
        -ms-transform: rotate(0deg); /* IE 9 */
        transform: rotate(0deg);
      }

      &.desc svg {
        -ms-transform: rotate(180deg); /* IE 9 */
        transform: rotate(180deg);
      }
    }

    .radio-option-content {
      .radio {
        margin-right: 3rem;
        label {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #202529;
        }

        input[type='radio']:checked ~ label:before {
          border-color: #0000A1;
        }

        input[type='radio']:checked ~ label:after {
          background-color: #0000A1 !important;
        }
      }
    }
  }

  .request-link {
    text-decoration: underline;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: #000000;
  }

  .demand-booking-form-container {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    padding: 72px 96px 40px 96px;
    border-radius: 10px;
    margin-bottom: 17px;

    .main-form-label {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
      margin-bottom: 45px;
    }

    .demand-form-container {
      margin-top: 48px;

      .main-label {
        font-weight: 600;
        font-size: 16px;
        color: #000;
        line-height: 16px;
      }

      .label-title {
        font-weight: 600;
        font-size: 12px;
        color: #000;
        line-height: 12px;
      }

      .first-column-grid {
        width: 33.3%;
      }

      .demand-form-field {
        width: 33.3%;
      }

      .demand-ddl {
        width: 100% !important;
        font-size: 16px !important;
        line-height: 22.4px !important;
      }

      .search-voyage-dropdown{
        .menu{
          height: 190px !important;
        }
      }
      
      .dropdown-loading{
        i.icon{
          top: -10px !important;
          left: 0;
        }
      }

      .radio-label {
        font-size: 16px !important;
        font-weight: 400px !important;
      }

      .input-field {
        border-radius: 4px !important;
        font-size: 16px !important;
        line-height: 22.4px !important;
      }
    }
  }

  .cards-container {
    width: 100%;
    // margin-top: 30px;
    margin-bottom: 30px;
    background-color: #c4c8df;
    border-radius: 8px;
    // &.card-top-label {
    //   height: 331px;
    // }
    &.discount {
      background-color: #c4dfd7;
    }
    // &.active {
    //   height: 466px;
    // }
    &.passengers-card {
      height: 237px;
    }
    &.passengers-no-type-card {
      height: 192px;
    }

    .voyage-type-label {
      font-size: 17px;
      font-weight: 600;
      line-height: 16px;
      color: #5963a5;
      padding-top: 15px;
      padding-left: 40px;

      &.biggest-discount-label {
        color: #159c74;
      }
    }

    .voyage-front-card-container {
      z-index: 998;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
      width: 100%;
      background: #ffffff;
      border-radius: 8px;
      &.card-active {
        border-left: 8px solid #0000A1 !important;
      }
      &.top-label {
        position: relative;
        top: 14px;
        border-left: 0px;
      }
      &.passengers-front-card {
        height: 192px;
      }

      .front-card-content-container {
        padding: 21px 60px 40px 40px;

        .savings-label {
          color: #0000A1;
          font-weight: 600;
          font-size: 16px;
          line-height: 14px;
          margin-bottom: 18px;
        }

        .vessel-container {
          background-color: #f5f7fe;
          border-radius: 8px;
          padding: 7px 10px;
          margin-left: auto;
          color: #a055f5;
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          align-items: center;
        }

        .location-container {
          width: 100%;
          height: 181px;

          .start-location-container {
            width: 200px;
          }

          .end-location-container {
            margin-left: 40px;
            width: 20%;
          }

          .top-card-label {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #6b6c70;
            margin-bottom: 16px;
          }

          .payload-details-container {
            width: 40%;
            .payload-column {
              width: 50%;
              .payload-details {
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                color: #202529;
              }
            }
          }

          .demand-input-column {
            width: 30%;
          }

          .voyage-input-container {
            align-items: center;

            .voyage-field {
              width: 120px;
              border-radius: 4px;
            }

            .field-number {
              font-size: 12px;
              line-height: 12px;
              font-weight: 600;
              color: #202529;
            }
          }

          .voyage-card-button {
            margin-top: 20px;
          }

          .content-sub-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 18px;
            &.active {
              color: #ffffff;
            }
          }

          .content-date-sub-title {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            &.active {
              color: #ffffff;
            }
          }

          .content-label {
            color: #96968c;
            font-size: 12px;
            font-weight: 600;
            line-height: 12px;

            &.active {
              color: #ffffff;
            }
          }

          .arrow {
            position: relative;
            width: 180px;
            margin-top: 40px;
            height: 0;
            border-bottom: 2px solid #bab3be;
            &.active {
              border-bottom: 2px solid #ffffff;
            }
          }

          .arrow::after {
            content: '';
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 10px solid #bab3be;
            position: absolute;
            right: -10px;
            top: -4px;
          }

          .arrow.active::after {
            content: '';
            width: 0;
            height: 0;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 10px solid #fff;
            position: absolute;
            right: -10px;
            top: -4px;
          }
        }
      }

      .card-menu .item {
        border-right: 1px solid #f5f7fe !important;
        color: #bab3be;
        font-size: 14px !important;
        line-height: 14px;
        font-weight: 600;
        padding: 20px 60px 20px 40px !important;
      }

      .card-menu .active.item {
        color: #0000A1;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        background-color: #f5f7fe;
        border-radius: 0px;
      }

      .tab-menu-container {
        background-color: #ffffff;
        padding: 19px 30px 19px 40px;
        align-items: center;
        border-right: 1px solid #f5f7fe;
        cursor: pointer;

        &.active {
          background-color: #f5f7fe;
        }

        .tab-menu-label {
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          color: #bab3be;

          &.active {
            color: #0000A1;
          }
        }
      }

      .tab-content-container {
        background-color: #f5f7fe;
        height: 122px;
        padding: 18px 40px 18px 40px;
        align-items: center;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .special-request-content {
          .text,
          .link {
            font-weight: 600;
            font-size: 16px;
          }

          .link {
            color: #0000A1;
          }
        }

        .btn-save-container {
          margin-left: auto;
        }

        .tab-info-label {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          color: #202529;
        }
        .input-side-label {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
          color: #202529;
          margin: auto;
        }

        .payload-tab-input {
          width: 90px;
          border-radius: 4px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          margin-right: 0px;
        }

        .payload-tab-ddl {
          width: 68px;
          background-color: white;
          border: 1px solid #ccc;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px solid #fff;
        }
      }
    }
  }
}

.popup-title {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
}

.popup-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: left;
}

.view-calculation-label {
  color: #0000A1;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.popup-info-icon {
  svg path {
    fill: #0000A1;
  }
  cursor: pointer;
  width: 12px;
  height: 12px;
  transform: translate(-7px,-1px);
}

.popup-content {
  text-align: left;
  font-size: 14px;
  line-height: 21px;
  width: 400px;
}

.swal-simple-reminder {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  width: 550px;
  background-color: #f1f9ff;
  border-radius: 8px;
  border: 1px solid #aed2f2;
  padding: 0.8rem 0.5rem;

  .swal-title {
    color: #202529;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }
  .swal-title:first-child {
    margin: 0 !important;
  }
  .swal-title:not(:first-child) {
    padding-bottom: 13px;
  }
  .swal-icon--custom {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
}
