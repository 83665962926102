.comments-coverage {
    background-color: #F5F7FE;
    padding: $rem-24;
    &.show-full {
      min-height: 725px;
    } 
    &.not-show-full {
      max-height: 190px;
      overflow-y: auto;
    }
    .title {
      display: flex;
      justify-content: space-between;
      .title-comment {
        display: flex;
        align-items: center;
        .back-button {
          color: $color-primary;
          cursor: pointer;
        }
        .comment-text {
          font-weight: $fontweight-600;
          font-size: $fontsize-20;
          color: #202529;
          margin-right: $rem-10;
        }
        .total-comments {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
      .add-comment_button {
        color: $color-primary;
        border: none;
        padding: 5px 10px;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        height: 32px;
        align-items: center;
        .icon-style {
          height: 25px;
          width: 30px;
        }
        &:hover {
          background-color: $color-primary;
          color: $color-white;
          svg path {
            stroke: #ffffff;
            fill: #fff;
          }
        }
      }
    }

    .radius {
      border-radius: 50%;
      color: $color-primary;
      background-color: #F0F0FA;
      margin-top: 5px;
      text-align: center;
    }
    .view-all {
      margin-top: $rem-30;
      color: $color-primary;
      font-weight: $fontweight-600;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
      display: inline-block;
    }
    .ui.input {
      border-radius: 4px;
    }
    .add-comment-actions {
      display: flex;
      margin-top: $rem-16;
      justify-content: end;
      .button {
        padding: 8px 24px;
        margin-left: -25px;
        border-radius: 4px;
        button {
          border-radius: 4px;
        }
        .cancel-button {
          border: none;
          background-color: none;
        }
      }
    }
}

.mobile-screen-feedback {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  .feedback-card-container {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    max-height: 350px;
    margin: 10px 0;
    .feedback-card {
      padding: 10px;
      padding-left: 20px;
      width: 100%;
      box-shadow: 0px 4px 20px 0px #00000026;
      margin-bottom: 15px;
      background-color: #F3F6FD;
    }
  }
  .add-feedback-div {
    width: 100%;
    text-align: right;
    margin: 10px 0;
    .custom-blue-button {
        height: auto;
        width: auto;
        padding: 10px 32px 10px 32px;
        border-radius: 8px;
        gap: 8px;
        background-color: $color-primary;
        color: $color-white;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-transform: none;
    }
  }
}
  