.profile-popup-container {
  border-radius: 10px !important;
}
.profile-menu-container {
  .text-black {
    color: #000000;
  }
  .text-white {
    color: #ffffff;
  }
  .profile-text {
    color: #0000A1;
  }
  .user-email {
    font-size: $fontsize-14;
    font-weight: 600;
    display: block;
    letter-spacing: 0.04em;
  }
  .profile {
    cursor: pointer;
    font-size: $fontsize-12;

  font-weight: 600;
}

.expand-collapse-icon {
  margin: auto;
  svg path {
    fill: #ffffff;
  }
}
}

.profile-popup-wrapper {
text-align: left;
min-width: 430px;
padding: 32px 40px 48px 40px;

.header {
  display: flex;
  justify-content: space-between;
}

.medium-text-bold {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  color: #4B7093;
}

.medium-small-text-grey {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #5C5C7A;
  min-width: 70px;
}

.medium-small-text-black {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #242433;
  min-width: 70px;
}

.medium-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #202529;
}

.welcome-switch-persona {
  border-bottom: 1px solid $color-grey-lighter;
  padding: $rem-8 $rem-16;
}

.list-role {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  .role-item {
    color: #0000A1;
    background-color: #E6E6F6;
    border-radius: 15px;
    font-weight: 500;
    padding: 2px 16px;
  }
}

.platform-list {
  gap: 5px;
  .platform-item {
    color: #0000A1;
    background-color: #E6E6F6;
    border-radius: 15px;
    font-weight: 500;
    padding: 2px;
    cursor: pointer ;
    display: block;
    margin-top: 5px;
    width: 50%;
    text-align: center;
  }
}

.form-dropdown {
  width: 100% !important;
  height: 46px !important;
  border-radius: 8px !important;
  border-color: #D9D9E3 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.item {
  display: inline-block;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  .btn {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    width: 140px;
    height: 40px;
    text-align: center;
  }

  .btn:hover {
    color: #0000A1;
    background-color: #E6E6F6;
  }

  .with-border {
    border: solid 1px #0000A1;
    background: none;
    color: #0000A1;
  }

  .with-background {
    background-color: #0000A1;
    color: #ffffff;
  }
}

.mobile-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  .mobile-btn {
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    width: 140px;
    height: 40px;
    text-align: center;
  }

  .mobile-btn:hover {
    color: #0000A1;
    background-color: #E6E6F6;
  }

  .mobile-with-border {
    border: solid 1px #0000A1;
    background: none;
    color: #0000A1;
  }

  .mobile-with-background {
    background-color: #0000A1;
    color: #ffffff;
  }
}
}

.mobile-profile-popup-container {
border-radius: 10px !important;
top: -30px !important;
}

.mobile-profile-menu-container {
  margin-top: 0px;
.text-black {
  color: #000000;
}
}

