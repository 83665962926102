.voyage-container {
    width: 256px;
    box-shadow: 0px 4px 16px rgba(45, 30, 78, 0.1);
    .title {
        font-family: $font-normal !important;
        font-weight: $fontweight-700;
        font-size: 11px;
        line-height: 12px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #000;
        flex-direction: column;
    }
}
