#gantt-chart-header {
  width: 100%;
  z-index: 994;
  padding-top: 50px;

  .gantt-chart-menu {
    width: 200px;
  }
  .gantt-header-days-group {
    position: relative;
    background-color: #f5f7fe;
    border-radius: 4px;
    border: 1px solid #fff;
  }

  // ==================== Create Vessel Schedule page ==========================
  &.create-vessel-schedule {
    width: 100%;

    .gantt-header-days {
      padding: 5px;
    }
    .gantt-header-days-group {
      width: 100%;
    }
  }

  // ==================== Vessel Schedule page ==========================
  &.vessel-schedule {
    position: fixed;
    height: 30px;
    background: #fff;
    margin-top: -60px;
    width: calc(100% - 330px);

    &.header-week-view,
    &.header-month-view {
      width: 100% !important;
      z-index: 994;
    }

    .gantt-header-month-line {
      padding-bottom: 3px;
    }

    //====================== Days ==============================
    .gantt-header-days-group {
      width: 99.5%;
    }
    .gantt-header-days {
      padding: 3px 0px 3px 5px;
    }
    .gantt-header-days-inner {
      padding: 2px 0px;
      width: 15px;

      &.has-alert {
        background-color: #ef5d60;
        color: #fff;
        border-radius: 50%;
        padding-left: 2px;
      }
    }
  }

  .day-title {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 200px;
    width: 202px;
    height: 72px;
    // padding: 8px;
    background: #f5f7fe;

    margin-right: 1px;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #6c696f;
    text-transform: capitalize;

    &.error {
      background: #fee7e7;
    }

    .vessel-title-href {
      width: 100%;
      height: 100%;
      padding: 8px;
      color: #202529;

      &:hover {
        color: #0000a1;
      }

      .vessel-title-href-filler {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    span {
      font-weight: normal;
      font-size: 10px;
      margin: 0 5px;
    }
  }
}
