
// dark theme
:root {
  &[data-theme="dark"] {
    --primaryBackground: #303134;
    --secondaryBackground: #{$color-black};
   
    --leftNavBackground:#1b1b1b;
    --inputPrimaryBackground: transparent;
    --tableHeaderBackground: #808080;

    --primaryTextColor: #{$color-white};
    --secondaryTextColor: #323436;
    --thirdTextColor: #999a9c;
    --fourthTextColor: #{$color-white};
    --mainValueColor:#bfc6cd;

    --primary: #00a19c;
    --primaryVariant: #31b4af;

    --secondary: #{$color-black};
    --secondaryVariant: #{$color-black};

    --primaryBorder: #686767;
    --secondaryBorder: #{$color-white};

    //Insight
    --insightTitle: #999a9c;

    //Icons
    --primaryIcon: #{$color-white};
    --secondaryIcon: #{$color-white};
  }
}