.timeline-speed-filter {
  .ui.input {
    background-color: transparent;
  }
  .ui.input > input {
    max-width: 95%;
    padding-left: 0px;
    padding-right: 0px;
    background-color: purple !important;
  }
  .ui.input > input:focus {
    color: rgba(238, 238, 238, 0.9) !important;
    opacity: 0.85;
    transition: all 0.1s ease-in;
  }
  .text-style {
    font-size: 12px;
  }
}
