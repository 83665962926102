.speed-table-container {
  .cell-alert {
    background-color: #ffafb7;
    cursor: pointer;
  }

  .warning-column {
    .warning-action-button {
      background: #ffffff;
      font-family: $font-normal !important;
      font-weight: $fontweight-700;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid;
      box-sizing: border-box;
      border-radius: 0;
      padding: 10px 8px;
      letter-spacing: 0.04em;
      text-transform: uppercase;

      &.active {
        color: rgb(161, 0, 255);
        border: 1px solid rgb(161, 0, 255);
        cursor: pointer;
      }

      &.active:hover {
        background: rgb(161, 0, 255);
        color: #ffffff;
      }

      &.resolved {
        color: rgb(181, 238, 171);
        border: 1px solid rgb(181, 238, 171);
        cursor: pointer;
      }

      &.resolved:hover {
        background: rgb(181, 238, 171);
        color: #ffffff;
      }

      &.pending {
        color: rgb(255, 198, 135);
        border: 1px solid rgb(255, 198, 135);
        cursor: pointer;
      }

      &.pending:hover {
        background: rgb(255, 198, 135);
        color: #ffffff;
      }
    }
  }
}

.vessel-monitoring {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  .speed-content-title {
    font-weight: 700;
    color: #0000A1;
  }
  .card-title {
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #96968c;
    width: 120px;
  }
  .card-value {
    font-weight: 500;
    font-size: 22px;
    color: #000;
  }
  .chart-title {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #000;
  }
  .chart-subtitle {
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #96968c;
  }
}

.vessel-speed-chart {
  .speed-content-title {
    font-weight: 700;
    color: #0000A1;
  }
}

.warning-action {
  .warning-action-title {
    font-weight: 700;
    color: #000;
  }
  .warning-action-subtitle {
    font-weight: 400;
    color: #000;
    font-size: 16px;
  }
  .warning-action-alert {
    font-weight: 400;
    font-size: 12px;
    color: red;
  }
  .warning-info {
    background: #f0f0fa;
    padding: 10px;
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    .warning-info-text {
      font-size: 10px;
    }
  }
  .warning-info-textarea {
    width: 100%;
    min-height: 150px;
  }
  .goTo {
    margin-left: 31px;
    font-size: 18px;
  }
}
