.stepped-line {
  .horizontal {
    background: linear-gradient(to bottom, #a4a7b2 70%, #ffffff 30%) !important;
    background-size: 2px 16px, 100% 10px !important;
    &.up-circle {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: solid 3px #ffffff;
        box-shadow: 0 0 0px 1px #a4a7b2;
        background: #a4a7b2;
        top: 0;
        left: 0;
        transform: translate(-7px, -100%);
      }
    }
    &.down-circle{
      &::after {
          content: "";
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: solid 3px #ffffff;
          box-shadow: 0 0 0px 1px #a4a7b2;
          background: #a4a7b2;
          bottom: 0;
          left: 0;
          transform: translate(-7px, 100%);
        }
    }
  }
  
  .vertical {
    background: linear-gradient(to right, #a4a7b2 70%, #ffffff 30%) !important;
    background-size: 16px 2px, 100% 2px !important;
  }  
}