.upload-file-container {
   
  .submit-booking-box {
    height: 240px;
    border-radius: 8px;
    background: var(--shades-white, #FFF);
    display: flex;
    .container {
      padding: 32px;
      height: 240px;
      flex:1;
      .title {
        color: var(--queen-blue-300-main, #4B7093);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
      }
      .body {
        margin-top: 32px;
        .title {
          color: var(--independence-grey-dark-n-300-form-labels, #5C5C7A);
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          margin-bottom: 12px;
        }
      }
      .submit-document-container {
        border-radius: 8px;
        background: var(--independence-grey-light-n-10, #F9F9FB);
        padding: 32px;
        .submit-doc-title {
          color: #4b7093;
          font-size: 18px;
          font-weight: 777;
        }
        .submit-doc-secondary-title {
          padding-top: 32px;
          padding-bottom: 12px;
          color: #5c5c7a;
          font-size: 14px;
          font-weight: 400;
        }
        .submit-doc-dropdown {
          display: flex !important;
          justify-content: space-between;
          height: 44px;
          align-items: center;
          .text {
            font-size: 16px;
          }
          .down:before {
            font-size: 15px;
            color: #6c696f;
          }
        }
        .submit-doc-skeleton-button {
          color: #0000a1;
        }
        .submit-doc-draft-container {
          margin-top: 32px;
          padding: 32px;
          background-color: #f9f9fb;
        }
        .submit-doc-draft-list-container {
          display: flex;
          align-items: center;
          span{
            color: var(--duke-blue-p-300-main, #0000A1);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
          .ui.basic.button.edit-button {
            box-shadow: none !important;
          }
          .ui.basic.button.delete-button {
            color: red;
            box-shadow: none !important;
          }
        }
      
        .submit-df-file {
          margin-top: 15px;
          margin-left: 0px;
        }
      
        .message-error {
          position: absolute;
          top: 120px;
          right: 25px;
          z-index: 99999;
        }

        button {
          box-shadow: none;
        }
      }
    }
  }

  .file-preview {
    color: #4B7093;
    font-weight: 500;
    font-size: 18px;
  }
  .header {
    color: #4B7093;
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
  }
  .upload-part-content:hover{
    background:  #F0F0F4;
  }
  .upload-part-content {
    width: 50%;
    background:  #ffffff;
    border-radius: 8px;
    padding: 1rem 2rem;
    // cursor: pointer;
    margin-bottom: 1rem;

    &.active {
      border-bottom-left-radius: 1px;
      border-bottom-right-radius: 1px;
      margin-bottom: -1px;
      background:  #F0F0F4;
    }

    .upload-file {
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 3rem;
      text-align: center;
      height: 200px;
      a.text-purple-bold-16 {
        text-decoration: underline;
      }
      .upload-icon {
        margin-bottom: 10px;
      }
      .success-message {
        color: #26A671;
        font-weight: 500;
        font-size: 16px;
      }
      .error-message-title {
        color: #C93626;
        font-weight: 500;
        font-size: 16px;
      }
      .error-message-content {
        color: #E74C3B;
        font-weight: 400;
        font-size: 16px;
      }
      &.active {
        padding-bottom: 1rem;

        .upload-icon svg path {
          fill: #0000a1;
        }
      }

      &.msg {
        border: 1px solid #a4a7b2;
      }
    }

    .download-template {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .download-button {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #6b6c70;
      margin: 0;

      svg path {
        stroke: #6b6c70;
      }
    }
  }
}
