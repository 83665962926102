.comments-content-coverage {
    margin-top: $rem-12;
    display: flex;
    align-items: center;
    margin-bottom: $rem-20;
    .avatar {
      display: inline-block;
      width: 48px;
      height: 48px;
      line-height: 48px;
      font-weight: 600;
      font-size: 24px;
      margin-right: $rem-16;
    }
    .comment-detail {  
      margin-top: 10px;
      .comment-text {
        font-weight: 400;
        font-size: 16px;
        color: #202529;
        margin-bottom: $rem-5;
      }
      .comment-footer {
        color: #6B6C70;
        font-weight: 400;
        font-size: 14px;
        display: flex;
        align-items: center;
        p {
          margin-right: 3px;
          margin-top: 4px;
        }
        .actions {
          color: $color-primary;
          font-size: $fontsize-20;
          i {
            margin: 0 12px;
            cursor: pointer;
          }
        }
      }

      .mobile-comment-text {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #5C5C7A;
      
      }

      .feedback-email {
        font-family: Commissioner;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #242433;
      }
    }
    .mobile-view-avatar {
      text-align: center;
      line-height: 50px;
      font-weight: 400;
      font-size: 24px;
      margin-right: $rem-16;
      background-color: #DFDFDF;
      color: #0000A1;
    }

  }