.modal-main-lease {
  .list-header {
    color: #4b7093 !important;
    margin: auto 0;
  }
  .icon-status {
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    margin: auto 1rem;
    color: #26a671;
  }
  .main-title {
    background: #ebf0f5 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 34px;
  }
  .lease-modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 500px;

    .voyage-sharing-upload-content {
      background: #f9f9fb;
      border-radius: 8px;
      padding: 1rem;
    }
  }

  .radio-option-content {
    .radio input[type="radio"]:checked ~ label:before {
      border-color: #242433;
    }
    .radio input[type="radio"]:checked ~ label:after {
      background-color: #242433 !important;
    }
    .radio-option-item {
      background: #f9f9fb;
      border-radius: 8px;
      width: 308px !important;
      padding: 16px 24px;
      box-sizing: border-box;
      color: #5c5c7a !important;
      &.active {
        background-color: #f0f0f4;
        color: #242433 !important;
      }
    }
    .lease-radio-title {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .cancel-btn {
    font-weight: 600;
    font-size: 16px;
    color: $color-primary !important;
    background: $color-white !important;
    width: 130px;
    height: 45px;
  }

  .date-period {
    width: 400px;
    padding-right: 20px;
  }
}
