.vod-range-slider {
  .range-slider-container {
    .vod-slider-container {
      flex: 4;
      .vod-lease-cargo-name {
        font-weight: 600;
        color: #5c5c7a;
      }
      .vod-lease-percentage {
        font-weight: 600;
        color: #242433;
      }
      // .vod-lease-slider {
      //   .semantic_ui_range_inner {
      //     margin: 0 !important;
      //     div:nth-child(1) {
      //       height: 8px !important;
      //     }
      //     div:nth-child(2) {
      //       height: 8px !important;
      //       background-color: #5C5C7A !important;
      //     }
      //     div:nth-child(3) {
      //       top: 2px !important;
      //     }
      //   }
      // }

      .vod-lease-slider {
        -webkit-appearance: none;
        width: 100%;
        height: 8px;
        margin: 1rem 0;
        border-radius: 10px;
        outline: 0;
        background: #dae3ec;
      }

      .vod-lease-slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        background: #f9f9fb;
        border: 1px solid #d9d9e3;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
        transform: rotate(-180deg);
        cursor: pointer;
      }
    }

    .vod-grid-capacity {
      flex: 1;
      font-weight: 600;
      color: #757595;
      width: 47px;
      white-space: pre;
      align-items: center;
    }
    .vod-input-container {
      align-items: center;
      flex: 3;
      .vod-grid-input {
        border-radius: 8px !important;
        height: 46px;
        .ui.label {
          display: flex;
          align-items: center;
          width: 41px;
        }
        &.no-label {
          border-radius: 8px !important;
          height: 46px;
        }
      }
    }
  }
}
