.checked-category-container {
    margin-top: 1rem;
    .btn-clear {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      border: none;
      width: 250px;
      border-radius: 4px;
      color: #0000A1;
      background-color: #ffffff;
      padding: 7px 1.5rem !important;
      width: auto;
      cursor: pointer;
      &:hover {
        color: #ffffff;
        background-color: #0000A1;
      }
    }
    
  }
    
  .maintenance-log-table {
    .maintenance-status {
      align-items: center;
      gap: 5px;
    } 
    tbody {
      tr:hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
      tr:hover .edit-icon path{
        cursor: pointer;
        stroke: #0000A1;
      }
      tr:nth-child(even) {
        td {            
          background-color: #FBFCFF !important;
        }
      }
      tr:nth-child(odd) {
        td {
        background-color: #fff !important;
        }
      }
    }    
  }
  