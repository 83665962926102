.card-content-container {
  .card {
    margin: 1rem 0;
    border-color: #f0f0fa;
    cursor: pointer;

    .left-content {
      flex: 1;
    }

    .header {
      padding: 4px 16px;
      background-color: #f0f0fa;
    }

    .body {
      padding: 1rem;
    }

    .medium-text-black {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #202529;
    }

    .medium-text-black-bold {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #202529;
    }

    .medium-large-text-black-bold {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #202529;
    }

    .medium-text-grey {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #6b6c70;
    }

    .medium-text-grey-bold {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #6b6c70;
    }

    .arrow {
      position: relative;
      width: 180px;
      margin-top: 33px;
      height: 0;
      border-bottom: 2px solid #bab3be;
      &.active {
        border-bottom: 2px solid #ffffff;
      }
    }

    .arrow::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 10px solid #bab3be;
      position: absolute;
      right: -10px;
      top: -4px;
    }

    .arrow.active::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 10px solid #fff;
      position: absolute;
      right: -10px;
      top: -4px;
    }
  }
  .card:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px;
    border-radius: 0px 0px 8px 8px;
    .nav-arrow svg path {
      stroke: #0000A1;
      stroke-width: 2;
    }
  }
}
