.vod-buyer-vessel-reservation-page {
  .vod-chartering-summary-page {
    padding: 165px 100px 50px 100px;
  }
  .vessel-chartering-listing {
    padding: 165px 100px 25px 100px;
  }
  .voyage-sharing-header-container {
    position: sticky;
    background: white;
    top: 0;
    z-index: 99;
  }
  .reservation-content-header {
    padding: 165px 100px 25px 100px;
    .reservation-content {
      .vod-sharing-reservation {
        .upload-file-component {
          .attachment-success {
            font-weight: 600;
            font-size: 16px;
            color: #26a671;
            margin-top: 5px;
          }
          .upload-popup {
            margin-top: 4px;
            .upload-popup-icon {
              color: #3b5773 !important;
            }
          }
        }
        .upload-file-content .upload-file {
          width: 812px;
        }
        .upload-file-container {
          background: #f9f9fb;
          border-radius: 8px;
          padding: 1rem;
        }
        .sharing-form-container {
          .form-inputs {
            color: #5c5c7a !important;
            font-weight: 600;
            .search-dropdown {
              display: flex;
              justify-content: space-between;
              border-radius: 8px;
              height: 3rem;
              width: 400px;
              .ui.selection.dropdown {
                border: 1px solid rgba(217, 217, 227, 1);
              }
            }
            .type-input {
              border-radius: 8px;
              border: 1px #d9d9e3;
              height: 3rem;
              width: 400px;
            }
          }
        }
      }
    }
  }
  .table-file-content {
    overflow-x: scroll;
    table {
      th {
        background-color: #dae3ec;
        // height: 72px;
        color: #242433;
        font-weight: 600;
      }
      td {
        color: #242433;
        padding: 1.5em 1em;
      }
    }
  }
  .voyage-sharing-listing {
    padding: 165px 100px 25px 100px;
    .voyage-sharing-header {
      flex: 0 0 auto;
      .header-preview-title {
        font-size: 14px;
        color: #4b7093;
      }
    }
  }
  .percentage-match-red {
    width: 138px;
    height: 32px;
    background: #fbdfd9;
    border-radius: 10px;
    justify-content: center;
    align-items: center;

    color: #c93626;
    font-size: 16px;
    font-weight: 600;
  }
  .percentage-match-green {
    width: 138px;
    height: 32px;
    background: #d5f6e8;
    border-radius: 10px;
    justify-content: center;
    align-items: center;

    color: #1c7d55;
    font-size: 16px;
    font-weight: 600;
  }
  .vod-summary-page {
    padding: 165px 100px 50px 100px;
    .summary-header {
      padding-top: 0px;
      height: 0px;
      .right-arrow {
        color: #242433;
        margin-top: -2px;
      }
      .header-text-orange {
        color: #d0460b;
      }
      .header-text-link {
        cursor: pointer;
      }
      .header-text-current {
        color: #242433;
      }
    }
    .summary-title {
      font-size: 34px;
      color: #242433;
      font-weight: 500;
    }
    .summary-content {
      .summary-content-column {
        overflow-x: auto;
      }
      .route-content {
        flex-wrap: wrap;
        .location {
          font-weight: 600;
          font-size: 14px;
          line-height: 12px;
          color: #3b5773;
          background: #ebf0f5;
          border-radius: 10px;
          padding: 9px 40px 9px 40px;
          margin: 5px 10px 5px 0;
          white-space: nowrap;
        }
        .arrow-location {
          color: #5c5c7a;
          margin: auto 0;
          margin-right: 10px;
          font-size: 16px;
        }
      }
      .summary-table-preview {
        background-color: #f9f9fb;
        padding: 16px;
        border-radius: 8px;
        .table-file-content {
          overflow-x: scroll;
          table {
            th {
              background-color: #dae3ec;
              // height: 72px;
              color: #242433;
              font-weight: 600;
            }
            td {
              color: #242433;
              padding: 1.5em 1em;
            }
          }
        }
      }
      .estimated-price {
        overflow: visible;
        font-size: 30px;
        font-weight: 600;
        color: #242433;
      }
      .estimated-savings-sub-title {
        font-size: 14px;
        color: #1c7d55;
      }
      .estimated-savings-sub-description {
        font-size: 16px;
        font-weight: 600;
        color: #1c7d55;
      }
      hr {
        width: 100%;
        border: 1px solid #d9d9e3;
      }
      .summary-content-title {
        font-size: 18px;
        font-weight: 600;
        color: #4b7093;
        margin-right: 0rem !important;
        width: 300px !important;
        // flex: 1 1 0;
      }
      .summary-content-sub-title {
        font-size: 14px;
        color: #5c5c7a;
      }
      .summary-content-column {
        flex: 3 1 0;
        .vessel-information-div {
          flex: 1 1 0;
        }
      }
      .summary-content-sub-description {
        font-size: 16px;
        font-weight: 600;
        color: #242433;
      }
      .vessel-information-title {
        font-size: 22px;
        font-weight: 600;
        color: #0000a1;
      }
    }
    .summary-actions {
      .back-button {
        .ui.button {
          background-color: #ffffff;
          border: 1px solid #0000a1;
          border-radius: 8px;
          color: #0000a1;
        }
      }
      .modify-button {
        .ui.button {
          background-color: #ffffff;
          border: 1px solid #0000a1;
          border-radius: 8px;
          color: #0000a1;
        }
      }
      .reserve-button {
        .ui.button {
          background-color: #0000a1;
          border-radius: 8px;
          color: #ffffff;
        }
      }
    }
  }
  .header {
    font-size: 34px;
    color: #242433;
  }
  .header-text-title {
    color: #4b7093 !important;
  }
  .sub-header {
    font-size: 18px;
    color: #4b7093;
    font-weight: 600 !important;
    .input-label {
      font-size: 14px;
      color: #5c5c7a;
    }
  }
  .search-button {
    justify-content: end;
  }
  .confirmation-footer {
    .mandatory-fields {
      color: #242433;
    }
  }
  .radio-option-content {
    .radio input[type="radio"]:checked ~ label:before {
      border-color: #242433;
    }
    .radio input[type="radio"]:checked ~ label:after {
      background-color: #242433 !important;
    }
    .radio-option-item {
      background: #f9f9fb;
      border-radius: 8px;
      width: 308px !important;
      padding: 16px 24px;
      box-sizing: border-box;
      color: #5c5c7a !important;
      &.active {
        background-color: #f0f0f4;
        color: #242433 !important;
      }
    }
    .lease-radio-title {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .text-blue-title {
    color: #4b7093;
    font-size: 18px;
    font-weight: 600;
  }
  .label-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5c5c7a;
  }
  .label-bold-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242433;
    &.modify-search {
      color: #0000a1;
      cursor: pointer;
    }
  }
  .label-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4b7093;
  }
  .grid {
    .grid-input {
      border-radius: 4px !important;
    }
    .grid-dropdown {
      width: 100% !important;
    }
    .date-special-request {
      button {
        height: 43.42px;
        width: 100%;
        .flex {
          justify-content: space-between;
        }
      }
    }
    .three.column.row .column {
      padding-right: 3rem;
    }
    .header-text {
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      color: #242433;
    }
    .header-text-title {
      color: #4b7093 !important;
    }
    .subheader {
      padding: 80px 20px 50px 20px;
    }
  }
  .vod-chartering-summary {
    padding: 30px 0px 10px 5px;
    .vessel-name-hyperlink {
      color: #0000a1 !important;
    }
    .div-border-top {
      border-top: 1px solid #e8e8ee;
      padding-top: 4rem;
    }
    .label-big-text {
      font-weight: 600;
      font-size: 26px;
      line-height: 24px;
      color: #242433;
    }
    .lowest-price {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 16px;
      gap: 4px;
      width: 131px;
      background: #d5f6e8;
      border-radius: 10px;
      color: #1c7d55;
    }
  }
}
.vod-buyer-vessel-listing {
  background-color: #f9f9fb;
  padding: 25px 100px 50px 100px;

  .label-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #5c5c7a;
  }

  .vod-vessel-sharing-listing {
    .percentage-match-green {
      width: 138px;
      height: 32px;
      background: #d5f6e8;
      border-radius: 10px;
      justify-content: center;
      align-items: center;

      color: #1c7d55;
      font-size: 16px;
      font-weight: 600;
    }
    .card-bottom {
      .table-file-content {
        overflow-x: scroll;
        table {
          th {
            background-color: #dae3ec;
            color: #242433;
            font-weight: 600;
          }
          td {
            color: #242433;
            padding: 1.5em 1em;
          }
        }
      }
      .label-header {
        font-size: 14px;
        font-weight: 400;
        color: #5c5c7a;
      }
      .select-button {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        align-content: center;
        color: #ffffff;
        background-color: #0000a1 !important;
      }
    }
    .route-content {
      margin: auto 0;
      width: 100%;
      flex-wrap: wrap;
      .location {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        margin-bottom: 0.5rem;
        height: 30px;
        background: #ebf0f5 !important;
        border-radius: 10px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #3b5773 !important;
      }
      .arrow-location {
        color: #5c5c7a;
        margin: auto 0;
        margin-right: 10px;
        font-size: 16px;
      }
    }
  }

  .background-card {
    background: $color-white !important;

    .text-blue-title {
      color: #4b7093;
      font-size: 18px;
      font-weight: 600;
    }

    .card-footer {
      padding: 0px 30px 20px 30px;

      .select-button {
        justify-content: end;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #ffffff;
        background: #0000a1;
        border: 1px solid #0000a1;
        border-radius: 6px;
        width: 140px;
      }
    }
  }

  .vod-card-container {
    border-radius: 8px;
    box-sizing: border-box;
    border-left: 8px solid #fbe86a;

    .card-top {
      background: #eaeaea;
      border-radius: 0px 8px 0px 0px;
      position: relative;
      align-items: center;

      .card-label {
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        align-items: center;
        padding: 12px 16px;
        color: #0000a1;
      }

      .lowest-price {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        gap: 4px;
        width: 131px;
        background: #d5f6e8;
        border-radius: 10px;
        color: #1c7d55;
      }
    }
    .card-bottom {
      padding-bottom: 1rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 8px 0px;

      .label-header {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #5c5c7a;
      }

      .label-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #242433;
      }

      .label-estimated-price {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #1c7d55;
      }

      .route-content {
        flex-wrap: wrap;
        .location {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          color: #0000a1;
          background: #e6e6f6;
          border-radius: 10px;
          padding: 8px;
          margin: 5px 10px 5px 0;
          white-space: nowrap;
        }

        .arrow-location {
          color: #5c5c7a;
          margin: auto 0;
          margin-right: 10px;
          font-size: 16px;
        }
      }

      .grid {
        flex: 1;
        margin: 1rem;
        padding: 1rem;

        &.details {
          background: #f9f9fb;
          border-radius: 8px;
        }

        .four.column.row .column:not(:last-child) {
          margin-right: 3rem;
        }
      }
    }
  }

  .vod-card-container:hover .card-top {
    background: #fff3b3;
  }
}

.hidden-preview-table {
  background-color: #fff;
  padding: 0 100px 0 100px;
  margin-bottom: 25px;
  transition: height 0.5s;
  overflow-y: hidden;
  &.is-visible {
    height: 300px;
  }
  &.not-visible {
    height: 0px;
  }
  .header-preview-title {
    font-size: 14px;
    color: #4b7093;
  }
  .table-preview {
    background-color: #f9f9fb;
    padding: 16px;
    border-radius: 8px;
  }
}

.modal-confirmation {
  .modal-header {
    font-weight: 600;
    background-color: #ebf0f5 !important;
    height: 70px;
    .modal-header-text {
      font-weight: 600;
      color: #4b7093;
      font-size: 20px;
      line-height: 40px;
    }
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    .terms-conditions {
      font-weight: 600;
      color: #c93626;
    }
  }

  .content-info {
    &.error .header-text {
      color: #c93626;
      background: #fef2f1;
      border-radius: 10px 10px 0px 0px;
    }
    &.success .header-text {
      color: #1c7d55;
      background: #eafaf4;
      border-radius: 10px 10px 0px 0px;
    }
    .header-text {
      font-weight: 500;
      font-size: 26px;
      line-height: 34px;
      padding: 14px 30px;
      margin-bottom: 0 !important;
    }
    .detail-part {
      padding: 34px 32px;
      .label-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #242433;
      }
      .label-bold-text {
        font-weight: bold;
      }
    }
  }

  .btn-action {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #0000a1;
    background: #ffffff;
    border: 1px solid #0000a1;
    border-radius: 6px;
  }

  .btn-action:hover {
    color: #ffffff;
    background: #0000a1;
  }

  .cancel-btn {
    font-weight: 600;
    font-size: 14px;
    color: $color-primary !important;
    background: $color-white !important;
  }
}