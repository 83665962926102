.confirm-request-booking-page {

    .button-demand {
        font-family: $font-normal !important;
        text-transform: capitalize !important;
        font-size: 16px !important;
        line-height: 16px !important;
        font-weight: 600 !important;
        padding: 16px 32px !important;
        border-radius: 4px !important;
    
        &.button-bg {
          background-color: #0000A1 !important;
          color: #fff !important;
        }
    }

    .banner-container {
        padding: 16px 26px 16px 26px;
        background-color: #F9B262;
        border-radius: 10px;
        width: 100%;
        
        .annoucement-content {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
    }

    .confirm-request-container {
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
        padding: 73px 200px 73px 96px;
        border-radius: 10px;
        margin-bottom: 80px;

        .info-divider-container {
          padding-right: 150px;
        }
    
        .main-form-label {
          font-size: 36px;
          font-weight: 600;
          line-height: 44px;
          margin-bottom: 45px;
        }

        .field-label {
          font-size: 12px;
          line-height: 12px;
          font-weight: 600;
        }

        .field-content {
          font-size: 18px;
          line-height: 18px;
          font-weight: 600;
        }

        .main-form-title {
          font-size: 16px;
          line-height: 16px;
          font-weight: 600;
          color: #0000A1;
        }

        .field-payload-content {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }
     
    }
}