.list-table-container {
  .vessel-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #0000A1;
    padding: 1rem;
    text-transform: capitalize;
  }

  .list-table-content {
    .card {
      box-shadow: inset 0px -1px 0px #e5e6dc;
      border-radius: 8px;
      margin-bottom: 17px;

      .header {
        padding: 1rem;

        &.Material {
          background-color: #fbe165;
        }

        &.People {
          background-color: #c4dfd7;
        }
        &.Marine {
          background-color: #aed2f2;
        }

        .voyage-id {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          margin-right: 5rem;
        }

        .job-type {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          margin: auto 0 0 auto;
        }
      }

      .body {
        padding: 1rem;

        .label-header {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          color: #6b6c70;
          margin-bottom: 1rem;
        }

        .label-value-bold {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
        }

        .bar {
          width: 30%;
          .bartext {
            font-size: 11px;

            div:first-child {
              width: 70%;
              display: inline-block;
              text-align: left;
            }

            div:last-child {
              display: inline-block;
              width: 30%;
              text-align: right;
            }
          }

          .progress-content {
            position: relative;
            .benchmark {
              position: absolute;
              width: 3px;
              height: 15px;
              margin-top: -4px;
              background: #d4d4d4;
              margin-left: auto;
              margin-right: auto;
              left: 30%;
              z-index: 1;
            }
            .progress {
              overflow: hidden;
              background-color: #e9ecef;
              position: relative;

              .progress-bar {
                background-color: #2bcf9e;
              }
            }
          }
        }
      }

      .table-content {
        table {
          border: none;
          background-color: transparent;
          thead {
            tr {
              th {
                border: none;
                background-color: transparent;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #6b6c70;
              }
            }
          }
          tbody {
            tr {
              td {
                font-size: 12px;
                line-height: 16px;
                color: #202529;
                div {
                  margin-bottom: 1rem;
                }

                div:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
