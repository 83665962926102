.system-alert-outer-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: var(--Queen-Blue-25, #EBF0F5);

    .system-alert-container {
        display: flex;
        flex-direction: column;
        margin-top: 110px;
        border-radius: 16px;
        background: #FFF;
        width: 1440px;
        min-height: 700px;

        margin-bottom: 100px;
    }
}