.booking-confirm-page {
  padding-bottom: 100px;
  .confirm-booking-title-container {
    align-items: center;
    .confirm-booking-title {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
    }

    .status-container {
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      width: 125px;
      height: 20px;
      padding: 3px 18px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color: $color-white;

      &.pending {
        background-color: #ef5d60;
      }

      &.success {
        background-color: #2bcf9e;
      }
    }
  }

  .confirm-booking-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .demand-details-title {
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: #6b6c70;
  }
  .demand-details-sub-title {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #000000;
  }

  .booking-button-container {
    margin-left: auto;
    display: flex;

    .button-icon-container {
      border: 1px solid #0000a1;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 12px;
      cursor: pointer;
      height: 48px;
      width: 48px;
      &:hover {
        background-color: #0000a1;
        svg path {
          fill: #ffffff;
        }
      }
    }
  }

  .confirm-cards-container {
    width: 100%;
    margin-bottom: 35px;
    .confirm-back-card-container {
      width: 100%;
      border-radius: 8px;
      background-color: #eaeaea;

      .front {
        width: 100%;
        .confirm-type-label {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          color: #242433;
          margin-left: 41px;
          margin-top: 16px;
        }
        .confirm-front-card-container {
          padding: 21px 60px 16px 40px;
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
          background: #ffffff;
          border-radius: 8px;
          position: relative;
          top: 18px;
          left: 0px;

          &.empty-card {
            top: -16px;
          }

          .savings-label {
            color: #0000a1;
            font-weight: 600;
            font-size: 16px;
            line-height: 14px;
            margin-bottom: 18px;
          }

          .location-container {
            width: 100%;

            &.passengers-card-container {
              width: 90%;
            }

            .start-location-container {
              width: 200px;
            }

            .end-location-container {
              margin-left: 40px;
              margin-right: 30px;
              width: 25%;
            }

            .payload-details-container {
              width: 25%;

              .payload-content-container {
                align-items: center;
              }

              .payload-details {
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                color: #202529;
              }
            }

            .seats-column {
              margin-top: 23px;
              margin-left: 30px;

              .seats-label {
                font-size: 12px;
                line-height: 12px;
                font-weight: 600;
                color: #202529;
                width: 200px;
              }
            }

            .top-card-label {
              font-weight: 600;
              font-size: 12px;
              line-height: 12px;
              color: #6b6c70;
              margin-bottom: 10px;
            }

            .content-sub-title {
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;
              &.active {
                color: #ffffff;
              }
            }

            .content-date-sub-title {
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
              &.active {
                color: #ffffff;
              }
            }

            .content-label {
              color: #96968c;
              font-size: 12px;
              font-weight: 600;
              line-height: 12px;

              &.active {
                color: #ffffff;
              }
            }

            .arrow {
              position: relative;
              width: 200px;
              margin-top: 40px;
              height: 0;
              border-bottom: 2px solid #bab3be;
              &.active {
                border-bottom: 2px solid #ffffff;
              }
            }

            .arrow::after {
              content: "";
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 10px solid #bab3be;
              position: absolute;
              right: -10px;
              top: -4px;
            }

            .arrow.active::after {
              content: "";
              width: 0;
              height: 0;
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 10px solid #fff;
              position: absolute;
              right: -10px;
              top: -4px;
            }
          }
        }
      }
    }
  }

  .original-card {
    .card-top {
      background: #ebecf3;
      border-radius: 8px 8px 0px 0px;
      .card-label {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        color: #202529;
        align-items: center;
        padding: 12px 16px;
      }
    }
    .card-bottom {
      padding: 16px 50px 27px 16px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
      border-radius: 0px 0px 8px 8px;
      .voyage-label {
        color: #202529;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
      }

      .reservation-details-container {
        width: 100%;

        .reservation-location-section {
          width: 45%;

          .start-location-container {
            width: 50%;
          }

          .end-location-container {
            margin-left: 40px;
            margin-right: 30px;
            width: 50%;
          }
        }

        .reservation-details-section {
          width: 35%;

          .payload-details-container {
            width: 100%;

            .payload-content-container {
              align-items: center;
            }

            .payload-details {
              font-size: 16px;
              line-height: 16px;
              font-weight: 600;
              color: #202529;
            }
          }
        }

        .property-label {
          margin-left: 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
        }

        .content-sub-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          &.active {
            color: #ffffff;
          }
        }

        .top-card-label {
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          color: #6b6c70;
          margin-bottom: 10px;
        }

        .status-section {
          width: 20%;

          .reservation-status-container {
            align-items: center;

            &.warning {
              svg path {
                fill: #f9b262;
              }
            }

            .reservation-status {
              font-weight: 600;
              font-size: 18px;
              line-height: 18px;

              &.warning {
                color: #f9b262;
              }
            }
          }
        }

        .content-date-sub-title {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          &.active {
            color: #ffffff;
          }
        }

        .content-label {
          color: #96968c;
          font-size: 12px;
          font-weight: 600;
          line-height: 12px;

          &.active {
            color: #ffffff;
          }
        }

        .arrow {
          position: relative;
          width: 200px;
          margin-top: 10px;
          height: 0;
          border-bottom: 2px solid #bab3be;
          &.active {
            border-bottom: 2px solid #ffffff;
          }
        }

        .arrow::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #bab3be;
          position: absolute;
          right: -10px;
          top: -4px;
        }

        .arrow.active::after {
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 10px solid #fff;
          position: absolute;
          right: -10px;
          top: -4px;
        }
      }
    }
  }
}

.button-demand {
  font-family: $font-normal !important;
  font-weight: $fontweight-600 !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
  line-height: 16px !important;
  padding: 16px 32px !important;
  border-radius: 8px !important;

  &.button-bg {
    background-color: #0000a1 !important;
    color: #fff !important;
  }
}
