.resp-fleet-component {
    width: 100%;
    .fleet-title  {
        font-family: Inter;
        font-size: 26px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        color: #242433;
        margin-bottom: 10px;
    }
    .fleet-chart-component {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-flow: row;
        .chart-component {
            width: 70%;
            margin: 0 auto;
            border-right: 1px solid #E8E8EE;
            text-align: left;
            display: flex;
            justify-content: left;
            text-align: left;
            .fleet-chart {
                text-align: left;
                width: 50%;
            }
            .fleet-labels {
                display: flex;
                flex-flow: column;
                text-align: left;
                justify-content: center;
                align-items: left;
                width: 50%;
                .labels-text {
                    font-family: Inter;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #242433;
                    width: 80%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        .co-emission {
            width: 30%; 
            display: flex;
            flex-flow: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            .emission-value {
                font-family: Inter;
                font-size: 34px;
                font-weight: 500;
                line-height: 40px;
                letter-spacing: 0em;
                color: #242433;
            }
            .emission-text {
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #5C5C7A;
            }

        }
    }

    @media (max-width: 768px) {
        .fleet-title {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
        }
        .fleet-chart-component {
            width: 100%;
            display: flex;
            flex-flow: column;
            .chart-component {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-right:none;
                margin: o auto;
                .fleet-chart {
                    text-align: left;
                    width: 35%;
                }
                .fleet-labels {
                    width: 65%;
                    .labels-text {
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #242433;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
            .co-emission {
                width: 100%; 
                display: flex;
                flex-flow: row;
                justify-content: left;
                text-align: left;
                .emission-value {
                    font-family: Inter;
                    font-size: 22px;
                    font-weight: 500;
                    line-height: 31px;
                    padding-left: 10px;
                }
                .emission-text {
                    font-family: Inter;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0em;
                    padding-left: 10px;

                }  
    
            }
        }
    }
}