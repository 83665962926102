.vod-notifications-container {
  background: #FFFFFF;
  border-radius: 16px;
  padding: 32px;
  margin: 14px;
  .slick-slider {
    .slick-dots {
      li {
        button:before {
          font-size: 10px;
          color: #242433;
        }
      }
    }
    .slick-list {
      .slick-track {
        .slick-slide {
          div > .notification-card:first-child {
            border-bottom: 2px solid #E8E8EE;
          }
        }
      }
    }
  }
  .vod-card-header {
    font-size: 26px;
    color: #242433;
    font-weight: 600;
    //letter-spacing: 0.5px;
  }
  .notification-card {
    padding: 20px;
    .notification-subject {
      color: #242433;
      font-size: 16px;
      font-weight: 600;
    }
    .notification-content {
      color: #202529;
      font-size: 14px;
    }
  }
}