.fuel-consumption-card {
    justify-content: space-between;
    margin: 0 73px;
    margin-top: 60px;
    padding-bottom: 45px;

    .fuel-consumption-detail {
        color: #0000a1;
        font-size: 34px;
        font-weight: 600;
    }

    .fuel-consumption-detail-child {
        font-size: 16px;
        margin-top: 16px;
    }

    .add-button {
        margin-top: 17px;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    .add-more {
        font-size: 16px;
        margin-top: 20px;
        color: #0000a1;
        cursor: pointer;
    }
    
    .btn-report, .btn-report:hover, .btn-report:focus, .btn-report:active {
        background-color: #0000a1;
        font-size: 16px;
        color: #fff;
        font-weight: 300;
        border-radius: 8px;
        margin-top: 40px;
    }

    .btn-report.disabled {
        background-color: #e0e0e0 !important;
        color: #000 !important;
    }
}

.fuel-grid {
    margin: 15px 0px !important;

    .react-dropdown-select-dropdown {
      top: 37px;
      box-shadow: none;
      border-radius: 8px;
    }

    .react-dropdown-select-item {
      padding: 10px 13px;
    }

    .react-dropdown-select-content {
        padding-left: 7px;
    }
}
.fuel-grid-input {
    margin-top: 10px;
    border-radius: 8px !important;
}

.remove-fuel-btn {
    margin: 14px;
    cursor: pointer;
    display: inline;
    position: absolute;
    z-index: 100;
}

.co2-result-div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    .fuel-consumption-detail {
        margin-bottom: 30px;
    }

    h4 {
        font-size: 28px;
    }
}