.booking-details-page {
  .nav-header {
    display: flex;
    align-items: center;
    gap: 5px;
    .text-orange {
      cursor: pointer;
      color: #D0460B;
      margin: 0 !important;
    }
    svg {
      margin-bottom: -3px;
    }
  }

  .booking-details-footer {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    background-color: #fff;
    padding: 1rem 165px;
    border-radius: 0.28571429rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }
  
  .reservation-upload-booking-details {
    background-color: #f9f9fb;
    padding-top: 40px;
    padding-bottom: 200px;
  }

  .reservation-details {
    padding-left: 80px;
    padding-right: 80px;
  }

}
.modal-add-booking-details-page {
  .content-message {
    display: flex;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
  }
  .success {
    color: #26A671;
  }
  .fail {
    color: #C93626;
  }
}
.msg-error-body{
  p{
    line-height: 18px;
  }
  li{
    line-height: 24px;
  }
}