.leadership-container {
  padding-bottom: 24px;
  font-family: $font-normal !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  .leadership-inner-text {
    display: flex;
    align-items: center;
    color: #6b6c70;
  }

  .card-body {
    display: flex;
    margin-right: 13px;

    .card-chart {
      width: 55%;
      min-height: 335px;
      padding: 16px;
      padding-left: 0;
    }

    .card-score {
      width: 45%;
      min-height: 335px;
      padding: 16px;
      display: flex;
      flex-direction: column;

      .card-score-item {
        display: flex;
        margin-bottom: 24px;

        .card-score-icon {
          margin-right: 12px;
        }
      }
    }
  }

  .text-bold {
    font-weight: 600;
  }

  .view-board-button,
  .view-board-button:hover,
  .view-board-button:focus {
    font-weight: 600;
    line-height: 14px;
    color: #0000A1;
    background-color: transparent;
    padding-left: 5px !important;
    margin-top: auto;
    margin-bottom: 40px;
    text-align: left;
  }

  .ranger-label-container {
    display: flex;

    .ranger-label-toogle {
      margin-left: 4px;
      margin-top: 2px;
    }
  }

  .margin-bottom-16 {
    margin-bottom: 16px;
  }

  .margin-bottom-24 {
    margin-bottom: 24px;
  }
  .margin-bottom-32 {
    margin-bottom: 32px;
  }
}

.leadership-board {
  font-size: 14px;
  line-height: 21px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .modal-content {
    padding: 32px 48px;
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  .close {
    cursor: pointer;
  }
  .table-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-right: 4px;
    padding: 8px 16px;
    background-color: #eaeaea;
    border-radius: 4px;

    font-weight: 600;
  }
  .table-cell {
    word-break: break-all;
    &.first {
      width: 13%;
    }
    &.second {
      width: 30%;
    }
    &.third {
      width: 20%;
    }
    &.fourth {
      width: 23%;
    }
    &.fifth {
      width: 14%;
    }
  }
  .table-body {
    max-height: 380px;
    overflow-y: auto;
    position: relative;
    padding-right: 4px;

    /* width */
    &::-webkit-scrollbar {
      position: absolute;
      width: 4px;
      left: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #c4c4c4;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #c4c4c4;
    }
  }
  .table-row {
    display: flex;
    align-items: center;
    margin: 8px 0;
    padding: 8px 16px;
    border: 1px solid #F0F0FA;
    border-radius: 4px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.highlighted {
      font-weight: 600;
      background-color: #F0F0FA;
      border-left: 4px solid #0000A1;
    }
  }
}
