// .custom-date-picker {
//   // padding: $rem-8 $rem-16;
//   outline: none;
//   border: none;
// }

// // Date Range

.date-range-picker-custom {
    color: rgba(0,0,0,0.87) !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
//   background-color: $color-primary !important;
//   color: #fff !important;
//   font-weight: 400 !important;
//   width: 200px;
//   padding: 0rem 1.5rem !important;
//   &.disabled {
//     background-color: #cccccc !important;
//   }
}

// .rdrSelected,
// .rdrInRange,
// .rdrStartEdge,
// .rdrEndEdge {
//   background: rgba(255, 255, 255, 0.5) !important;
// }

// .rdrWeekDay {
//   color: #fff !important;
// }

// .rdrMonthAndYearWrapper {
//   color: #fff;
// }

// .rdrCalendarWrapper {
//   background: $color-primary !important;
// }

// .rdrDayNumber span {
//   color: #fff !important;
// }

// // Month Range

// .react-datepicker {
//   font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
//   font-size: 0.8rem;
//   background-color: #004a97;
//   color: #fff;
//   border: none;
//   border-radius: 0.3rem;
//   display: inline-block;
//   position: relative;
// }

// .react-datepicker__header {
//   text-align: center;
//   background-color: #004a97;
//   color: #fff;
//   border-bottom: none;
//   border-top-left-radius: 0.3rem;
//   padding-top: 8px;
//   position: relative;
// }

// .react-datepicker__month-text,
// .react-datepicker__quarter-text,
// .react-datepicker__year-text {
//   border: 0.5px solid transparent;
//   padding: 5px;

//   &:hover {
//     background-color: #6897c4;
//     border: 0.5px solid #2a87d0;
//     border-radius: 0.3rem;
//   }
// }

// .react-datepicker__month-text--keyboard-selected {
//   background-color: rgba(255, 255, 255, 0.5);
//   text-align: center;
// }
