.confirm-schedule-overview-container{
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 999;

    .schedule-overview-page{
        position: relative;
        background-color: #F5F7FE;
        padding: 2rem 165px;

        .schedule-overview-drawer-btn{
            width: 180px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;

            &.published{
                width: 250px;
            }

            &.disabled{
                color: #d9d9d9;
                cursor: not-allowed;
            }
        }

        .schedule-overview-container{
            padding: 0rem;
            .schedule-overview-content{
                .label-header{
                    font-size: 14px;
                    line-height: 14px;
                    color: #202529;
                }

                .label-value{
                    font-size: 14px;
                    line-height: 14px;
                    color: #202529;
                }

                .bar{
                    margin-bottom: 3rem;

                    &.bottom-bar{
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .schedule-overview-footer-container{
            .right-content{
                margin-left: auto;
            }
        }
    }
}

.cvs-footer {

    .schedule-overview-page{
        position: relative;
        background-color: #F5F7FE;
        padding: 2rem 165px;

        .schedule-overview-drawer-btn{
            width: 180px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;

            &.published{
                width: 250px;
            }

            &.disabled{
                color: #d9d9d9;
                cursor: not-allowed;
            }
        }

        .schedule-overview-container{
            padding: 0rem;
            .schedule-overview-content{
                .label-header{
                    font-size: 14px;
                    line-height: 14px;
                    color: #202529;
                }

                .label-value{
                    font-size: 14px;
                    line-height: 14px;
                    color: #202529;
                }

                .bar{
                    margin-bottom: 3rem;

                    &.bottom-bar{
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .schedule-overview-footer-container{
            .right-content{
                margin-left: auto;
            }
        }
    }
}

.confirm-publish-dummy-voyage-table-container {

    .ui.table thead th {
        color: #6b6c70;
        font-size: 14px;
    }

    .cpdvt-cell-strong{
        font-size: 18px;
        font-weight: 600;
    }

    .cpdvt-location-pill {
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: #460073;
        background: #f0f0fa;
        border-radius: 8px;
        padding: 5px 10px;
        margin: 5px 10px 5px 0;
    }

    .cpdvt-location-arrow {
        color: #460073;
        margin: auto 10px auto 0;
        font-size: 16px;
    }
}