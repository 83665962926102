.voyage-sidebar-modal {
  position: absolute;
  top: 0;
  right: 0;
  width: 960px;
  height: 100%;
  overflow-y: hidden;

  .voyage-sidebar-close-button-container {
    position: absolute;
    top: 14px;
    right: 14px;

    .voyage-sidebar-close-button {
      background-color: transparent;
      padding: 0;
    }
  }

  .voyage-sidebar-header {
    position: relative;
    width: 100%;
    padding: 32px 32px 0px;

    &.voyage-sidebar-material-header {
      background-color: #fbe165;
    }

    &.voyage-sidebar-standby-header {
      background-color: #e9a85efd;
    }

    &.voyage-sidebar-people-header {
      background-color: #c4dfd7;
    }

    &.voyage-sidebar-marine-header {
      background-color: #aed2f2;
    }

    &.voyage-sidebar-default-header {
      background-color: #eaeaea;
    }

    .voyage-sidebar-header-title {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.03em;
      color: #202529;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        text-overflow: unset;
      }
      .new-trip-id {
        font-size: 14px;
        font-weight: 500;
        color: #5C5C7A;
        margin-top: -10px;
      }
    }

    .voyage-sidebar-subtitle-create-by{
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }

    .voyage-sidebar-header-content {
      justify-content: space-between;
    }

    .voyage-sidebar-header-label {
      font-size: 16px;
      line-height: 21px;
      color: #202529;

      &.voyage-sidebar-header-label-bold {
        font-weight: 600;
      }

      &.voyage-sidebar-header-label-red {
        color: $color-highlight-red;
      }
    }

    .voyage-sidebar-download-button {
      .download-button {
        font-weight: 500;
        font-size: 14px;
        background-color: transparent;
        color: #202529;
        margin-right: 30px;

        .download-icon {
          height: 24px;
          cursor: pointer;
        }

        &.disabled {
          opacity: 1 !important;
          border-color: transparent;
          color: #6b6c70;
        }
      }
      .voyage-id {
        gap: 6px;
      }   
      .voyage-sidebar-copy-icon-container {
        padding: 4px 3px;
        user-select: none;
        transition: all 0.2s;
        border-radius: 3px;
        &:active {
          border: 1px solid #dfdfe5;
        }
        .voyage-sidebar-copy-icon {
          cursor: pointer;       
        }
      }
         
    }
    .voyage-activity-container {
      margin-top: 20px;
      .voyage-activity-select {
        padding: 12px 16px;
        border: 1px solid #D9D9E3;
        border-radius: 8px;
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        background-color: #FFF;
        span {
          color: #757595;
        }
      }
      .voyage-activity-title {
        span {
          font-size: 14px;
          color: #5C5C7A;
        }        
      }
    }
  }

  .voyage-sidebar-subheader {
    position: relative;
    height: 90px;
    background-color: #f5f7fe;
    padding: 1rem 32px;

    .voyage-sidebar-subheader-item {
      &.voyage-sidebar-subheader-item-long {
        width: 30%;
      }

      .voyage-sidebar-subheader-label {
        font-size: 14px;
        line-height: 21px;
        color: #202529;
        padding: 5px 0;

        &.voyage-sidebar-subheader-label-bold {
          font-weight: 600;
        }
      }
    }
  }

  .voyage-sidebar-body {
    position: relative;
    height: calc(100vh - 360px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 72px;

    .voyage-sidebar-body-top-message {
      position: fixed;
      top: 270px;
      // margin: 24px 24px 22.5px 40px;
      width: calc(100% - 10px);
      height: 72px;
      background-color: #fff;
      padding: 24px;
      z-index: 2;
    }

    .voyage-sidebar-body-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #6b6c70;
    }

    .voyage-sidebar-body-warning {
      margin: -10px 0 15px 20px;
      font-size: 14px;
      color: #0000A1;
    }
  }

  .voyage-sidebar-footer {
    position: relative;
    width: 100%;
    height: 90px;
    background-color: #fff;
    padding: 24px;
    border-top: 1px solid #e0e1e8;

    .ui.button {
      &.primary {
        background: #0000a1;
        color: #fff;
      }

      &.secondary {
        background: #fff;
        color: #0000a1;

        &.disabled {
          border-color: transparent;
          color: #bab3be;
        }
      }
    }
  }

  .voyage-sidebar-info-button {
    align-self: center;
  }

  .voyage-sidebar-d3-control-container {
    padding: 0 24px;
    display: flex;
    gap: 8px;
    
    &.pcsb-user{
      padding: 24px 32px;
      margin: 0 24px;
      background: var(--independence-grey-light-n-20, #F0F0F4);
      border-radius: 8px;
    }
    .voyage-sidebar-d3-optimisation-button,
    .voyage-sidebar-demand-data-button,
    .voyage-sidebar-d3-edit-button {
      border: 1px solid #0000a1 !important;
      color: #0000a1 !important;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      font-weight: 700;
      font-size: 16px;
    }

    .voyage-sidebar-d3-optimisation-button,
    .voyage-sidebar-demand-data-button {
      background-color: #fff;
      gap: 8px;
      width: 100%;
      &.optimisation-button-disable{
        background: var(--independence-grey-light-n-50-disabled-text, #CACAD8);
        color: #fff !important;
        border-color: transparent!important;

        svg {
          path {
            stroke: #fff;
            fill: #fff;
          }
        }
      }
      svg {
        path {
          stroke: #0000a1;
          fill: #0000a1;
        }
      }

      &:hover {
        color: #fff !important;
        background-color: #0000a1;

        svg {
          path {
            stroke: #fff;
            fill: #fff;
          }
        }
      }

      .d3-optimisation-icon {
        svg {
          vertical-align: middle;
          animation: anime 2s linear infinite;
        }
      }
    }

    .voyage-sidebar-d3-edit-button {
      width: 48px;
      background-color: #fff;
      svg {
        path {
          stroke: #0000a1;
        }
      }

      &:hover {
        background-color: #0000a1;

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  &.voyage-error-sidebar-modal {
    .voyage-sidebar-header {
      background-color: #f8d7da;
    }
  }

  &.voyage-replaced-sidebar-modal {
    .voyage-sidebar-header {
      background-color: #d9d9e3;
    }
  }
}

.voyage-sidebar-info-message-container {
  min-width: 300px;
  padding: 0.5rem 1rem;

  .voyage-sidebar-info-message-header {
    font-weight: 600;
    border-bottom: 1px solid #e0e1e8;
  }

  // .voyage-sidebar-info-message-content {}

  // .voyage-sidebar-info-message-left {}

  .voyage-sidebar-info-message-right {
    margin-left: auto;
  }

  .voyage-sidebar-info-message-label {
    &.voyage-sidebar-info-message-green {
      color: green;
    }

    &.voyage-sidebar-info-message-red {
      color: $color-highlight-red;
    }
  }
}

@keyframes anime {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.voyage-sidebar-compare-modal {
  border-radius: 10px !important;
  height: 464px;
  width: 652px !important;
  overflow: hidden;

  .header {
    padding: 15px 32px !important;
    font-weight: 500 !important;
    font-size: 26px !important;
    color: #4b7093 !important;
    background: #ebf0f5 !important;
    border-bottom: none !important;
    .header-content {
      align-items: center;
      svg {
        margin-top: 10px;
      }
    }
  }
  .content {
    padding: 30px 40px !important;
    font-size: 16px;
    .list {
      font-size: 16px;
      margin-left: 35px;
    }
  }

  .footer {
    bottom: 0;
    right: 0;
    position: absolute;

    .btn {
      font-weight: 600;
      font-size: 16px;
      border: 1px solid #0000a1;
      padding: 12px 48px;
      span {
        line-height: 22px;
      }
      border-radius: 8px;
    }

    .btn-action2 {
      color: #0000a1 !important;
      background-color: #ffffff;
      &:hover {
        color: white !important;
        background-color: #0000a1;
        svg path {
          stroke: #ffffff;
        }
      }
    }

    .btn-action3 {
      color: #ffffff !important;
      background-color: #0000a1 !important;
    }
  }
}

.modal-voyage-activity {
  width: 900px;
  right: 0;
  border-radius: 10px !important;
  overflow: hidden;

  .modal-voyage-activity-header {
    background-color: #EBF0F5;
    padding: 27px 40px;

    span {
      font-size: 26px;
      color: #4B7093;
      font-weight: 500;
    }
  }

  .modal-voyage-activity-content {
    padding: 24px 40px 40px 40px;

    .modal-voyage-text {
      span {
        font-size: 16px;
        color: #242433;
      }
    }
    .modal-voyage-body {
      margin-top: 20px;
      display: flex;
      gap: 30px;
      justify-content: space-between;
      .voyage-activity-selection {
        width: 300px;
        p {
          color: #5C5C7A;
        }
        .voyage-activity-dropdown {
          justify-content: space-between;
          height: 46px;
          background-color: #fff;
          font-size: 14px;
          display: flex;
          color: #242433;
          width: 100%;
          align-items: center;
          border-radius: 8px;
          margin-top: 8px;
          padding-left: 16px !important;

          .menu {
            max-height: 130px !important;
            border-radius: 0 0 8px 8px;
          }

          .search {
            height: 46px !important;
          }
        }
      }
      .voyage-activity-colour-content {
        display: flex;
        gap: 3px;
        margin-top: 8px;
      }
      .voyage-activity-colour {
        p {
          color: #5C5C7A;
        }
        .voyage-activity-colour-box {
          padding: 6px;
          border: 2px solid #FFF;
          border-radius: 4px;
          cursor: pointer;

          .voyage-activity-colour-box-content {
            width: 30px;
            height: 30px;            
            border-radius: 4px;
          }
        }
      }
    }

    // Remarks Section
   .voyage-activity-remarks {
      width: 100%;
      margin-top: 20px;

      textarea {
        width: 100%;
        height: 60px;
        border: 1px solid #d9d9e3;
        border-radius: 8px;
        padding: 12px 16px;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #242433;
        resize: none;
        box-shadow: none;
        outline: none;
        overflow-y: auto;
        line-height: 20px;
      }
    }

    .voyage-activity-remarks-count {
      font-size: 12px;
      color: #888888;
      text-align: right;
    }

    .modal-voyage-activity-action {
      margin-top: 20px;
      text-align: right;
      .btn {
        padding: 15px 32px;
        border-radius: 8px;
        background: none;
        border: 1px solid #0000A1;
        color: #0000A1;

        &.btn-confirm {
          background-color: #0000A1;
          color: #FFF;
          margin-left: 20px;
        }
      }
    }
  }
}

.voyage-activity-remarks-container {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: #242433;

  span {
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 100%;
    max-width: 100%;
  }
}

.schedule-breakdown-tooltip {
  max-width: 334px !important;
  background-color: #ffffff !important;
  border-radius: 10px !important;
  border: none !important;
  padding: 10px !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
  text-align: left;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
    top: 100%;
    left: 20px;
    transform: translateX(0);
    margin-top: -4px;
  }
}

.broken-down {
  top: 0 !important;
}

@media only screen and (min-width: 1920px) {
  .modal-voyage-activity {
    width: 950px;
  }
}
