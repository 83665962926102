.mooring-buoy-management-page {
  padding: 128px 165px 20px;
  .maintenance {
    padding: 0 15px !important;
  }
  .left-content {
    flex: 1;
  }

  .right-content {
    margin: auto;
  }

  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  .medium-text-black {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-large-text-black-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .medium-text-purple {
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .medium-text-blue-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4a5496;
  }

  .btn-text-purple-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    cursor: pointer;
  }

  .btn-action {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    background-color: #ffffff;
    border: 1px solid #0000A1;
    .items-center {
      align-items: center;
      svg {
        width: 30px !important;
        path {
          stroke-width: 4;
        }
      }
    }
  }

  .btn-action:hover,
  .btn-action:hover svg path {
    color: #ffffff;
    background-color: #0000A1;
    stroke: #ffffff;
  }

  .btn-back {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0000A1;
    border: none;
    width: 250px;
    border-radius: 4px;
    background-color: #ffffff;
    width: auto;
    &:disabled {
      background-color: gray;
      color: white;
    }
    &:hover {
      color: #ffffff;
      background-color: #0000A1;
    }
  }

  table tr td {
    i {
      color: #0000A1;
      cursor: pointer;
    }
  }

  .page-title {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }

  .filter-content {
    margin: 1rem 0;
    padding: 2rem;
    background: #f5f7fe;
    border-radius: 4px;

    .filter-status {
      width: 100% !important;
      background-color: #ffffff !important;
      font-size: 1em !important;
      font-weight: 400;
      display: inline-flex;
      color: rgba(0, 0, 0, 0.87);
      padding-top: 2px;
      border: 1px solid #ccc !important;
      &:focus {
        border: 1px solid #96c8da;
        box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
      }
      i {
        font-size: 1em;
      }
    }

    .checked-category-container {
      flex-wrap: wrap;
      max-height: 100px;
      overflow-y: auto;

      .checked-category {
        background: #f0f0fa;
        border-radius: 20px;
        border: 1px solid #4a5496;
        .fitler-category-icon{
          svg{
            path{
              stroke: #4a5496;
            }
          }
        }

        .filter-icon-cancel {
          color: #4a5496 !important;
        }
        
        .category-text {
          font-weight: 600;
          font-size: 14px;
          color: #4a5496;
          margin: 5px;
        }

        div,
        i {
          margin: auto;
        }
      }
    }

    .utilization-filter {
      span {
        margin: auto 1rem auto 2.5rem;
      }

      .input {
        width: 60px;
        border-radius: 4px;
        padding-right: 0;
      }
    }

    .btn-apply {
      border: 1px solid #0000A1;
    }
  }

  .mooring-buoy-table-container {
    .icon-action-table{
      i{
        background: none !important;
      }
    }
    table {
      border: none;
      border-collapse: collapse;

      thead th {
        background-color: #f0f0fa !important;
        border-left: none !important;
      }

      tbody {
        tr:nth-child(2n) {
          background-color: #ffffff !important;
        }
        tr:nth-child(4n-1) {
          background-color: #f5f7fe !important;
        }
        tr:nth-child(4n) {
          background-color: #f5f7fe !important;
        }
        .view-log {
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
          color: #0000A1;
          cursor: pointer;
        }
        .row {
          height: 60px;
          &.expand {
            border: 1.5px solid #0000A1;
            border-bottom: none;
          }

          td {
            padding-top: 22px;
            &.location-next {
              padding-top: 0.78571429em;
              vertical-align: middle;
              .name {
                margin-bottom: 3px;
              }
              .time {
                font-size: 0.85rem;
                color: #6b6c70;
              }
            }
            .action-item {
              color: #6b6c70;
            }
          }
        }

        .row-edit {
          &.expand {
            border: 1.5px solid #0000A1;
            border-top: none;
          }

          .content {
            padding: 1rem 6rem;

            .sub-table-content {
              padding: 1rem;
              .medium-large-text-black-bold {
                color: #4a5496;
                font-weight: 600;
                font-size: 16px;
                line-height: 16px;
              }
              .ui.table {
                background: transparent !important;
              }
              table tr {
                background-color: transparent !important;
                th,
                td {
                  border: none;
                  font-size: 16px;
                }

                th {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 14px;
                  color: #6b6c70;
                  background-color: transparent !important;
                }

                td {
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 24px;
                  color: #000000;
                  border-bottom: 0.5px solid #e0e1e8;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal-mooring-buoy-management {
  .grid {
    margin: auto;
    padding: 0 60px;
  }

  .large-text-bold {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #202529;
    background: #f5f7fe;
    border-radius: 4px 4px 0px 0px;
  }

  .medium-small-text-black-bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 1rem;
  }

  .medium-text-black {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .medium-text-black-bold {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
  }

  .medium-large-text-black-bold {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
  }

  .mooring-buoy-management-dropdown {
    width: 100% !important;

    input,
    .text {
      font-size: 16px;
      line-height: 24px;
      color: #202529 !important;
    }

    i {
      font-size: 25px;
      padding: 0.5rem 0;
      float: right;
    }
  }

  .mooring-buoy-management-dropdown {
    border: 1px solid #202529 !important;
  }

  .upload-maintenance_log {
    border: 1px solid #202529;
    height: 200px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: dashed;
    flex-direction: column;
    svg {
      height: 40px;
      cursor: pointer;
      path {
        stroke-width: 3;
      }
    }
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #202529;
      .browse-text {
        color: #0000A1;
        font-weight: 600;
      }
    }
  }

  .buttons {
    margin: 0 60px 40px 0px;
  }
}
