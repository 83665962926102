.shipment-en-route-container {
  .custom-slider .slider-body {
    padding: 0 !important;
  }

  .ui.tabular.menu {
    border-bottom: none;
    margin-bottom: 34px;
    overflow: auto;
    overflow-y: hidden;
    .item {
      color: #bab3be;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      border: none;
    }
  }

  .ui.tabular.menu .active.item {
    border-bottom: 2px solid #0000a1;
    color: #000000;
  }

  .empty-tab {
    height: 400px;
    font-size: 18px;
    line-height: 24px;
    color: #202529;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
