@mixin button-chart {
    bottom: 0;
    margin: 0;
    position: absolute;
    z-index: 990;
    background-color: white;
}
.schedule-comparison-container {
    .month-toggle {
        /*.ui.slider.checkbox input:focus:checked~.box:before,
        .ui.slider.checkbox input:focus:checked~label:before {
            background-color: #0000A1 !important;
        }*/

        .ui.slider.checkbox input:focus:checked~.box:before,
        .ui.slider.checkbox input:focus:checked~label:before {
            background-color: #0000A1 !important
        }

        .ui.slider.checkbox .box:before, .ui.slider.checkbox label:before {
            background-color: #CCCCCC;
        }

        /*.ui.slider.checkbox input:focus:checked~.box:after,
        .ui.slider.checkbox input:focus:checked~label:after {
            background-color: #0000A1 !important;
        }*/

        /*.ui.slider.checkbox .box:before, .ui.slider.checkbox label:before {
            background-color: #0000A1;
        }*/
    }

    .tab-container {
        min-height: 20%;
        border: none;
        .menu {
            margin-top: -1rem;
            .item {
              font-size: 18px;
              font-weight: 600 !important;
              color: #bab3be;
            }
            .active {
              // text-decoration: underline;
              border-bottom: 2px solid #0000A1 !important;
              // text-decoration-color: #0000A1;
              // text-decoration-thickness: 3px;
              color: black;
            }
        }
        div {
            .segment {
                border: none;
            }
        }
    }

    .period-picker {
        .date-range-picker-custom {
            width: 100%;
            .flex {
                span {
                    float: left;
                    width: 100%;
                    text-align-last: start;
                }
                .caret {
                    float: right;
                    width: 100%;
                    text-align-last: end;
                }
            }
        }
    }

    .text-navigate {
        float: right;
        color: #0000A1;
        font-weight: 600;

        &.is-recent {
            display: none;
        }
    }
    .title-name {
        .right-aligned {
            span {
                font-weight: 600;
                place-self: center;
                line-height: 21px;
            }
        }
    }
    .region-dropdown {
        .label-header {
            font-weight: 600;
            line-height: 14px;
        }
    }

    .kpi-dropdown {
        .dropdown {
            border: none;
        }
        .legend-icon {
            vertical-align: middle;
        }
    }

    .highchart-relative {
        position: relative;

        .button-left {
            @include button-chart;
            left: 0;
        }

        .button-right {
            @include button-chart;
            right: 0;
        }
    }
}