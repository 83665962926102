.dropdown-checkbox-custom {
  position: absolute;
  width: 100%;
  &.show {
    z-index: 11;
  }

  &.hide {
    z-index: 0;
  }

  .filter-search input {
    box-sizing: border-box;
    border-radius: 4px !important;
    z-index: 1;
  }
  .checkbox-container {
    background-color: #ffffff;
    border: 1px solid #96c8da;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;

    &.hide {
      display: none;
    }

    .checkbox-filter {
      flex: unset;

      input[type='checkbox'] + label::before {
        background-color: #ffffff;
      }

      input[type='checkbox']:checked + label::after {
        background-color: #0000A1;
        color: #ffffff;
      }
    }

    .filter-not-found {
      font-size: 14px;
    }
  }
}
