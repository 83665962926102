.vtcp-container {
    position: relative;
    left: 25px !important;
    width: calc(100% - 25px);
    margin-bottom: 1rem;

    .vtcp-bullet-container {
        position: relative;
        width: 22px;
    }

    .vtcp-bullet {
        position: relative;
        top: 16px;
        width: 22px;
        height: 22px;
        background-color: #FFF;
        border: 2px solid #4A5496;
        border-radius: 50%;

        &.vtcp-bullet-journey {
            border-color: #BAB3BE;

            .vtcp-bullet-inner {
                &.vtcp-bullet-inner-filled {
                    background-color: #BAB3BE;
                }
            }
        }
    }

    .vtcp-bullet-inner {
        position: relative;
        width: 18px;
        height: 18px;
        background-color: #FFF;
        margin: auto;
        border: 3px solid #FFF;
        border-radius: 50%;

        &.vtcp-bullet-inner-filled {
            background-color: #4A5496;
        }
    }

    .vtcp-bullet-lower {
        position: absolute;
        top: 38px;
        bottom: 0px;
        width: 100%;
        background-color: #FFF;
    }

    .vtcp {
        position: relative;
        left: 1rem;
        width: 800px;
    }

    .vtcp-header {
        position: relative;
        width: 100%;
        min-height: 60px;
        background-color: #EBECF3;
        padding: 0 16px;
        border-radius: 8px 8px 0 0;

        &.vtcp-header-journey {
            background-color: #F5F7FE;
        }

        &.vtcp-header-expanded {
            padding: 16px;
        }

        &>div {
            // display: inline-block;
            // vertical-align: middle;
            align-self: center;
        }

        .vtcp-header-message-good-weather {
            margin-bottom: 22px;
            gap: 10px;
            span {
                font-size: 18px;
                color: #409B31;
                font-weight: 500;
            }
        }
    }

    .vtcp-header-location {
        font-weight: 600;
        font-size: 18px;
        line-height: 18px;
        align-self: center;
    }

    .vtcp-header-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #4a5496;
        background: #fff;
        padding: 4px 8px;
        border: 1px solid #c4c8df;
        border-radius: 4px;
    }

    .vtcp-header-error-icon {
        position: relative;
        margin-right: 0.5rem !important;
    }

    // .vtcp-header-subtitles {}

    .vtcp-header-daterange {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        width: 100%;
    }

    .vtcp-header-error-message {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: red;
    }

    .vtcp-header-expand-toggle {
        position: relative;
        width: 27.5px;
        height: 26px;
        margin-left: auto;
    }

    .vtcp-header-expand-toggle-btn {
        position: relative;
        background-color: transparent;
        padding: 0 !important;
        border: 0;

        &:hover,
        &:focus {
            background-color: transparent;
        }

        &.button.disabled {
            border: 0;
            background-color: transparent;
        }
    }

    .vtcp-header-input-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 8px;
    }

    .vtcp-body {
        position: relative;
        width: 100%;
        background-color: #FBFCFF;
        padding: 0 32px;
    }

    .vtcp-body-section {
        position: relative;
        width: 100%;
        border-bottom: 1px solid #E0E1E8;
        padding: 32px 0;

        &:last-child {
            border-bottom: none
        }
    }
    .vtcp-body-weather-header {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #5C5C7A;
        margin-bottom: 20px;
    }
    .weather-alert-warning {
        margin-top: 3px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #E74C3B;
    }

    .vtcp-body-weather-alert {
        width: 100%;
        background: #FEF2F1;
        padding: 16px 24px;
        margin-bottom: 20px;
        border-radius: 8px;

        .vtcp-body-weather-alert-header {
            color: #C93626;
        }

        .vtcp-body-weather-alert-body {
            padding-left: 36px;
            color: #242433;
        }

        .vtcp-body-weather-alert-icon {
            padding: 5px;
            vertical-align: middle;
        }

        .vtcp-body-weather-alert-label {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;

            &.vtcp-body-weather-alert-label-bold {
                font-weight: 500;
            }
        }
    }

    .vtcp-body-content-half {
        position: relative;
        width: 50%;
    }

    .vtcp-body-content-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 8px;

        &.vtcp-body-content-label-lg {
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
        }

        &.vtcp-body-content-label-sm {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        &.vtcp-body-content-label-top-mar{
            margin-top: 8px;
        }
    }

    .vtcp-underdeck-row {
        width: 100%;

        .vtcp-underdeck-col {
            align-self: center;

            &.vtcp-underdeck-col-10 {
                width: 100%;
            }

            &.vtcp-underdeck-col-9 {
                width: 90%;
            }

            &.vtcp-underdeck-col-8 {
                width: 80%;
            }

            &.vtcp-underdeck-col-7 {
                width: 70%;
            }

            &.vtcp-underdeck-col-6 {
                width: 60%;
            }

            &.vtcp-underdeck-col-5 {
                width: 50%;
            }

            &.vtcp-underdeck-col-4 {
                width: 40%;
            }

            &.vtcp-underdeck-col-3 {
                width: 30%;
            }

            &.vtcp-underdeck-col-2 {
                width: 20%;
            }

            &.vtcp-underdeck-col-1 {
                width: 10%;
            }

        }
    }

    .vtcp-underdeck-del-btn {
        background-color: transparent;
        padding: 0 !important;

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    .vtcp-underdeck-new-mat-btn {
        // max-height: 38px;
        height: 38px;
        padding: 0 !important;
        background-color: transparent;

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    .vtcp-underdeck-new-mat-btn-img {
        vertical-align: middle;
    }

    .vtcp-underdeck-new-mat-btn-text {
        display: inline-block;
        vertical-align: middle;
    }

    .vtcp-footer {
        position: relative;
        margin-top: 11.5px;
    }

    .vtcp-footer-speed {
        position: relative;
        left: -16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #6B6C70;
    }

    .vtcp-footer-btn-container {
        padding-left: calc(1rem + 0.25em);
        border-left: 1px solid #E0E1E8;

        &:first-child {
            padding: 0;
            border: none;
        }
    }

    .vtcp-footer-btn {
        height: 30px;
        background-color: transparent;
        padding: 0 !important;

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }

    .vtcp-footer-btn-img {
        vertical-align: middle;
    }

    .vtcp-footer-btn-text {
        display: inline-block;
        vertical-align: middle;
    }    
}

.vtp-dropdown {
    background-color: #FFF !important;
}