.fuel-capping-container {
  background-color: #ebf0f5;
  padding: 112px 80px;
  overflow: auto;
  letter-spacing:0.05em !important;
  .box {
    border-radius: 16px;
    background-color: #ffffff;
    padding: 24px 34px;
    min-height: calc(100vh - 20px);

    .title {
      font-size: 26px;
      font-weight: 500;
      color: #242433;
      padding-top: 31px;
    }

    .ui.disabled.input{
      opacity: 1;
    }

    button.optimise{
      border-radius: 8px !important;
      font-weight: 600 !important;
      font-size: 16px !important;
      line-height: 24px !important;
      min-width: 180px;
      height: 48px;

      &.custom-btn{
        color: #0000A1 !important ;
        border: 1px solid #0000A1 ;
      }

      &.custom-btn:hover{
        color: #0000A1 !important;
        border: 1px solid #0000A1 !important;
      }
      
    }

    .info-icon{
      position: absolute;
      color: #3B5773;
      font-size: 12px;
      top: -5px;
    }

    .box{
        border-radius: 16px;
        background-color: #FFFFFF;
        padding: 24px 34px;
        min-height: calc(100vh - 90px - 80px - 32px - 32px);
        .title {
          font-size: 26px;
          font-weight: 500;
          color: #242433;
          padding-top: 31px;
        }
      
        .content {
          margin-top: 42px;
          box-sizing: border-box;
          .vessel-info-section {
            .section-title {
                color: #242433;
                font-size: 18px;
                font-weight: 500;
            }
            .section-content {
              display: grid;
              margin-top: 30px;
              grid-template-columns: 282px 282px 282px;
              row-gap: 36px;
              column-gap: 40px;

              .label {
                color: #5C5C7A;

                .required {
                  color: red;
                }
              }
              .dropdown {
                border-radius: 8px;
                border-color: #D9D9E3;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 46px;
                padding: 12px 16px;
                font-size: 14px;
                margin-top: 10px;
              }

              .input-field {
                input {
                  border-radius: 8px;
                  margin-top: 10px;
                  border-color: #D9D9E3;
                  height: 46px;
                }
              }

              .fuel-capping-date-picker {
                i.calendar{
                  line-height: unset;
                }
              }
            }
          }
        }

        .section-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2rem;
        }
        .summary-footer {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          padding: 1rem;

          .summary-footer-item {
            display: flex;
            align-items: center;
            gap: 1.5rem;
          }
        }
    }
  }
}

