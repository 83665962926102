.virtus-support {
  z-index: 9997;
  position: fixed;
  bottom: 16%;
  right: 2%;
  
}
.virtus-support-btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #FFFFFF; 
  border-radius: 50%;
  box-shadow: 0px 0px 10px #CCCCCC;
}
.virtus-support-btn-active {
  background-color: #0000A1; 
}

.virtus-support-popup-container {
  z-index: 9998;
  position: fixed;
  bottom: 2.5%;
  right: 5%;
  width: 800px;
  border-radius: 20px;
  background-color: #FFFFFF; 
  box-shadow: 0px 0px 10px #CCCCCC;
  .content-header {
    width: 100%;
    background-color: #0000A1;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
    .content-header-text {
        color: #ffffff;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }
    .content-header-icon {
        cursor: pointer;
    }
  }
}
.content-form {
  height: 92vh;
  overflow-y: scroll;
  border-radius: 20px;
  .contact-sub-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.0168em;
    text-align: left;
    color: #242433;
  }
  .contact-sub-text-link {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.0168em;
    text-align: left;
    color: #407BFF;   
    padding-bottom: 20px; 
  }
}

#hubspotForm iframe {
  height: 900px !important;
}

@media only screen and (max-width: 1600px) {
  .virtus-support-popup-container {
      right: 7%; 
  }
}

@media only screen and (max-width: 768px) {
  .virtus-support-popup-container {
      right: 80px; 
  }
}