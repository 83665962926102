.summary-section-container {
  margin: 1rem 0;
  padding: 2.5rem 1.5rem;
  background-color: #f9f9fb;

  .link-buttons{
    display: flex;
    justify-content: end;
    gap: 10px;
  }

  .link {
    display: contents;
    float: right;
    color: #0000A1;
    cursor: pointer;
    &.disabled {
      color: grey;
      cursor: not-allowed;
    }
  }

  .text-bold {
    font-weight: 600;
  }

  .text-lg {
    font-size: 1.25rem;
  }

  .text-xl {
    font-size: 1.5rem;
  }

  .summary-title {
    font-weight: 600;
    font-size: 1.75rem;
    svg {
      color: grey;
    }
    padding-bottom: 10px;
  }

  .summary-content {
    display: grid;
    grid-template-columns: 5fr 3fr;
    grid-gap: 2rem;
  }

  table {
    max-width: 45rem;
  }

  td {
    background-color: white;
    padding: 1rem;
    border: 1px solid #e8e8ed;
    border-collapse: collapse;

    &.value {
      border: 2px solid #e8e8ed;
      text-align: right;
    }
  }

  th {
    text-align: right;
    padding: 0.5rem;

    &.value {
      text-align: right;
    }
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .items-end {
    align-items: end;
  }

  .block {
    display: block;
  }

  .hightlight {
    background-color: #fdfbe2;
    padding: 1.5rem;
  }

  .green {
    color: #599941;
  }

  .red {
    color: rgb(224,73,24);
  }

  .p-1 {
    padding: 1rem;
  }

  .d-flex {
    display: flex;
  }

  .gap-1 {
    gap: 1rem;
  }

  .gap-2 {
    gap: 2rem;
  }

  .items-baseline {
    align-items: baseline;
  }

  .text-md{
    font-size: 1rem;
  }

  .black{
    color: black;
  }

  .text-normal {
    font-weight: 400;
  }

  .float-right {
    float: right;
  }

  .capitalize {
    text-transform: capitalize;
  }
  .pb-1 {
    padding-bottom: 1rem;
  }
}